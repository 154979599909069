import { Card, CardActionArea, CardContent, Box, Stack, Typography, Button, CardActions, CardHeader, CardMedia, useMediaQuery, useTheme  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { joinSx, formatDuration, formatDifficulty } from "../tools";

interface SolutionBoxCardProps {
    name: string,
    cover: string,
    itemCount: number,
    url: string,
    textColor?: '#000000' | string,
    desc?: string,
}

export default function SolutionBoxCard(props: SolutionBoxCardProps) {
    const { name, cover, itemCount, url, textColor } = props
    const navigate = useNavigate();

    function navigateSolutionBoxPage() {
        navigate(url);
    }

    return <Card
        sx={{
            maxWidth: 400,
            position: "relative",
            backgroundImage: `url('${cover}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        }}
        onClick={navigateSolutionBoxPage}>
        <CardActionArea sx={{ height: '100%' }}>
            <CardContent sx={{ p: 2 }}>
                <Box sx={{ p: 1.5, gap: 1.5, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: 'space-between' }}>
                    <Stack>
                        <Typography variant="h5" sx={{ "&:hover": { textDecoration: "underline" }, color: `${textColor}` }}>{name}</Typography>
                        <Typography variant="body2" sx={{ color: `${textColor}` }}>{itemCount} items</Typography>
                    </Stack>
                    <Typography variant="body1" sx={{ "&:hover": { textDecoration: "underline" }, color: `${textColor}` }}>+21 more</Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>
}

export function SolutionBoxcatalogCard(props: SolutionBoxCardProps) {
    const { name, cover, itemCount, url, textColor, desc } = props
    const navigate = useNavigate();
    const theme = useTheme();    
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return <Card sx={joinSx({ maxWidth: isMobile? '100%' : 300, "&:hover": { " .btnMore": { visibility: "visible" } }, })}>
        <CardMedia
            component="img"
            sx={{ width: "100%", height: "auto" }}
            image={cover }
        />
        <CardHeader
            sx={{ mb: 0 }}
            titleTypographyProps={{ fontSize: 16, width: 198, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}
            subheaderTypographyProps={{ fontSize: 14 }}
            title={name}
            subheader={'by Waziup'}
        />
        {
           
                <>
                    <CardContent sx={{ pt: 'inherit' }}>
                        <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                            {desc}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ p: 2, pt: 'inherit' }}>                        
                        <Button size="small" onClick={() => navigate(url)}>Learn More</Button>
                    </CardActions>
                </>
        }
    </Card>
}
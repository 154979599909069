import { Avatar, Box, Breadcrumbs, Card, CardActionArea, CardContent, CardHeader, CardMedia, 
    Chip, CircularProgress, Tooltip, Typography, Link } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Solution, SolutionsResponse, getSolutions } from '../../api'
import ErrorView from '../ErrorView'
import Page, { PageContent } from '../Page'
import { lineClamp } from '../tools'
import { useSession } from '../../ident'
import { time } from 'console'

export default function SolutionsPage() {
    const navigate = useNavigate();

    const [page, setPage] = useState<SolutionsResponse | null>(null);
    const [err, setErr] = useState<any>(null);
    const [showBreadcrumbs, setShowBreadcrumbs] = useState(true);

    const sess = useSession();
    useEffect(() => {
        getSolutions({}).then(setPage, setErr);
    }, [sess]);

    let content: JSX.Element;
    if (err) content = <ErrorView err={err} />;
    else if (page === null) content = <CircularProgress />;
    else {
        content = <>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                {page.solutions.length} solutions
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                {page.solutions.map((solution) => <SolutionCard key={solution.id} solution={solution} />)}
            </Box>
        </>
    }

    return <Page title='Solutions'>
        <PageContent>{content}</PageContent>
    </Page>
}


interface SolutionCardProps {
    solution: Solution;
}

export function SolutionCard(props: SolutionCardProps) {
    const { solution } = props;
    const navigate = useNavigate();

    return (
        <Card sx={{ maxWidth: ["unset", 300], width: ["100%", "unset"] }} onClick={() => navigate(`/solutions/${solution.weakId!}`)}>
            <CardActionArea>
                <CardHeader
                    title={solution.title}
                    titleTypographyProps={{ fontSize: '1rem' }}
                    subheader={`by ${solution.orgName}`}
                />
                <CardMedia
                    component="img"
                    height={200}
                    image={solution.coverUrl!}
                    alt={solution.title}
                />
                <CardContent sx={{ marginTop: -4.7 }}>
                    <Box sx={{mb: 1, gap: 0, whiteSpace: "nowrap", overflow: "hidden" }}>

                        {solution.resources.map((r, i) => {
                            function handleClick() {
                                navigate(`/resources/${r.resource}`);
                            }
                            function handleMouseDown(e: React.MouseEvent) {
                                navigate(`/resources/${r.resource}`);
                                e.preventDefault();
                            }
                            if (i === 0) {
                                return <Box key={r.id} sx={{ zIndex: solution.resources.length, position: "relative", display: "inline-block", verticalAlign: "top" }}>
                                    <Chip
                                        avatar={<Avatar src={r.iconUrl!} />}
                                        label={r.title}
                                        sx={{ bgcolor: "background.paper", cursor: "pointer", "&:hover": { bgcolor: "#eeeeee !important" } }}
                                        onClick={handleClick}
                                        onMouseDown={handleMouseDown}
                                        variant="outlined"
                                    />

                                </Box>
                            }

                            return <Box key={r.id} sx={{ zIndex: solution.resources.length-i, position: "relative", display: "inline-block", verticalAlign: "top" }}>
                                <Tooltip title={r.title}>
                                    <Box

                                        sx={{ height: 32, fontSize: 0.75, boxSizing: "border-box", borderRadius: "16px", background: "white", border: "1px solid #bdbdbd", ml: "-6px", bgcolor: "background.paper", cursor: "pointer", "&:hover": { bgcolor: "#eeeeee !important" } }}
                                        onClick={handleClick}
                                        onMouseDown={handleMouseDown}
                                    >
                                        <Avatar src={r.iconUrl!} sx={{ width: 30, height: 30 }} />
                                    </Box>
                                </Tooltip>
                            </Box>
                        })}


                        {/* <Chip
                            avatar={<Avatar src={avatar1(type)} />}
                            label={featured}
                            sx={{ backgroundColor: "#ffffff", boxShadow: 1 }}
                            variant="outlined"
                        />
                        <Chip
                            avatar={<Avatar src={avatar2(type)} />}
                            label={type === 'theft' ? 'teensy' : "wazidev"}
                            sx={{ backgroundColor: "#ffffff", boxShadow: 1 }}
                            variant="outlined"
                        /> */}
                    </Box>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ...lineClamp(2) }}
                    >
                        {solution.desc}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

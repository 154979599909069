import { Add, AddOutlined, ArrowDropDown, ArrowForward, BlockOutlined, Check, CheckOutlined, CloseOutlined, DeleteOutlined } from "@mui/icons-material";
import { Box, Checkbox, Chip, FormControl, InputBase, Link, ListItemText, MenuItem, Select, Skeleton, TablePagination, Tooltip, Typography } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useMemo, useState } from "react";
import { addOrganizationMembers, addOrgMembers, findOrgMembers, listOrganizationMembers, ListOrganizationMembersResponse, Organization, OrganizationId, OrganizationMember, OrgMembersList, removeOrganizationMembers, RemoveOrganizationMembersRequest, RemoveOrganizationMembersResponse, updateOrganizationMembers, UsersQuery } from "../../api";
import { useSession } from "../../ident";
import DataGrid, { DataGridPlaceholder } from "../DataGrid";
import { UserAvatar } from "../users/UsersTable";
import TimeAgo from "../TimeAgo";
import { useForceUpdate } from "../../tools";
import DialogSelectUsers from "../users/DlgSelectUsers";
import Selection, { useIsSelected, useSelectionCount } from "../../Selection";
import { MsgBox } from "@halliday/mui-msgbox";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(
    sub: number,
    user: string,
    email: string,
    courses: string,
    tests: string,
    roles: string[],
) {
    return { sub, user, email, courses, tests, roles };
}

const rows = [
    createData(248289761001, 'Agnes Arnold', 'agnes.arnold@gmail.com', '4 / 100%', '1 / 0 (0%)', ["Trainer"]),
    createData(248289761002, 'Gesine Weber', 'gesine.weber@gmail.com', '2 / 80%', '2 / 1 (50%)', ["Trainer", "Editor"]),
    createData(248289761003, 'Nadine Winter', 'nadine.winter@gmail.com', '8 / 45%', '8 / 7 (87.5%)', [""]),
    createData(248289761004, 'Cäcilie Lang', 'cäcilie.lang@gmail.com', '3 / 27%', '0 / 0 (0%)', [""]),
    createData(248289761005, 'Stefan Schröder', 'stefan.schröder@gmail.com', '0 / 0%', '12 / 8 (75%)', ["Admin"]),
    createData(248289761006, 'Hilde Schumacher', 'hilde.schumacher@gmail.com', '8 / 60%', '8 / 6 (75%)', ["Editor"]),
];

export interface MembersPageProps {
    org: Organization
}

export default function MembersPage(props: MembersPageProps) {
    const { org } = props;

    const [dlgAddMembersOpen, setDlgAddMembersOpen] = useState(false);
    const [x, setX] = useState(0);

    function askInviteMembers() {
        setDlgAddMembersOpen(true);
    }
    function closeDlgAddMembers() {
        setDlgAddMembersOpen(false);
    }

    async function handleUsersSelect(users: UsersQuery) {
        await addOrganizationMembers({ id: org.id, users });
        setDlgAddMembersOpen(false);
        setX(x + 1);
    }

    const sess = useSession();

    const [pageSize, setPageSize] = useState(10);

    const [pages, setPages] = useState<ListOrganizationMembersResponse[]>([]);
    const [n, setN] = useState(0);
    const [err, setErr] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        listOrganizationMembers({ org: org.id, pageSize })
            .then(page => {
                setPages([page]);
                setErr(null);
            }, err => {
                setErr(err);
            }).finally(() => {
                setLoading(false);
            });
    }, [sess, org, x]);

    const selection = useMemo(() => new Selection<OrganizationMember>(), [sess, org, x]);
    const forceUpdate = useForceUpdate();
    selection.on("change", forceUpdate);

    const page = pages[n];

    function onPageSizeChange(ev: {}, pageSize: number) {
        setPageSize(pageSize);
    }

    async function removeMembers() {
        let resp: RemoveOrganizationMembersResponse;
        try {
            resp = await removeOrganizationMembers({
                org: org.id,
                all: selection.isAll,
                ids: Array.from(selection.items).map(m => m.user.id),
            });
        } catch (err) {
            reportError(err);
        }
        setX(x + 1);
    }

    function askRemoveMembers() {
        MsgBox({
            title: "Remove Members",
            text: `Are you sure you want to remove ${numSelected} members?`,
            handleOk: removeMembers,
            handleCancel: () => { },
        });
    }

    if (!page) return null;
    const { members, numMembers } = page;

    const numSelected = selection.isAll ? numMembers - selection.size : selection.size;

    return <Box>
        <OrganizationMembersTable org={org.id} members={members} selection={selection} numMembers={numMembers} />
        <TablePagination
            component="div"
            showFirstButton
            onRowsPerPageChange={(ev) => onPageSizeChange(ev, parseInt(ev.target.value))}
            count={numMembers}
            rowsPerPage={pageSize}
            page={n}
            onPageChange={(ev, page) => { setN(page); }}
        />
        <Box>
            <Button startIcon={<AddOutlined />} onClick={askInviteMembers} sx={{ mr: 2 }}>Invite Members</Button>
            <Button startIcon={<DeleteOutlined />} disabled={numSelected === 0} onClick={askRemoveMembers}>Remove Members ({numSelected})</Button>
        </Box>
        <DialogSelectUsers open={dlgAddMembersOpen} onClose={closeDlgAddMembers} onUsersSelect={handleUsersSelect} />
    </Box>;
}

type Role = {
    name: string,
    label: string,
}

const programRoles: Role[] = [
    { name: "org-admin", label: "Organization Admin" },
    // { name: "org-manager", label: "Organization Manager" },
];

type OrganizationMembersTableProps = {
    org: OrganizationId,
    members: OrganizationMember[],
    numMembers: number,
    selection: Selection<OrganizationMember>,
}

function OrganizationMembersTable(props: OrganizationMembersTableProps) {
    const { org, members, numMembers, selection } = props;

    const sess = useSession();

    function acceptMemberJoinRequest(member: OrganizationMember) {
        // onMembersUpdates({}, { ids: [member.user.id] }, { added: true });
    }

    function rejectMemberJoinRequest(member: OrganizationMember) {
        // onMembersRemove({}, { ids: [member.user.id] });
    }

    const forceUpdate = useForceUpdate();

    function handleRolesChange(member: OrganizationMember, roles: string[] | string) {
        const r = Array.isArray(roles) ? roles : [roles];
        updateOrganizationMembers({ query: { org: org, ids: [member.user.id] }, update: { roles: r } });
        // onMembersUpdates({}, { ids: [member.user.id] }, { roles: r });
        member.roles = r;
        // user.roles.length = 0;
        // user.roles.push(...r)
        forceUpdate();
    }



    // if (!page) return null;

    // const { members, numMembers } = page;

    return <>
        <DataGrid<OrganizationMember>
            items={members}
            numItemsTotal={numMembers}
            selection={selection}
            // loader={loader}
            // pageSize={loader.pageSize}
            // onPageChange={onPageChange}
            // onPageSizeChange={onPageSizeChange}
            sx={{ ">thead,>tbody": { ">tr": { borderBottom: 0, borderColor: "divider", ">td,>th": {}, ">th": {} } }, ">tbody": { bgcolor: "grey.100", borderTopLeftRadius: 2, borderTopRightRadius: 2, ">tr": { borderBottom: 1, borderColor: "divider" } } }}
            itemKey={member => member.user.id}
            columns={[{
                key: "avatar",
                head: "",
                renderData: (member) => <UserAvatar user={member.user} />,
                props: { sx: { maxWidth: "56px", minWidth: "56px", pl: 3 } },
                skeleton: <Skeleton variant="circular" width={40} height={40} />
            }, {
                key: "username",
                head: "Username",
                renderData: ({ user }) => {
                    return <Box sx={{ display: "flex", alignItems: "center" }}>
                        {user.username ? (
                            <Tooltip title={user.usernameVerified ? "Username verified" : "Username unverified"}>
                                <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }}>
                                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit" }} style={{ fontStyle: user.usernameVerified ? "normal" : "italic" }}>
                                        {user.username}
                                    </Box>
                                    {user.usernameVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
                                </Box>
                            </Tooltip>
                        ) : "-"}
                        {sess?.sub === user.id && <Chip sx={{ ml: 1 }} label="You" />}
                        {user.suspended && <Tooltip title="Suspended"><BlockOutlined fontSize="small" color="error" sx={{ ml: 1, verticalAlign: "middle" }} /></Tooltip>}
                    </Box>
                },
                props: { sx: { flex: "1 10em" } },
                skeleton: <Skeleton variant="text" />
            }, {
                key: "email",
                head: "Email",
                renderData: ({ user }) => (
                    user.email ? (
                        <Tooltip title={user.emailVerified ? "Email verified" : "Email unverified"}>
                            <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }} >
                                <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit" }} style={{ fontStyle: user.emailVerified ? "normal" : "italic" }}>
                                    {user.email}
                                </Box>
                                {user.emailVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
                            </Box>
                        </Tooltip>
                    ) : "-"
                ),
                props: { sx: { flex: "2 15em" } },
                skeleton: <Skeleton variant="text" />
            }, {
                key: "role",
                head: "Role",
                renderData: (member) => {
                    const { user, joined, added, addedAt, roles } = member;
                    if (!added) {
                        return <Box sx={{ height: 36, "&:hover .fly": { bgcolor: "background.paper", boxShadow: 2, color: "text.secondary", pointerEvents: "auto" } }}>
                            <Box className="fly" sx={{ p: 1, borderRadius: 1, mx: -1, mt: -4, mb: -4, display: "inline-block", color: "transparent", pointerEvents: "none", transition: theme => theme.transitions.create(["box-shadow", "color", "background-color"]) }}>
                                <Typography variant="body2" sx={{ height: 24, mb: "2px", color: "inherit" }}>Join Request</Typography>
                                <Box sx={{ pointerEvents: "auto" }}>
                                    <Button variant="contained" color="success" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} disableElevation onClick={() => acceptMemberJoinRequest(member)}><CheckOutlined /></Button>
                                    <Button variant="contained" color="error" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} disableElevation onClick={() => rejectMemberJoinRequest(member)}><CloseOutlined /></Button>
                                </Box>
                            </Box>
                        </Box>
                    }
                    if (!joined) {
                        return <Box>
                            <Typography variant="body2" color="text.disabled">Invitation pending&hellip;</Typography>
                            {/* {userRoles.includes("admin") && <Button variant="text" size="small" sx={{ mt: 1 }} onClick={() => rejectMemberJoinRequest(member)}>Accept Invitation</Button>} */}
                        </Box>
                    }
                    return <Box onPointerDown={ev => ev.stopPropagation()}>
                        <FormControl sx={{ m: 1, width: 200 }}>
                            <Select
                                multiple
                                value={roles ?? []}
                                onChange={ev => handleRolesChange(member, ev.target.value)}
                                input={<InputBase />}
                                placeholder="No Roles"
                                displayEmpty
                                renderValue={roles => roles.map(r => programRoles.find(d => d.name === r)?.label ?? r).join(', ') || <Typography variant="body2" color="text.disabled">No Roles</Typography>}
                            >
                                {programRoles.map(({ name, label }) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={roles?.includes(name) ?? false} />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                },
                props: { sx: { flex: "2 15em" } },
                skeleton: <Skeleton variant="text" />
            }, {
                key: "addedAt",
                head: "Added At",
                renderData: ({ addedAt }) => addedAt ? <TimeAgo time={addedAt} /> : "-",
                props: { sx: { flex: "1 10em" } },
                skeleton: <Skeleton variant="text" />
            }]}
            // tableBodyProps={{sx: {}}}
            // tableHeadProps={tableHeadProps}
            renderPlaceholder={() => (
                <DataGridPlaceholder>
                    <Typography variant="body2" color="text.disabled">No users found</Typography>
                </DataGridPlaceholder>
            )}
        />
    </>;
}
import { Alert, AlertProps, AlertTitle } from "@mui/material";
import { snake2title } from "../tools";
import { useEffect } from "react";

export interface ErrorViewProps extends AlertProps {
    err?: any
}

export default function ErrorView(props: ErrorViewProps) {
    const { err, ...alertProps } = props;

    useEffect(() => { if (err) console.error(err); }, [err]);

    if (!err) return null;
    let title = errorTitle(err);
    let message = errroMessage(err);
    if (title && !message) {
        message = title;
        title = undefined;
    }


    return <Alert severity="error" {...alertProps}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {message}
    </Alert>
}

function errorTitle(err: any): string | undefined {
    if (typeof err === "object") {
        if ("title" in err) return snake2title(`${err.title}`);
        if ("name" in err) return snake2title(`${err.name}`);
    }
}

function errroMessage(err: any, withCode = true): string {
    if (typeof err === "string") return err;
    if (typeof err === "object") {
        if (withCode && "code" in err) return `Code ${err.code}: ${errroMessage(err, false)}`;
        if ("error" in err) return `${err.error}`;
        if ("message" in err) return `${err.message}`;
        if ("msg" in err) return `${err.msg}`;
        if ("msg" in err) return `${err.msg}`;
        if ("title" in err) return `${err.title}`;
        if ("name" in err) return `${err.name}`;
        return "";
    }
    return `${err}`;
}
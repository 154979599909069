import { MsgBoxContainer } from '@halliday/mui-msgbox';
import { ToastContainer } from '@halliday/mui-toast';
import { L, useLanguage } from '@halliday/react-i18n';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MyProgramsPage from './programs/MyProgramsPage';
import ProgramsPage from './programs/ProgramsPage';
import ProgramInfoPage from './programs/ProgramInfoPage';
import ProgramEditorPage from './programs/editor/ProgramEditorPage';

import MyCoursesPage from './courses/MyCoursesPage';
import CoursesPage from './courses/CoursesPage';
import CoursePage from './courses/CoursePage';
import CourseEditorPage from './courses/editor/CourseEditorPage';
import TopicEditorPage from './courses/editor/old/TopicEditorPage';

import SolutionsPage from './solutions/SolutionsPage';
import SolutionPage from './solutions/SolutionPage';
import SolutionEditorPage from './solutions/SolutionEditorPage';

import MyLabPage from './resources/MyLabPage';
import ResourcesPage from './resources/ResourcesPage';
import ResourcePage from './resources/ResourcePage';
import ResourceEditorPage from './resources/ResourceEditorPage';


import OrganizationPage from './orgs/OrganizationPage';
import OrganizationsPage from './orgs/OrganizationsPages';

import UsersPage from './users/UsersPage';

import MaintenancePage from './settings/MaintenancePage';
import SettingsPage from './settings/SettingsPage';

import { PageParent } from './Page';
import AgriboxPage from './pages/AgriboxPage';
import CityboxPage from './pages/CityboxPage';
import NotFoundPage from './pages/NotFoundPage';
import FilesPage from './pages/FilesPage';
import TestPage from './pages/TestPage';
import TextPage from './pages/TextPage';
import IdentityTools from './ident/Identity';
import WaziLabHomePage from './WaziLabHomePage';
import { Box } from '@mui/material';
import MVPDIYKitPage from './boxes/MVPDIYKitPage';
import ApplicationBusinessBoxPage from './boxes/ApplicationBusinessBox';
import TrainingKitBoxPage from './boxes/TrainingKitBox';
import SolutionBoxPage from './resources/SolutionBoxPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#394E69',  //dark dark grey
    },
    secondary: {
      main: '#f35e19', //waziup orange
    },
  },
  typography: {
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0em",
      fontWeight: 400,
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "1.5rem",
      lineHeight: 1.333,
      letterSpacing: "0em",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1rem",
      fontWeight: "bold"
    },
    h4: {
      fontSize: "1rem",
      fontWeight: "bold"
    },
    h5: {
      fontSize: "1.1rem",
      fontWeight: "bold"
    }
  }
});

function App() {

  const l = useLanguage();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <IdentityTools />
          <PageParent>
            <Routes>
              <Route path="/" element={<WaziLabHomePage />} />

              <Route path="/my-programs" element={<MyProgramsPage />} />
              <Route path="/programs" element={<ProgramsPage />} />
              <Route path="/programs/:id" element={<ProgramInfoPage variant="info" />} />
              <Route path="/programs/:id/units/:unitId" element={<ProgramInfoPage variant="units" />} />
              <Route path="/programs/:id/events" element={<ProgramInfoPage variant="events" />} />
              <Route path="/programs/:id/progress" element={<ProgramInfoPage variant="progress" />} />
              <Route path="/programs/:id/editor" element={<ProgramEditorPage />} />

              <Route path="/solutions" element={<SolutionsPage />} />
              <Route path="/solutions/:id" element={<SolutionPage />} />
              <Route path="/solutions/:org/:short" element={<SolutionPage />} />
              <Route path="/solutions/:id/editor" element={<SolutionEditorPage />} />

              <Route path="/my-courses" element={<MyCoursesPage />} />
              <Route path="/courses" element={<CoursesPage />} />
              <Route path="/courses/:id" element={<CoursePage />} />
              <Route path="/courses/:org/:module/:course" element={<CoursePage />} />
              <Route path="/courses/:id/editor" element={<CourseEditorPage />} />
              <Route path="/topics/:id/editor" element={<TopicEditorPage />} />

              <Route path="/my-lab" element={<MyLabPage />} />
              <Route path="/resources" element={<ResourcesPage />} />
              <Route path="/resources/:id" element={<ResourcePage />} />
              <Route path="/resources/:org/:short" element={<ResourcePage />} />
              <Route path="/resources/:id/docs/:docId" element={<ResourcePage />} />
              <Route path="/resources/:org/:short/docs/:docId" element={<ResourcePage />} />
              <Route path="/resources/:org/:short/:docName" element={<ResourcePage />} />
              <Route path="/resources/:id/editor" element={<ResourceEditorPage />} />

              <Route path="/orgs/" element={<OrganizationsPage />} />
              <Route path="/orgs/:id" element={<OrganizationPage />} />

              <Route path="/users" element={<UsersPage />} />

              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/settings/maintenance/" element={<MaintenancePage />} />

              <Route path="/businessbox/agribox" element={<AgriboxPage />} />
              <Route path="/businessbox/citybox" element={<CityboxPage />} />
              <Route path="/businessbox/mvp-diy-kit" element={<MVPDIYKitPage />} />
              <Route path="/businessbox/application-business-box" element={<ApplicationBusinessBoxPage />} />
              <Route path="/businessbox/training-kit" element={<TrainingKitBoxPage />} />
              <Route path="/businessbox" element={<SolutionBoxPage />} />


              <Route path="/files" element={<FilesPage />} />

              <Route path="/test" element={<TestPage />} />


              <Route path="/terms-of-service" element={<TextPage title={L("terms_of_service")} url={"/i18n/" + l + "/" + "toc.html"} />} />
              <Route path="/privacy" element={<TextPage title={L("privacy_policy")} url={"/i18n/" + l + "/" + "privacy.html"} />} />
              <Route path="/imprint" element={<TextPage title={L("imprint")} url={"/i18n/" + l + "/" + "imprint.html"} />} />
              <Route path="/funding-acknowledgement" element={<TextPage title="Funding Acknowledgement" url={"/i18n/" + l + "/" + "funding-acknowledgement.html"} />} />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </PageParent>
      </BrowserRouter>
      <ToastContainer />
      <MsgBoxContainer />
    </ThemeProvider>
  );
}

export default App;

import { QuizOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { useState } from "react";
import { AnswerQuizResponse, answerQuiz } from "../../api";
import firstOfSet from "../../tools";
import { useCourse } from "./CoursePage";

export type AnswerProps = {
    right?: boolean,
    feedback: string,
    text: string,
}

export type QuizType = "single-choice" | "multiple-choice" | "true-false" | string;

export type QuizProps = {
    id: string,
    type: QuizType,
    title: string,
    answers: AnswerProps[],
}

export function QuizViewer(props: QuizProps) {
    const { id, type, title, answers } = props;
    const course = useCourse()!;

    const [checkedAnswers, setCheckedAnswers] = useState<Set<number>>(new Set);
    function toggleAnswer(idx: number) {
        if(answer?.right) return;
        
        setCheckedAnswers(answers => {

            if (type === "multiple-choice") {
                if (answers.has(idx)) answers.delete(idx);
                else answers.add(idx);
                return new Set(answers);
            }

            if (type === "single-choice" || !type) {
                if (answers.has(idx)) return new Set;
                return new Set([idx]);
            }

            return answers;
        });
    }



    const [answer, setAnswer] = useState<AnswerQuizResponse | null>(null);
    const [answerIdx, setAnswerIdx] = useState(0);

    function submitAnswer() {
        setAnswer(null);
        if (type === "single-choice" || !type) {
            const idx = firstOfSet(checkedAnswers)!;
            setAnswerIdx(idx);
            setAnswer(null);
            answerQuiz({ quiz: id, course: course.id, answer: idx }).then(setAnswer, reportError)
        }
    }

    return <Box className="quiz" sx={{ bgcolor: "grey.100", px: 2, py: 3, borderRadius: 2, position: "relative" }}>
        <QuizOutlined sx={{ position: "absolute", top: "0", right: "0", height: "100%", padding: "2%", width: "auto", pointerEvents: "none", boxSizing: "border-box", opacity: ".03" }} />
        <Typography variant="h3" sx={{ fontSize: "1.5rem", display: "flex", alignItems: "center" }}>
            <QuizOutlined fontSize="medium" sx={{ mr: 2 }} />
            {title}
        </Typography>
        <List>
            {answers.map(({ right, text }, idx) => {
                return <ListItem key={idx} sx={{ mb: "0 !important", px: 2 }} disablePadding>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={checkedAnswers.has(idx)}
                            tabIndex={-1}
                            onClick={() => toggleAnswer(idx)}
                        />
                    </ListItemIcon>
                    <ListItemText primary={text} secondary={answerIdx === idx ? answer?.feedback : null} secondaryTypographyProps={{sx: {color: (answerIdx === idx && answer) ? (answer.right ? "success.main" : "error.main") : null}}}/>
                </ListItem>;
            })}
        </List>
        <Button variant="outlined" sx={{ ml: 2 }} disabled={checkedAnswers.size === 0 || answer?.right} onClick={submitAnswer}>Submit</Button>
    </Box>
}


export function quizPropsFromElement(elm: Element): QuizProps {
    return {
        type: elm.getAttribute("type") ?? "",
        title: elm.getAttribute("title") ?? "",
        id: elm.id,
        answers: Array.from(elm.children).map(answerPropsFromElement),
    }
}

function answerPropsFromElement(elm: Element): AnswerProps {
    return {
        right: elm.hasAttribute("right"),
        text: elm.innerHTML,
        feedback: elm.getAttribute("feedback") ?? "",
    }
}
export function quizPropsToHTML(props: QuizProps) {
    return `<quiz id="${props.id}" type="${props.type}" title="${escapeHTML(props.title)}">${props.answers.map(answerPropsToHTML).join("\n")}</quiz>`;
}

function answerPropsToHTML(props: AnswerProps) {
    return `<answer ${props.right ? "right " : ""}feedback="${escapeHTML(props.feedback)}">${escapeHTML(props.text)}</answer>`;
}

function escapeHTML(str: string) {
    return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, '&quot;');
}

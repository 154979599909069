import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { NewUser, User, addUsers } from "../../api";
import DialogCloseButton from "../DialogCloseButton";
import ErrorView from "../ErrorView";
import PasswordField from "./PasswordField";

export interface DlgAddSingleUserProps extends DialogProps {
    onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown" | "closedClicked" | "cancelClicked") => void),
    onUserAdd?: (user: User) => void,
}

const emailRegExp = /\S+@\S+\.\S+/;

export function DlgAddSingleUser(props: DlgAddSingleUserProps) {
    const {onUserAdd, ...dialogProps} = props;

    function handleCancel(ev: {}) {
        props.onClose?.(ev, "cancelClicked");
    }

    function handleClose(ev: {}) {
        props.onClose?.(ev, "closedClicked");
    }
    
    const [showErrors, setShowErrors] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    const [err, setErr] = useState<any>(null);
    
    const emailErr = !emailRegExp.test(email);
    let emailHelperText: string | undefined;
    if (showErrors) {
        if (!email.trim()) emailHelperText = "Email address can not be empty.";
        else if (emailErr) emailHelperText = "Invalid email address format."
    }
    
    const usernameErr = username.trim() === "";
    let usernameHelperText: string | undefined;
    if (showErrors) {
        if (usernameErr) usernameHelperText = "Username can not be empty."
    }

    const hasErr = emailErr || usernameErr;
    if (!hasErr && showErrors) setShowErrors(false);

    const [loading, setLoading] = useState(false);

    function handleSubmit() {
        if (hasErr) {
            setShowErrors(true);
            return
        }
        setLoading(true);
        setErr(null);
        const req: NewUser = { email, password, username };
        addUsers({users: [ req ]}).then(resp => {
            const user: User = {
                id: resp.ids[0],
                createdAt: new Date(),
                ...req,
                suspended: false,
                updatedAt: new Date(),
                roles: [],
            };
            onUserAdd?.(user);
        }, (err) => {
            setErr(err);
            setLoading(false);
        });
        // const req: RegisterUserRequest = { email, password, preferred_username: username };
        // api.registerUser(req).then((resp) => {
        //     const user: User = {
        //         created_at: new Date(),
        //         ...req,
        //         suspended: false,
        //         ...resp,
        //         updated_at: new Date(),
        //     };
        //     onUserAdd?.(user);
        // }, (err) => {
        //     setErr(err);
        //     setLoading(false);
        // });
    }

    return <Dialog {...dialogProps}>
        <DialogTitle>New User</DialogTitle>
        <DialogCloseButton onClick={handleClose} />
        <DialogContent>
          <DialogContentText sx={{mb: 2}}>
            Create a new user by filling the fields below.
          </DialogContentText>
          <form id="new-user" onSubmit={preventDefault}>
            <TextField autoFocus id="email" label="Email Address" type="email" fullWidth variant="filled" autoComplete="off" sx={{mb: 2}} value={email} helperText={emailHelperText} error={showErrors && emailErr} onChange={ev => { setEmail(ev.target.value); }} disabled={loading} />
            <TextField id="name" label="Anzeigename" type="text" fullWidth variant="filled" autoComplete="off" sx={{mb: 4}} value={username} helperText={usernameHelperText} error={showErrors && usernameErr} onChange={ev => { setUsername(ev.target.value); }} disabled={loading}/>
            <PasswordField id="password" label="Password" fullWidth variant="filled" autoComplete="off" helperText="Optional." sx={{mb: 2}} value={password} onChange={ev => { setPassword(ev.target.value); }} disabled={loading}/>
          </form>
          {err && <ErrorView err={err} /> }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" disabled={loading}>Create</Button>
        </DialogActions>
      </Dialog>
}

function preventDefault(ev: React.SyntheticEvent) {
    ev.preventDefault();
}

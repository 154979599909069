import { useState } from "react";
import LoadingButton from "../LoadingButton";
import { importContents } from "../../api";
import { GitHub, Download } from "@mui/icons-material";
import { Card, Typography } from "@mui/material";
import Page from "../Page";
import { PageContent } from "../Page";

export default function MaintenancePage() {
  const [importing, setImporting] = useState(false);
  const [importLogs, setImportLogs] = useState("");

  function importC() {
    setImporting(true);
    setImportLogs("");
    importContents()
      .then(
        (resp) => {
          setImportLogs(resp.importLogs);
        },
        (err) => {
          console.error(err);
          alert(
            "Error: " +
              JSON.stringify(err) +
              "\n\nSee console for more details."
          );
        }
      )
      .finally(() => {
        setImporting(false);
      });
  }

  return (
    <Page>
      <PageContent>
        <div>
          <Card
            sx={{ minWidth: 275 }}
            style={{
              width: "50%"
            }}
            variant="outlined"
          >
            <div>
              <Typography
                variant="h2"
                component="div"
                style={{
                  textAlign: "center",
                  marginTop: "5%",
                }}
              >
                Import Contents
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingButton
                onClick={importC}
                variant="outlined"
                loading={importing}
                disabled={importing}
                endIcon={<Download />}
                style={{ margin: "5%", width: "50%" }}
              >
                Import
              </LoadingButton>
            </div>
          </Card>
        </div>
        <div>
          <pre>
            {importLogs}
          </pre>
        </div>
      </PageContent>
    </Page>
  );
}

import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Course, Program, ProgramMember, ProgramUnit, ProgramUnitProgress, Topic, courseProgress, findProgram, findProgramProgress, findProgramUnit, genericProgress, getTopics, listProgramMembers } from "../../../api";
import { useSession } from "../../../ident";
import DialogCloseButton from "../../DialogCloseButton";
import ErrorView from "../../ErrorView";
import { UserAvatar } from "../../users/UsersTable";


export type ParticipantsPageProps = {
    programId: Program["id"],
}

export default function ProgressPage(props: ParticipantsPageProps) {
    const { programId } = props;

    const navigate = useNavigate();

    const [program, setProgram] = useState<Program | null>(null);
    const [members, setMembers] = useState<ProgramMember[]>([]);
    const [progress, setProgress] = useState<ProgramUnitProgress[]>([])
    const [err, setErr] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const session = useSession();


    const [peakUnit, setPeakUnit] = useState<ProgramUnit | null>(null);
    const [peakMember, setPeakMember] = useState<ProgramMember | null>(null)
    const [peakOpen, setPeakOpen] = useState(false);
    const [peakLoading, setPeakLoading] = useState(true);
    const [peakErr, setPeakErr] = useState<any>(null);
    const [peakCourse, setPeakCourse] = useState<Course | null>(null);

    function showDetailProgress(member: ProgramMember, unit: ProgramUnit) {
        setPeakOpen(true);
        setPeakUnit(null);
        setPeakMember(member);
        setPeakErr(null);
        setPeakLoading(true);
        findProgramUnit({ program: programId, unit: unit.id, user: member.user.id })
            .then(resp => setPeakUnit(resp.unit))
            .catch(setPeakErr)
            .finally(() => setPeakLoading(false));
    }

    useEffect(() => {
        setLoading(true);
        setErr(null);
        Promise.all([
            listProgramMembers({ program: programId }).then(resp => setMembers(resp.members)),
            findProgram({ id: programId }).then(resp => setProgram(resp.program)),
            findProgramProgress({ program: programId }).then(resp => setProgress(resp.progress)),
        ]).catch(setErr).finally(() => setLoading(false));
    }, [programId, session]);

    let content: ReactNode;
    if (loading) {
        content = <Typography variant="body1">Loading...</Typography>;
    } else if (err) {
        content = <ErrorView err={err} />;
    } else {

        let peakContent: ReactNode;
        if (peakLoading) {
            peakContent = <Typography variant="body1">Loading...</Typography>;
        } else if (peakErr) {
            peakContent = <ErrorView err={peakErr} />;
        } else {
            peakContent = <>
                <Typography sx={{ pb: 2 }}>User: {peakMember!.user.username}, Unit: {peakUnit!.name}</Typography>
                <Typography variant="h4">Courses ({peakUnit!.courses?.length ?? 0})</Typography>
                <List>
                    {!peakUnit!.courses?.length && (
                        <ListItem>
                            <ListItemText primary="No courses in this unit" />
                        </ListItem>
                    )}
                    {peakUnit!.courses?.map(c => {

                        function openCourseDetails() {
                            setPeakCourse(peakCourse === c ? null : c);
                            // navigate(`/courses/${c.id}`);
                        }

                        const open = peakCourse === c;

                        return <Box component="li" sx={{ borderBottom: "1px solid", borderBottomColor: "divider" }}>
                            <ListItemButton component="div" key={c.id} onClick={openCourseDetails}>
                                <ListItemIcon sx={{ minWidth: 32 }}><ExpandMoreOutlined sx={{ transform: open ? "none" : "rotate(-90deg)" }} /></ListItemIcon>
                                <ListItemText primary={c.title} sx={{ mr: 4 }} />
                                <Typography variant="body2" color="text.secondary">{Math.round(courseProgress(c) * 100)}%</Typography>
                            </ListItemButton>
                            <CourseProgressDetails open={open} unit={peakUnit!} course={c} member={peakMember!} />
                        </Box>
                    })}
                </List>
            </>;
        }

        content = <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderRight: "1px solid", borderRightColor: "divider" }}></TableCell>
                            {program!.units!.map(unit => {

                                function navigateUnit() {
                                    navigate(`/programs/${programId}/units/${unit.id}`);
                                }

                                return <TableCell key={unit.id} sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={navigateUnit}>{unit.name}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {members.map((member) => {
                            const { user } = member;
                            return <TableRow
                                key={user.id}
                            >
                                <TableCell component="th" scope="row" sx={{ borderRight: "1px solid", borderRightColor: "divider" }}>
                                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                        <UserAvatar user={user} sx={{ width: 32, height: 32 }} />
                                        <span>{user.username}</span>
                                    </Box>
                                </TableCell>
                                {program!.units!.map(unit => {
                                    const { numCourses, numQuiz } = unit;
                                    const hasProgress = numCourses > 0 || numQuiz > 0;
                                    const p = progress.find(p => p.unit === unit.id && p.user === user.id);
                                    if (!hasProgress) {
                                        return <TableCell key={unit.id}>-</TableCell>;
                                    }
                                    const c = p ? genericProgress(unit.numTopics, p.numTopicsDone, unit.numQuiz, p.numQuizDone) : 0;
                                    return <TableCell key={unit.id} onClick={() => showDetailProgress(member, unit)} sx={{ "&:hover": { bgcolor: "action.hover" }, cursor: "pointer" }}>
                                        {Math.round(c * 100)}%
                                    </TableCell>
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={peakOpen}
                onClose={() => setPeakOpen(false)}
                aria-labelledby="progress-dialog-title"
                aria-describedby="progress-dialog-description"
                PaperProps={{ sx: { minWidth: 450, minHeight: 600 } }}
            >
                <DialogTitle id="progress-dialog-title" sx={{ pb: 0 }}>
                    Unit Progress
                </DialogTitle>
                <DialogCloseButton onClick={() => setPeakOpen(false)} />
                <DialogContent>
                    {peakContent}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPeakOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    }

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Typography variant="h5" sx={{ flexGrow: 1 }}>Progress</Typography>
                {/* <Button startIcon={<AddOutlined />} onClick={askAddMembers}>Add Members</Button> */}
            </Box>
            {content}

        </Box>
    )
}

interface CourseProgressDetailsProps {
    open: boolean;
    unit: ProgramUnit;
    course: Course;
    member: ProgramMember;
}

function CourseProgressDetails(props: CourseProgressDetailsProps) {
    const { open, unit, course, member } = props;
    const { user } = member;


    const [err, setErr] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState<Topic[]>([]);

    useEffect(() => {
        if(!open) return;
        setLoading(true);
        setTopics([]);
        setErr(null);
        getTopics({ course: course.id, user: user.id })
            .then(({ topics }) => { setTopics(topics) })
            .catch(setErr)
            .finally(() => setLoading(false));
    }, [open, user.id, course.id]);

    let content: ReactNode;
    if (loading) {
        content = <Typography variant="body1">Loading...</Typography>;
    } else if (err) {
        content = <ErrorView err={err} />;
    } else {
        content = <Box>
            <List>
                {topics.map(topic => (
                    <ListItem key={topic.id} dense>
                        <ListItemIcon>{topic.done ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}</ListItemIcon>
                        <ListItemText primary={topic.title} />
                    </ListItem>
                ))}
            </List>
        </Box>
    }

    const collapsed = !open || loading;

    return <Collapse in={!collapsed}>{content}</Collapse>;
}
import { useEffect } from "react";
import { File, OrganizationId, postFileRaw } from "./api";
import { hub } from "./Hub";


var input = document.createElement("input");
input.type = "file";
input.name = "file";
input.id = "file";
input.accept = "image/*, application/pdf";
input.multiple = true;
input.style.display = "none";

type UploadCallback = (files: File[]) => void;

export function askUpload(org: OrganizationId | null, cb: UploadCallback = () => { }, accept = "image/*, application/pdf", multiple = true) {
    document.body.append(input);
    input.multiple = multiple;
    input.accept = accept;
    input.oninput = handleFileInput.bind(null, org, cb);
    input.click();
}

function handleFileInput(org: OrganizationId | null, cb: UploadCallback, ev: Event) {
    const files = input.files;
    if (files) uploadFiles(org, cb, Array.from(files));
}

// type UploadListener = (req: NewFileRequest, resp: NewFileResponse) => void;
// const uploadListeners = new Set<UploadListener>();

// function emitUpload(req: NewFileRequest, resp: NewFileResponse) {
//     const listeners = Array.from(uploadListeners);
//     for(const listener of listeners) {
//         try {
//             listener(req, resp);
//         } catch(err) {
//             console.error("Calling UploadListener", listener, err);
//         }
//     }
// }

// export function useUploadListener(l: UploadListener, deps?: React.DependencyList) {
//     useEffect(() => {
//         uploadListeners.add(l);
//         return () => { uploadListeners.delete(l) };
//     }, deps);    
// }

async function uploadFiles(org: OrganizationId | null, cb: UploadCallback, files: globalThis.File[]) {
    var resp = new Array<File>();
    for (const file of files) {
        try {
            const f = await uploadFile(org, file);
            resp.push(f);
        } catch (err) {
            console.error(err);
        }
    }
    cb(resp);
}

async function uploadFile(org: OrganizationId | null, file: globalThis.File): Promise<File> {
    const resp = await postFileRaw({ org }, file);
    const now = new Date();
    const f: File = {
        org: org!,
        orgName: "",
        owner: null,
        // owner: api.sess?.userinfo?.sub ?? null, 
        createdAt: now,
        modifiedAt: now,
        name: file.name,
        ext: fileExt(file.name),
        size: file.size,
        related: null,
        ...resp,
    }
    hub.emit(".upload-file", f);
    return f;
    // if (file.type.startsWith("image/")) {
    //     return await uploadImage(api, file);
    // }
    // if (file.type === "application/pdf") {
    //     return await uploadPDF(api, file);
    // }
    // throw `Unsupported file type '${file.type}' for '${file.name}'.`
}

// async function uploadPDF(api: API, file: globalThis.File): Promise<File> {
//     throw "Uploading PDF files is not supported yet.";
// }

// function uploadImage(api: API, file: globalThis.File) {
//     return new Promise<File>(async (resolve, reject) => {
//         var img = document.createElement("img");
//         img.src = URL.createObjectURL(file);
//         img.onload = async () => {
//             const w = img.naturalWidth, h = img.naturalHeight;
//             var thumbnail: Blob | undefined;
//             if (hasThumbnail(w, h)) {
//                 thumbnail = await createThumbnail(img);
//             }
//             URL.revokeObjectURL(img.src);
//             const req: NewFileRequest = {
//                 width: w,
//                 height: h,
//                 file: file,
//                 thumbnail: thumbnail,
//             };
//             const resp = await api.postFile(req);
//             emitUpload(req, resp);
//             const now = new Date();
//             resolve({
//                 id: resp.id,
//                 name: file.name,
//                 ext: fileExt(file.name),
//                 width: w,
//                 height: h,
//                 size: file.size,
//                 related: null,
//                 owner: null,
//                 createdAt: now,
//                 modifiedAt: now,
//             });
//         }
//         img.onerror = () => {
//             URL.revokeObjectURL(img.src);
//             reject(`The file '${file.name}' failed to load as image in your browser.`);
//         }
//     })
// }

export function fileExt(name: string) {
    const i = name.lastIndexOf(".");
    return name.slice(i);
}

export function createThumbnail(img: HTMLImageElement): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
        const w = img.naturalWidth, h = img.naturalHeight;
        const [tw, th, r] = thumbnailSize(w, h);
        const canvas = document.createElement("canvas");
        canvas.width = tw;
        canvas.height = th;
        const ctx = canvas.getContext("2d")!;
        if (w > h) ctx.drawImage(img, w * (1 - r) / 2, 0, w * r, h, 0, 0, tw, th);
        else ctx.drawImage(img, 0, h * (1 - r) / 2, w, h * r, 0, 0, tw, th);
        canvas.toBlob((blob) => resolve(blob!), "image/png");
    })
}

export function hasThumbnail(w: number, h: number) {
    return (w > 420 || h > 180) && (w > 180 || h > 420);
}

export function thumbnailSize(w: number, h: number): [number, number, number] {
    if (!hasThumbnail(w, h)) {
        return [w, h, 0];
    }
    let r, tw, th: number;
    if (w > h) {
        const s = Math.min(180 / h, 1);
        r = Math.min(420 / (w * s), 1);
        th = h * s;
        tw = w * s * r;
    } else {
        const s = Math.min(180 / w, 1);
        r = Math.min(420 / (h * s), 1);
        tw = w * s;
        th = h * s * r;
    }
    return [tw, th, r];
}
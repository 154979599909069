import { Tooltip } from "@mui/material";
import { memo, useEffect, useState } from "react";

export interface TimeAgoProps {
    time: Date
}

export default function TimeAgo(props: TimeAgoProps) {
    return <Tooltip title={props.time.toLocaleString()}><span>{timeAgo(props.time)}</span></Tooltip>;
}

const ms = 1
const s = 1000*ms
const min = 60*s
const h = 60*min

export function timeAgo(date: Date) {
    const now = new Date();
    const diff = now.getTime()-date.getTime();
    if (diff < 0) return date.toLocaleString();
    if (diff < 30*s) return "just now";
    if (diff < 90*s) return "1 minute ago";
    if (diff < 60*min) return `${Math.round(diff/min)} minutes ago`;
    if (diff < 1.5*h) return `1 hour ago`;
    if (diff < 24*h) return `${Math.round(diff/h)} hours ago`;
    return date.toLocaleDateString();
}

var tickers = new Set<(now: Date) => void>();
let interval: ReturnType<typeof setInterval>;
function tick() {
    const now = new Date();
    for(const ticker of Array.from(tickers)) {
        try {
            ticker(now);
        } catch(err) {
            console.error("Ticker failed:", err);
        }
    }
}

function enableTickers() {
    interval = setInterval(tick, 1000);
}
function disableTickers() {
    clearInterval(interval);
}

export const TimeSince = memo(function TimeSince(props: TimeAgoProps) {
    const [now, setNow] = useState(new Date());
    useEffect(() => {
        tickers.add(setNow);
        if(tickers.size === 1) enableTickers();
        return () => {
            tickers.delete(setNow);
            if(tickers.size === 0) disableTickers(); 
        }
    });
    return <>{timeSince(props.time, now)}</>;
});

export function timeSince(time: Date, now: Date = new Date()) {
    const d = Math.round((now.getTime()-time.getTime())/1000);
    if (d < 60) return `0min ${d}s`;
    if (d < 3600) return `${Math.floor(d/60)}min ${d%60}s`;
    return `${Math.floor(d/3600)}h ${Math.floor((d%3600)/60)}min ${d%60}s`;
}

import { ArrowDropDownOutlined, ArrowDropUp, CloseOutlined, EditOutlined, FormatBoldOutlined, FormatSizeOutlined, ImageOutlined, LinkOutlined, QuizOutlined, SaveOutlined } from "@mui/icons-material";
import { Box, BoxProps, Button, ButtonProps, Checkbox, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputBase, InputLabel, Paper, Stack, Tab, Tabs, TextField, ThemeProvider, Toolbar, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { AtomicBlockUtils, ContentBlock, ContentState, EditorState, Modifier, RawDraftContentBlock, RawDraftEntity, RichUtils, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { Fragment, ReactNode, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import youtubeLogoSrc from "../../../../assets/images/youtube_logo.svg";
import { genRandomUUID, useForceUpdate } from "../../../../tools";
import Page, { TOC, TOCHeader, TOCList, TOCListItem } from "../../../Page";
import { QuizProps, quizPropsFromElement, quizPropsToHTML } from "../../Quiz";
import { addBlockAfter, id, insertAtomicBlockWithData } from "../../../tools";
import { YouTubePlayer } from "../../../YouTube";
import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import { joinSx } from "../../../tools";
import FilesList from "../../../FilesList";
import styled from "@emotion/styled";

interface TabPanelProps extends BoxProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...boxProps } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...boxProps}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

export default function TopicEditorPage() {
    const query = useQuery();
    const navigate = useNavigate();
    const theme = useTheme();

    const [tab, setTab] = useState(0);

    function handleTabChange(ev: {}, newTab: number) {
        setTab(newTab);
    }

    const a11yProps = (index: number) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });


    useLayoutEffect(() => {
        const box = ref.current! as HTMLElement;
        const fileListPanel = box.querySelector(".fileListPanel")! as HTMLElement;
        const scrollbarWidth = fileListPanel.offsetWidth - fileListPanel.clientWidth;
        box.style.minWidth = `${274 + scrollbarWidth}px`;
    }, []);

    const ref = useRef<HTMLDivElement>(null);


    // The main page content:
    const content = <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row" }} id="TopicEditor">
        <TopicEditor sx={{ flexGrow: 1 }} />
        <Box sx={{ width: 0, minWidth: 300, borderTop: `1px solid ${theme.palette.divider}`, borderLeft: `1px solid ${theme.palette.divider}`, display: "flex", flexDirection: "column" }} ref={ref}>
            <Stack direction="row" alignItems="center" sx={{ p: 2 }}>
                <Typography variant="body2" color="text.secondary" sx={{ flexGrow: 1 }}>10 files</Typography>
                <Button>Upload</Button>
            </Stack>
            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example" sx={{ px: 1 }}>
                <Tab label="Topic" {...a11yProps(0)} sx={{ minWidth: "auto", p: 1, width: "33%" }} />
                <Tab label="Course" {...a11yProps(1)} sx={{ minWidth: "auto", p: 1, width: "33%" }} />
                <Tab label="Org." {...a11yProps(2)} sx={{ minWidth: "auto", p: 1, width: "33%" }} />
            </Tabs>
            <TabPanel value={tab} index={0} sx={{ flexGrow: 1, height: 0, overflowY: "scroll" }} className="fileListPanel">
                <FilesList />
            </TabPanel>
        </Box>
    </Box>

    // The table-of-contents (TOC) will be shown on a left bar on desktop
    // or on a second AppBar on mobile.
    const toc = <TOC>
        <TOCHeader><Typography variant="body2">3 Items</Typography></TOCHeader>
        <TOCList>
            <TOCListItem
                active
                primary="Getting started with Arduino"
                secondary="Introduction to the most famous Arduino controller of all time."
                footer="6 topics, 21 files, 2 quiz" />
            <TOCListItem
                primary="Fundamentals of IoT"
                secondary="Beginners Course."
                footer="2 topics, 1 quiz" />
            <TOCListItem
                primary="Getting started with Waziup Technologies"
                secondary="Part 2: Hardware Gateway"
                footer="4 topics, 3 images" />
        </TOCList>
    </TOC>;



    // Now returns the whole page component:
    return <Page
        title="Test Page 123"

        // Below opener, primary and secondary will only show on mobile
        toc={toc}
        tocOpener="2."
        tocPrimary="Introduction to Arduino"
        tocSecondary="This is some very cool stuff. Very cool, I promise!"

        // Will show a navigate-back arrow on desktop
        onNavigateBack={() => navigate(-1)}
    >
        {content}
    </Page>;
}

const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;

// const sampleMarkup = `
//     <h1>Welcome!</h1>
//     <p>This is the first lecture. <strong>Feel free to edit everything</strong></p>
//     <youtube>M7lc1UVf-VE</youtube>
//     <p>${loremIpsum}</p>
//     <quiz>{"question": "What time is it?"}</quiz>
//     <p>${loremIpsum}</p>
//     <p>${loremIpsum}</p>`;

const sampleMarkup = `
    <h1>Welcome!</h1>
    <quiz type="single-choice" title="So what is the current topic name?" id="eb1a7678-64d5-4f01-bcc5-a8bf8023fa86">
        <answer right feedback="Yes, you did it!">Fundamentals of IoT</answer>
        <answer feedback="Oh no! Try again!">Basics of electronics</answer>
        <answer feedback="Oh no! Try again!">Radio communications</answer>
        <answer feedback="Oh no! Try again!">Web Apps</answer>
        <answer feedback="Close, but no cigar!">Bonbons and Lollipops</answer>
    </quiz>`;

// const sampleMarkup = "<h1>Welcome!</h1><p>How are you?</p>";

const flyingInputTheme = createTheme({
    palette: {
        primary: {
            main: "#e9f5ff",
        },
        text: {
            primary: "rgba(255, 255, 255, 0.87)",
            secondary: "rgba(255, 255, 255, 0.6)",
        }
    }
});

export type EditorCtx = {
    setReadonly: (readonly: boolean) => void;
    currentBlock: ContentBlock | null;
    removeEntity: (entityKey: string) => void;
    removeBlock: (blockKey: string) => void;
    // focusBlock: ContentBlock | null;
}

export const EditorContext = React.createContext<EditorCtx>(null as any);

export interface TopicEditorProps extends BoxProps {
}

function TopicEditor(props: TopicEditorProps) {
    const theme = useTheme();

    const [readonly, setReadonly] = useState(false);

    const [editorState, setEditorState] = useState(
        () => {
            const state = convertFromHTML({
                // htmlToBlock: (nodeName: string, node: any) => {
                //     if (nodeName === 'youtube') {
                //         return {
                //             type: 'atomic',
                //             data: {
                //                 is: "youtube",
                //                 // focus: false,
                //                 videoId: node.innerText,
                //             }
                //         };
                //     }
                //     if (nodeName === 'quiz') {
                //         return {
                //             type: 'atomic',
                //             data: {
                //                 is: "quiz",
                //                 // focus: false,
                //                 props: quizPropsFromElement(node)
                //             }
                //         };
                //     }
                // },

                htmlToBlock: (nodeName: string, node: any) => {
                    if (nodeName === 'youtube') {
                        return {
                            type: 'atomic',
                            data: {}
                        };
                    }
                    if (nodeName === 'quiz') {
                        return {
                            type: 'atomic',
                            data: {}
                        };
                    }
                },
                htmlToEntity: (nodeName: string, node: Node, createEntity: ContentState["createEntity"]) => {
                    switch (nodeName) {
                        case 'img':
                            return createEntity('IMAGE', 'IMMUTABLE', { src: (node as HTMLImageElement).src });
                        case "youtube":
                            return createEntity('YOUTUBE', 'IMMUTABLE', { videoId: (node as HTMLElement).innerText });
                        case "quiz":
                            return createEntity('QUIZ', 'IMMUTABLE', { props: quizPropsFromElement(node as Element) });
                    }
                }
            })(sampleMarkup);
            return EditorState.createWithContent(state);
        },
    );

    const propsBtnInlinseStyle = (style: string): ButtonProps => {
        return {
            color: editorState.getCurrentInlineStyle().has(style) ? "secondary" : undefined,
            onClick: () => {
                setEditorState(RichUtils.toggleInlineStyle(editorState, style));
            },
            onMouseDown: (ev) => {
                ev.preventDefault();
            }
        }
    }

    const propsBtnBlockStyle = (block: string): ButtonProps => {
        return {
            onClick: () => {
                setEditorState(RichUtils.toggleBlockType(editorState, block));
            },
            onMouseDown: (ev) => {
                ev.preventDefault();
            }
        }
    }

    const propsAddYouTube = (): ButtonProps => {
        return {
            onClick: () => {
                setReadonly(true);
                const data = { videoId: "" };
                Object.defineProperty(data, "autoOpenEditor", { value: true, enumerable: false });
                createEntity('YOUTUBE', 'IMMUTABLE', data);
            }
        }
    }

    const createEntity: ContentState["createEntity"] = (type, mutability, data) => {
        let content = editorState.getCurrentContent();
        content = content.createEntity(type, mutability, data);
        const entityKey = content.getLastCreatedEntityKey();
        Object.defineProperty(data, "entityKey", { value: entityKey, enumerable: false });
        const state = EditorState.set(
            editorState,
            { currentContent: content },
        );
        setEditorState(AtomicBlockUtils.insertAtomicBlock(state, entityKey, ' '));
        return content;
    }

    const removeBlock = (blockKey: string) => {
        const contentState = editorState.getCurrentContent();
        const block = contentState.getBlockForKey(blockKey);
        const next = contentState.getBlockAfter(blockKey);
        const removeSelection = new SelectionState({
            anchorKey: blockKey,
            anchorOffset: block.getText().length,
            focusKey: next ? next.getKey() : "",
            focusOffset: 0
        });
        let newContentState = Modifier.removeRange(
            contentState,
            removeSelection,
            "forward"
        );
        let newEditorState = EditorState.push(
            editorState,
            newContentState,
            "remove-range"
        );
        setEditorState(newEditorState);
    }

    const removeEntity = (entityKey: string) => {
        const contentState = editorState.getCurrentContent();
        const block = contentState.getBlockForKey(entityKey);
        const next = contentState.getBlockAfter(block.getKey());
        const removeSelection = new SelectionState({
            anchorKey: block.getKey(),
            anchorOffset: block.getText().length,
            focusKey: next ? next.getKey() : "",
            focusOffset: 0
        });
        let newContentState = Modifier.removeRange(
            contentState,
            removeSelection,
            "forward"
        );
        let newEditorState = EditorState.push(
            editorState,
            newContentState,
            "remove-range"
        );
        setEditorState(newEditorState);
    }

    const propsAddQuiz = (): ButtonProps => {
        return {
            onClick: () => {
                const props: QuizProps = {
                    id: genRandomUUID(),
                    title: "What time is it?",
                    type: "single-choice",
                    answers: [
                        {
                            text: "Fundamentals of IoT",
                            right: true,
                            feedback: "Yes, you did it!"
                        }
                    ]
                }
                createEntity('QUIZ', 'IMMUTABLE', { props });
            }
        }
    }

    function save() {
        const html = convertToHTML({
            blockToHTML: (block: RawDraftContentBlock) => {
                if (block.type === 'atomic') {
                    return <p />;
                }
            },
            entityToHTML: (entity: RawDraftEntity) => {
                if (entity.type === 'IMAGE') {
                    return `<img src="${entity.data.src}" />`;
                }
                if (entity.type === 'YOUTUBE') {
                    return `<youtube>${entity.data.videoId}</youtube>`;
                }
                if (entity.type === 'QUIZ') {
                    return quizPropsToHTML(entity.data.props);
                }
            }
        })(editorState.getCurrentContent());
        console.log(html);
    }

    const boxRef = useRef<HTMLElement>(null);

    function handleMouseOver(ev: React.MouseEvent) {
        if (ev.target instanceof HTMLElement) {
            let elm = ev.target;
            while (elm.getAttribute("data-block") !== "true") {
                elm = elm.parentElement!;
                if (elm === null || elm.getAttribute("data-contents") == "true") {
                    return
                }
            }
            const offsetKey = elm.getAttribute("data-offset-key")!;
            const [blockKey, startKey, endKey] = offsetKey.split("-");
            const block = editorState.getCurrentContent().getBlockForKey(blockKey);
            const box = boxRef.current!;
            box.style.top = `${elm.offsetTop + 48}px`;
            box.style.height = `${elm.offsetHeight}px`;
            ctx.currentBlock = block;
        }
    }

    function addBlockAbove() {
        setEditorState(addBlockAfter(editorState, ctx.currentBlock!.getKey(), "", {}));
    }

    function addBlockBelow() {
        setEditorState(addBlockAfter(editorState, "", ctx.currentBlock!.getKey(), {}));
    }

    function addImage() {
        createEntity('IMAGE', 'IMMUTABLE', { src: "https://www.draft-js-plugins.com/images/canada-landscape-small.jpg" });
    }

    const ctx: EditorCtx = {
        setReadonly,
        currentBlock: null,
        removeEntity,
        removeBlock,
        // focusBlock: null,
    };

    // const [ctx] = useState<EditorCtx>({
    //     setReadonly,
    //     currentBlock: null,
    //     removeEntity,
    //     removeBlock,
    //     // focusBlock: null,
    // });

    // useEffect(() => {
    //     function handleSelectionChange() {
    //         const sel = window.getSelection()!;
    //         let elm = sel.getRangeAt(0).commonAncestorContainer as HTMLElement;
    //         while (elm instanceof Text || elm.getAttribute("data-block") !== "true") {
    //             elm = elm.parentElement!;
    //             if (elm === null || elm.getAttribute("data-contents") == "true") {
    //                 return
    //             }
    //         }

    //         const offsetKey = elm.getAttribute("data-offset-key")!;
    //         const [blockKey, startKey, endKey] = offsetKey.split("-");
    //         const block = editorState.getCurrentContent().getBlockForKey(blockKey);
    //         console.log("focus block", block, elm);
    //         if (block) {
    //             let m = ctx.focusBlock?.getData().get("mutable");
    //             if(m) m.focus = false;
    //             const m2 = block.getData().get("mutable");
    //             if(m2) m2.focus = true;
    //             ctx.focusBlock = block;
    //         }

    //         elm.parentElement!.querySelector(".focus")?.classList.remove("focus");
    //         elm.classList.add("focus");
    //     }
    //     document.addEventListener("selectionchange", handleSelectionChange);
    //     return () => {
    //         document.removeEventListener("selectionchange", handleSelectionChange);
    //     }
    // }, [editorState]);

    return <Box
        {...props}
        sx={joinSx({
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // "& .public-DraftEditor-content [data-block='true']:hover": { backgroundColor: "grey.100" },
            "& .DraftEditor-root": { pt: 6, pb: 32, px: 0, boxSizing: "border-box", minHeight: "100%", ...theme.typography.body1 },
            "& .DraftEditor-editorContainer": { height: "auto" },
            "& .public-DraftEditor-content": {},
            "& div[data-block=true]:not([contenteditable=false])": { py: 1.5 },
            "& h1[data-block=true]": { m: 0, pt: 3, pb: 2, fontWeight: "normal", fontSize: "2.5rem", lineHeight: 1.167, letterSpacing: "-0.01562em" },
            "& h2[data-block=true]": { m: 0, pt: 3, pb: 2, fontWeight: "normal", fontSize: "1.75rem", lineHeight: 1.235, letterSpacing: "0.00735em" },
            "& figure[data-block=true]": { m: 0 },
            "& [data-block=true]": { color: '#222222', fontSize: '1em', textAlign: 'justify', lineHeight: 1.5, m: 0, px: 6, "&.focus": { backgroundColor: "grey.50" } },
        }, props.sx)}
        onMouseOver={handleMouseOver}
    >
        <Toolbar sx={{ backgroundColor: "#f1f1f1", borderTop: `1px solid ${theme.palette.divider}`, borderBottom: `1px solid ${theme.palette.divider}` }} variant="dense">
            <IconButton {...propsBtnBlockStyle("header-one")}><FormatSizeOutlined /></IconButton>
            <IconButton {...propsBtnInlinseStyle("BOLD")}><FormatBoldOutlined /></IconButton>
            <Button {...propsAddYouTube()}><img src={youtubeLogoSrc}></img></Button>
            <IconButton {...propsAddQuiz()}><QuizOutlined /></IconButton>
            <IconButton onClick={addImage}><ImageOutlined /></IconButton>
            <IconButton onClick={save}><SaveOutlined /></IconButton>
        </Toolbar>
        <EditorContext.Provider value={ctx}>
            <Box sx={{ height: "100%", overflowY: "auto", position: "relative" }}>
                <Editor editorState={editorState} onChange={setEditorState} plugins={plugins} readOnly={readonly} />
                <Box sx={{ position: "absolute", top: 0, left: 8, display: "flex", flexDirection: "column", zIndex: 1 }} ref={boxRef}>
                    <Box sx={{ flexGrow: "1" }}>
                        {/* <IconButton size="small" onClick={addBlockAbove}><ArrowDropUp /></IconButton> */}
                    </Box>
                    <IconButton size="small" onClick={addBlockBelow}><ArrowDropDownOutlined /></IconButton>
                </Box>
            </Box>
        </EditorContext.Provider>
    </Box>;
}

const myPlugin: EditorPlugin = {

    blockRendererFn: (block, { getEditorState, setEditorState }) => {
        const type = block.getType();
        if (type === "atomic") {
            const entityKey = block.getEntityAt(0);
            if (entityKey) {
                const contentState = getEditorState().getCurrentContent();
                const entity = contentState.getEntity(entityKey);
                switch (entity.getType()) {
                    case "YOUTUBE":
                        return {
                            component: YouTubeBlock,
                            props: {
                                changeData: createChangeData(block, { getEditorState, setEditorState })
                            },
                            editable: false
                        }
                    case "QUIZ":
                        return {
                            component: QuizBlock,
                            props: {},
                            editable: false
                        }
                    case "IMAGE":
                        return {
                            component: ImageBlock,
                            editable: false,
                            props: {}
                        }
                }
            }
        }
    }
}

const createChangeData = (
    contentBlock: ContentBlock,
    {
        getEditorState,
        setEditorState,
    }: {
        setEditorState(editorState: EditorState): void; // a function to update the EditorState
        getEditorState(): EditorState; // a function to get the current EditorState
    }
) => (data: Record<string, unknown>) => {
    const entityKey = contentBlock.getEntityAt(0);
    if (entityKey) {
        const editorState = getEditorState();
        const contentState = editorState.getCurrentContent();
        contentState.mergeEntityData(entityKey, { ...data });
        setEditorState(
            EditorState.forceSelection(editorState, editorState.getSelection())
        );
    }
};

const plugins = [myPlugin];


// const blockRendererFn: EditorPlugin["blockRendererFn"] = (block) => {
//     // if (type === 'youtube') {
//     //     return {
//     //         component: YouTubeBlock,
//     //         editable: false,
//     //         // props: {
//     //         //     foo: 'bar',
//     //         // },
//     //     };
//     // }
//     // if (type === 'quiz') {
//     //     // const quiz = JSON.parse(block.getText());
//     //     return {
//     //         component: QuizBlock,
//     //         editable: false,
//     //         props: {},
//     //     };
//     // }
// }

interface BlockProps {
    contentState: ContentState,
    block: ContentBlock,
    blockProps: any,
}


function ImageBlock(props: BlockProps) {
    const { contentState, block, blockProps } = props;
    const { changeData } = blockProps;
    const entity = contentState.getEntity(block.getEntityAt(0)!)!;
    const { src } = entity.getData();
    return <img src={src} />;
}

function YouTubeBlock(props: BlockProps) {
    console.log({ props });
    const { contentState, block, blockProps } = props;
    const { changeData } = blockProps;
    const entityKey = block.getEntityAt(0);
    const entity = contentState.getEntity(entityKey);
    const { videoId, autoOpenEditor } = entity.getData();
    const blockKey = block.getKey();
    console.log({entityKey, blockKey, videoId, autoOpenEditor})

    const [open, setOpen] = useState(!!autoOpenEditor);
    function handleClose() {
        setOpen(false);
        editor.setReadonly(false);
        if (!videoId) {
            editor.removeBlock(blockKey);
        }
    }

    const editor = useContext(EditorContext)!;
    // const [focus, setFocus] = useState(true);

    // function handleFocus(ev: React.FocusEvent<HTMLDivElement>) {
    //     setTimeout(() => setFocus(true), 1);
    // }
    // function handleBlur(ev: React.FocusEvent<HTMLDivElement>) {
    //     setTimeout(() => setFocus(false), 1);
    // }

    // function handleInputFocus() {
    //     editor.setReadonly(true);
    // }
    // function handleInputBlur() {
    //     editor.setReadonly(false);
    // }

    // function handleKeyDown(ev: React.KeyboardEvent<HTMLElement>) {
    //     const input = ev.target as HTMLInputElement;
    //     if (ev.key === "Enter") {
    //         changeData({
    //             videoId: input.value
    //         });
    //     }
    // }

    function openEditDialog() {
        editor.setReadonly(true);
        setOpen(true);
    }

    const [videoUrl, setVideoUrl] = useState("https://www.youtube.com/watch?v=" + videoId);
    const isEmptyVideoUrlError = videoUrl.trim() === "";
    const isInvalidUrlError = !videoUrl.match(/https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/);
    const isVideoUrlError = isEmptyVideoUrlError || isInvalidUrlError;
    const [showVideoUrlError, setShowVideoUrlError] = useState(false);
    let videoUrlHelperText: ReactNode;
    if (showVideoUrlError) {
        if (isEmptyVideoUrlError) videoUrlHelperText = "URL is required";
        else if (isInvalidUrlError) videoUrlHelperText = "Invalid URL";
    }

    function submitVideoUrl() {
        if (isVideoUrlError) {
            setShowVideoUrlError(true);
            return;
        }
        const videoId = videoUrl.match(/https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/)![1];
        changeData({ videoId });
        handleClose();
    }

    return <Box>
        <Box sx={{ width: "80%", maxWidth: "800px", height: "auto", aspectRatio: "16/9", mx: "auto", display: "block", position: "relative", "&:hover .ButtonEdit": { opacity: 1 } }}>
            {videoId ? <Box component="img" src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} sx={{ width: "100%", display: "block" }} /> : <Box sx={{ width: "100%", height: "100%", bgcolor: "#186dbf26" }} />}
            <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center", opacity: 0, transition: theme => theme.transitions.create("opacity") }} className="ButtonEdit">
                <IconButton sx={{ color: "white", bgcolor: "#cfcfcfcc", p: 4, "&:hover": { bgcolor: "#b3b3b3cc" } }} onClick={openEditDialog} ><EditOutlined sx={{ fontSize: "2rem" }} /></IconButton>
            </Box>
        </Box>

        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
        // PaperComponent={(props) => <Paper {...props} component="form" />}
        // PaperProps={{
        //     onSubmit: (event: React.FormEvent<HTMLElement>) => {
        //         event.preventDefault();
        //         // const formData = new FormData(event.currentTarget);
        //         // const formJson = Object.fromEntries((formData as any).entries());
        //         // const email = formJson.email;
        //         // console.log(email);
        //         // handleClose();
        //     },
        // }}
        >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                onSubmit={(ev) => {
                    ev.preventDefault()
                    submitVideoUrl();
                }}
            >
                <DialogTitle>YouTube Video</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a youtube video URL.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="youtube-video-url"
                        name="youtube-video-url"
                        label="YouTube Video URL"
                        fullWidth
                        variant="standard"
                        error={showVideoUrlError}
                        helperText={videoUrlHelperText}
                        value={videoUrl}
                        onChange={(ev) => { setShowVideoUrlError(false); setVideoUrl(ev.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                </DialogActions>
            </Box>
        </Dialog>
    </Box>;

    // InputProps={{ sx: {  } }}

    // return <Box sx={{ py: 2, my: 2, display: "flex", position: "relative" }} tabIndex={1} onFocusCapture={handleFocus} onBlurCapture={handleBlur}>
    //     <YouTubePlayer key={videoId} videoId={videoId} />
    //     {focus && (
    //         <Box sx={{ position: "absolute", bottom: "34px", left: "10px", width: "600px", zIndex: 5, background: "#42a5f5", boxShadow: (theme) => theme.shadows[3], borderRadius: 2, display: "flex", alignItems: "center", pl: 2 }} >
    //             <ThemeProvider theme={flyingInputTheme}>
    //                 <LinkOutlined color="primary" />
    //                 <InputBase fullWidth defaultValue={videoId} sx={{ pl: 1, py: 0.5, pr: 2 }} onKeyDown={handleKeyDown} onFocus={handleInputFocus} onBlur={handleInputBlur} />
    //             </ThemeProvider>
    //         </Box>
    //     )}
    // </Box>
}

function QuizBlock(props: BlockProps) {
    const { contentState, block } = props;
    const entity = contentState.getEntity(block.getEntityAt(0));
    const { props: data }: { props: QuizProps } = entity.getData();

    return <Box sx={{ py: 3 }}>
        <Paper sx={{ p: 3, bgcolor: "grey.100" }}>
            This is a quiz.
        </Paper>
    </Box>;

    // const [title, setTitle] = useState(data.title);

    // const editor = useContext(EditorContext)!;
    // function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    //     const title = ev.target.value;
    //     data.title = title;;
    //     setTitle(title);
    // }

    // function handleFocus(ev: React.FocusEvent<HTMLInputElement>) {
    //     editor.setReadonly(true);
    // }
    // function handleBlur(ev: React.FocusEvent<HTMLInputElement>) {
    //     editor.setReadonly(false);
    // }

    // function handleAddAnswerClick() {
    //     answers.push({
    //         text: "",
    //         feedback: ""
    //     });
    //     forceUpdate();
    // }

    // const { answers } = data;
    // const forceUpdate = useForceUpdate();

    // console.log(answers);
    // // if(!answers) {
    // // }


    // return <Box sx={{ py: 3 }}>
    //     <Paper sx={{ p: 3, bgcolor: "grey.100" }}>
    //         <TextField label="Question" variant="standard" fullWidth sx={{ mb: 2 }} onChange={handleChange} value={title} onFocus={handleFocus} onBlur={handleBlur} />
    //         <Stack spacing={2} direction="row" alignItems="baseline" sx={{ mr: 5 }}>
    //             <Typography variant="body2" sx={{ flexGrow: 1 }}>Answers</Typography>
    //             <Typography variant="body2" sx={{ textAlign: "center", width: 42 }} >Right?</Typography>
    //         </Stack>
    //         {answers.map((answer, index) => {
    //             const { text, feedback, right = false } = answer;

    //             function handleTextChange(ev: React.ChangeEvent<HTMLInputElement>) {
    //                 const text = ev.target.value;
    //                 answer.text = text;
    //             }

    //             function handleCheckboxChange(ev: {}, checked: boolean) {
    //                 if (checked) answer.right = true;
    //                 delete answer.right;
    //             }

    //             function handleDelete() {
    //                 answers.splice(index, 1);
    //                 forceUpdate();
    //             }

    //             return <Fragment key={id(answer)}>
    //                 <Stack
    //                     spacing={2}
    //                     direction="row"
    //                     alignItems="baseline"
    //                     sx={{
    //                         mb: 2,
    //                         // "& .closer": {opacity: 0, "transition": (theme) => theme.transitions.create("opacity", {duration: theme.transitions.duration.shortest})},
    //                         // "&:hover .closer": {opacity: 1}
    //                     }}>
    //                     <Typography variant="body2">{index + 1}.</Typography>
    //                     <TextField variant="standard" fullWidth onChange={handleTextChange} defaultValue={text} onFocus={handleFocus} onBlur={handleBlur} />
    //                     <Stack direction="row" alignItems="baseline">
    //                         <Checkbox defaultChecked={right} onChange={handleCheckboxChange} />
    //                         <IconButton className="closer" onClick={handleDelete}><CloseOutlined /></IconButton>
    //                     </Stack>
    //                 </Stack>
    //                 <Stack
    //                     spacing={2}
    //                     direction="row"
    //                     alignItems="baseline"
    //                     sx={{
    //                         pl: 4,
    //                         pr: 12,
    //                         mb: 2,
    //                         // "& .closer": {opacity: 0, "transition": (theme) => theme.transitions.create("opacity", {duration: theme.transitions.duration.shortest})},
    //                         // "&:hover .closer": {opacity: 1}
    //                     }}>
    //                     <Typography variant="body2">Feedback:</Typography>
    //                     <TextField variant="standard" fullWidth onChange={handleTextChange} defaultValue={feedback} onFocus={handleFocus} onBlur={handleBlur} />
    //                 </Stack>
    //             </Fragment>
    //         })}
    //         <Button variant="outlined" onClick={handleAddAnswerClick} sx={{ mt: 2 }}>Add Answer</Button>
    //     </Paper>
    // </Box>;
}



function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

import { Box, Button, ButtonBase, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography, styled } from "@mui/material";
import { CopyProgramResponse, CoursesResponse, ListProgramsRequest, ListProgramsResponse, Organization, ResourcesResponse, copyProgram, getCourses, getResources, listPrograms } from "../../api";
import { pageParams } from "../Page";
import HTMLRenderer from "../HTMLRenderer";
import { lineClamp, renderHTML } from "../tools";
import { useEffect, useState } from "react";
import { AddOutlined, DevicesOther, DevicesOutlined, ExpandMore, SchoolOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ErrorView from "../ErrorView";
import { reportError } from "@halliday/mui-msgbox";
import { toast } from "@halliday/mui-toast";
import { useSession } from "../../ident";
import ProgramPlaceholderCover from '../../assets/images/prog-placeholder-cover.png';


export interface CoursesPageProps {
    org: Organization
}

interface ModulesPageParams {
    pageToken?: string;
    q?: string;
}

const Img = styled("img")({});

const numResourcesDisplayed = 4;
const numProgramsDisplayed = 4;
const numCoursesDisplayed = 4;

export default function OverviewPage(props: CoursesPageProps) {
    const { org } = props;
    const params = pageParams() as ModulesPageParams;

    const [showMore, setShowMore] = useState(false);
    const [resources, setResources] = useState<ResourcesResponse | null>(null);
    const [courses, setCourses] = useState<CoursesResponse | null>(null);
    const [programs, setPrograms] = useState<ListProgramsResponse | null>(null);
    const navigate = useNavigate();

    const sess = useSession();
    const isAdmin = org.roles.includes("org-admin") || org.roles.includes("admin") || sess?.user.roles.includes("admin");

    useEffect(() => {
        getResources({ org: [org.id], pageSize: numResourcesDisplayed }).then(setResources);
        getCourses({ orgs: [org.id], pageSize: numCoursesDisplayed }).then(setCourses);
        listPrograms({ org: [org.id], pageSize: numProgramsDisplayed, drafts: true }).then(setPrograms);
    }, [org.id]);

    const navigateResources = () => navigate("/resources");
    const navigateCourses = () => navigate("/courses");
    const navigatePrograms = () => navigate("/programs");

    const [dlgStartNewProgramOpen, setDlgStartNewProgramOpen] = useState(false);
    const openDlgStartNewProgram = () => setDlgStartNewProgramOpen(true);
    const closeDlgStartNewProgram = () => setDlgStartNewProgramOpen(false);

    return <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ position: "relative", marginBottom: "20px" }}>
            <Collapse in={showMore} collapsedSize={200}>
                <Box sx={{ maxWidth: "1200px", " img.img-float-right": { float: "right", ml: 6, mb: 2 }, " img.image-size-m": { maxWidth: "50%" }, " h1": { mt: 0 } }}>
                    {/* {renderHTML(`
                        <h1>Dar Teknohama Business Incubator</h1>
                        <img src="http://localhost:3000/static/images/dar-teknohama-business-incubator.jpg" alt="Dar Teknohama Business Incubator" class="img-float-right image-size-m" />
                        <p>
                            DTBi is a Tech Incubator in the country that promotes the growth of ICT technology-based emerging
                            companies, Start-ups and those with innovative ideas contributing to job creation (including youth and
                            women) and enhanced economic health of the nation.
                            The Dar Teknohama Business Incubator (DTBi) is a Not-For-Profit Company run as a business registered in
                            Tanzania. It has its own Board made up of members from Private Sector, Public Sector, NGOs & CSO.
                            COSTECH is a shareholder and is part of the founding members, which includes infoDev of the World Bank.
                            DTBi is a Tech Incubator in the country that promotes the growth of ICT technology-based emerging
                            companies, Start-ups and those with innovative ideas contributing to job creation (including youth and
                            women) and enhanced economic health of the nation.
                        </p>
                        <p>
                            Furthermore the incubator's aim is to serve as a hub for technology start-ups and actively identifies
                            and provides for the value-added services required to support incubator clients success. Turning an idea
                            into a profitable business requires passion and dedication which, the staff of DTBi does provide,
                            including the valuable support entrepreneurs need. DTBi provides business development services and
                            assists early stage ICT companies by lowering the cost of business and increasing the chances of
                            business survival by providing access to shared resources, facilitating access to finance and markets
                            through credible support, guidance and business management, and networking for technical trends and
                            opportunities to access markets.
                        </p>
                    `)} */}
                    {renderHTML(org.desc || "")}
                </Box>
            </Collapse>
            <Box sx={{ height: "32px", position: "absolute", bottom: "39px", left: 0, width: "100%", background: "linear-gradient(180deg, #ffffff00, #ffffff)", opacity: showMore ? 0 : 1, transition: theme => theme.transitions.create("opacity") }} />
            <Button onClick={() => setShowMore(!showMore)} startIcon={<ExpandMore sx={{ transform: showMore ? "rotate(180deg)" : "rotate(0deg)", transition: theme => theme.transitions.create("transform") }} />} sx={{ mt: 1 }} size="small">{showMore ? "Show Less" : "Show More"}</Button>
        </Box>

        <Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, width: "100%", gap: 2 }} >
                {resources?.numFound ?
                    <Box sx={{ px: 3, py: 3, bgcolor: "grey.100", borderRadius: 3, maxWidth: "490px", minHeight: "420px", display: "inline-flex", flexDirection: "column", gap: 1, width: { xs: "100%", sm: "calc(50% - 16px)" }, mb: 4, boxSizing: "border-box" }}>
                        <Box sx={{ display: "flex", gap: 2, alignItems: "baseline" }}>
                            <Typography variant="h2" sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={navigateResources}>Resources</Typography>
                            <Typography variant="body2">{resources?.numFound}</Typography>
                        </Box>
                        <Box component="ul" sx={{ flexGrow: 1, listStyle: "none", m: 0, p: 0, gap: 1, display: "flex", flexDirection: "column" }}>
                            {resources ? (
                                resources.numFound > 0 ? <>
                                    {resources.resources.map(resource => (
                                        <ButtonBase component="li" key={resource.id} sx={{ display: "flex", gap: 2, alignItems: "flex-start", p: 1, borderRadius: 1, m: 0, bgcolor: "background.paper" }} onClick={() => navigate(`/resources/${resource.weakId!}`)}>
                                            <Img src={resource.coverUrl} alt={resource.title} sx={{ height: 64, backgroundColor: resource.color, borderRadius: 1 }} />
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant="body1">{resource.title}</Typography>
                                                <Typography variant="body2" color="text.secondary">{resource.desc}</Typography>
                                            </Box>
                                        </ButtonBase>
                                    ))}
                                </>
                                    : (<>
                                        <DevicesOther sx={{ fontSize: "128px", display: "block", mx: "auto", mt: 6, color: "grey.200" }} />
                                        <Typography variant="body1" sx={{ textAlign: "center", color: "grey.500" }}>No resources found for this organization</Typography>
                                    </>)
                            ) : <>
                                <Skeleton variant="rectangular" height={50} />
                            </>}
                        </Box>
                        {resources && resources.numFound > numResourcesDisplayed && <Button startIcon={<ExpandMore />} sx={{ display: "flex", mx: "auto", px: 4 }} onClick={navigateResources}>{resources.numFound - numResourcesDisplayed} more</Button>}
                    </Box>
                    : <></>
                }

                {
                    courses?.numFound ?
                        <Box sx={{ px: 3, py: 3, bgcolor: "grey.100", borderRadius: 3, maxWidth: "490px", minHeight: "420px", display: "inline-flex", flexDirection: "column", gap: 1, width: { xs: "100%", sm: "calc(50% - 16px)" }, mr: 4, mb: 4, boxSizing: "border-box" }}>
                            <Box sx={{ display: "flex", gap: 2, alignItems: "baseline" }}>
                                <Typography variant="h2" sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={navigateCourses}>Courses</Typography>
                                <Typography variant="body2">{courses?.numFound}</Typography>
                            </Box>
                            <Box component="ul" sx={{ flexGrow: 1, listStyle: "none", m: 0, p: 0, gap: 1, display: "flex", flexDirection: "column" }}>
                                {courses ? (
                                    courses.numFound > 0 ? <>
                                        {courses.courses.map(course => (
                                            <ButtonBase component="li" key={course.id} sx={{ display: "flex", gap: 2, alignItems: "flex-start", p: 1, borderRadius: 1, m: 0, bgcolor: "background.paper" }} onClick={() => navigate(`/courses/${course.id}`)}>
                                                <Img src={course.cover!.url} alt={course.title} sx={{ height: 64, borderRadius: 1 }} />
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Typography variant="body1">{course.title}</Typography>
                                                    <Typography variant="body2" color="text.secondary">{course.desc}</Typography>
                                                </Box>
                                            </ButtonBase>
                                        ))}
                                    </> : (
                                        <>
                                            <SchoolOutlined sx={{ fontSize: "128px", display: "block", mx: "auto", mt: 6, color: "grey.200" }} />
                                            <Typography variant="body1" sx={{ textAlign: "center", color: "grey.500" }}>No courses found for this organization</Typography>
                                        </>
                                    )
                                ) : <>
                                    <Skeleton variant="rectangular" height={50} />
                                </>}
                            </Box>
                            {courses && courses.numFound > numCoursesDisplayed && <Button startIcon={<ExpandMore />} sx={{ display: "flex", mx: "auto", px: 4 }} onClick={navigateCourses}>{courses.numFound - numCoursesDisplayed} more</Button>}
                        </Box>
                        : <></>
                }
            </Box>

            <Box sx={{ px: 3, py: 3, bgcolor: "grey.100", borderRadius: 3, maxWidth: "1200px", minHeight: "400px", display: "flex", flexDirection: "column", gap: 1, width: "100%", mb: 4, boxSizing: "border-box" }}>
                <Box sx={{ display: "flex", gap: 2, alignItems: "baseline" }}>
                    <Typography variant="h2" sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={navigatePrograms}>Programs</Typography>
                    <Typography variant="body2">{programs?.numFound}</Typography>
                </Box>
                <Box component="ul" sx={{ flexGrow: 1, listStyle: "none", m: 0, p: 0, gap: 2, display: "flex" }}>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', alignItems: 'flex-start' }} mt={2} mb={['15%', '5%']}>
                        {programs ? <>
                            {programs.programs.map(program => (
                                <ButtonBase component="li" key={program.id} sx={{ display: "flex", gap: 2, alignItems: "flex-start", p: 1, width: "316px", borderRadius: 1, m: 0, bgcolor: "background.paper", flexDirection: "column" }} onClick={() => navigate(`/programs/${program.id}`)}>
                                    <Img src={program.cover ? program.cover.url : ProgramPlaceholderCover} alt={program.name} sx={{ width: 300, borderRadius: 1 }} />
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="body1">{program.name}</Typography>
                                        <Typography variant="body2" color="text.secondary">{program.desc}</Typography>
                                    </Box>
                                </ButtonBase>
                            ))}
                            {isAdmin && (
                                <ButtonBase sx={{ width: "316px", borderRadius: 1, border: "1px solid", borderColor: "divider", m: 0, display: "flex", alignItems: "center", justifyContent: "flex-start", color: "text.secondary", flexDirection: "column", p: 1 }} onClick={openDlgStartNewProgram}>
                                    <AddOutlined sx={{ fontSize: "3rem", my: "80px" }} />
                                    <Typography variant="body1">Start new Program</Typography>
                                </ButtonBase>
                            )}
                        </> : <>
                            <Skeleton variant="rectangular" height={50} />
                        </>}
                    </Box>
                </Box>
            </Box>
        </Box>

        <StartProgramDialog open={dlgStartNewProgramOpen} org={org} onClose={closeDlgStartNewProgram} />
    </Box>;
}


interface StartProgramDialogProps extends DialogProps {
    org: Organization;
}

export function StartProgramDialog(props: StartProgramDialogProps) {
    const { org } = props;

    const handleClose = () => {
        props.onClose?.({}, "escapeKeyDown");
    }

    return <Dialog {...props}>
        <DialogTitle>Program Templates</DialogTitle>
        <StartProgramDialogContent org={org} />
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
    </Dialog>;
}

interface StartProgramDialogContentProps {
    org: Organization;
}

function StartProgramDialogContent(props: StartProgramDialogContentProps) {
    const { org } = props;

    const { err, loading, programs } = useListProgramsResponse({ tag: ["template"] });

    const navigate = useNavigate();

    return <DialogContent>
        <DialogContentText>
            {loading && <CircularProgress />}
            <ErrorView err={err} />
            {programs && <List>
                {programs.map(program => {

                    function previewProgram() {
                        navigate(`/programs/${program.id}`);
                    }

                    async function setupProgram() {
                        let resp: CopyProgramResponse;
                        try {
                            resp = await copyProgram({ program: program.id, org: org.id });
                        } catch (err) {
                            reportError(err);
                            return;
                        }
                        toast("Program setup successfully.");
                        navigate(`/programs/${resp.id}/editor`);
                    }

                    return <Box component="li" key={program.id} sx={{ display: "flex", gap: 2, p: 1, mb: 2 }}>
                        <Box component="img" src={program.cover!.url} sx={{ width: 105, height: 70 }} />
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h3">{program.name}</Typography>
                            <Typography variant="body1" sx={lineClamp(3)}>{program.desc}</Typography>
                            <Typography variant="body1">{program.numUnits} Units • {program.numEvents} Events</Typography>
                            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                                <Button variant="outlined" color="primary" onClick={setupProgram}>Setup</Button>
                                <Button variant="text" color="primary" onClick={previewProgram}>Preview</Button>
                            </Box>
                        </Box>
                    </Box>;
                })}
            </List>}
            {/* if no programs are set as templates show a nice message to the user */}
            {programs && programs.length === 0 && <Typography variant="body1">No program templates found for this organization</Typography>}
        </DialogContentText>
    </DialogContent>
}

function useListProgramsResponse(req: ListProgramsRequest) {
    const [resp, setResp] = useState<ListProgramsResponse | null>(null);
    const [err, setErr] = useState<Error | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        listPrograms(req).then((resp) => { setResp(resp); setErr(null); }, setErr).finally(() => setLoading(false));
    }, [req.org, req.user, req.role, req.from, req.to, req.q, req.withMembership, req.pageSize, req.pageToken]);
    return { err, loading, ...resp };
}

import { Box, SvgIcon, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from "react-router-dom";
import Page, { PageContent } from "../Page";
import Link from '@mui/material/Link';

function SettingsPage() {
    const navigate = useNavigate();

    return <Page
        title="Settings"
    >
        <PageContent>
            {/* <Link onClick={() => { navigate("/settings/maintenance"); }}>
                Import contents
            </Link>
            <Link onClick={() => { navigate("/users"); }}>
                Manage users
            </Link> */}

            <Box display='flex' flexDirection={['column', 'row']} gap={2}>
                <Box
                    component='button'
                    display='flex' justifyContent='center' alignItems='center' gap={1}
                    sx={{ cursor: 'pointer', width: ['100%', 200], height: 100, border: '1px solid #394E69', borderRadius: 4 }}
                    onClick={() => { navigate("/settings/maintenance") }}
                >
                    <SvgIcon component={FileDownloadOutlinedIcon} inheritViewBox color="primary" />
                    <Typography variant="body1" color="primary">Import Contents</Typography>
                </Box>
                <Box
                    component='button'
                    display='flex' justifyContent='center' alignItems='center' gap={1}
                    sx={{ cursor: 'pointer', width: ['100%', 200], height: 100, border: '1px solid #394E69', borderRadius: 4 }}
                    onClick={() => { navigate("/users") }}
                >
                    <SvgIcon component={ManageAccountsIcon} inheritViewBox color="primary"/>
                    <Typography variant="body1" color="primary">Manage users</Typography>
                </Box>
            </Box>
        </PageContent>
    </Page>
}

export default SettingsPage;

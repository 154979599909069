import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./dummy_api";
import App from "./components/App";
import { loadLanguage, config as i18nConfig } from "@halliday/react-i18n";
import { setupIdent } from "./ident";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root') as HTMLElement
// );

window.dataLayer = window.dataLayer || [];

measureScrollBarWidth();

const setup = Promise.all([loadLanguage(), setupIdent()]);

setup.then(() => {
  const container = document.getElementById("root") as HTMLElement;
  const root = ReactDOM.createRoot(container);
  root.render(<App />);
});

function measureScrollBarWidth() {
  const c = document.createElement("div");
  c.style.position = "absolute";
  c.style.overflow = "scroll";
  c.style.boxSizing = "border-box";
  c.style.width = "100px";
  document.body.appendChild(c);
  const scrollbarWidth = 100 - c.scrollWidth;
  document.body.removeChild(c);
  document.body.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
}

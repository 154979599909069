import { SearchOutlined, Close } from "@mui/icons-material";
import { FilledTextFieldProps, TextField, InputAdornment, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

export interface SearchTextFieldProps extends Omit<FilledTextFieldProps, "variant"> {
    onClose?: (ev: {}) => void
}
export function SearchTextField(props: SearchTextFieldProps) {
    const { onClose, ...textFieldProps } = props;
    function handleBlur(ev: React.FocusEvent) {
        const empty = (ev.target as HTMLInputElement).value === "";
        if (empty && props.onClose) props.onClose(ev);
    }
    return <TextField
        variant="filled"
        autoFocus
        hiddenLabel
        size="small"
        onBlur={handleBlur}
        InputProps={{
            startAdornment: <InputAdornment position="start"><SearchOutlined /></InputAdornment>,
            endAdornment: <InputAdornment position="end"><IconButton size="small" onClick={onClose}><Close fontSize="small" /></IconButton></InputAdornment>
        }}
        {...textFieldProps}
    />
}

const inputDebounce = 500;

interface SearchButtonProps {
    search: string,
    onSearchChange: (search: string) => void,
    textFieldProps?: SearchTextFieldProps,
}

export function SearchButton(props: SearchButtonProps) {

    const [searchOpen, setSearchOpen] = useState(false);

    function openSearch() {
        setSearchOpen(true);
    }
    
    function closeSearch() {
        setSearchOpen(false);
        setSearch("");
        props.onSearchChange("");
        if (state.timeout) {
            clearTimeout(state.timeout);
            state.timeout = null;
        }
    }

    const state = useState<{
        timeout: ReturnType<typeof setTimeout> | null,
        search: string,
    }>({
        timeout: null,
        search: "",
    })[0];

    const [search, setSearch] = useState<string | null>(null);

    function handleSearchChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const q = ev.target.value;
        state.search = q;

        setSearch(q);

        if (state.timeout) clearTimeout(state.timeout);
        state.timeout = setTimeout(() => {

            props.onSearchChange(state.search);
            setSearch(null);
            state.timeout = null;
        }, inputDebounce);
    }

    useEffect(() => {
        return () => {
            if (state.timeout) {
                clearTimeout(state.timeout);
                state.timeout = null;
            }
        }
    }, [state]);


    if (searchOpen) {
        return <SearchTextField onClose={closeSearch} value={search ?? props.search} onChange={handleSearchChange} {... props.textFieldProps}/>;
    }
    
    return <Tooltip title="Search"><IconButton onClick={openSearch}><SearchOutlined /></IconButton></Tooltip>;
}
import { Box, Chip, SxProps, Theme } from "@mui/material";
import { lineHeight, textAlign } from "@mui/system";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, alertPropsFromElement } from "./Alert";
import HTMLRenderer, { renderNode } from "./HTMLRenderer";
import { quizPropsFromElement, QuizViewer } from "./courses/Quiz";
import { YouTubePlayer } from "./YouTube";
import { SystemStyleObject } from "@mui/system/styleFunctionSx";
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';


import {
    BlockMapBuilder,
    CharacterMetadata,
    ContentBlock,
    Modifier,
    EditorState,
    genKey,
    SelectionState,
    ContentState
} from 'draft-js'

import { List, Repeat, OrderedMap } from 'immutable'
import Figure, { figurePropsFromElement } from "./Figure";

export function renderHTML(html: string): JSX.Element {
    return <Box className="htmlRenderer" sx={{
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        " > *:first-child": {
            marginTop: 0,
        },
        "& .h1-primitive": {
            fontWeight: "normal",
            fontSize: "2rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            marginTop: 2,
            marginBottom: 3,
            "&:not(:first-child)": {
                marginTop: 8,
            }
        },
        "& .h2-primitive": {
            fontWeight: "normal",
            fontSize: "1.50rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",
            marginTop: 2,
            marginBottom: 2,
        },
        "& .pre-primitive": {
            backgroundColor: '#f2f1f1',
            padding: 0.5,
            borderRadius: 1,
            " code": {
                display: "block",
            },
        },
        "& .code-primitive": {
            color: '#282828',
            padding: ["4px", "6px"],
            verticalAlign: "middle",
            overflowX: "auto",
        },
        "& .p-primitive .code-primitive": {
            display: "inline-block",
            bgcolor: "#f2f1f1",
            px: "4px",
            py: "0px",
        },
        "& .img-primitive": {
            maxWidth: ['100%', '95%'],
            marginLeft: 0,
            // boxShadow: "1px 1px 4px #00000082",
            // marginBottom: 8,
            // display: "block",
            // mx: "auto",
        },
        "& .p-primitive > .img-primitive:only-child": {
            marginTop: 6,
            marginBottom: 1,
            display: "block",
            mx: "auto",
        },
        "& .p-primitive": {
            // marginTop: 1,
            // marginBottom: 1,
            color: '#222222',
            fontSize: '1em',
            textAlign: 'justify',
            lineHeight: 1.5,
        },
        "& .ul-primitive": {
            marginTop: 1.5,
            marginBottom: 1.5,
        },
        "& .li-primitive": {
            fontSize: '1em',
            padding: .235,
            // backgroundColor: '#f5f5f5',
            borderRadius: 1,
            marginBottom: .5,
            lineHeight: 1.235
        },
        "& .iframe-primitive": {
            minWidth: ['100%', 650],
            height: '80vh',
            border: '.5px solid #fff',
            overflowY: 'hidden',
        },

        "& .table-primitive": {
            borderSpacing: 0,
            width: "max-content"
        },

        "& .td-primitive, & .th-primitive": {
            border: ".3px solid #ddd",
            padding: 1,
        },
        "& .tr-primitive:nth-of-type(even)": {
            backgroundColor: "#f5f5f5",
        },
        "& .th-primitive": {
            position: "relative", // Ensure proper positioning for the pseudo-element
            paddingTop: 1,
            paddingBottom: 1,
            textAlign: "left",
            backgroundColor: "#f5f5f5",
            color: "#000",
            "&::after": {
                content: "''",
                position: "absolute",
                top: 0,
                right: "-1px", // Adjust for border width
                bottom: 0,
                width: "100%", // Take up the entire width of the cell
                borderRight: ".5px solid #ddd",
                zIndex: -1,
            },
        }

    }}>
        <HTMLRenderer html={html} renderElement={renderElement} />
    </Box>
}

// Define a CopyButton component
function CopyButton({ code }: { code: string }) {
    const [copied, setCopied] = useState(false);

    function removeLineNumbers(code: any) {
        // Split the code by line breaks
        const lines = code.split('\n');

        // Remove line numbers (assuming line numbers start at 1)
        const codeWithoutLineNumbers = lines
            .map((line: any) => line.replace(/^\s*\d+\s*/, ''))
            .join('\n');

        return codeWithoutLineNumbers;
    }

    function formatCode(code: any) {
        const lines = code.split('\n');
        const codeLines: any = [];

        let indentationLevel = 0;

        lines.forEach((line: any) => {
            // Remove line numbers (assuming line numbers start at 1)
            const lineWithoutNumbers = line.replace(/^\s*\d+\s*/, '');

            // Check if this line increases or decreases the indentation level
            const openBracesCount = (lineWithoutNumbers.match(/{/g) || []).length;
            const closeBracesCount = (lineWithoutNumbers.match(/}/g) || []).length;

            indentationLevel += openBracesCount - closeBracesCount;

            // Create the indented line
            const indentedLine = '  '.repeat(indentationLevel) + lineWithoutNumbers;

            codeLines.push(indentedLine);
        });

        return codeLines.join('\n');
    }


    const copyToClipboard = () => {
        const codeWithoutLineNumbers = removeLineNumbers(code);
        const formattedCode = formatCode(codeWithoutLineNumbers);

        navigator.clipboard.writeText(formattedCode).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };

    return (
        <div>
            {
                copied ? <LibraryAddCheckIcon sx={{ color: '#00000082', fontSize: 'large', position: 'absolute', right: '0px', top: '0px', padding: '5px' }} /> :
                    <ContentCopyIcon onClick={copyToClipboard} sx={{ cursor: 'pointer', color: '#00000082', fontSize: 'large', position: 'absolute', right: '0px', top: '0px', padding: '5px' }} />
            }
        </div>
    );
}

function renderElement(elm: Element): JSX.Element | null | undefined {
    switch (elm.tagName) {
        case "YOUTUBE":
            return <Box sx={{ display: "flex", justifyContent: 'center', marginTop: 4, marginBottom: 4 }}><YouTubePlayer videoId={elm.textContent!} /></Box>
        case "QUIZ":
            return <Box sx={{ display: "block", marginTop: 4, marginBottom: 4 }}><QuizViewer {...(quizPropsFromElement(elm))} /></Box>
        case "ALERT":
            return <Box sx={{ display: "block", marginTop: 4, marginBottom: 4 }}><Alert {...(alertPropsFromElement(elm))} /></Box>
        case "FIGURE":
            return <Box sx={{ display: "block", marginTop: 4, marginBottom: 4 }}><Figure {...(figurePropsFromElement(elm))} /></Box>
        case "TABLE":
            return <Box sx={{ overflowX: "auto", scrollbarWidth: "thin" }}>{renderNode({ html: "" }, elm)}</Box>
        case "PRE":
            return (
                // get the innerHTML of the element and render it as a <pre> element
                <div className="code-container">
                    <pre
                        dangerouslySetInnerHTML={{ __html: elm.innerHTML }}
                        className="pre-primitive code-primitive pre-code p-primitive"
                    />
                    <CopyButton code={elm.textContent || ""} />
                </div>
            );
    }
}

////////////////////////////////////////////////////////////////////////////////


export function useSearchField(name: string): [value: string | null, setValue: (value: string | null) => void] {
    const { search } = useLocation();
    const navigate = useNavigate();
    return useMemo(() => {
        const query = new URLSearchParams(search);
        const value = query.get(name);
        const dispatch = (newValue: string | null) => {
            if (newValue === null) query.delete(name);
            else query.set(name, newValue);
            navigate("?" + query.toString());
        }
        return [value, dispatch];
    }, [search]);
}



////////////////////////////////////////////////////////////////////////////////

let counter = 0;
let map = new WeakMap<any, number>();

export function id(v: any): number {
    let n = map.get(v);
    if (n !== undefined) return n;
    n = counter++;
    map.set(v, n);
    return n;
}

////////////////////////////////////////////////////////////////////////////////

// see https://github.com/facebook/draft-js/issues/553
export function insertAtomicBlockWithData(editorState: EditorState, entityKey: string, blockType: string, blockData: any, character: any) {
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()

    const afterRemovalContentState = Modifier.removeRange(
        contentState,
        selectionState,
        'backward'
    )

    const targetSelectionState = afterRemovalContentState.getSelectionAfter()
    const afterSplitContentState = Modifier.splitBlock(afterRemovalContentState, targetSelectionState)
    const insertionTarget = afterSplitContentState.getSelectionAfter()

    const asAtomicBlock = Modifier.setBlockType(
        afterSplitContentState,
        insertionTarget,
        blockType
    )

    const charData = CharacterMetadata.create({ entity: entityKey });

    const block = new ContentBlock({
        key: genKey(),
        type: blockType,
        text: character,
        characterList: List(Repeat(charData, character.length)),
        data: blockData
    });
    const data = block.getData();

    const fragmentArray = [
        block,
        new ContentBlock({
            key: genKey(),
            type: 'unstyled',
            text: '',
            characterList: List()
        })
    ];

    // for(const [key, value] of Object.entries(blockData)) {
    //     data.set(key, value);
    // }

    const fragment = BlockMapBuilder.createFromArray(fragmentArray)

    const withAtomicBlock = Modifier.replaceWithFragment(
        asAtomicBlock,
        insertionTarget, fragment
    )

    const newContentState = withAtomicBlock.merge({
        selectionBefore: selectionState,
        selectionAfter: withAtomicBlock.getSelectionAfter().set('hasFocus', true)
    })

    return EditorState.push(editorState, newContentState as any, 'insert-fragment')
}

export function addBlockAfter(editorState: EditorState, keyAfter: string, keyBefore: string, blockParams: Record<string, any>): EditorState {
    const newBlock = new ContentBlock({
        key: genKey(),
        type: 'unstyled',
        text: '',
        ...blockParams,
    });

    const contentState = editorState.getCurrentContent();
    const oldBlockMap = contentState.getBlockMap();
    const newBlockMap = OrderedMap().withMutations(map => {
        for (let [k, v] of oldBlockMap.entries() as any) {
            if (k === keyAfter) {
                map.set(newBlock.getKey(), newBlock);
            }
            map.set(k, v);
            if (keyBefore === k) {
                map.set(newBlock.getKey(), newBlock);
            }
        }
    });

    return EditorState.forceSelection(
        EditorState.push(
            editorState,
            ContentState
                .createFromBlockArray(Array.from(newBlockMap.values() as any))
                .set('selectionBefore', contentState.getSelectionBefore())
                .set('selectionAfter', contentState.getSelectionAfter()) as any,
            'insert-fragment'
        ),
        SelectionState.createEmpty(newBlock.getKey())
    );
}

////////////////////////////////////////////////////////////////////////////////

export function joinSx(a: SystemStyleObject<Theme>, b?: SxProps<Theme>): SxProps<Theme> {
    if (b === undefined)
        return a;
    if (Array.isArray(b)) {
        return [a, ...b];
    }
    return [a, b as SystemStyleObject<Theme>];
}

/////////////////////////////////////////////////////////////////////////////////

export function lineClamp(n: number): SystemStyleObject {
    return {
        lineClamp: `${n}`,
        WebkitLineClamp: `${n}`,
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "-webkit-box"
    }
}

/////////////////////////////////////////////////////////////////////////////////

export function formatDuration(duration: number) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    if (minutes === 0) return `${hours}h`;
    return `${hours}h ${minutes}m`;
}

export function formatDifficulty(difficulty: number) {
    return ["", "Beginner", "Intermediate", "Advanced"][difficulty];
}

////////////////////////////////////////////////////////////////////////////////

export function formatDate(inputDateStr: any, longFormat: boolean = false) {
    const options = {
        month: 'short',
    } as const;

    const inputDate = new Date(inputDateStr);
    const day = inputDate.getDate();
    const daySuffix = getDaySuffix(day);

    return `${inputDate.toLocaleDateString(undefined, options)} ${day}${daySuffix} ${longFormat ? ',' + inputDate.getFullYear() : ""}`;
}

// Function to get the day suffix (e.g., "st" for 1st, "nd" for 2nd, etc.)
export function getDaySuffix(day: number): string {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}


// Function to get the count of the weeks from range of date
interface DateRange {
    from: Date;
    to: Date;
    type?: string
}
export function DateRangeInWeeks({ from, to }: DateRange) {
    // Parse the 'from' and 'to' dates as Date objects
    const fromDate = new Date(from);
    const toDate = new Date(to);

    // Calculate the difference in milliseconds between the two dates
    const dateDiffInMilliseconds = toDate.getTime() - fromDate.getTime();

    // Calculate the number of weeks by dividing the milliseconds difference by the milliseconds in a week
    const weeks = Math.ceil(dateDiffInMilliseconds / (7 * 24 * 60 * 60 * 1000));

    return (
        <span>{weeks} {`${weeks > 1 ? 'weeks' : 'week'}`}</span>
    );
}


interface DateChipProps {
    date: Date | null;
    type: string
}
export function DateChip({ date, type }: DateChipProps): JSX.Element {
    switch (type) {
        case 'start':
            return <Chip icon={<TodayRoundedIcon />} label={`Starts: ${formatDate(date)}`} />
            break;

        case 'end':
            return <Chip icon={<EventRoundedIcon />} label={`Ends: ${formatDate(date)}`} />
            break;

        default: return <Chip icon={<TodayRoundedIcon />} label={`Self Start`} />

    }
}

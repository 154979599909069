import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import {Alert,AlertTitle,Autocomplete,Box,Button,Checkbox,Chip,FormControl,Grid,IconButton,InputBase,Menu,MenuItem,Paper,Skeleton,Stack,TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Course, CoursesResponse, OrgItemId, Topic, TopicsResponse, deleteTopic, getCourse, getCourses, getTopics, patchTopic, postTopic } from "../../../api";
import Page, {
  PageContent,
  TOC,
  TOCHeader,
  TOCList,
  TOCListItem
} from "../../Page";


import { Add } from "@mui/icons-material";
import MoreVert from "@mui/icons-material/MoreVert";
import { useSession } from "../../../ident";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TopicBoxProps {
  topic: Topic;
  index: number;
  onRemove: () => void;
}


function TopicBox(props: TopicBoxProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [title, setTitle] = useState(props.topic.title);
  const open = Boolean(anchorEl);
  const sess = useSession();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickDelete = () => {
    deleteTopic({ id: props.topic.id }).then((resp) => {
      props.onRemove();
    });
    handleClose();
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleOnKeyUp = (event: React.KeyboardEvent) => {
      if(event.key == "Enter")
          changeTitle();
  };

  const handleBlur = (event: React.FocusEvent) => {
      changeTitle();
  };

  const changeTitle = () => {
      patchTopic({id: props.topic.id, title}).then((resp) => {
      })
  };

  return (
    <Paper
      elevation={2}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "2.5rem",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="drag icon">
        <DragHandleIcon />
      </IconButton>
      <Typography>{props.index + 1}. </Typography>
      <InputBase
        sx={{                     
          flex: 1, 
          width: 0, 
          ml: 1, 
          fontSize: "1rem",
          overflow: 'hidden', 
          whiteSpace: 'nowrap', 
          textOverflow: 'ellipsis', 
          '&:focus-within': { 
              borderRadius: '3px 3px 0px 0px',
              px: 1, 
              bgcolor: 'grey.200', 
              borderBottom: '1px solid #1976d2'
          }
      }}
        value={title}
        placeholder="topic name"
        inputProps={{ "aria-label": "topic name" }}
        onChange={handleInputChanged}
        onKeyUp={handleOnKeyUp}
        onBlur={handleBlur}
      />
      <Chip label="draft" />
      <IconButton
        type="submit"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={() => navigate("/topics/" + props.topic.id + "/editor")}
      >
        <EditIcon/>
      </IconButton>
      <IconButton
        id="more-vert"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-vert',
        }}
      >
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
        <MenuItem onClick={handleClose}>Move</MenuItem>
        <MenuItem onClick={handleClose}>Something else?</MenuItem>
      </Menu>
    </Paper>
  )
}


function CourseEditorPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [page, setPage] = useState<TopicsResponse | null>(null);
  const { moduleId, courseId } = params;
  const [course, setCourse] = useState<Course | null>(null);
  const [err, setErr] = useState<any>(null);
  const [currentModule, setCurrentModule] = useState<CoursesResponse | null>(null);
  const sess = useSession();


  useEffect(() => {
    if (!courseId) navigate("/courses");
    else getCourse({ id: courseId }).then((course) => {
      setCourse(course);

      getCourses({ module: course.module, orgs: [course.org] }).then(setCurrentModule, setErr);
    }, setErr);

    getTopics({ course: courseId}).then((page) => {
      setPage(page);
      setErr(null);
    }, setErr);
  }, [sess, courseId]);

  const insertNewTopic = async () => {
    const name = prompt("Insert new topic:");
    if (name) {
      const rank = page!.topics.length;
      postTopic({ course: courseId!, title: name, org: course!.org, rank }).then((resp) => {
        setPage(page => {
          return {
            topics: [
              ...page!.topics,
              {
                id: resp,
                createdAt: new Date(),
                org: course!.org,
                orgName: course!.orgName,
                title: name,
                desc: "",
                modifiedAt: new Date(),
                course: course!.id,
                content: "",
                draft: true,
                owner: null, // todo
                rank,
              }
            ],
            numTotal: page!.numTotal + 1,
            numFound: page!.numFound + 1,
          }
        });
      });
    }
  }

  function setShowCourse(id: string) {
    navigate(`/courses/${id}/editor`);
  }

  const skeleton = (
    <Stack spacing={1}>
      <Skeleton variant="text" />
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={118} />
    </Stack>
  );

  let content: JSX.Element | null;
  if (!course || !currentModule) return null;
  const { courses } = currentModule;

  // Get number of courses and titles in the course
  var noCourses = courses.length;

  if (err) {
    content = <span>{`${err}`}</span>;
  } else if (course === null) {
    content = skeleton;
  } else {
    let topicComponents: JSX.Element | undefined;
    if (page) {
      const { topics } = page;

      topicComponents = (
        <>
          {topics.sort((a, b) => a.rank - b.rank).map((topic, index) => {
            function onRemove() {
              page!.topics = page!.topics.filter((c) => c !== topic);
              setPage({ ...page! });
            }
            return (
              <TopicBox key={topic.id} topic={topic} index={index} onRemove={onRemove}></TopicBox>
            );
          })}
        </>
      );
    }

    content = (
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <Box component="form" noValidate autoComplete="off">
            <FormControl sx={{ width: "100%" }}>
              <Box>
                <Typography variant="body1">Title</Typography>
                <TextField
                  fullWidth
                  multiline
                  id="outlined-bare"
                  variant="outlined"
                  value={course.title}
                  InputProps={{ style: { fontSize: "1rem", padding: "8px" } }}
                />
              </Box>
              <Box mt={3}>
                <Typography variant="body1">Description (optional)</Typography>
                <TextField
                  fullWidth
                  multiline
                  id="outlined-bare"
                  variant="outlined"
                  rows={4}
                  value={course.desc}
                  InputProps={{ style: { fontSize: "1rem", padding: "8px" } }}
                />
              </Box>
              <Box mt={3}>
                <Typography variant="body1">Files</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: 100,
                      height: 100,
                    },
                  }}
                >
                  <Paper elevation={2} />
                  <Paper elevation={2} />
                  <Paper elevation={2} />
                </Box>
              </Box>
              <Box mt={3}>
                <Typography variant="body1">Relative tools</Typography>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="checkboxes-tags-demo"
                  options={tools}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      hiddenLabel
                      {...params}
                      placeholder="Favorites"
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>Warning (this cannot be undone)</AlertTitle>
                  <Button startIcon={<DeleteIcon />} color="error">Delete Course</Button>
                  {" "}
                  {/* <strong>!</strong> */}
                </Alert>
              </Box>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">Topics</Typography>
              {/* <IconButton
                aria-label="delete"
                size="small"
                sx={{
                  ":hover": { backgroundColor: "primary.main", color: "#6B0024" },
                }}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton> */}
            </Box>
          </Box>
          <Box
            sx={{ mt: 1, mb: 1 }}
            gap={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {topicComponents}
          </Box>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button startIcon={<Add />} variant="contained" onClick={insertNewTopic}>
              Add Topic
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }

  // The table-of-contents (TOC) will be shown on a left bar on desktop
  // or on a second AppBar on mobile.
  var toc = 
    noCourses != 1 ? (
      <TOC>
        <TOCHeader>
          <Typography variant="body2">{noCourses} courses</Typography>
        </TOCHeader>
        <TOCList>
          {courses.sort((a, b) => a.rank - b.rank).map(
            (
              item
            ) => (
              <div
                key={item.id}
                onClick={() => {
                  setShowCourse(item.id);
                }}
              >
                <TOCListItem
                  active={item.id === courseId}
                  primary={item.title}
                />
              </div>
            )
          )}
        </TOCList>
      </TOC>
    ) : null;


  // Now returns the whole currentModule component:
  return (
    <Page
      title="Course Editor"
      toc={toc}
      onNavigateBack={() => navigate(-1)}
    >
      <PageContent>{content}</PageContent>
    </Page>
  );
}

export default CourseEditorPage;

const dummy_array = [1, 2, 3, 4];
const tools = [
  { label: "arduino mini", year: 1994 },
  { label: "itsybitsy board", year: 1972 },
  { label: "wazidev", year: 1974 },
  { label: "teensy board", year: 2008 },
];

interface TopicsListProps {
  course: OrgItemId;
  topics: Topic[];
}

// export function TopicsList(props: TopicsListProps) {
//   const { course } = props;

//   const [page, setPage] = useState<TopicsResponse | null>(null);
//   const [err, setErr] = useState<any>(null);
//   const api = useAPI();

//   const navigate = useNavigate();

//   const [name, setName] = useState<React.ChangeEvent | null>();
//   const [isNameFocused, setIsNamedFocused] = useState(false);

//   useEffect(() => {
//     api.getTopics({ course }).then((page) => {
//       setPage(page);
//       setErr(null);
//     }, setErr);
//   }, [course]);

//   function handleClick(event: Event) {
//     const { target } = event;
//     if (target) console.log((target as HTMLButtonElement).value);
//   }

//   if (err) return <ErrorView err={err} />;
//   if (page === null) return null;
//   const { topics } = page;
//   console.log('this is topic list: ', topics);


  //   <DragDropContext
  //     onDragEnd={(param) => {
  //       const srcIdx = param.source.index;
  //       const desIdx: any = param.destination?.index;
  //       if (desIdx) {
  //         topics.splice(desIdx, 0, topics.splice(srcIdx, 1)[0]);
  //       }
  //       console.log(param);
  //     }}
  //   >
  //     <List sx={{ width: "100%", bgcolor: "background.paper" }}>
  //       <Droppable droppableId="droppableId-1">
  //         {(provided, _) => (
  //           <div ref={provided.innerRef} {...provided.droppableProps}>
  //             {topics.map((topic, index) => (
  //               <Draggable
  //                 key={topic.id}
  //                 draggableId={`draggbleID-${topic.id}`}
  //                 index={index}
  //               >
  //                 {(provided, snapshot) => (
  //                   <ListItem
  //                     ref={provided.innerRef}
  //                     {...provided.draggableProps}
  //                     {...provided.dragHandleProps}
  //                     key={topic.id}
  //                     sx={{
  //                       border: 1,
  //                       borderRadius: 1,
  //                       borderColor: "#c9c9c9",
  //                       marginBottom: 1,
  //                       backgroundColor: "#fff",
  //                     }}
  //                   >
  //                     <Grid
  //                       container
  //                       display="flex"
  //                       alignItems="center"
  //                       justifyContent="space-between"
  //                     >
  //                       <Grid
  //                         item
  //                         sm={9}
  //                         md={10}
  //                         display="flex"
  //                         alignItems="center"
  //                       >
  //                         <DragHandleIcon
  //                           color="primary"
  //                           sx={{ marginRight: 2 }}
  //                         />
  //                         {!isNameFocused ? (
  //                           <Typography
  //                             onClick={() => {
  //                               setIsNamedFocused(true);
  //                             }}
  //                           >
  //                             {topic.title}
  //                           </Typography>
  //                         ) : (
  //                           <TextField
  //                             autoFocus
  //                             value={topic.title}
  //                             onChange={() => handleClick}
  //                             onBlur={(event) => setIsNamedFocused(false)}
  //                           />
  //                         )}
  //                       </Grid>
  //                       <Grid
  //                         item
  //                         sm={3}
  //                         md={2}
  //                         display="flex"
  //                         justifyContent="space-between"
  //                       >
  //                         <Chip
  //                           label="edit content"
  //                           sx={{ backgroundColor: "#BDC8D7", color: "#14355E" }}
  //                           onClick={() =>
  //                             navigate(`/courses/editor/:id/topic/${topic.id}`)
  //                           }
  //                         />
  //                         <IconButton aria-label="delete" size="small">
  //                           <DeleteIcon fontSize="medium" color="primary" />
  //                         </IconButton>
  //                       </Grid>
  //                     </Grid>
  //                   </ListItem>
  //                 )}
  //               </Draggable>
  //             ))}
  //             {provided.placeholder}
  //           </div>
  //         )}
  //       </Droppable>
  //     </List>
  //   </DragDropContext>;
  //}


import { toast } from "@halliday/mui-toast";
import { EditOutlined, FormatListNumbered, GroupOutlined, InfoOutlined, SaveOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, LinearProgress, Stack, Switch, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteOrganization, File, fileUrl, Organization, patchOrganization, PatchOrganizationRequest } from "../../api";
import firstOfSet, { formatSize, slugify, useForceUpdate } from "../../tools";
import FileSelectionDialog from "../FileSelectionDialog";
import { hub } from "../../Hub";

export interface SettingsPageProps {
    org: Organization
}

export default function SettingsPage(props: SettingsPageProps) {
    const { org } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const [title, setTitle] = useState(org.title);

    function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setTitle(e.target.value);
    }

    const [desc, setDesc] = useState(org.desc);

    function handleDescChange(e: React.ChangeEvent<HTMLInputElement>) {
        setDesc(e.target.value);
    }

    const [publ, setPublic] = useState(org.public);

    function handlePublicChange(e: React.ChangeEvent<HTMLInputElement>) {
        setPublic(e.target.checked);
    }

    const [open, setOpen] = useState(org.open);

    function handleOpenChange(e: React.ChangeEvent<HTMLInputElement>) {
        setOpen(e.target.checked);
    }

    const [icon, setIcon] = useState(org.cover);

    const hasChanges = title !== org.title || desc !== org.desc || publ !== org.public || open !== org.open || icon?.id !== org.cover?.id;

    const forceUpdate = useForceUpdate();

    const [iconDlgOpen, setIconDlgOpen] = useState(false);
    function openIconDlg() {
        setIconDlgOpen(true);
    }
    function closeIconDlg() {
        setIconDlgOpen(false);
    }

    async function submitChanges() {
        const p: PatchOrganizationRequest = { slug: org.slug };
        if (title !== org.title) p.title = title;
        if (desc !== org.desc) p.desc = desc;
        if (publ !== org.public) p.public = publ;
        if (open !== org.open) p.open = open;
        if (icon?.id !== org.cover?.id) p.cover = icon?.id;
        try {
            await patchOrganization(p);
        } catch (err) {
            alert("There was an error saving your changes.\n" + err);
            return;
        }
        if (title !== org.title) {
            org.title = title;
            org.slug = slugify(title);
            navigate("/orgs/" + org.slug, { replace: true });
        }
        org.desc = desc;
        org.public = publ;
        org.open = open;
        org.cover = icon;
        org.coverUrl = icon?.url;
        hub.emit(".organization-update", org);
        forceUpdate();
        toast("Changes saved successfully.")
    }

    function resetChanges() {
        setTitle(org.title);
        setDesc(org.desc);
        setPublic(org.public);
        setOpen(org.open);
    }

    function onIconSelect(files: Set<File>) {
        closeIconDlg();

        const icon = firstOfSet(files);
        if (!icon) return;
        setIcon(icon);
    }

    async function deleteGroup() {
        if (!window.confirm("Are you sure you want to delete this group?\nThis action can not be undone.")) return;
        await deleteOrganization({ id: org.slug });
        navigate("/orgs");
    }

    return <Box sx={{ maxWidth: 1400 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
            <Box sx={{ marginTop: "-75px", display: "flex", gap: 1 }}>
                <Button variant="outlined" startIcon={<SaveOutlined />} onClick={submitChanges} disabled={!hasChanges}>Save</Button>
                <Button onClick={resetChanges} disabled={!hasChanges} color="inherit">Reset</Button>
            </Box>
        </Box>
        <Grid container rowSpacing={3} columnSpacing={12}>
            <Grid item xs={12} lg={7}>
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" sx={{ mb: 1 }}>Organization Title</Typography>
                    <TextField variant="outlined" fullWidth sx={{ mb: 3 }} value={title} onChange={handleTitleChange} />

                    <Typography variant="h4" sx={{ mb: 1 }}>Organization Description</Typography>
                    <TextField variant="outlined" fullWidth sx={{ mb: 3 }} InputProps={{ sx: { minHeight: 102, alignItems: "start" } }} value={desc} onChange={handleDescChange} multiline />

                    <Stack direction="row" spacing={2} sx={{ mb: 1 }} alignItems="baseline">
                        <Typography variant="h4">Organization Icon</Typography>
                        <Typography variant="body2">Optimal size 64x64 px</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ mb: 3, pl: 2 }} alignItems="center">
                        {/* <Avatar>H</Avatar> */}
                        {icon ? <Avatar alt={title} src={icon?.url} sx={{ bgcolor: "#bdbdbd" }} /> : <Avatar alt={title} sx={{ bgcolor: "#bdbdbd" }} ><GroupOutlined /></Avatar>}
                        <Button startIcon={<EditOutlined />} variant="outlined" color="inherit" size="small" onClick={openIconDlg}>Change</Button>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
                <Box sx={{ mb: 6 }}>
                    <Grid container rowSpacing={6} columnSpacing={4}>
                        <Grid item xs={4}><Typography variant="h4">Visibility</Typography></Grid>
                        <Grid item xs={8}>
                            <Stack>
                                <div><Switch checked={publ} onChange={handlePublicChange} /><Typography component="label">{publ ? "Public" : "Members Only"}</Typography></div>
                                <Typography variant="body2" color="text.secondary"><InfoOutlined fontSize="small" sx={{ verticalAlign: "sub", mr: .5 }} />{publ ? "Content is visible to all platform users." : "Content is visible to members of this organization only."}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={4}><Typography variant="h4">Membership</Typography></Grid>
                        <Grid item xs={8}>
                            <Stack>
                                <div><Switch checked={open} onChange={handleOpenChange} /><Typography component="label">{open ? "Open for everyone" : "Invitation Only"}</Typography></div>
                                <Typography variant="body2" color="text.secondary"><InfoOutlined fontSize="small" sx={{ verticalAlign: "sub", mr: .5 }} />{open ? "Everyone can join this organization." : "Invite users to join this organization."}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={4}><Typography variant="h4">Storage</Typography></Grid>
                        <Grid item xs={8}>
                            <StorageBar value={org.filesSize! || 0} max={2.00 * 1024 * 1024 * 1024} />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} lg={7}>
                <Box sx={{ minHeight: 160, borderWidth: 1, borderRadius: theme.shape.borderRadius, borderStyle: "solid", borderColor: theme.palette.error.light, p: 3, mb: 6 }}>
                    <Typography variant="h4" sx={{ bgcolor: "background.default", px: 2, mt: -5.25, mb: 3, color: "error.main", width: "fit-content" }}>Danger Zone</Typography>

                    <Stack direction="row" alignItems="center" gap={3}>
                        <Button variant="outlined" color="error" onClick={deleteGroup}>Delete Organization</Button>
                        <Typography variant="body2">This can not be undone!</Typography>
                    </Stack>

                </Box>
            </Grid>
        </Grid>

        <FileSelectionDialog open={iconDlgOpen} onClose={closeIconDlg} onSelectionInput={onIconSelect} org={org.id} />
    </Box>
}

interface StorageBarProps {
    value: number,
    max: number
}

function StorageBar(props: StorageBarProps) {
    const { value, max } = props;
    return <>
        <LinearProgress variant="determinate" value={value / max * 100} sx={{ mb: 0.5 }} />
        <Typography variant="body2" color="text.secondary">{formatSize(value)} ({Math.round(value / max * 100)}%) used of {formatSize(max)}</Typography>
    </>
}
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Breadcrumbs, useMediaQuery, useTheme } from '@mui/material';
import Link from '@mui/material/Link';

interface BreadCrumbsProps {
    name?: string;
    activeUnit?: string;
    activeDoc?: string;
    progName?: string;
  }

const BreadCrumbs = ( props: BreadCrumbsProps ) => {
    const location = useLocation();

    const pathnames = location.pathname.split('/').filter((x) => x);
    const pathurls = location.pathname.split('/').filter((x) => x);

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const capitalizeFirstLetter = (str: string) => {
        const words = str.split('-');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(' ');
    };

    // check if pathnames includes 'programs', 'courses', 'solutions', 'resources' and array only has 2 element then set the name of the program as the last element of the pathnames array
    if (pathnames.includes('programs') && pathnames.length === 2) {
        // pathnames[1] = props.name ? props.name : pathnames[1];
        pathnames[1] = props.progName ? props.progName : props.name ? props.name : pathnames[1];
    }

    if (pathnames.includes('courses') && pathnames.length === 2) {
        pathnames[1] = props.name ? props.name : pathnames[1];
    }

    if (pathnames.includes('solutions') && pathnames.length === 2) {
        pathnames[1] = props.name ? props.name : pathnames[1];
    }

    if (pathnames.includes('resources') && pathnames.length === 2) {
        pathnames[1] = props.name ? props.name : pathnames[1];
    }

    if (pathnames.includes('orgs') && pathnames.length === 2) {
        pathnames[1] = props.name ? props.name : pathnames[1];
    }

    // check if the pathnames includes 'programs', 'solution', 'units' and array has more than 2 elements then set the name of the program as the last element of the pathnames array
    if (pathnames.includes('programs') && pathnames.length > 2) {
        pathnames[1] = props.progName ? props.progName : props.name ? props.name : pathnames[1];
    }

    if (pathnames.includes('resources') && pathnames.length > 2) {
        pathnames[1] = props.name ? props.name : pathnames[1];
        // remove the second element of the array
        pathnames.splice(2, 1);
    }

    if (pathnames.includes('solutions') && pathnames.length > 2) {
        pathnames[1] = props.name ? props.name : pathnames[1];
        // remove the second element of the array
        pathnames.splice(2, 1);
    }

    if (pathnames.includes('units') && pathnames.length >= 4) {
        pathnames[2] = props.activeUnit ? props.activeUnit : pathnames[2];
        // remove the third element of the array
        pathnames.splice(3, 1);
    }

    if (pathnames.includes('docs') && pathnames.length >= 4) {
        pathnames[2] = props.activeDoc ? props.activeDoc : pathnames[2];
        // remove the third element of the array
        pathnames.splice(3, 1);
        // adjust the url path to combine the unit and doc name
        pathurls[1] = pathurls[1] + "/" + pathurls[2];
    }

    if (pathnames.includes('resources') && pathnames.length === 3) {
        pathnames[2] = props.activeDoc ? props.activeDoc : pathnames[2];
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '4rem', marginLeft: sm ? '3rem' : '1rem', marginBottom: '1rem' }}>
        {pathnames.length === 0 ? null : (
            <Link color="inherit" href="/" underline="hover">
                Home
            </Link>
        )}
    
        {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathurls.slice(0, index + 1).join('/')}`;
    
            return last ? (
            <Typography color="textPrimary" key={to}>
                {capitalizeFirstLetter(value)}
            </Typography>
            ) : (
            <span key={to}>
                <Link color="inherit" href={to} underline="hover">
                {capitalizeFirstLetter(value)}
                </Link>
            </span>
            );
        })}
        </Breadcrumbs>
    );
};

export default BreadCrumbs;

import { styled } from "@mui/material";
import { apiUrl } from "../config";

export interface FigureProps {
    src: string;
    link?: string;
    target?: string;
    rel?: string;
    alt?: string;
    title?: string;
    caption?: string;
    width?: string;
    height?: string;
    maxWidth?: string;
    maxHeight?: string;
    attr?: string;
    attrLink?: string;
}

export function figurePropsFromElement(elm: Element): FigureProps {
    return {
        src: elm.getAttribute("src") ?? "",
        link: elm.getAttribute("link") ?? undefined,
        target: elm.getAttribute("target") ?? undefined,
        rel: elm.getAttribute("rel") ?? undefined,
        alt: elm.getAttribute("alt") ?? undefined,
        title: elm.getAttribute("title") ?? undefined,
        caption: elm.getAttribute("caption") ?? undefined,
        width: elm.getAttribute("width") ?? undefined,
        height: elm.getAttribute("height") ?? undefined,
        maxWidth: elm.getAttribute("maxWidth") ?? undefined,
        maxHeight: elm.getAttribute("maxHeight") ?? undefined,
        attr: elm.getAttribute("attr") ?? undefined,
        attrLink: elm.getAttribute("attrLink") ?? undefined,
    }
}

export default function Figure(props: FigureProps) {
    const { src, link, target, rel, alt, title, caption, width, height, maxWidth, maxHeight, attr, attrLink } = props;

    const imgSrc = new URL(src, new URL(apiUrl, window.location.href).origin).toString();
    const img = <Img src={imgSrc} alt={alt || caption || title} sx={{ maxWidth: maxWidth ? `min(${maxWidth}, 100%)` : maxWidth, width, maxHeight, height }} />;
    return <StyledFigure>
        {link ? <a href={link} rel={rel} target={target}>{img}</a> : img}
        {(caption || title || attr) && <figcaption>
            {title && <h4>{title}</h4>}
            {caption}
            {attr && <a href={attrLink} target="_blank" rel="noopener noreferrer">{attr}</a>}
        </figcaption>}
    </StyledFigure>
}

const Img = styled("img")({ maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" });

const StyledFigure = styled("figure")(({ theme }) => ({
    "margin": 0, 
    " figcaption": { fontSize: "0.8em", color: theme.palette.text.secondary, marginTop: "8px", marginBottom: "8px", textAlign: "center" },
    " h4": { margin: "0" }
}));
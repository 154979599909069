import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { File, OrganizationId, OrgItemId } from "../api";
import { drawerWidth } from "./Drawer";
import FilesList, { useFilesSelection } from "./FilesList";

interface FileSelectionDialogProps extends DialogProps {
    title?: string,
    multiple?: boolean,
    accept?: string,
    onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => void,
    defaultSelection?: Set<OrgItemId>,
    onSelectionInput: (sel: Set<File>) => void,
    org?: OrganizationId,
}


export default function FileSelectionDialog(props: FileSelectionDialogProps) {
    const { title, defaultSelection, onSelectionInput, org, ...dialogProps } = props;

    const handleClose = (ev: React.MouseEvent) => {
        if (props.onClose)
            props.onClose(ev, "closeClick")
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const selection = useFilesSelection(props.multiple, defaultSelection);

    const handleSubmit = () => {
        onSelectionInput(selection.files);
    }

    return <Dialog
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth="xl"
        sx={{ml: {sm: 0, lg: drawerWidth+"px"}}}
        {...dialogProps}
    >
        <DialogTitle>{title || "Select a file"}</DialogTitle>
        <DialogContent>
            <FilesList selectable={true} selection={selection} org={org}/>
            {/* <DialogContentText>
                You can set my maximum width and whether to adapt or not.
            </DialogContentText> */}
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="secondary" disabled={selection.size === 0} onClick={handleSubmit}>
                { selection.size <= 1 ? "Select file" : `Select ${selection.size} files`}
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
}
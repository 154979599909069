import { Box, Button, Dialog, DialogActions, DialogProps, DialogTitle, IconButton, Tooltip, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import Selection from "../../Selection";
import { ListUsersResponse, User, UsersQuery } from "../../api";
import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { useUsers } from "../../loaders";
import { useForceUpdate, useListener } from "../../tools";
import { SearchTextField } from "../SearchTextField";
import UsersTable from "./UsersTable";
import { useSession } from "../../ident";

interface CommonSelectUsersProps {
    onUsersSelect(users: UsersQuery): void;
    updateAutoJoin?: autoJoinUpdater;
    updateInvitationMail?: invitationMailUpdater;
    autoJoinParent?: boolean;
    sendInvitationMailParent?: boolean;
}

interface DialogSelectUsersProps extends DialogProps, CommonSelectUsersProps {
    onClose(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeClick" | "cancelClick"): void;
}

export default function DialogSelectUsers(props: DialogSelectUsersProps) {
    const { onUsersSelect, ...dialogProps } = props;
    return <Dialog fullWidth {...dialogProps} PaperProps={{ sx: { maxWidth: 750 } }}>
        <PanelSelectUsers {...props} />
    </Dialog>

}

interface PanelSelectUsersProps extends CommonSelectUsersProps {
    onClose(event: {}, reason: "cancelClick" | "closeClick"): void;
}

const defaultPageSize = 10;

interface autoJoinUpdater { (state: boolean): void }
interface invitationMailUpdater { (state: boolean): void }

function PanelSelectUsers(props: PanelSelectUsersProps) {
    const { onUsersSelect, onClose, updateAutoJoin, updateInvitationMail, autoJoinParent, sendInvitationMailParent } = props;

    const session = useSession();

    const [searchOpen, setSearchOpen] = useState(false);
    function closeSearch() { setSearch(""); setSearchOpen(false); }
    function openSearch() { setSearchOpen(true); }

    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [search, setSearch] = useState("");

    const [sendInvitationMail, setSendInvitationMail] = useState(true);
    const [autoJoin, setAutoJoin] = useState(false);

    const loader = useUsers(search, pageSize);
    const selection = useMemo(() => new Selection<User>(), [loader]);

    function handleSearchChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setSearch(ev.target.value);
    }

    function onPageSizeChange(ev: {}, pageSize: number) {
        setPageSize(pageSize);
    }

    const forceUpdate = useForceUpdate();

    useListener(selection, "change", forceUpdate);

    function submit() {
        onUsersSelect({
            all: selection.isAll,
            search,
            ids: Array.from(selection.items).map(u => u.id),
        });
    }

    const [page, setPage] = useState<ListUsersResponse | null>(null);
    function handlePageChange(ev: {}, n: number, p: Promise<ListUsersResponse>) {
        p.then(setPage);
    }

    const numTotal = page?.numUsersFound ?? 0;
    const numSelected = selection.isAll ? numTotal - selection.size : selection.size;

    const isAdmin = session?.user.roles.includes("admin") ?? false;

    function handleSendInvitationMailChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setSendInvitationMail(ev.target.checked);
        updateInvitationMail!(ev.target.checked);
    }

    function handleAutoJoinChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setAutoJoin(ev.target.checked);
        updateAutoJoin!(ev.target.checked);
    }

    useEffect(() => { 
        if (autoJoinParent !== undefined) {
            setAutoJoin(autoJoinParent);
        }
        if (sendInvitationMailParent !== undefined) {
            setSendInvitationMail(sendInvitationMailParent);
        }
     }, [autoJoinParent, sendInvitationMailParent]);

    return <>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, pr: 1.5 }}>
            <DialogTitle sx={{ pb: .5, pr: .5 }}>Select Users</DialogTitle>
            <Box sx={{ flexGrow: 1, marginTop: "14px" }}>
                {numSelected > 0 ?
                    <Typography color="primary.main" variant="body2" component="span">{numSelected} of {numTotal} selected</Typography> :
                    <Typography variant="body2" component="span">{numTotal} found</Typography>}
            </Box>
            {searchOpen ?
                <SearchTextField onClose={closeSearch} placeholder="Name, Email, …" value={search} onChange={handleSearchChange} /> :
                <Tooltip title="Search"><IconButton onClick={openSearch}><SearchOutlined /></IconButton></Tooltip>}
            <IconButton onClick={(ev) => { onClose(ev, "closeClick") }}><CloseOutlined /></IconButton>
        </Box>
        <Box sx={{ px: 0 }}>
            <UsersTable
                sx={{ " .DataGridBody": { maxHeight: "calc(100vh - 280px)", overflowY: "scroll" } }}
                loader={loader}
                selection={selection}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
                onPageChange={handlePageChange}
            />
        </Box>

        <DialogActions sx={{ px: 1.5 }}>
        {isAdmin && <>
            <FormControlLabel control={<Checkbox checked={sendInvitationMail} onChange={handleSendInvitationMailChange} />} label="Send Invitation Email" />
            <FormControlLabel control={<Checkbox checked={autoJoin} onChange={handleAutoJoinChange} />} label="Auto Join" />
        </>}
            <Button onClick={(ev) => { onClose(ev, "cancelClick") }} color="inherit">Cancel</Button>
            <Button onClick={submit} disabled={numSelected === 0}>Submit ({numSelected})</Button>
        </DialogActions>
    </>
}

import { createSvgIcon, styled, SxProps } from "@mui/material";

const CheckSmall = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
    >
        <path d="M400-304 240-464l56-56 104 104 264-264 56 56-320 320Z" />
    </svg>,
    'CheckSmall',
);

export default CheckSmall;
import { MsgBox } from "@halliday/mui-msgbox";
import { toast } from "@halliday/mui-toast";
import { AddOutlined, BlockOutlined, CheckOutlined, CircleOutlined, DeleteOutlined, EditOutlined, Help, HelpOutline, HelpOutlined, PasswordOutlined, SchoolOutlined, Terminal, TerminalOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, Chip, ChipProps, Dialog, DialogActions, DialogContent, DialogProps, Divider, FormControl, IconButton, Input, InputAdornment, InputBase, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Typography, styled } from "@mui/material";
import { useState } from "react";
import { hub } from "../../Hub";
import Selection from "../../Selection";
import { User, UserId, UserUpdate, UsersQuery, deleteUsers, updateUsers } from "../../api";
import { preventDefault, useForceUpdate } from "../../tools";
import DialogCloseButton from "../DialogCloseButton";
import TimeAgo from "../TimeAgo";
import { AccountAvatar } from "./AccountAvatar";

export type EditUserPanelProps = {
    user: User,
    autoFocusField?: "email" | "username" | "password",
    onClose?: (ev: {}, reason: "cancelClick" | "saveClick" | "deleteClick") => void,
}

const emailRegExp = /\S+@\S+\.\S+/;

function isValidEmail(email: string) { return emailRegExp.test(email); }
function isValidUsername(username: string) { return username.trim() !== ""; }

export type UserUpdateEvent = {
    selection: Selection<UserId>,
    update: UserUpdate,
}

export function EditUserPanel(props: EditUserPanelProps) {
    const { user, onClose } = props;

    const [showErrors, setShowErrors] = useState(false);

    const [email, setEmail] = useState(user.email || "");
    const hasEmailErr = email !== "" && !isValidEmail(email);
    const [emailVerified, setEmailVerified] = useState(user.emailVerified || false);
    const [username, setUsername] = useState(user.username || "");
    const hasUsernameErr = username !== "" && !isValidUsername(username);
    const [usernameVerified, setUsernameVerified] = useState(user.usernameVerified || false);
    const [password, setPassword] = useState("");

    const [err, setErr] = useState<any>(null);

    const hasErr = hasEmailErr || hasUsernameErr;
    if (!hasErr && showErrors) setShowErrors(false);

    const [loading, setLoading] = useState(false);

    //

    async function submit() {
        if (hasErr) {
            setShowErrors(true);
            return;
        }
        const update: UserUpdate = {};
        const query: UsersQuery = { ids: [user.id] };

        if (email !== user.email) update.email = email;
        if (emailVerified !== user.emailVerified) update.emailVerified = emailVerified;
        if (username !== user.username) update.username = username;
        if (usernameVerified !== user.usernameVerified) update.usernameVerified = usernameVerified;
        if (password !== "") update.newPassword = password;
        if (addedRoles.length) update.addRoles = addedRoles;
        if (removedRoles.length) update.removeRoles = removedRoles;

        if (Object.keys(update).length === 0) {
            MsgBox("No changes to save.");
            return;
        }

        setLoading(true);
        try {
            await updateUsers({ query, update });
        } catch (err) {
            reportError(err);
            return;
        } finally {
            setLoading(false);
        }

        user.email = email || undefined;
        user.emailVerified = emailVerified;
        user.username = username || undefined;
        user.usernameVerified = usernameVerified;
        user.updatedAt = new Date();

        const selection = new Selection<UserId>([user.id]);
        hub.emit(".user-update", { selection, update });

        onClose?.({}, "saveClick");
        toast("User updated.");
    }

    //


    function suspendUser() {
        MsgBox({
            title: "Suspend User",
            text: <>The selected account will be suspended and can not login anymore. You can release the user at any time.<br />The account will not be deleted!</>,
            handleCancel: () => { },
            handleOk: () => {
                updateUsers({ query: { ids: [user.id] }, update: { suspended: true } }).then(() => {
                    user.suspended = true;
                    // forceUpdate();
                    toast("User suspended.");
                }, reportError);
            }
        });
    }

    function releaseUser() {
        updateUsers({ query: { ids: [user.id] }, update: { suspended: false } }).then(() => {
            user.suspended = false;
            // forceUpdate();
            toast("User released.");
        }, reportError);
    }

    function deleteUser() {
        MsgBox({
            title: "Delete User",
            text: <>The selected user will be deleted. This can not be undone!</>,
            handleCancel: () => { },
            handleOk: () => {
                deleteUsers({ ids: [user.id] }).then(() => {
                    // onUserDelete(user);
                    toast("User deleted.");
                    onClose?.({}, "deleteClick");
                }, reportError);
            }
        });
    }

    function askChangePassword() {

    }

    //

    let emailHelperText: string | undefined;
    if (showErrors) {
        if (!email.trim()) emailHelperText = "Email address can not be empty.";
        else if (hasEmailErr) emailHelperText = "Invalid email address format."
    }

    const emailVerifiedInput = (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Box component="label" id="email-verified-label" sx={{ fontSize: 12 }}>Verified</Box>
            <Checkbox checked={emailVerified} aria-labelledby="email-verified-label" sx={{ mt: -1.5, mb: -.5 }} color="default" onChange={(ev, checked) => { setEmailVerified(checked); }} />
        </Box>
    );

    let usernameHelperText: string | undefined;
    if (showErrors) {
        if (hasUsernameErr) usernameHelperText = "Username can not be empty."
    }

    const usernameVerifiedInput = (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Box component="label" id="username-verified-label" sx={{ fontSize: 12 }}>Verified</Box>
            <Checkbox checked={usernameVerified} aria-labelledby="username-verified-label" sx={{ mt: -1.5, mb: -.5 }} color="default" onChange={(ev, checked) => { setUsernameVerified(checked); }} />
        </Box>
    );

    function handleNewRoleClick(ev: React.MouseEvent) {
        ev.currentTarget.querySelector("input")!.focus();
    }

    const [newRole, setNewRole] = useState("");

    function handleNewRoleSubmit(ev: React.FormEvent) {
        console.log("handleNewRoleSubmit");
        ev.preventDefault();
        addRoles(newRole);
        setNewRole("");
        closeMenuRoles();
    }

    function addRoles(value: string) {
        console.log("addRole submitted")
        // ev.preventDefault();
        setNewRole("");
        const roles = value
            .split(/[\s,]/)
            .map(role => role.trim().toLowerCase())
            .filter(r => r !== "");
        setRemovedRoles(removedRoles.filter(r => !roles.includes(r)));
        setAddedRoles([...addedRoles, ...roles.filter(r => !user.roles.includes(r) && !addedRoles.includes(r))]);
        closeMenuRoles();
    }

    const [addedRoles, setAddedRoles] = useState<Array<string>>([]);
    const [removedRoles, setRemovedRoles] = useState<Array<string>>([]);

    function handleRoleRemove(ev: React.SyntheticEvent, role: string) {
        setAddedRoles(addedRoles.filter(r => r !== role));
        if (user.roles.includes(role) && !removedRoles.includes(role))
            setRemovedRoles([...removedRoles, role]);
    }

    const roles = [...user.roles, ...addedRoles].filter(role => !removedRoles.includes(role));


    const [menuRolesAnchorEl, setMenuRolesAnchorEl] = useState<null | HTMLElement>(null);
    function openMenuRoles(ev: React.MouseEvent<HTMLElement>) {
        setMenuRolesAnchorEl(ev.currentTarget);
    }
    function closeMenuRoles() {
        setMenuRolesAnchorEl(null);
    }

    return <>
        <DialogContent sx={{ pt: 5 }}>
            <Form id="edit-user" onSubmit={preventDefault} sx={{ mb: 2 }}>
                <AccountAvatar name={user.username || user.email || "??"} picture={user.picture} sx={{ width: 64, height: 64, mx: "auto" }} />

                <Typography variant="h3" sx={{ mb: 1 }}>Personal</Typography>
                <TextField
                    autoFocus={props.autoFocusField === "username" || !props.autoFocusField}
                    id="username"
                    label="Anzeigename"
                    type="text"
                    fullWidth
                    variant="filled"
                    autoComplete="off"
                    sx={{ mb: 2 }}
                    value={username}
                    helperText={usernameHelperText}
                    error={showErrors && hasUsernameErr}
                    onChange={ev => { setUsername(ev.target.value); }}
                    disabled={loading}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{usernameVerifiedInput}</InputAdornment>
                    }} />
                <TextField
                    autoFocus={props.autoFocusField === "email"}
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="filled"
                    autoComplete="off"
                    sx={{ mb: 4 }}
                    value={email}
                    helperText={emailHelperText}
                    error={showErrors && hasEmailErr}
                    onChange={ev => { setEmail(ev.target.value); }}
                    disabled={loading}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{emailVerifiedInput}</InputAdornment>
                    }}
                />

                {/* <Typography variant="h3" sx={{ mb: 1 }}>Login</Typography>
                <PasswordField
                    autoFocus={props.autoFocusField === "password"}
                    id="password"
                    label="Password (optional)"
                    fullWidth
                    variant="filled"
                    autoComplete="off"
                    sx={{ mb: 4 }}
                    value={password}
                    onChange={ev => { setPassword(ev.target.value); }}
                    disabled={loading} /> */}
            </Form>

            <Typography variant="h3" sx={{ mb: 1 }}>Roles</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 4 }}>
                {roles.map(role => <RoleChild key={role} label={role} onDelete={ev => handleRoleRemove(ev, role)} />)}
                {/* <Box sx={{ display: "flex", alignItems: "center", borderRadius: 16, px: .5, "&:hover": { bgcolor: "action.hover" }, "&:focus-within": { bgcolor: "action.focus" } }} onClick={handleNewRoleClick}>
                        <AddOutlined sx={{ color: "#616161" }} />
                        <Input type="text" placeholder="Add Role" sx={{ width: "120px", px: 1, height: "32px" }} id="new-role-input" value={newRole} onChange={ev => { setNewRole(ev.currentTarget.value); }} autoComplete="off" />
                    </Box> */}
                <Button startIcon={<AddOutlined />} sx={{ textTransform: "none", bgcolor: menuRolesAnchorEl !== null ? "action.focus" : undefined }} color="inherit" onClick={openMenuRoles} >Add Role</Button>
            </Box>

            <Menu anchorEl={menuRolesAnchorEl} onClose={closeMenuRoles} open={menuRolesAnchorEl !== null} PaperProps={{ sx: { minWidth: 240 } }} MenuListProps={{ sx: { pb: 0 } }}>
                <MenuItem onClick={() => { addRoles("admin"); }}>
                    <ListItemIcon>
                        <TerminalOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Admin" secondary="All permissions" />
                    {roles.includes("admin") && <CheckOutlined sx={{ color: "primary.main" }} />}
                </MenuItem>
                <MenuItem onClick={() => { addRoles("trainer"); }}>
                    <ListItemIcon>
                        <SchoolOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Trainer" secondary="Manage Courses" />
                    {roles.includes("trainer") && <CheckOutlined sx={{ color: "primary.main" }} />}
                </MenuItem>
                {/* <Box component="li" >
                    <TextField label="Custom Role" variant="filled" fullWidth InputProps={{sx: {pt: 1}}} />
                </Box> */}
                <Box component="li" sx={{ bgcolor: "rgba(0,0,0,0.06)", mt: 1, position: "relative", "&::before": { content: "''", height: "4px", bgcolor: "background.paper", borderBottomRightRadius: 4, borderBottomLeftRadius: 4, display: "block" } }} onKeyDown={stopPropagation}>
                    <IconButton sx={{ position: "absolute", top: 4, right: 0, zIndex: 1 }}>
                        <HelpOutline fontSize="small" sx={{ opacity: .6 }} />
                    </IconButton>
                    <Form id="new-role" onSubmit={handleNewRoleSubmit}>
                        <Box sx={{ display: "flex", px: 2, py: 1.5 }}>
                            <ListItemIcon sx={{ minWidth: 36, mt: "18px" }}>
                                <EditOutlined />
                            </ListItemIcon>
                            <FormControl variant="standard">
                                <InputLabel id="new-role-helper" htmlFor="new-role">
                                    Custom Role
                                </InputLabel>
                                <Input aria-describedby="new-role-helper" disableUnderline placeholder="role_name" id="new-role" value={newRole} onChange={ev => { setNewRole(ev.currentTarget.value); }} autoComplete="off" />
                            </FormControl>
                        </Box>
                    </Form>
                </Box>
            </Menu>

            <Typography variant="h3" sx={{ mb: 1 }}>Actions</Typography>

            <Box display="flex" gap={1} flexWrap="wrap" mb={4}>
                {/* {user.suspended ?
                    <Button startIcon={<CircleOutlined />} sx={{ textTransform: "none" }} color="inherit" onClick={releaseUser}>Release</Button> :
                    <Button startIcon={<BlockOutlined />} sx={{ textTransform: "none" }} color="inherit" onClick={suspendUser}>Suspend</Button>} */}
                <Divider orientation="vertical" flexItem />
                <Button startIcon={<DeleteOutlined />} sx={{ textTransform: "none" }} color="inherit" onClick={deleteUser}>Delete</Button>
                <Divider orientation="vertical" flexItem />
                <Button startIcon={<PasswordOutlined />} sx={{ textTransform: "none" }} color="inherit" onClick={askChangePassword}>Change Password</Button>
            </Box>

            <Typography variant="body2" color="text.secondary">
                Created at <TimeAgo time={new Date(user.createdAt)} />,
                Last updated at: {user.createdAt.getTime() === user.updatedAt!.getTime() ? "never" : <TimeAgo time={new Date(user.updatedAt!)} />}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={(ev) => { onClose?.(ev, "cancelClick") }}>Cancel</Button>
            <Button variant="contained" onClick={submit} disableElevation>Save</Button>
        </DialogActions>
    </>
}

function stopPropagation(ev: React.SyntheticEvent) {
    ev.stopPropagation();
}

export function RoleChild(props: ChipProps) {
    let bgcolor: string | undefined;
    let icon: React.ReactElement | undefined;
    if (props.label === "admin") {
        bgcolor = "#ffcaca";
        icon = <Terminal />;
    } else if (props.label === "trainer") {
        bgcolor = "#c3fdff";
        icon = <SchoolOutlined />;
    }
    const label = (props.label as string).replaceAll(/(^|_)./g, c => c[c.length - 1].toUpperCase());
    return <Chip {...props} sx={{ bgcolor }} icon={icon} label={label} />;
}

// const Input = styled("input")({
//     display: "block",
//     border: "none",
//     outline: "none",
//     background: "unset",
//     margin: 0,
//     padding: 0,
// });

const Form = styled("form")({});

export interface EditUserDialogProps extends DialogProps, EditUserPanelProps {
    onClose?: (ev: {}, reason: "backdropClick" | "escapeKeyDown" | "closeClick" | "cancelClick" | "saveClick" | "deleteClick") => void,
}

export function EditUserDialog(props: EditUserDialogProps) {
    const { user, autoFocusField: focusField, ...dialogProps } = props;
    const { onClose } = dialogProps;

    // function handleSubmitClick(ev: React.MouseEvent) {
    //     onClose?.(ev, "saveClick")
    // }

    return <Dialog fullWidth PaperProps={{ sx: { maxWidth: 400 } }} {...dialogProps}>
        <DialogCloseButton onClick={(ev) => { onClose?.(ev, "closeClick") }} />
        <EditUserPanel user={user} autoFocusField={focusField} onClose={onClose} />
    </Dialog>
}
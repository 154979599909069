import { toast } from "@halliday/mui-toast";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModuleCourse, Topic, TopicsResponse, enroleCourse, getTopics } from "../../api";
import { imgSrcAbs } from "../HTMLRenderer";
import { formatDifficulty, formatDuration } from "../tools";
import { useSession } from "../../ident";


export interface TopicDialogProps {
    open: boolean,
    onClose: () => void,
    course: ModuleCourse,
}


export default function TopicDialog(props: TopicDialogProps) {
    const { open, onClose, course } = props;
    const navigate = useNavigate();
    const sess = useSession();

    const { title, desc, id, numTopics } = course;

    function addToMyCourses() {
        enroleCourse({ course: id }).then((resp) => {
            navigate("/my-courses");
            toast("Successfully added!");
        }, reportError);
    }

    function previewTopic() {
        navigate(`/courses/${id}`);
    }

    return <Dialog onClose={onClose} aria-labelledby="course-dialog-title" open={open} fullWidth maxWidth="xs">
        <DialogTitle id="course-dialog-title">
            {title}
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
        <TopicDialogContent course={course} />
        <DialogActions sx={{ justifyContent: "center", gap: 2, pb: 3 }}>
            { course.joined ? (<Button variant="contained" onClick={() => navigate(`/courses/${course.id}`)} disabled={!sess} disableElevation>Open</Button>) : (<Button variant="contained" onClick={addToMyCourses} disabled={!sess} disableElevation>Join Course</Button>) }
        </DialogActions>
    </Dialog>;
}



interface TopicDialogContentProps {
    course: ModuleCourse,
}

function TopicDialogContent(props: TopicDialogContentProps) {
    const { course } = props;
    const navigate = useNavigate();

    const { title, desc, id, weakId, numTopics, difficulty, duration } = course;

    const [page, setPage] = useState<TopicsResponse | null>(null);
    const [err, setErr] = useState<any>(null);
    const [numTopicsShown, setNumTopicsShown] = useState<number>(3);

    const topics = page ? page.topics : [];

    // function navigateCourse() {
    //     navigate(`/courses/${weakId!}`);
    // }

    const sess = useSession();
    useEffect(() => {
        getTopics({ course: id }).then((topic) => {
            setPage(topic);
            setErr(null);
        }, setErr);
    }, [sess, id]);

    return <DialogContent>
        <Typography variant="subtitle1">{desc}</Typography>
        <Typography variant="caption">
            by {course.orgName} • Duration: {formatDuration(course.duration!)} • Difficulty: {formatDifficulty(course.difficulty!)}
        </Typography>
        <List sx={{ mt: 1 }}>
            {topics.slice(0, numTopicsShown).map((topic, index) => {
                return <>
                    <TopicListItem key={topic.id || index} course={course} topic={topic} topicId={topic.id} />
                    <Divider hidden={index === numTopics - 1 ? true : false} variant="inset" component="li" />
                </>
            }
            )}
            {numTopics > 3 && numTopicsShown < numTopics && (
                <ListItem>
                    <ListItemText inset secondary={<>+ {numTopics - 3} more</>} secondaryTypographyProps={{ sx: { cursor: "pointer", ":hover": { textDecoration: "underline" } }, onClick: () => setNumTopicsShown(numTopics)}} />
                </ListItem>
            )}
            {/* if numTopics is more than 3 */}
            {numTopicsShown > 3 && (
                <ListItem>
                    <ListItemText inset secondary={<>- {numTopics - 3} less</>} secondaryTypographyProps={{ sx: { cursor: "pointer", ":hover": { textDecoration: "underline" } }, onClick: () => setNumTopicsShown(3)}} />
                </ListItem>
            )}
        </List>
    </DialogContent>
}

type TopicListItemProps = {
    course: ModuleCourse,
    topic: Topic,
    topicId: string,
}

function TopicListItem(props: TopicListItemProps) {
    const { course, topic, topicId } = props;
    const navigage = useNavigate();

    const { title, desc, content } = topic;
    const { id, weakId } = course;

    const img = useMemo(() => {
        const imgs = findImages(content);
        if (imgs.length === 0) return null;
        return <ImagePreview img={imgs[0]} />;
    }, [content])

    function navigateTopic() {
        navigage(`/courses/${id}?topic=${topicId}`);
    }

    return <ListItemButton onClick={navigateTopic}>
        <ListItemIcon>
            <BookmarkIcon />
        </ListItemIcon>
        <ListItemText
            primary={title}
            secondary={
                <Typography sx={{ display: 'inline' }} component="span" variant="caption" color="text.secondary">
                    {desc}
                </Typography>
            }
        />
        {img}
    </ListItemButton>
}

type Img = {
    tag: string,
    src: string,
    file?: string,
}

function findImages(html: string): Img[] {
    const template = document.createElement("template");
    template.innerHTML = html;
    const elms = Array.from(template.content.querySelectorAll("img,youtube"));
    return elms.map<Img>(elm => {
        const tag = elm.tagName.toLowerCase();
        switch (tag) {
            case "img":
                return { tag, src: elm.getAttribute("src")!, file: elm.getAttribute("data-file") ?? undefined };
            case "youtube":
                return { tag, src: elm.innerHTML };
            default:
                throw new Error("Unexpected tag: " + tag);
        }
    });
}

type ImagePreviewProps = {
    img: Img,
}

function ImagePreview(props: ImagePreviewProps) {
    const { img } = props;
    let src: string;
    switch (img.tag) {
        case "img":
            src = imgSrcAbs(img.src);
            break;
        case "youtube":
            src = `https://img.youtube.com/vi/${img.src}/0.jpg`;
            break;
        default:
            return null;
    }
    return <Image src={src} alt="" width={64} height={36} sx={{ objectFit: "contain" }} />;
}

const Image = styled('img')({});

import MenuIcon from '@mui/icons-material/Menu';
import { Button } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { MyAccountAvatar } from "./ident/AccountAvatar";
import { useSession } from "../ident";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery } from '@mui/material';

interface AppBarProps {
    drawerOpen: boolean,
    onDrawerToggle: () => void,
    title: string,
    variant?: "normal" | "prominent",
    actions?: React.ReactNode,
    onNavigateBack?: React.MouseEventHandler,
    onLoginClick?: React.MouseEventHandler,
    prevPage?: string,
    doc?: string,
    docName?: string,
    scrollToHeading?: string,
    org?: string,
    pl?: string,
}

const AppBar = React.forwardRef((props: AppBarProps, ref: React.Ref<HTMLDivElement>) => {
    const { variant, onLoginClick } = props;

    const theme = useTheme();
    // const sm = useMediaQuery(theme.breakpoints.up('sm'));
    // const lg = useMediaQuery(theme.breakpoints.up('lg'));
    // const md = useMediaQuery(theme.breakpoints.up('md'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    function openProfile() {
        window.openProfile();
    }

    const sess = useSession();

    return (
        <MuiAppBar position="fixed" color="inherit" elevation={0} ref={ref}>
            {/* <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', pl: xl ? ["298px"] : ["112px"] }}> */}
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', pl: props.pl && !xl ? [props.pl] : ["16px", "112px", null, "298px"] }}> 
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={props.onDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" } }}
                >
                    <MenuIcon />
                </IconButton>

                <Typography variant="h6" component="div" sx={{ flexGrow: 1, alignSelf: variant === "prominent" ? "flex-end" : undefined }}>
                    {props.title}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {props.actions}
                    {sess ? (
                        <MyAccountAvatar onClick={openProfile} sx={{ cursor: 'pointer', ml: 2 }} />
                    ) : (
                        <Button variant="outlined" onClick={onLoginClick}>
                            Login
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </MuiAppBar >
    )
});

export default AppBar;

var profilePictureInput = document.createElement("input");
profilePictureInput.type = "file";
profilePictureInput.accept = "image/*";
profilePictureInput.style.display = "none";

function selectProfilePicture(cb: (pic: Blob) => void) {
    document.body.append(profilePictureInput);
    profilePictureInput.onchange = (ev: Event) => {
        const file = profilePictureInput.files![0];
        if (file) {
            uploadProfilePicture(file, cb);
        }
    }
    profilePictureInput.click();
}

function uploadProfilePicture(file: File, cb: (pic: Blob) => void) {
    const src = URL.createObjectURL(file);
    const img = document.createElement("img");
    img.src = src;
    img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = 64;
        canvas.height = 64;
        const s = Math.min(64 / img.naturalWidth, 64 / img.naturalHeight);
        const ctx = canvas.getContext("2d")!;
        ctx.drawImage(img, 32 - s * img.naturalWidth, 32 - img.naturalHeight, s * img.naturalWidth, s * img.naturalHeight);
        URL.revokeObjectURL(src);
        canvas.toBlob((blob) => {
            if (!blob) {
                alert("Your browser was not able to process the image. Please try another file!");
                return;
            }
            cb(blob);
        }, "image/png")
    }
    img.onerror = () => {
        alert("Your browser failed to load the file as image. Please try another file!");
        URL.revokeObjectURL(src);
    }
}

//  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', pl: ["16px", "112px", null, "298px"] }}> 
import { Avatar, Box, BoxProps, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CardProps, IconButton, InputAdornment, styled, SxProps, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Resource, patchResource as apiPatchResource } from "../../api";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { useRef, useState } from "react";
import placeholderImage from "../../assets/images/cover_card_resource.png";
import resourceCover from "../../assets/images/resource-cover.png";
import { joinSx, lineClamp } from "../tools";
import { toast } from "@halliday/mui-toast";
import { useSession } from "../../ident";

export interface ResourceCardProps extends CardProps {
  item: Resource;
}

const noSpinButton: SxProps = {
  "&::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "&::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  MozAppearance: "textfield",
};

export default function ResourceCard(props: ResourceCardProps) {
  const { item, sx, ...cardProps } = props;
  const navigate = useNavigate()

  const [count, setCount] = useState(item.count ?? 0);
  const [focused, setFocused] = useState(false);

  const sess = useSession();

  function addToMyLab() {
    if (sess === null) {
      window.openLogin();
      return
    }
    state.autoFocusInput = true;
    setCount(1);
  }

  // function removeFromMyLab() {
  //   setCount(0);
  // }

  function increaseCount() {
    setCount(count + 1);
    inputRef.current!.focus();
  }

  function decreaseCount() {
    setCount(count - 1);
    inputRef.current!.focus();
  }

  function handleAdornmentMouseDown(ev: React.MouseEvent) {
    ev.preventDefault();
  }

  async function patchResource(count: number) {
    setCount(count);

    await apiPatchResource({ id: item.id, count });

    if (!item.count && count) {
      window.dataLayer.push({
        "event": "addToMyLab",
        "resourceId": item.id,
        "resourceTitle": item.title,
        "count": count
      });
    }

    item.count = count;
  }

  async function handleKeyDown(ev: React.KeyboardEvent) {
    if (ev.key === "Enter") {
      await submitCount();
    }
  }

  async function submitCount() {
    const oldCount = item.count ?? 0;
    await patchResource(count);

    async function undoChange() {
      await patchResource(oldCount);
      toast(null);
    }

    let msg: string | undefined;
    if (oldCount === 0 && count > 0) {
      msg = item.title + " added to your lab.";
    } else if (oldCount > 0 && count === 0) {
      msg = item.title + " removed from your lab.";
    } else if (oldCount != count) {
      msg = item.title + " count updated.";
    }

    if (msg) {
      toast({
        message: msg,
        SnackbarProps: { ClickAwayListenerProps: { mouseEvent: false } },
        action: <Button onClick={undoChange} color="secondary">Undo</Button>
      });
    }
  }

  async function handleInputBlur() {
    state.autoFocusInput = false;
    setFocused(false);
    await submitCount();
  }

  function handleInputFocus() {
    setFocused(true);
  }

  const [state] = useState({
    autoFocusInput: false,
  });


  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Card sx={joinSx({ width: 300, height: 400 }, sx)} {...cardProps}>
      <ResourceCover
        width={300}
        height={200}
        res={item}
      />
      <CardHeader
        avatar={<Avatar src={item.iconUrl} aria-label={item.title} imgProps={{ loading: "lazy" }} />}
        title={item.title}
        titleTypographyProps={{ sx: { ...lineClamp(1) }, fontSize: '1rem' }}
        subheader={<>by <OrganizationIcon src={item.orgIconUrl} /> {item.orgName}</>}
        subheaderTypographyProps={{ sx: { ...lineClamp(1) } }}
      />

      <CardContent sx={{ pt: 0, height: 40 }}>
        <Typography variant="body2" color="text.secondary" sx={{ ...lineClamp(2) }}>{item.desc}</Typography>
      </CardContent>
      <CardActions sx={{ p: 2, pt: 1, gap: 1 }}>
        {(count > 0 || focused) ? <>
          <TextField
            type="number"
            value={isNaN(count) ? "" : count}
            autoFocus={state.autoFocusInput}
            onChange={(e) => setCount(parseInt(e.target.value))}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            onKeyDown={handleKeyDown}
            label="Lab count"
            size="small"
            sx={{ width: "125px", mr: 1 }}
            InputProps={{
              sx: { px: "4px" },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="decrease item count"
                    size="small"
                    onClick={decreaseCount}
                    onMouseDown={handleAdornmentMouseDown}
                    edge="start"
                  >
                    <RemoveOutlined />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="increase item count"
                    size="small"
                    onClick={increaseCount}
                    onMouseDown={handleAdornmentMouseDown}
                    edge="end"
                  >
                    <AddOutlined />
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: { sx: { ...noSpinButton, textAlign: "center", py: "6px", fontSize: "0.875rem", lineHeight: "1.75", height: "auto" }, ref: inputRef }
            }}
          />
          {/* <IconButton size="small" onClick={removeFromMyLab}>
            <DeleteOutlined />
          </IconButton> */}
        </> : (
          <Button variant="outlined" size="medium" startIcon={<AddOutlined />} onClick={addToMyLab} sx={{ width: "125px" }}>My Lab</Button>
        )}
        <Button size="medium" onClick={() => navigate(`/resources/${item.weakId!}`)}>Learn More</Button>
        {/* <Button size="medium" onClick={() => navigate(`/resources/${item.weakId!}/overview`)}>Learn More</Button> */}
      </CardActions>
    </Card>
  );
}

interface ResourceCoverProps extends BoxProps {
  res: Resource;
}

const Img = styled("img")({});

export function ResourceCover(props: ResourceCoverProps) {
  const { res, sx, ...boxProps } = props;
  const backgroundColor = res.color === "#000000" ? "#f1f1f1" : res.color;
  return <Box sx={joinSx({ position: "relative", overflow: "hidden", backgroundColor, backgroundImage: `url(${resourceCover})`, backgroundSize: "cover" }, sx)} {...boxProps}>
    <Img src={res.coverUrl || placeholderImage} loading="lazy" alt={res.title} sx={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", filter: "drop-shadow(1px 1px 4px #0007)", "&:hover": { transform: "scale(1.05)" }, transition: (theme) => theme.transitions.create("transform", { duration: theme.transitions.duration.short }) }} />
  </Box>;
}

// interface ButtonAddToCardProps {
//   children?: React.ReactNode;
//   count: number,
//   onCountChange: (count: number) => void;
// }

// function ButtonAddToCard(props: ButtonAddToCardProps) {
//   const { count, onCountChange, children } = props;
//   const [focus, setFocus] = useState(false);

//   return <TextField
//     type="number"
//     value={count}
//     autoFocus={state.autoFocusInput}
//     onChange={(e) => setCount(parseInt(e.target.value))}
//     onBlur={handleInputBlur}
//     size="small"
//     sx={{ width: "125px", mr: 1 }}
//     InputProps={{
//       sx: { px: "4px" },
//       startAdornment: (
//         <InputAdornment position="start">
//           <IconButton
//             aria-label="decrease item count"
//             size="small"
//             onClick={decreaseCount}
//             onMouseDown={handleAdornmentMouseDown}
//             edge="start"
//           >
//             <RemoveOutlined />
//           </IconButton>
//         </InputAdornment>
//       ),
//       endAdornment: (
//         <InputAdornment position="end">
//           <IconButton
//             aria-label="increase item count"
//             size="small"
//             onClick={increaseCount}
//             onMouseDown={handleAdornmentMouseDown}
//             edge="end"
//           >
//             <AddOutlined />
//           </IconButton>
//         </InputAdornment>
//       ),
//       inputProps: { sx: { ...noSpinButton, textAlign: "center", py: "6px", fontSize: "0.875rem", lineHeight: "1.75", height: "auto" }, ref: inputRef }
//     }}
//   />
// }

const OrganizationIcon = styled("img")({
  height: 24,
  display: "inline-block",
  marginTop: "-6px",
  marginBottom: "-6px"
});


import { L, useLanguage } from "@halliday/react-i18n";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page, { PageContent } from "../Page";

interface TextPageProps {
    url: string,
    title: string,
}

function TextPage(props: TextPageProps) {
    const {title, url} = props;
    const navigate = useNavigate();

    const [content, setContent] = useState("");

    function setErr(err: any) {
        setContent("Error loading content:" + err);
    }

    useEffect(() => {
        fetch(url)
            .then(res => res.text())
            .then(setContent, setErr);
    }, [url]);

    return <Page 
    title={title} 
    // onNavigateBack={() => navigate("/")}
    >
        <PageContent>
            <Typography dangerouslySetInnerHTML={{__html: content}} />
        </PageContent>
    </Page>
}

export default TextPage;
import { useMemo } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { CoursesResponse, Module, ModulesResponse, getCourses, getModules } from "../../api";
import Page, { pageParams, PageContent } from "../Page";
import { TOC } from "../Page";
import LecturesCard, { LecturesCardSkeleton } from "./LecturesCard";
import { Grid, InputBase, ListItemText, MenuItem, Select, SelectChangeEvent, List, ListItemButton, ListItemIcon, } from "@mui/material";
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import ErrorView from "../ErrorView";
import wazicloudLogo from "../../assets/images/wazicloud-logo.svg";
import arduinoLogo from "../../assets/images/arduino-logo.png";
import { useSession } from "../../ident";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export interface CoursesPageProps { }

interface CoursesPageParams {
  pageToken?: string;
  q?: string;
  org?: string;
  module?: string;
  course?: string;
}

type Difficulty = 1 | 2 | 3;
const difficulties: Record<Difficulty, string> = {
  1: "Beginner",
  2: "Intermediate",
  3: "Advanced",
};
type Duration = string;

type Provider = {
  id: string;
  name: string;
  logo: string;
};
const allProviders: Provider[] = [
  { id: "Waziup", name: "Waziup", logo: wazicloudLogo },
  { id: "Arduino", name: "Arduino", logo: arduinoLogo },
];

export default function CoursesPage() {
  const params = pageParams() as CoursesPageParams;

  const [page, setPage] = useState<ModulesResponse | null>(null);
  const [err, setErr] = useState<any>(null);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const sess = useSession();
  useEffect(() => {
    getModules({ q: params.q, pageToken: params.pageToken }).then((page) => {
      setPage(page);
    }, setErr);
  }, [sess, params.pageToken, params.q]);

  const [module, setModule] = useState<Module[]>([]);
  const [difficulty, setDifficulty] = useState<Difficulty | null>(null);
  const [duration, setDuration] = useState<string | null>(null);
  const [provider, setProvider] = useState<string | null>(null);

  let content: JSX.Element;
  if (err) {
    content = <ErrorView err={err} />;
  } else if (page === null) {
    content = <span>Loading..</span>;
  } else {
    content = (
      <Box sx={{ flexGrow: 1, pl: 2, pr: 2 }}>
        <Box>
          <CoursesGrid
            module={module.length > 0 ? module[0].id : undefined}
            difficulty={difficulty ?? undefined}
            duration={duration ?? undefined}
            provider={provider ?? undefined}
            modulesList={page.modules}
          />
        </Box>
      </Box>
    );
  }

  function tocWithIconListItem(id: string, checked: boolean, primary: string, icon: string | undefined, onClick: React.MouseEventHandler) {
    return (
      <ListItemButton
        key={id}
        role={undefined}
        onClick={onClick}
        dense
        selected={checked}
        sx={{
          mb: 2,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "132px",
          "&.Mui-selected": {
            bgcolor: checked ? "#CCE6FF" : null, // Adjust bgcolor
          },
        }}
      >
        <ListItemIcon sx={{ mb: 0.6, justifyContent: "center" }}>
          {icon && <img src={icon} style={{ width: 24, height: 24, objectFit: "contain" }} alt="Module Icon" />}
        </ListItemIcon>
        <ListItemText primary={primary} sx={{ m: 0, textAlign:"center" }} />
      </ListItemButton>
    );
  }

  const toc =  <TOC sx={{ borderRight: "none", px: 3, minWidth: "56px", width: "fit-content", pt: 2 }}>
    <List sx={{ pt: 0}}>
      <ListItemButton
        role={undefined}
        dense
        sx={{
          mb: 2,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "132px",
          "&.Mui-selected": {
            bgcolor: module.length === 0 ? "#CCE6FF" : null, // Adjust bgcolor
          },
        }}
        onClick={() => setModule([])}
        selected={module.length === 0}
      >
        <ListItemIcon sx={{ mb: 0.6, justifyContent: "center" }}>
          <LocalLibraryOutlinedIcon sx={{ height: 24, width: 24 }} />
        </ListItemIcon>
        <ListItemText primary={"All"} sx={{ m: 0 }} />
      </ListItemButton>

      {page?.modules.map((m, i) => (
        tocWithIconListItem(m.id, module.length > 0 && module[0].id === m.id, m.shortTitle,
           m.iconUrl, () => setModule([m]))
      ))}
    </List>
  </TOC >;

  return (
    <Page
      title="Course Catalogue"
      toc={toc}
      shortTOC={true}
    >
      <PageContent sx={{ pt: 2 }}>
        <Box
          sx={{ display: "flex", gap: 3, p: 1, pr: 5, m: -1, overflowX: "auto" }}
        >
        { sm && (
            <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={module.length > 0 ? module[0].id : "All"}
            onChange={(ev: SelectChangeEvent<string>) => {
              const selectedModuleId = ev.target.value;
              if (selectedModuleId === "All") {
                setModule([]);
              } else {
                const selectedModule = page?.modules.find(
                  (m) => m.id === selectedModuleId
                );
                if (selectedModule) {
                  setModule([selectedModule]);
                }
              }
            }}
            input={
              <InputBase
                sx={{ bgcolor: "#b1d8ff", pl: 1.5, pr: 1, borderRadius: 1, maxWidth: 250, }}
              />
            }
          >
            <MenuItem key="All" value="All">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <LocalLibraryOutlinedIcon sx={{ mr: 1.5, height: 24, width: 24 }} />
                All Modules
              </Box>
            </MenuItem>
            {page?.modules.map((module, i) => {
              return (
                <MenuItem key={module.id} value={module.id}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {
                      module.iconUrl && (
                        <img
                          src={module.iconUrl}
                          style={{ width: 24, height: 24, objectFit: "contain" }}
                          alt="Module Icon"
                        />
                      )
                    }
                    {module.title}
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
          )}

          <Select
            labelId="select-provider-label"
            id="select-provider"
            displayEmpty
            value={provider ?? ""}
            onChange={(ev: SelectChangeEvent<string>) => {
              const provider = ev.target.value as string;
              setProvider(provider || null);
            }}
            input={
              <InputBase
                sx={{
                  bgcolor: provider ? "#b1d8ff" : "grey.200",
                  pl: 1.5,
                  pr: 1,
                  borderRadius: 1,
                  maxWidth: 250,
                }}
              />
            }
            renderValue={(value) => {
              if (!value)
                return (
                  <Box component="span" sx={{ color: "text.secondary" }}>
                    Provider
                  </Box>
                );
              return {
                All: "All",
                Waziup: "Waziup",
                Arduino: "Arduino",
              }[value as string];
            }}
          >
            <MenuItem value="">All</MenuItem>
            {allProviders.map((p) => {
              return (
                <MenuItem key={p.id} value={p.id}>
                  <ListItemText
                    primary={p.name}
                    sx={{ mr: 4 }}
                  />
                  <img
                    src={p.logo}
                    style={{ width: 24, height: 24, objectFit: "contain" }}
                    alt="Provider Logo"
                  />
                </MenuItem>
              );
            })}
          </Select>

          <Select
            labelId="select-duration-label"
            id="select-duration"
            displayEmpty
            value={duration ?? ""}
            onChange={(ev: SelectChangeEvent<string>) => {
              const duration = ev.target.value as string;
              setDuration(duration || null);
            }}
            input={
              <InputBase
                sx={{
                  bgcolor: duration ? "#b1d8ff" : "grey.200",
                  pl: 1.5,
                  pr: 1,
                  borderRadius: 1,
                  maxWidth: 250,
                }}
              />
            }
            renderValue={(value) => {
              if (!value)
                return (
                  <Box component="span" sx={{ color: "text.secondary" }}>
                    Duration
                  </Box>
                );
              return {
                30: "< 30 min",
                120: "< 2 hours",
                360: "< 6 hours",
                600: "< 10 hours",
              }[value as string];
            }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="30">Less than 30 minutes</MenuItem>
            <MenuItem value="120">Less than 2 hours</MenuItem>
            <MenuItem value="360">Less than 6 hours</MenuItem>
            <MenuItem value="600">Less than 10 hours</MenuItem>
          </Select>

          <Select
            labelId="select-difficulty-label"
            id="select-difficulty"
            displayEmpty
            value={difficulty ?? ""}
            onChange={(ev: SelectChangeEvent<Difficulty | "">) => {
              const selectedDifficulty = ev.target.value as Difficulty | "";
              setDifficulty(
                selectedDifficulty !== "" ? selectedDifficulty : null
              );
            }}
            input={
              <InputBase
                sx={{
                  bgcolor: difficulty ? "#b1d8ff" : "grey.200",
                  pl: 1.5,
                  pr: 1,
                  borderRadius: 1,
                  maxWidth: 250,
                }}
              />
            }
            renderValue={(value) => {
              if (!value)
                return (
                  <Box component="span" sx={{ color: "text.secondary" }}>
                    Difficulty
                  </Box>
                );
              return difficulties[value];
            }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value={1}>Beginner</MenuItem>
            <MenuItem value={2}>Intermediate</MenuItem>
            <MenuItem value={3}>Advanced</MenuItem>
          </Select>
        </Box>
        <br />
        {content}
      </PageContent>
    </Page>
  );
}

export interface CoursesGridProps {
  module?: string;
  difficulty?: Difficulty;
  duration?: Duration;
  provider?: string;
  modulesList?: Array<any>;
}

export function CoursesGrid(props: CoursesGridProps) {
  const { module, difficulty, duration, provider, modulesList } = props;

  const [err, setErr] = useState<any>(null);
  const [allCourses, setAllCourses] = useState<CoursesResponse | null>(null);
  const sess = useSession();

  // useEffect to get all courses without passing any parameters
  useEffect(() => {
    getCourses({}).then((page) => {
      setAllCourses(page);
      setErr(null);
    }, setErr);
  }, [sess]);

  const filteredCourses = useMemo(() => {
    let filtered = allCourses?.courses ?? [];

    if (module) {
      filtered = filtered.filter((course) => course.module === module);
    }

    if (difficulty) {
      filtered = filtered.filter((course) => course.difficulty && course.difficulty === difficulty);
    }

    if (duration) {
      const durationValue = parseInt(duration, 10); // Parse the duration parameter into a number
      filtered = filtered.filter((course) => course.duration && course.duration.valueOf() <= durationValue);
    }

    if (provider) {
      filtered = filtered.filter((course) => course.orgName === provider);
    }

    // Group each module with its courses using groupBy
    const modules = Object.values(groupBy(filtered, 'module')).map((courses) => {
      const firstCourse = courses[0];
      return {
        id: firstCourse.module,
        title: firstCourse.moduleTitle,
        courses: courses,
        module: firstCourse.module,
      };
    });

    // Get module order from modulesList
    const modulesOrder = modulesList?.map((module) => module.id) ?? [];

    // Sort the modules by the order in modulesList
    modules.sort((a, b) => {
      const aIndex = modulesOrder.indexOf(a.id);
      const bIndex = modulesOrder.indexOf(b.id);
      return aIndex - bIndex;
    });

    return modules ?? [];
  }, [allCourses, module, duration, provider, difficulty, modulesList]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  if (err) return <ErrorView err={err} />;

  if (allCourses === null) {
    var children: React.ReactNode[] = [];
    for (var i = 0; i < 4; i++) {
      children.push(
        <Grid item xs={12} md={4} pl={0} key={i}>
          <LecturesCardSkeleton />
        </Grid>
      );
    }
    return <Grid container spacing={2} mt={1} children={children} />;
  }

  if (sm) {
    return (
      <>
        {filteredCourses.map((module) => (
          <div key={module.id}
            style={{
              marginBottom: "20px"
            }}
          >
            <Typography variant="h6" fontWeight={"bold"}>{module.title}</Typography>
            <Divider sx={{ mb: 2 }} />
            {module.courses.map((course) => (
              <LecturesCard
                course={course}
                module={module.title}
                key={course.id}
                sx={{ maxWidth: "unset" }}
              />
            ))}
          </div>
        ))}
      </>
    );
  } else {
    return (
      <Grid container spacing={2} mt={1}>
        {filteredCourses.map((module) => (
          <Grid item xs={12} key={module.id}>
            <Typography variant="h6" fontWeight={"bold"}>{module.title}</Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              {module.courses.map((course) => (
                <Grid item xs={12} md={4} pl={0} key={course.id}>
                  <LecturesCard course={course} module={module.title} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}

function groupBy<K extends string, T extends Record<K, string | number>>(arr: T[], key: K): Record<K, T[]> {
  return arr.reduce<any>((acc, curr) => {
    (acc[curr[key]] = acc[curr[key]] || []).push(curr);
    return acc;
  }, {}) as Record<K, T[]>;
}

import { CloseOutlined } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";

export default function DialogCloseButton(props: IconButtonProps) {
    return <IconButton
        aria-label="close"
        sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        }}
        {...props}
    >
        <CloseOutlined />
    </IconButton>;
}
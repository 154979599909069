import { useEffect, useState } from "react";
import { SelectChangeEvent, Stack, } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import Page, {
    PageContent, TOC, TOCList, TOCListItem,
} from "../../Page";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaticipantsPage from "./ParticipantsPage";
import { Program, findProgram } from "../../../api";
import ManageProgramPage from "./ManageProgramPage";
import ProgressPage from "./ProgressPage";


const tocItems = [
    // { title: "Overview", id: "overview" },
    // { title: "Units", id: "unit" },
    // { title: "Events", id: "event" },
    { title: "Manage Program", id: "manage" },
    { title: "Participants", id: "participant" },
    { title: "Progress", id: "progress" },
]


export default function ProgramEditor() {
    const navigate = useNavigate();
    const [audienceList, setAudienceList] = useState<string[]>([]);
    const [activeTopicId, setActiveTopicId] = useState<string | null>("manage");
    const [program, setProgram] = useState<Program | null>(null);
    const [err, setErr] = useState<any>(null);
    const programId = useParams().id!;

    async function loadProgram() {
        try {
            const resp = await findProgram({ id: programId });
            setProgram(resp.program);
            setErr(null);
        } catch (err) {
            setErr(err);
        }
    }

    const handleChange = (event: SelectChangeEvent<typeof audienceList>) => {
        const {
            target: { value },
        } = event;
        setAudienceList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // Event handler for updating activeTopicId when a TOC item is clicked
    const handleTOCItemClick = (id: string) => {
        setActiveTopicId(id);
    };

    useEffect(() => { loadProgram(); }, [programId]);

    let title: string = "Please wait...";
    let currentTab: string;
    let content: JSX.Element | null = null;
    let tocContent: JSX.Element | null = null;

    tocContent = <TOC>
        <TOCList>
            {tocItems.map(({ title, id }) => (
                <TOCListItem
                    active={id === activeTopicId}
                    key={id}
                    primary={<Stack direction="row" alignItems="center">
                        <Typography sx={{ flexGrow: 1 }}>{title}</Typography>
                        {/* {done && <CheckOutlined />} */}
                    </Stack>
                    }
                    onClick={() => handleTOCItemClick(id)}
                    opener={<CheckCircleOutlineIcon />}
                />
            ))}
        </TOCList>
        {/* <Button variant="contained" sx={{ m: 1 }}>SAVE PROGRAM</Button> */}
    </TOC>

    switch (activeTopicId) {
        // case "overview":
        //     content = <OverviewPage programId={programId} />;
        //     currentTab = "Overviews"
        //     break;
        // case "unit":
        //     content = <UnitsPage />;
        //     currentTab = "Units"
        //     break;
        // case "event":
        //     content = <EventsPage />;
        //     currentTab = "Events"
        //     break;
        case "manage":
            content = <ManageProgramPage programId={programId} />;
            currentTab = "Manage Program"
            break;
        case "participant":
            content = <PaticipantsPage programId={programId} />;
            currentTab = "Participants"
            break;
        case "progress":
            content = <ProgressPage programId={programId} />;
            currentTab = "Progress"
            break;
    }


    return <Page
        title={program?.name}
        // onNavigateBack={() => navigate("/my-programs")} 
        toc={tocContent}
    >
        <PageContent>{content}</PageContent>
    </Page>
}

import { ArrowRight, LocalLibraryOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Grid, Paper, Stack, Typography, styled, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../Page";
import { CardCourse, CardResource } from "../pages/BusinessboxComponents/Cards";
import { lineClamp } from "../tools";

import raspberryPiIcon from "./raspberry-pi-icon.png";
import solarPanelIcon from "./solar-panel-icon.png";
import cover from "./solution-box-background.png";
import wazidevIcon from "./wazidev-icon.png";
import waziSenseIcon from "./wazisense-icon.png";
import waterproofCasingIcon from "./waterproof-casing-icon.png";

export default function ApplicationBusinessBoxPage() {

    const navigate = useNavigate();

    function navigateSeeAllResources() {
        navigate("/resources?category=application-business-box");
    }

    const content = (
        <Box
            sx={{
                pl: [3, 6, null, null, 8],
                pr: [3, 4, null, null, 12],
                pt: [0, 4],
                pb: ["120px", "440px"],
                height: ["fit-content", undefined, "calc(100vh - 104px)"],
                // height: 'calc(100vh - 56px)',
                backgroundImage: `url(${cover})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: 'bottom',
                backgroundPositionX: 'center',
                // overflowY: 'auto',
                boxSizing: "border-box",
            }}
        >
            <Grid container spacing={6}>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="h3">Application Business Box</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        align="justify"
                                    >
                                        The Application Business Box is a very fundamental box for any IoT project to get started with actuall business application development.
                                        It includes a Raspberry Pi 4 B for general computing applications, a WaziSense for edge measurements and long-range communication, all enclosed in a waterproof casing for outdoor field deployment.
                                        The WaziSense comes with a solar panel for energy autonomy, internally charging a battery for continuous operation.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item >
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle1" color={"text.secondary"}>
                                            Need more options?
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRight />
                                            <Typography variant="subtitle1">Contact your DIH</Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRight />
                                            <Typography variant="subtitle1">
                                                List of contents
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRight />
                                            <Typography variant="subtitle1">
                                                Shipping prices
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                        <Typography variant="h6" mr={3}>29 pieces in this box</Typography>
                                        <Typography variant="caption" sx={{ color: "#186DBF", cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={navigateSeeAllResources}>see all (29)</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xl={6}>
                                            <CardResource icon={raspberryPiIcon} quantity={1} title={"Raspberry Pi 4 B"} subtitle={"by Raspberry Pi"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={wazidevIcon} quantity={2} title={"WaziDev 1.4 Pro"} subtitle={"by Waziup"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={waziSenseIcon} quantity={2} title={"WaziSense"} subtitle={"by Waziup"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={solarPanelIcon} quantity={1} title={"WaziHat Pro Single"} subtitle={"by Waziup"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                        <Typography variant="h6" mr={3}>16 courses in this box</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item sm={6} xl={5}>
                                            <CardCourse title={"Basic of Electronics"} subtitle={"by Waziup"} desc={"Current, voltage, wires… IoT is, at its base, just electronics. In this course, we will learn the basics that you need to know to start building IoT prototypes."} />
                                        </Grid>
                                        <Grid item sm={6} xl={5}>
                                            <CardCourse title={"Overview of Boards and Peripherals"} subtitle={"by Waziup"} desc={"An overview of the available boards and peripherals in the IoT world"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item>
                    <Grid container spacing={2} display="flex" flexDirection="column">
                        <Grid item>
                            <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                <Typography variant="h6" mr={3}>18 Solutions in this box</Typography>
                                <Typography variant="caption" sx={{ color: "#186DBF", cursor: "pointer" }}>see all (18)</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item md={3}>
                                    <CardSolutions title={"Autonomous Irrigation"} desc={"Using AI to optimize plant irrigation procedures."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions sx={{ mt: [undefined, 3] }} title={"Cattle Theft"} desc={"Monitor stock positions and record data."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions title={"Weather Station"} desc={"Measure rain, wind, temperature and display the values in the cloud."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions sx={{ mt: [undefined, 3] }} title={"Fish Farm"} desc={"Collect water data, including temperture and other data."} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </Box>
    );

    const action = (
        <Button
            size="large"
            startIcon={<LocalLibraryOutlined />}
            sx={{
                mr: 2,
                bgcolor: "grey.200",
                ":hover": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                },
            }}
            onClick={() => navigate("/resources")}
        >
            Resource Catalog
        </Button>
    );

    return (
        <Page
            title="Businessbox"
            actions={action}
            onNavigateBack={() => navigate(-1)}
        >
            {/* <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} {...containerProps} className="LabPageContainer" ref={ref}>
                <Box sx={joinSx({ pl: [3, 6, null, null, 8], pr: [3, 4, null, null, 12], pt: [0, 4], pb: 8, display: "flex", flexDirection: "column", maxWidth: 1200, flexGrow: 1 }, sx)} className="LabPageContent" {...boxProps}>
                    {props.children}
                </Box>
            </Box> */}

            {content}
        </Page>
    );
}


export function ApplicationBusinessBoxCard() {

    const navigate = useNavigate();

    function navigateSolutionBoxPage() {
        navigate("/businessbox/application-business-box");
    }

    return <Card sx={{ maxWidth: 380, bgcolor: "#b9b9b944", position: "relative" }} onClick={navigateSolutionBoxPage}>
        <CardActionArea>
            <Box sx={{ p: 2, pb: 0, gap: 2, display: "flex", alignItems: "baseline" }}>
                <Typography variant="h5" sx={{ "&:hover": { textDecoration: "underline" } }}>Application Business Box</Typography>
                <Typography variant="body2">19 items</Typography>
            </Box>
            <CardContent sx={{ pt: 1 }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                    <BoxCardItem title="WaziSense" icon={waziSenseIcon} quantity={1} />
                    <BoxCardItem title="Outdoor Casing" icon={waterproofCasingIcon} quantity={1} />
                    <BoxCardItem title="Solar Panel" icon={solarPanelIcon} quantity={1} />
                    <Box sx={{ pt: 5 }}>
                        <Typography variant="body1" sx={{ "&:hover": { textDecoration: "underline" } }}>+16 more</Typography>
                    </Box>
                </Box>
            </CardContent>

            {/* <SVG sx={{ position: "absolute", width: "100%", bottom: 0, left: 0, zIndex: -1, opacity: .6 }} width="378" height="47" viewBox="0 0 378 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10.2965C65.0913 -4.36946 147.004 -2.46176 197.627 10.2965C247.033 22.7483 333.299 24.6851 378 10.2965V40.7344H0V10.2965Z" fill="#F87B43" />
                <path d="M0 16.5621C65.0913 1.89617 147.004 3.80387 197.627 16.5621C247.033 29.0139 333.299 30.9507 378 16.5621V47H0V16.5621Z" fill="#2861A6" />
            </SVG> */}
        </CardActionArea>
    </Card>
}

type BoxCardItemProps = {
    title: string;
    icon: string;
    quantity: number;
}

function BoxCardItem(props: BoxCardItemProps) {
    const { title, icon, quantity } = props;

    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>{title}</Typography>
        <Box component="img" src={icon} sx={{ width: 40, height: 40 }} />
        <Typography variant="body1">{quantity}x</Typography>
    </Box>
}

const SVG = styled("svg")({});

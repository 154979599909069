import { restCache } from "@halliday/rest";
import { Course, Program, Solution, File } from "./api";

const cover: File = {
  url: "https://picsum.photos/seed/def456/800/600",
  width: 800,
  height: 600,
  id: "def456",
  name: "cover.jpg",
  ext: ".jpg",
  related: null,
  createdAt: new Date("07/12/2021"),
  modifiedAt: new Date("07/12/2021"),
  size: 0,
  owner: null,
  org: "abc123",
  orgName: "Waziup",
}

const course1: Course = {
  id: "abc123",
  weakId: "design-thinking",

  owner: null,

  org: "abc123",
  orgName: "Waziup",
  orgIconUrl: "https:/picsum.photos/seed/abc123/80/80",

  createdAt: new Date("07/12/2021"),
  modifiedAt: new Date("07/12/2021"),

  module: "abc123",
  moduleTitle: "Design Module",
  title: "Introduction to Design Thinking",
  desc: "Design thinking is a process for creative problem solving",
  cover: cover,
  files: [],
  rank: 1,

  joined: true,
  joinedAt: new Date("07/12/2021"),
  numTopics: 5,
  numTopicsDone: 3,
  numQuiz: 12,
  numQuizDone: 8,
};

const course2: Course = {
  id: "def789",
  weakId: "design-thinking",

  owner: null,

  org: "abc123",
  orgName: "Waziup",
  orgIconUrl: "https://picsum.photos/seed/abc123/80/80",

  createdAt: new Date("07/12/2021"),
  modifiedAt: new Date("07/12/2021"),

  module: "abc123",
  moduleTitle: "Design Module",
  title: "Fundamentals of IoT",
  desc: "Learn about the Internet of Things",
  cover: cover,
  files: [],
  rank: 2,

  joined: true,
  joinedAt: new Date("07/12/2021"),
  numTopics: 5,
  numTopicsDone: 3,
  numQuiz: 12,
  numQuizDone: 8,
};

const course3: Course = {
  id: "c456",
  weakId: "design-thinking",

  owner: null,

  org: "abc123",
  orgName: "Waziup",
  orgIconUrl: "https://picsum.photos/seed/abc123/80/80",

  createdAt: new Date("07/12/2021"),
  modifiedAt: new Date("07/12/2021"),

  module: "abc123",
  moduleTitle: "Design Module",
  title: "Test course 3",
  desc: "3rd course in the dummy api",
  cover: cover,
  files: [],
  rank: 3,
  joined: true,
  joinedAt: new Date("07/12/2021"),
  numTopics: 7,
  numTopicsDone: 2,
  numQuiz: 5,
  numQuizDone: 3,
};

const solution1: Solution = {
  title: "Cattle Hustling",
  desc: "Cattle rustlings is increasingly becoming a major problem in many parts of Africa in recent years. Despite the growing level of cattle theft and its consequences for society, the situation has yet to receive an effective intervention.",
  intro:
    "A simple tracking solution for livestock witht the help of Geofencing.",
  cover: null,
  coverUrl: cover.url,
  draft: true,
  resources: [],
  steps: [],
  lines: [],

  id: "abc123",
  weakId: "def123",
  owner: null,
  ownerPreferredUsername: "random",
  ownerPicture: "",
  org: "random",
  orgName: "",
  orgIconUrl: "random",
  createdAt: new Date("07/12/2021"),
  modifiedAt: new Date("07/12/2021"),
};

const program: Program = {
  id: "prog123",
  name: "HUBiquitous Talent Program",
  desc: "A program to help you become a better developer",
  createdAt: new Date("07/12/2021"),
  individualStart: false,
  started: true,
  startsAt: new Date("07/12/2021"),
  endsAt: new Date("11/12/2021"),
  content: "",
  join: "open",
  tags: [],
  added: true,
  addedAt: new Date("07/12/2021"),
  joined: true,
  joinedAt: new Date("07/12/2021"),
  cover: {
    width: 800,
    height: 600,
    size: 0,
    id: "abc123",
    ext: ".jpg",
    name: "cover.jpg",
    related: null,
    createdAt: new Date("07/12/2021"),
    owner: null,
    modifiedAt: new Date("07/12/2021"),
    org: "",
    orgName: "",
    url: "https://picsum.photos/seed/abc123/800/600",
  },
  audience: ["startups", "enthusiasts"],
  // createdBy: ,
  roles: [],
  org: {
    id: "abc123",
    slug: "hubiquitous",
    title: "HUBiquitous",
    desc: "A company that does stuff",
    owner: null,
    createdAt: new Date("07/12/2021"),
    modifiedAt: new Date("07/12/2021"),
    cover: null,
    banner: null,
    public: true,
    open: true,
    joined: true,
    joinedAt: new Date("07/12/2021"),
    added: true,
    addedAt: new Date("07/12/2021"),
    roles: [],
  },
  events: [
    {
      id: "event345",
      name: "Event-2",
      desc: "some targets or agendas of the event",
      date: new Date("09/28/2023"),
      link: "asd",// contents
      content: "<p>test content</p>",
    },
    {
      id: "event456",
      name: "Event-3",
      desc: "some targets or agendas of the event",
      date: new Date("09/18/2023"),
      link: "",
      content: "<p>test content</p>",
    },
    {
      id: "event123",
      name: "Welcome meeting",
      desc: "A meeting to welcome you to the program",
      date: new Date("08/28/2023"),
      // link: "https://google.com",
      link: "as32",
      content: "<p>test content</p>",
    },
    {
      id: "event234",
      name: "Event-1",
      desc: "some targets or agendas of the event",
      date: new Date("09/05/2023"),
      link: "6454",
      content: "<p>test content</p>",
    },
  ],
  numEvents: 1,
  units: [
    {
      id: "abc123",
      name: "Unit 1",
      desc: "Hardware & Communications",
      target: "Learn about hardware and communications",
      from: new Date("07/12/2021"),
      to: new Date("07/12/2021"),
      content: "",
      numCourses: 0,
      courses: [],
      numSolutions: 1,
      solutions: [solution1],
      numQuiz: 0,
      numQuizDone: 0,
      numTopics: 0,
      numTopicsDone: 0,
    },
    {
      id: "abc456",
      name: "Unit 2",
      desc: "Getting started with Waziup Technologies",
      target: "Learn about Waziup Technologies",
      from: new Date("07/12/2021"),
      to: new Date("07/12/2021"),
      content: "",
      numCourses: 2,
      courses: [course1, course2],
      numSolutions: 0,
      solutions: [],
      numQuiz: 0,
      numQuizDone: 0,
      numTopics: 4,
      numTopicsDone: 2,
    },
    {
      id: "abc789",
      name: "Unit 3",
      desc: "IoT Platforms Web & Mobile App",
      target: "Learn about IoT Platforms Web & Mobile App",
      from: new Date("07/12/2021"),
      to: new Date("07/12/2021"),
      content: "",
      numCourses: 2,
      courses: [course2, course3],
      numSolutions: 0,
      solutions: [solution1],
      numQuiz: 0,
      numQuizDone: 0,
      numTopics: 4,
      numTopicsDone: 2,
    },
    {
      id: "abc012",
      name: "Unit 4",
      desc: "Data Analysus Visualisation & AI",
      target: "Learn about Data Analysus Visualisation & AI",
      from: new Date("07/12/2021"),
      to: new Date("07/12/2021"),
      content: "",
      numCourses: 2,
      courses: [course1, course2],
      numSolutions: 0,
      solutions: [],
      numQuiz: 0,
      numQuizDone: 0,
      numTopics: 4,
      numTopicsDone: 2,
    },
    {
      id: "abc345",
      name: "Unit 5",
      desc: "Casing and Deployment",
      target: "Learn about Casing and Deployment",
      from: new Date("07/12/2021"),
      to: new Date("07/12/2021"),
      content: "",
      numCourses: 2,
      courses: [course3, course2],
      numSolutions: 0,
      solutions: [],
      numQuiz: 0,
      numQuizDone: 0,
      numTopics: 4,
      numTopicsDone: 2,
    },
  ],
  numUnits: 5,
  members: [
    {
      user: {
        id: "abcdef",
        createdAt: new Date(),
        username: "John Doe",
        roles: ["tutor"],
        updatedAt: new Date(),
      },
      added: true,
      addedAt: new Date("2021-10-07"),
      joined: true,
      joinedAt: new Date("2021-10-07"),
      // invitedBy: ,
      roles: ["trainer"],
    },
    {
      user: {
        id: "abf457",
        createdAt: new Date(),
        username: "Jeff Starkman",
        roles: ["admin"],
        updatedAt: new Date(),
      },
      added: true,
      addedAt: new Date("2021-10-07"),
      joined: false,
      joinedAt: null,
      // invitedBy: ,
      roles: [],
    },
  ],
  numMembers: 2,
  teams: [
    {
      id: "abc123",
      name: "Team 1",
      desc: "Team 1 description",
      members: [
        {
          user: {
            id: "abcdef",
            createdAt: new Date(),
            username: "John Doe",
            roles: [],
            updatedAt: new Date(),
          },
          roles: [],
        },
        {
          user: {
            id: "abf457",
            createdAt: new Date(),
            username: "Jeff Starkman",
            roles: ["admin"],
            updatedAt: new Date(),
          },
          roles: [],
        },
      ],
      courses: [],
      roles: [],
      solutions: [],
    },
  ],
  numTeams: 1,
};

if (window.location.hash == "#dummy") {
  const d: Record<string, any> = {
    "http://localhost:80/api/v1/programs": { programs: [program] },
    "http://localhost:80/api/v1/programs/prog123": { program },
  };
  for (const unit of program.units!) {
    const clone = { ...unit };
    delete unit.courses;
    delete unit.solutions;
    d[`http://localhost:80/api/v1/programs/prog123/units/${unit.id}`] = {
      unit: clone,
    };
  }
  Object.assign(restCache, d);
}

import { Box, BoxProps, Checkbox, CheckboxProps, Table, TableBody, TableBodyProps, TableCell, TableCellProps, TableHead, TableHeadProps, TableProps, TableRow, TableRowProps } from "@mui/material";
import { Key, forwardRef, useEffect } from "react";
// import Selection, { useSelectionCount } from "../Selection";
import clsx from "clsx";
import Selection from "../Selection";
import { joinSx, useListener, useState2 } from "../tools";

// export type DataGridPage<T> = {
//     items: T[],
// }

// interface DataGridSelection<T> {
//     has(item: T): boolean;
//     on(ev: "change", listener: () => void): void;
//     off(ev: "change", listener: () => void): void;
//     clear(): void;
//     all(): void;
//     isAll: boolean;
//     size: number;
//     handleChange(ev: {}, checked: boolean, item: T): void;
// }

export type DataGridColumn<T> = {
    key: string,
    head: string,
    renderData: (data: T) => React.ReactNode,
    skeleton?: React.ReactNode,
    props?: TableCellProps,
}

export interface DataGridProps<T> extends TableProps {
    items: T[],
    numItemsTotal: number,
    itemKey?: (t: T) => Key | null | undefined,
    itemsIndex?: number,

    loading?: boolean,
    skeleton?: React.ReactNode,
    columns: DataGridColumn<T>[],
    tableHeadProps?: TableHeadProps,
    tableBodyProps?: TableBodyProps,
    selection?: Selection<T>,
    renderPlaceholder?: () => React.ReactNode,
    renderRow?: (props: DataGridRowProps<T>) => React.ReactNode,
}

export default function DataGrid<T>(props: DataGridProps<T>) {
    const { items, numItemsTotal, itemKey, itemsIndex, loading, columns, tableHeadProps, tableBodyProps, selection, renderPlaceholder = defaultRenderPlaceholder, renderRow = defaultRenderRow, className, sx, ...tableProps } = props;
    return <Table
        sx={joinSx({ minWidth: 650, display: "block", ">thead,>tbody": { display: "block", ">tr": { display: "flex", alignItems: "center", borderBottom: 1, borderColor: "divider", ">td,>th": { display: "block", borderWidth: 0, py: 2 }, ">th": { py: 1 } } } }, sx)}
        aria-label="userlist"
        className={clsx("DataGrid", className)}
        {...tableProps}
    >
        <TableHead {...tableHeadProps} className={clsx("DataGridHead", tableHeadProps?.className)}>
            <TableRow>
                {/* {selection && <TableCell sx={{ pr: 0, maxWidth: 58, minWidth: 58 }}><SelectionCheckbox sel={selection} numTotal={numItemsTotal} color="default" /></TableCell>} */}
                {selection && <TableCell sx={{ pr: 0, maxWidth: 58, minWidth: 58 }}></TableCell>}
                {columns.map((column) => <TableCell key={column.key} {...column.props}>{column.head}</TableCell>)}
            </TableRow>
        </TableHead>
        {/* {loading && <LinearProgress />} */}
        <TableBody {...tableBodyProps} className={clsx("DataGridBody", tableBodyProps?.className)}>
            {items.map((item, index) => renderRow({ index: itemsIndex !== undefined ? itemsIndex + index : undefined, key: itemKey?.(item), item, selection, columns }))}
            {!items.length && renderPlaceholder && renderPlaceholder()}
        </TableBody>
    </Table>
}

////////////////////////////////////////////////////////////////////////////////

// export interface DataPage<T> {
//     items: T[],
//     page: number,
//     numItems: number,
// }

// export interface DataTableAdapter<T> {
//     loadPage(n: number): Promise<DataPage<T>>;
// }

// export interface DataTableProps<T> extends Omit<DataGridProps<T>, "items" | "numItemsTotal"> {
//     loader: GenericLoader<T>,
//     pageSize: number,
//     onPageSizeChange: (ev: {}, pageSize: number) => void,
//     onPageChange?: (ev: {}, page: Promise<GenericPage<T>>) => void,
// }

// export function DataTable<T>(props: DataTableProps<T>) {

//     const { loader, pageSize, onPageSizeChange, onPageChange, ...dataGridProps } = props;

//     const [n, setN] = useState(0);
//     const [page, setPage] = useState<GenericPage<T> | null>(null);
//     const [loading, setLoading] = useState(false);

//     function changePage(n: number) {
//         // setN(n);
//         // setLoading(true);
//         const p = loader(n);
//         onPageChange?.({}, p);
//         p.then(page => {
//             setPage(page);
//             setN(n);
//         });
//     }

//     useEffect(() => { changePage(0); }, [loader]);
//     // const n = page?.page ?? 0;
//     console.log(page, n, pageSize)

//     return <>
//         <DataGrid {...dataGridProps} loading={loading} items={page?.items ?? []} numItemsTotal={page?.numItems ?? 0} itemsIndex={n * pageSize} />
//         <TablePagination
//             component="div"
//             showFirstButton
//             onRowsPerPageChange={(ev) => onPageSizeChange(ev, parseInt(ev.target.value))}
//             sx={{ px: 1.5 }}
//             count={page ? page.numItems : 0}
//             rowsPerPage={pageSize}
//             page={n}
//             onPageChange={(ev, page) => { changePage(page); }}
//         />
//     </>;
// }


////////////////////////////////////////////////////////////////////////////////

function defaultRenderRow<T>(props: DataGridRowProps<T>): React.ReactNode {
    return <DataGridRow {...props} />;
}

function defaultRenderPlaceholder(): React.ReactNode {
    return <DataGridPlaceholder />;
}

export interface DataGridPlaceholderProps extends BoxProps { }

export function DataGridPlaceholder(props: DataGridPlaceholderProps) {
    return <tr><Box {...props} component="td" sx={joinSx({ display: "flex !important", alignItems: "center", justifyContent: "center", px: 4, py: 2, minHeight: "150px", width: "100%" }, props.sx)} /></tr>;
}

export interface DataGridRowProps<T> extends TableRowProps {
    // itemKey: (t: T) => Key | null | undefined,
    item: T,
    index?: number,
    // checkboxSelection?: boolean,
    selection?: Selection<T>,
    columns: DataGridColumn<T>[],
}

export function DataGridRow<T>(props: DataGridRowProps<T>) {
    const { item, index, selection, columns, sx, className, ...tableRowProps } = props;

    const [selected, setSelected] = useState2(selection?.has(item) || false, [selection, item]);
    useListener(selection, "change", () => { setSelected(selection?.has(item) || false); });

    function handlePointerDown(ev: React.PointerEvent) {
        if (!selection) return;
        ev.stopPropagation();
        ev.preventDefault();
        selection.toggle(item);
    }

    return <TableRow
        sx={joinSx({
            '&:last-child td, &:last-child th': { border: 0 },
            "&:hover .btn-user-row": { opacity: 1 },
            "&:hover": !selection ? {} : { bgcolor: "#0000000a" },
            cursor: !selection ? undefined : "pointer",
            transition: (theme) => theme.transitions.create(['background-color'], { duration: theme.transitions.duration.shortest }),
        }, sx)}
        onPointerDown={selection ? (ev) => { selection.handlePointerDown(ev, item); } : undefined}
        className={clsx("DataGridRow", className)}
        {...tableRowProps}
    >
        {selection && <TableCell sx={{ pr: 0, maxWidth: 58, minWidth: 58 }}><Checkbox checked={selected} onPointerDown={handlePointerDown} /></TableCell>}
        {columns.map((column) => <TableCell key={column.key} {...column.props}>{column.renderData(item)}</TableCell>)}
    </TableRow>
};


interface SelectionCheckboxProps extends CheckboxProps {
    sel: Selection<any>,
    numTotal: number,
}

const SelectionCheckbox = forwardRef((props: SelectionCheckboxProps, ref) => {
    const { sel, numTotal, ...checkboxProps } = props;
    const numSelected = useSelectionCount(sel, numTotal);
    const checked = numSelected === numTotal;
    const indeterminate = numSelected > 0 && numSelected < numTotal;
    return <Checkbox checked={checked} indeterminate={indeterminate} onChange={(ev, checked) => { if (checked) sel.all(); else sel.clear(); }} {...checkboxProps} />
});

export function useSelectionCount(s: Selection<any> | undefined, numTotal: number): number {
    const [count, setCount] = useState2(s ? (s.isAll ? numTotal - s.size : s.size) : 0, [s]);
    useEffect(() => {
        if (!s) return;
        const onChange = () => {
            setCount(s.isAll ? numTotal - s.size : s.size);
        }
        s.on("change", onChange);
        return () => s.off("change", onChange);
    }, [s, numTotal, setCount]);
    return count;
}
import { useEffect } from "react";
import { useState2 } from "./tools";

export type SelectionProps<T> = {
    multiple?: boolean,
    get: (i: number) => T | undefined,
}

// interface SetLike<T> {
//     add(value: T): this;
//     clear(): void;
//     delete(value: T): boolean;
//     has(value: T): boolean;
//     readonly size: number;
//     [Symbol.iterator](): IterableIterator<T>;
// }

export const longPressTime = 500;

export type SelectEventOptions = {
    noEmit?: boolean,
}

export interface SetLike<T> {
    add(value: T): this;
    clear(): void;
    delete(value: T): boolean;
    has(value: T): boolean;
    readonly size: number;
    [Symbol.iterator](): IterableIterator<T>;
}

export default class Selection<T = any> {

    // onChange?: () => void;
    // onNavigate?: (t: T) => void;
    itemAt?: (idx: number) => T | undefined;
    focus: number = 0;
    multiple = true;
    items: SetLike<T>;

    constructor(items: Iterable<T> = [], public isAll = false, public isHot = false) {
        this.items = new Set(items);
    }

    clear(opts?: SelectEventOptions) {
        this.items.clear();
        this.isHot = false;
        this.isAll = false;
        if(!opts?.noEmit) this.emit("change");
    }

    all(opts?: SelectEventOptions) {
        this.items.clear();
        this.isAll = true;
        if(!opts?.noEmit) this.emit("change");
    }

    set(t: Array<T>, opts?: SelectEventOptions) {
        this.isAll = false;
        this.items.clear();
        for(const i of t) this.items.add(i);
        this.isAll = false;
        if(!opts?.noEmit) this.emit("change");
    }

    add(t: T, opts?: SelectEventOptions) {
        if (!this.has(t)) {
            if (this.isAll) this.items.delete(t);
            else this.items.add(t);
            if(!opts?.noEmit) this.emit("change");
        }
    }

    remove(t: T, opts?: SelectEventOptions) {
        if (this.has(t)) {
            if (this.isAll) this.items.add(t);
            else this.items.delete(t);
            if(!opts?.noEmit) this.emit("change");
        }
    }

    toggle(t: T, opts?: SelectEventOptions) {
        if (this.has(t)) this.remove(t, opts);
        else this.add(t, opts);
    }

    get size() {
        return this.items.size;
    }

    handleChange(ev: React.SyntheticEvent, sel: boolean, t: T, idx: number = 0) {
        if (this.isHot) {
            if (sel) this.add(t);
            else this.remove(t);
            return;
        }
        if (sel) this.set([t]);
        else this.clear();
    }

    handlePointerDown(ev: React.PointerEvent, t: T, idx: number = 0) {
        let handled = false;
        ev.preventDefault();

        const box = ev.currentTarget as HTMLDivElement;

        const onPointerLeave = (ev: PointerEvent) => {
            cleanup();
        }

        const onPointerUp = (ev: PointerEvent) => {
            cleanup();
            ev.preventDefault();

            if (handled) {
                return;
            }

            if (ev.pointerType === "mouse") {
                if (this.has(t)) {
                    if (ev.ctrlKey || this.isHot) {

                        // unselect element
                        this.remove(t);
                        return;
                    }

                    if (this.items.size > 1 || this.isAll) {
                        // reduce selection to current element
                        this.set([t]);
                        return
                    }

                    // navigate to selected element
                    this.emit("navigate", t);
                    return;
                }
            } else {

                if (this.isHot) {
                    // add to selection
                    this.toggle(t);
                    this.emit("change");
                    return;
                }

                if (this.items.size > 1 || this.isAll) {
                    // reduce selection to current element
                    this.set([t]);
                    return
                }

                if (this.items.size !== 0 || this.isAll) {
                    this.clear();
                    return
                }

                // navigate to selected element
                this.set([t]);
                this.emit("navigate", t);
                return;
            }
        }

        const handleLongPress = () => {
            cleanup();

            this.isHot = true;
            this.add(t);
        }

        const cleanup = () => {
            box.removeEventListener("pointerleave", onPointerLeave);
            box.removeEventListener("pointerup", onPointerUp);
            clearTimeout(longPressTimeout);
        }

        const longPressTimeout = setTimeout(handleLongPress, longPressTime);
        box.addEventListener("pointerleave", onPointerLeave);
        box.addEventListener("pointerup", onPointerUp);

        if (ev.shiftKey && this.itemAt) {
            handled = true;
            const hasT = this.has(t);
            for (let i = this.focus; i !== idx; i += (idx < i ? -1 : 1)) {
                const t = this.itemAt(i);
                if (t !== undefined) {
                    if (hasT) this.remove(t);
                    else this.add(t);
                }
            }
            if (hasT) this.remove(t);
            else this.add(t);
            this.focus = idx;
            return;
        }

        this.focus = idx;

        if (!this.has(t) && ev.pointerType === "mouse") {
            handled = true;

            if (ev.ctrlKey || this.isHot) {
                // add to selection
                this.add(t);
                return;
            }

            // select only current element
            this.set([t]);
            return;
        }
    }

    has(t: T) {
        return this.isAll !== this.items.has(t);
    }

    // set(t: Iterable<T>) {
    //     this.items.clear();
    //     for (const item of t) {
    //         this.items.add(item);
    //     }
    //     this.all = false;
    // }

    private changeListeners = new Set<Function>();
    private navigateListeners = new Set<Function>();

    on(ev: "change", cb: () => void): void;
    on(ev: "navigate", cb: (t: T) => void): void;
    on(ev: string, cb: Function): void {
        if (ev === "change") this.changeListeners.add(cb);
        else if (ev === "navigate") this.navigateListeners.add(cb);
    }

    off(ev: "change", cb: () => void): void;
    off(ev: "navigate", cb: (t: T) => void): void;
    off(ev: string, cb: Function): void {
        if (ev === "change") this.changeListeners.delete(cb);
        else if (ev === "navigate") this.navigateListeners.delete(cb);
    }

    private emit(ev: "change"): void;
    private emit(ev: "navigate", t: T): void;
    private emit(ev: string, arg0?: any): void {
        if (ev === "change") {
            for (const cb of this.changeListeners) {
                try { cb(); }
                catch (e) { console.error(e); }
            }
        } else if (ev === "navigate") {
            for (const cb of this.navigateListeners) {
                try { cb(arg0); }
                catch (e) { console.error(e); }
            }
        }

    }
}


export function useIsSelected<T>(s: Selection | undefined, t: T): boolean {
    const [selected, setSelected] = useState2(s?.has(t) ?? false, [s, t]);
    useEffect(() => {
        if (!s) return;
        const onChange = () => {
            setSelected(s.has(t));
        }
        s.on("change", onChange);
        return () => s.off("change", onChange);
    }, [s, t, setSelected]);
    return selected;
}

export function useSelectionCount(s: Selection | undefined, numTotal: number): number {
    const [count, setCount] = useState2(s ? (s.isAll ? numTotal - s.size : s.size) : 0, [s]);
    useEffect(() => {
        if (!s) return;
        const onChange = () => {
            setCount(s.isAll ? numTotal - s.size : s.size);
        }
        s.on("change", onChange);
        return () => s.off("change", onChange);
    }, [s, numTotal, setCount]);
    return count;
}

// function firstOfSet<T>(items: SetLike<T>): T | undefined {
//     for (const t of items) return t;
// }

import { ArrowLeft, ArrowRight, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, CheckOutlined, RestartAltOutlined } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Checkbox, CircularProgress, Divider, Fade, FormControlLabel, LinearProgress, Link, Menu, Paper, Stack, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
// import { useLayoutEffect, useRef } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { completeUserCourseTopic, Course, getCourse, getTopics, resetCourse, resetCourseTopic, Topic, TopicId, TopicsResponse } from "../../api";
import { useForceUpdate } from "../../tools";
import ErrorView from "../ErrorView";
import Page, { PageContent, TOC, TOCHeader, TOCList, TOCListItem } from "../Page";
import { renderHTML, useSearchField } from "../tools";
import { useSession } from "../../ident";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { MsgBox, reportError } from "@halliday/mui-msgbox";
import CheckSmall from "../icons/CheckSmall";
import BookmarkIcon from '@mui/icons-material/Bookmark';

const pageDoneMinDelay = 1500;
const autoPageDone = false;

function CoursePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [myCourse, setMyCourse] = useState<Course | null>(null);
  const [page, setPage] = useState<TopicsResponse | null>(null);
  const [err, setErr] = useState<any>(null);

  let [activeTopicId, setActiveTopicId] = useSearchField("topic");

  let handleScroll: React.UIEventHandler<HTMLDivElement> | undefined;

  const [state] = useState<{
    pageDoneTimeout?: ReturnType<typeof setTimeout>
  }>({});

  // useEffect(() => {
  //   if (autoPageDone) {
  //     if (state.pageDoneTimeout) {
  //       clearTimeout(state.pageDoneTimeout);
  //       state.pageDoneTimeout = undefined;
  //     }
  //   }
  // }, [activeTopicId]);

  const sess = useSession();
  useEffect(() => {
    if (state.pageDoneTimeout)
      clearTimeout(state.pageDoneTimeout);

    if (!id) {
      navigate("/my-courses");
      return;
    }
    setMyCourse(null);
    setPage(null);
    setErr(null);
    getCourse({ id }).then((course) => {
      setMyCourse(course);
    }, setErr);

    getTopics({ course: id }).then((page) => {
      setPage(page);
    }, setErr);
  }, [sess, id]);

  let title: string = "Please wait...";
  let content: JSX.Element | null;
  let tocContent: JSX.Element | null = null;
  let nav: JSX.Element | null = null;

  let ref: React.Ref<HTMLElement> | undefined;

  const [progressMenuAnchorEl, setProgressMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleProgressClick = (event: React.MouseEvent<HTMLButtonElement>) => { setProgressMenuAnchorEl(event.currentTarget); };
  const closeProgressMenu = () => { setProgressMenuAnchorEl(null); };

  // useLayoutEffect(() => {
  //   const container = ref.current!;
  //   container.scrollTop = 0;
  // }, [activeTopicId]);

  function askResetCourse() {
    MsgBox({
      title: "Reset Course",
      text: "Are you sure you want to reset this course?",
      handleYes: async () => {
        try {
          await resetCourse({ course: id! });
        } catch (err) {
          reportError(err);
          return;
        }

        const { topics } = page!;
        topics.forEach(topic => topic.done = false);
        myCourse!.numQuizDone = 0;
        myCourse!.numTopicsDone = 0;
        forceUpdate();
      },
      handleNo: () => { },
    })
  }

  function completeTopic(topicId: TopicId) {
    completeUserCourseTopic({ course: id!, topic: topicId });
    const topic = page!.topics.find(topic => topic.id === topicId)!;
    if (!topic.done) {
      topic.done = true;
      myCourse!.numTopicsDone++;
      forceUpdate();
    }
  }

  function resetTopic(topicId: TopicId) {
    resetCourseTopic({ course: id!, topic: topicId });
    const topic = page!.topics.find(topic => topic.id === topicId)!;
    if (topic.done) {
      topic.done = false;
      myCourse!.numTopicsDone--;
      forceUpdate();
    }
  }

  const forceUpdate = useForceUpdate();

  if (err) content = <ErrorView err={err} />;
  else if (page === null || myCourse === null) content = <CircularProgress />;
  else {
    const course = myCourse;
    const { topics } = page;

    let activeTopicIndex = topics.findIndex(
      (topic) => topic.id === activeTopicId
    );
    if (activeTopicIndex === -1) {
      activeTopicIndex = 0;
      activeTopicId = topics[0].id;
    }

    const activeTopic = topics[activeTopicIndex];

    // const handleTopicCompleteChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    //   if (ev.target.checked) completeTopic(activeTopic.id);
    //   else resetTopic(activeTopic.id);
    // }


    const numTopicsDone = topics.filter(topic => topic.done).length;
    const progress = numTopicsDone / topics.length;

    const pageDone = () => {
      if (!sess) return;
      if (!activeTopic.done) {
        completeUserCourseTopic({ course: id!, topic: activeTopicId! });
        activeTopic.done = true;
        forceUpdate();
      }
    }

    handleScroll = (ev: React.WheelEvent<HTMLDivElement>) => {
      if(autoPageDone) {
        if (isScrollExhausted(ev.target as HTMLDivElement)) {
          pageDone();
        }
      }
    }

    ref = (ref: HTMLElement) => {
      if (!ref) return;
      Promise.allSettled(
        Array.from(ref.querySelectorAll("img"))
          .map((img) =>
            new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
            })
          )
      ).then(() => {
        if (autoPageDone) {
          if (isScrollExhausted(ref)) {
            state.pageDoneTimeout = setTimeout(pageDone, pageDoneMinDelay);
          }
        }
      });
    }

    title = course.title;

    // function to check if activeTopicId id done and return true or false
    const done = topics.find(topic => topic.id === activeTopicId)?.done;

    content = (
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h1" mb={2}>{activeTopic.title}</Typography>
        <CourseContext.Provider value={myCourse}>
          {renderHTML(activeTopic.content)}
        </CourseContext.Provider>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "center", gap: 2}}>
          {myCourse.joined && (
            done ? <Button 
              sx={{ mr: "auto" }}
              variant="contained"
              startIcon={<CheckOutlined />}
              onClick={() => resetTopic(activeTopicId!)}
            >
              Mark Topic Undone
            </Button> : 
            <Button
              sx={{ mr: "auto" }}
              variant="contained"
              startIcon={<CheckBoxOutlineBlankOutlined />}
              onClick={() => completeTopic(activeTopicId!)}
              >
                Mark Topic Done
            </Button>
          )}
        </Box>
      </Box>
    );

    tocContent = <>
      {/* <TOCHeader><Typography sx={{ fontSize: '1.1em' }}>{title}</Typography></TOCHeader> */}
      <Typography variant="button" pl={2} pt={2} color='text.secondary'>Topics</Typography>
      <TOCList>
        {topics.map(({ title, id, done }) => (
          <TOCListItem
            active={id === activeTopicId}
            key={id}
            opener={id === activeTopicId ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            sx={{ "&:hover .checkbox-blank": { visibility: "visible" } }}
            primary={<Stack direction="row" alignItems="center">
              <Typography sx={{ flexGrow: 1, mr: 2 }}>{title}</Typography>
              {myCourse.joined && (
                <Box sx={{ width: 24, height: 24, "&:hover": { " .check": { display: "none" }, " .check-box": { display: "block" } } }}>
                  {done ? <CheckSmall className="check" /> : null}
                </Box>
              )}
            </Stack>
            }
            onClick={() => setActiveTopicId(id)}
          />
        ))}
      </TOCList>
      {course.joined && <>
        <Box sx={{ p: 3, pb: 0, position: "relative" }} onMouseLeave={closeProgressMenu}>
          <Typography sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }} id="progress-menu-btn" onClick={handleProgressClick}>Progress</Typography>
          <Fade in={progressMenuAnchorEl !== null} id="progress-menu">
            <Paper sx={{ position: "absolute", left: 8, right: 8, bottom: 40, px: 2, py: 2, alignItems: "flex-start" }} elevation={4}>
              <Box sx={{ px: 2 }}>
                {topics.length > 0 && <Typography sx={{ mb: 2 }}>{numTopicsDone} of {topics.length} topics done</Typography>}
                {course.numQuiz > 0 && <Typography sx={{ mb: 2 }}>{myCourse.numQuizDone} of {course.numQuiz} quiz done</Typography>}
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Button startIcon={<RestartAltOutlined />} onClick={askResetCourse}>Restart Course</Button>
            </Paper>
          </Fade>
        </Box>
        <Box sx={{ p: 3, pt: 0 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <LinearProgress variant="determinate" value={progress * 100} sx={{ flexGrow: 1 }} />
            <Typography color="text.secondary">{Math.round(progress * 100)}%</Typography>
          </Stack>
        </Box>
      </>}
    </>;

    const prevTopic: Topic | undefined = topics[activeTopicIndex - 1];
    const nextTopic: Topic | undefined = topics[activeTopicIndex + 1];
    nav = (
      <Toolbar>
        <Button
          startIcon={<ArrowLeft />}
          sx={{ mr: "auto" }}
          disabled={!prevTopic}
          onClick={() => setActiveTopicId(prevTopic!.id)}
        >
          Previous Topic
        </Button>
        {/* <FormControlLabel control={<Checkbox checked={activeTopic.done} onChange={handleTopicCompleteChange} />} label="Completed" sx={{ mr: 3 }} /> */}
        <Button
          endIcon={<ArrowRight />}
          disabled={!nextTopic}
          variant="outlined"
          onClick={() => setActiveTopicId(nextTopic!.id)}
        >
          Next Topic
        </Button>
      </Toolbar>
    );
  }

  // scroll to top when activeTopicId changes
  useEffect(() => {
    // Scroll to the top when activeTopicId changes
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Scroll to the position of the first h1 or h2 element
    const headingElement = document.querySelector('h1, h2');
    if (headingElement) {
      headingElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeTopicId]);

  return (
    <Page
      tocPrimary={title}
      title={title}
      toc={<TOC>{tocContent}</TOC>}
      prevPage="Courses"
    >
      <PageContent ref={ref} containerProps={{ onScroll: handleScroll }} title={title} >{content}</PageContent>
      {nav}
    </Page>
  );
}

export const CourseContext = createContext<Course | null>(null);
export function useCourse() {
  return useContext(CourseContext);
}


function isScrollExhausted(elm: HTMLElement) {
  const { scrollTop, scrollHeight, clientHeight } = elm;
  const deviation = window.innerHeight / 20;
  return scrollTop + clientHeight + deviation >= scrollHeight;
}

export default CoursePage;

import { toast } from "@halliday/mui-toast";
import { Label, LocalLibraryOutlined, } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Box, Breadcrumbs, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputBase, Link, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled, tableCellClasses, useMediaQuery, useTheme } from "@mui/material";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Course, CoursesResponse, courseProgress, getCourses, toImageURL, unenroleCourse } from "../../api";
import ContentMissing from "../../assets/images/course-not-found.svg";
import placeholderImage from "../../assets/images/cover_card_course.png";
import { useSession } from "../../ident";
import ErrorView from "../ErrorView";
import Page, { PageContent } from "../Page";
import { formatDifficulty, lineClamp } from "../tools";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';



const BannerDesktop = styled("img")({
  height: 350,
  display: "block",
});

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: '#4CAF50',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
  height: "fit-content",
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingRight: "8px",
  paddingLeft: "8px",
}));

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    // borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function CustomProgressWithLabel(props: LinearProgressProps & { value: number }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    props.value < 100 ?
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <CustomLinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
      :
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: "center" }}>
        <Button sx={{ minWidth: 32, order: [2, null, 1] }} variant="text" startIcon={<WorkspacePremiumIcon />}> {!isMobile && "Certificate"} </Button>
        <Item sx={{ order: [1, null, 2] }}>COMPLETED</Item>
      </Box>
  );
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '1.2rem',
    color: '#828282',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function MyCoursesPage() {
  const navigate = useNavigate();

  const [page, setPage] = useState<CoursesResponse | null>(null);
  const [err, setErr] = useState<any>(null);
  const [difficulty, setDifficulty] = useState<Difficulty | null>(null);

  const sess = useSession();
  useEffect(() => {
    getCourses({ joined: true }).then(setPage, setErr);
  }, [sess]);


  const courseCatalog = (
    <Button
      size="medium"
      startIcon={<LocalLibraryOutlined />}
      sx={{
        mr: 2,
        bgcolor: "grey.200",
        ":hover": {
          backgroundColor: "primary.main",
          color: "#fff",
        },
      }}
      onClick={() => navigate("/courses")}
    >
      Course Catalog
    </Button>
  );

  if(page?.courses.length === 0){
    navigate('/courses')
    return null
  }
  if (err) return <ErrorView err={err} />;
  if (page === null) return <CircularProgress />;


  return (
    <Page title="My Courses" actions={courseCatalog}>
      <PageContent sx={{ pb: 'inherit' }}>
         <MyCoursesList courses={page}/>
      </PageContent>
    </Page>
  );
}
////////////////////////////////////////////////////////////////////////////////

type Difficulty = "beginner" | "intermediate" | "advanced";
const difficulties: Record<Difficulty, string> = {
  beginner: "Beginner",
  intermediate: "Intermediate",
  advanced: "Advanced",
};

export interface CourseResponseProps{
  courses: CoursesResponse | null;
}

export function MyCoursesList(props: CourseResponseProps) {

  const [page, setPage] = useState<CoursesResponse | null>(props.courses);
  const [difficulty, setDifficulty] = useState<Difficulty | null>(null);

  return (
    <Box>
      <Stack flexDirection={"row"} alignItems={"center"} gap={1} mb={1.5}>
        <Typography>Difficulty level:</Typography>
        <Select
          labelId="select-diffulty-label"
          id="select-diffulty"
          displayEmpty
          value={difficulty ?? ""}
          onChange={(ev: SelectChangeEvent<Difficulty | "">) => {
            const difficulty = ev.target.value as Difficulty | "";
            setDifficulty(difficulty || null);
          }}
          input={<InputBase sx={{ bgcolor: difficulty ? "#b1d8ff" : "grey.200", pl: 1.5, pr: 1, borderRadius: 1, maxWidth: 250 }} />}
          renderValue={(value) => {
            if (!value) return <Box component="span" sx={{ color: "text.secondary" }}>All</Box>
            return difficulties[value];
          }}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value={"beginner"}>Beginner</MenuItem>
          <MenuItem value="intermediate">Intermediate</MenuItem>
          <MenuItem value="advanced">Advanced</MenuItem>
        </Select>
      </Stack>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {page?.courses.filter(item => difficulty == null ? true : ["", "beginner", "intermediate", "advanced"][item.difficulty!] === difficulty).map((userCourse) => {
          function onRemove() {
            page!.courses = page!.courses.filter(c => c !== userCourse);
            setPage({ ...page! });
          }
          return <MCCard key={userCourse.id} course={userCourse} onRemove={onRemove} />
        })}
      </Box>
    </Box>
  );
}

// CTRows = Course Table Rows
export interface MCCardProps {
  course: Course;
  onRemove: () => void
}

export function MCCard(props: MCCardProps) {
  const navigate = useNavigate();
  const { course, onRemove } = props;
  const progress = courseProgress(course);
  const { id, numQuiz, title, numTopics, owner, cover, difficulty, } = course;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const boolOpen = Boolean(anchorEl);
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 80 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCloseClick(ev: React.MouseEvent) {
    handleClose();
    ev.stopPropagation();
  }

  function deleteFromMyCourse() {
    unenroleCourse({ id }).then(() => {
      toast("successfully removed from your courses!");
    }, reportError);
    onRemove();
  }

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} sx={{ height: "8px", borderRadius: 25 }} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const dialog = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Remove this course?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You will loose your progress permanently!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickClose}>cancel</Button>
        <Button onClick={deleteFromMyCourse} autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Card
        sx={{
          minWidth: 275,
          display: "flex",
          flexDirection: isMobile ? 'column' : 'row',
          cursor: " pointer",
          transition: 'box-shadow 0.3s', // Add a transition for a smoother effect
          boxShadow: isHovered ? '1px 5px 5px #EBEBEB' : 'none',
        }}
        variant="outlined"
        onClick={() => navigate(`/courses/${id}`)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardMedia
          component="img"
          sx={{ width: ["100%", null, 160], height: "fit-content" }}
          image={cover?.url ?? placeholderImage}
          alt="course cover image"
        />
        <CardContent style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", flexGrow: 1 }}>
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"} flexDirection={isMobile ? 'column' : 'row'}>
            <Box gap={1}>
              <Typography sx={{ ...lineClamp(2), fontSize: ["1.1em", null, "1.24em"], fontWeight: 600, color: '#4F4F4F' }}>{title}</Typography>
              <Typography sx={{ color: '#828282' }}>{`${numTopics} topics • ${formatDifficulty(difficulty!)} Level`}</Typography>
            </Box>


            <Box display={isMobile ? 'none' : 'flex'} justifyContent={"center"} alignItems={"center"} mr={2} >
              {/* <ListItem sx={{ pr: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#F3F3F3" }}>
                    <MenuBookIcon sx={{ color: "#4f4f4f" }} />
                  </Avatar>
                </ListItemAvatar>
              </ListItem>

              <ListItem sx={{ pr: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#F3F3F3" }}>
                    <InventoryOutlinedIcon sx={{ color: "#4f4f4f" }} />
                  </Avatar>
                </ListItemAvatar>
              </ListItem> */}
              {/* <ListItem sx={{ border: "1px #D9D9D9 solid", borderRadius: 6, p: 0, pr: 1 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#E1F3FF" }}>
                    <InventoryOutlinedIcon sx={{ color: "#186DBF" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="7/10" color="#828282" />
              </ListItem> */}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: ["start", null, "center"], gap: 2, justifyContent: "space-around" }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress variant="determinate" value={progress*100} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >{`${Math.round(progress*100)}%`}</Typography>
              </Box>
            </Box>
            <IconButton
              aria-label="settings"
              id="basic-button"
              aria-controls={boolOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={boolOpen ? "true" : undefined}
              onClick={handleClick}
              onMouseDown={handleStopPropagation}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={boolOpen}
              onClose={handleClose}
              onClick={handleCloseClick}
              onMouseDown={handleStopPropagation}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClickOpen}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Remove</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </CardContent>
      </Card>
      {dialog}
    </>
  )
}


function handleStopPropagation(ev: React.SyntheticEvent) {
  ev.stopPropagation();
}

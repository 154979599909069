import { MsgBox } from "@halliday/mui-msgbox";
import { toast } from "@halliday/mui-toast";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CloseIcon from '@mui/icons-material/Close';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event';
import { AccordionProps, Alert, AlertTitle, Avatar, Box, Button, Chip, CircularProgress, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, styled, tableCellClasses, useMediaQuery, useTheme } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from "@mui/material/Typography";
import { deepOrange, deepPurple } from '@mui/material/colors';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams, } from "react-router-dom";
import { FindProgramResponse, Program, findProgram, joinProgram, leaveProgram, unitProgress } from "../../api";
import Hubiquitous from "../../assets/images/hubiquitous-logo.png";
import { useSession } from "../../ident";
import { useForceUpdate } from "../../tools";
import ErrorView from "../ErrorView";
import Page, { PageContent, TOC, TOCList, TOCListItem, } from "../Page";
import { DateChip, DateRangeInWeeks, formatDate, renderHTML } from "../tools";
import ProgramEventContent from "./ProgramEventContent";
import ProgramUnitPage from "./ProgramUnitPage";
import ProgressPage from "./ProgramProgressPage";
import dayjs from 'dayjs';

let totalNumCourses = 0;
let totalNumSolutions = 0;

export type ProgramPageProps = {
    variant: "info" | "events" | "units" | "progress";
}

const getFormattedDate = (date: Date) => {
    const formattedDate = dayjs(date).format("MMMM D, YYYY");
    return formattedDate;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '1px solid #E5E4E2'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: '1px solid #E5E4E2'
    },
}));

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    padding: '0px',
    backgroundColor:
        theme.palette.mode === 'dark'
            ? '#ffffff0d'
            : '#fff',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: 'none',
}));


export default function ProgramPage(props: ProgramPageProps) {
    const { variant } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const navigate = useNavigate();
    const { id: progID, unitId: activeUnitId } = useParams();

    const [page, setPage] = useState<FindProgramResponse | null>(null);
    const [err, setErr] = useState<any>(null);
    const [tocSelected, setTocSelected] = useState<string>("Program info")
    const [unit, setUnit] = useState<string>("")
    const [countCourse, setcountCourse] = useState<number>()

    function navigateUnit(unitId: string) {
        navigate(`/programs/${progID}/units/${unitId}`);
    }

    const sess = useSession();
    useEffect(() => {

        if (!progID) {
            navigate("/my-programs");
            return;
        }
        setPage(null);
        setErr(null);
        findProgram({ id: progID }).then((program) => {
            setPage(program)
        }, setErr);
    }, [sess, progID]);

    useEffect(() => {
        let count = 0;
        page?.program.units?.map(unit => count = count + unit.numCourses)
        setcountCourse(count)
    });

    let name: string = "Please wait...";
    let tocHidden: boolean = false;
    let hasUserRoles: boolean = false;
    let content: ReactElement | null;
    let tocContent: JSX.Element | null = null;

    if (err) content = <ErrorView err={err} />;
    else if (page === null) content = <CircularProgress />;
    else {
        const { program } = page
        const { units, events, startsAt, endsAt, roles } = page.program;

        hasUserRoles = roles?.includes('program-admin' || 'program-manage') || sess?.user.roles.includes('admin') ? true : false;
        console.log(hasUserRoles)

        let activeUnitIndex = units!.findIndex(
            (unit) => unit.id === activeUnitId
        );

        // Loop through the units and add the "numCourses" to the total
        units?.forEach(unit => {
            if (unit.numCourses) {
                totalNumCourses += unit.numCourses;
            }
        });
        // Loop through the units and add the "numSolutions" to the total
        units?.forEach(unit => {
            if (unit.numSolutions) {
                totalNumSolutions += unit.numSolutions;
            }
        });

        let activeUnit = units![activeUnitIndex];
        name = program.name;

        switch (variant) {
            case "info":
                content = <Box sx={{ display: 'flex', flexDirection: ["column",], alignItems: 'flex-start', justifyContent: 'space-between', gap: [1, 3] }}>
                    <Box display='flex' flexDirection={["column"]} justifyContent='start' gap={[2]}>
                        {/* <Box width={["100%", 500]}><img src={program.cover?.url} width="100%" /></Box> */}
                        <Box display='flex' flexDirection='column' gap={1} sx={{ ":last-of-type": { mt: "auto" } }}>
                            {/* <Box width={[200, 250]}><img src={Hubiquitous} alt="hubiquitous logo" width={"100%"} /></Box> */}
                            <Typography variant="h1" sx={{ fontWeight: 600 }}>{program.name}</Typography>
                            <Typography variant="body1" mt={1}>{program.desc}</Typography>
                            <Stack flexDirection='row' gap={1.5}>
                                <Typography variant="body1" mt={1} fontWeight={500}>Created by</Typography>
                                <Typography variant="body1" mt={1} fontWeight={600}><Link href="#" underline="hover">{program.org.title}</Link></Typography>
                                
                                {/* <Typography variant="body1" mt={1} fontWeight={600}> {program.org.title}</Typography> */}
                            </Stack>
                            {/* <Stack flexDirection='row' gap={1}>
                                <DateChip date={program.startsAt} type="start" />
                                <DateChip date={program.endsAt} type="end" />
                            </Stack> */}
                            {/* <Typography my={2}>by <b>{program.org.title}</b> </Typography> */}
                            <Stack display="flex" flexDirection={["column", "row"]} gap={2}>
                                {/* <Typography><b>Program Type:</b> {program.individualStart ? "Individual" : "Group"}</Typography> */}
                                <JoinProgramButton myProg={program} />
                            </Stack>
                        </Box>
                    </Box>
                    <Divider variant="middle" flexItem />
                    <Table sx={{ minWidth: [null, 650], border: 'thin' }} aria-label="simple table">
                        <TableBody>
                            <StyledTableRow >
                                <StyledTableCell align="left" sx={{ fontWeight: 700, width: 200 }}>Number of courses</StyledTableCell>
                                <StyledTableCell align="left">{countCourse} courses</StyledTableCell>
                            </StyledTableRow >
                            <StyledTableRow >
                                <StyledTableCell align="left" sx={{ fontWeight: 700 }}>Duration</StyledTableCell>
                                <StyledTableCell align="left"><DateRangeInWeeks from={startsAt!} to={endsAt!} /></StyledTableCell>
                            </StyledTableRow >
                            <StyledTableRow >
                                <StyledTableCell align="left" sx={{ fontWeight: 700 }}>Start date</StyledTableCell>
                                <StyledTableCell align="left">{formatDate(program.startsAt)}</StyledTableCell>
                            </StyledTableRow >
                            <StyledTableRow >
                                <StyledTableCell align="left" sx={{ fontWeight: 700 }}>End date</StyledTableCell>
                                <StyledTableCell align="left">{formatDate(program.endsAt)}</StyledTableCell>
                            </StyledTableRow >
                        </TableBody>
                    </Table>

                    <Box>{renderHTML(program.content)}</Box>
                </Box>
                break;

            case "events":
                content = <Box> <ProgramEventContent events={events!} /> </Box>;
                break;

            case "units":
                content = <ProgramUnitPage programId={program.id} unitId={activeUnit.id} programAdded={program.added} programJoined={program.joined} />;
                break;

            case "progress":
                content = <ProgressPage programId={program.id} />;
                break;
        }

        tocHidden = ((program.joined === true) && (program.added === true)) || (sess?.user.roles.includes("admin")) ? false : true;

        tocContent = <TOCList>

            {/* <Typography variant="caption" pl={2} pt={2} color='text.secondary'>Program Outline</Typography> */}
            <TOCListItem
                key={progID}
                active={variant === "info"}
                primary={<Stack direction="column" alignItems="left">
                    <Typography sx={{ flexGrow: 1 }}>Info</Typography>
                </Stack>}
                opener={<FeedOutlinedIcon />}
                onClick={() => {
                    setTocSelected('Program info')
                    navigate(`/programs/${progID}`);
                }}
            />

            <TOCListItem
                key={progID}
                active={variant === "progress"}
                primary={<Stack direction="column" alignItems="left">
                    <Typography sx={{ flexGrow: 1 }}>Progress</Typography>
                </Stack>}
                opener={<DonutLargeIcon />}
                onClick={() => {
                    setTocSelected('Program progress')
                    navigate(`/programs/${progID}/progress`);
                }}
            />

            <TOCListItem
                key={variant}
                active={variant === "events"}
                primary={<Stack direction="column" alignItems="left">
                    <Typography sx={{ flexGrow: 1 }}>Events</Typography>
                </Stack>}
                opener={<EventIcon />}
                onClick={() => {
                    setTocSelected('Events')
                    navigate(`/programs/${progID}/events`);
                }}

            />

            <Accordion defaultExpanded elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id={program.id}
                >
                    <Typography variant="body1">Program Units</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* {units!.map(unit => { */}
                    {units!.sort((a, b) => new Date(a.from).getTime() - new Date(b.from).getTime()).map(unit => {
                        const { name, id, from, to } = unit;
                        const progress = unitProgress(unit);

                        return <TOCListItem
                            active={variant === "units" && id === activeUnitId}
                            key={id}
                            primary={<Stack direction="column" alignItems="left">
                                <Typography sx={{ flexGrow: 1 }}>{name}</Typography>
                            </Stack>
                            }
                            opener={<BookmarkBorderIcon />}
                            onClick={() => {
                                setTocSelected(name)
                                navigate(`/programs/${progID}/units/${id}`);
                                setUnit(name)
                            }}
                            secondary={
                                <Stack>
                                    <Typography variant="caption"><DateRangeInWeeks from={from} to={to} />{progress > 0 && `, ${Math.round(progress * 100)}% done`}</Typography>
                                </Stack>
                            }
                        />
                    })}
                </AccordionDetails>
            </Accordion>

        </TOCList>;
    }
    const programEditor =
        <Stack flexDirection='row' gap={1}>
            {/* <Chip variant="outlined" color="info" avatar={<Avatar sx={{bgcolor: deepPurple[500]}}><Typography color='#fff' fontSize='small'>{2}</Typography></Avatar>} label="New Join Request!" /> */}
            <Button
                size="medium"
                startIcon={<EditOutlinedIcon />}
                sx={{
                    mr: 2,
                    bgcolor: "grey.200",
                    ":hover": {
                        backgroundColor: "primary.main",
                        color: "#fff",
                    },
                }}
                onClick={() => navigate(`/programs/${progID}/editor`)}
            >
                Edit Program
            </Button>
        </Stack>
    // const programEditorButton = hasRole ? programEditor : null;
    const programEditorButton = hasUserRoles ? programEditor : <></>;

    return <Page
        title={'Program'}
        progName={name}
        tocPrimary={tocSelected}
        toc={<TOC>{tocContent}</TOC>}
        actions={programEditorButton}
        activeUnit={unit}
    >
        <PageContent sx={{ pt: isMobile ? null : 0 }} title={name} activeUnit={unit} >{content!}</PageContent>
    </Page>
}

export interface JoinProgramProps {
    myProg: Program;
}

export function JoinProgramButton(props: JoinProgramProps) {
    const { myProg } = props;
    const { name, id, startsAt, added, addedAt, joined, desc } = myProg;
    const [open, setOpen] = React.useState(false);
    const [openLeaveProgramDialog, setOpenLeaveProgramDialog] = React.useState(false);

    const forceUpdate = useForceUpdate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sess = useSession();

    async function joinProgramNow() {
        if (!sess) {
            window.openLogin();
            return;
        }
        try {
            await joinProgram({ id });
        } catch (err) {
            reportError(err);
            return
        }
        switch (myProg.join) {
            case "open":
                myProg.joined = true;
                myProg.joinedAt = new Date();
                myProg.added = true;
                myProg.addedAt = myProg.joinedAt;
                toast(`You joined ${name}. Happy learning!`);
                setOpen(false);
                break;
            case "request":
                if (addedAt) return;
                MsgBox({
                    title: "Request Sent",
                    text: "Your request has been sent. You will be notified when your request is approved.",
                });
                myProg.joined = true;
                myProg.joinedAt = new Date();
                setOpen(false);
                break;
            case "invite":
                // unreachable
                break;
        }

    }

    const handleOpenLeaveProgDialog = () => {
        setOpenLeaveProgramDialog(true);
    };
    const handleCloseLeaveProgDialog = () => {
        setOpenLeaveProgramDialog(false);
    };

    const removalDialog = (
        <Dialog
            open={openLeaveProgramDialog}
            onClose={handleCloseLeaveProgDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Leave this Program?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You will loose your progress permanently!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseLeaveProgDialog}>cancel</Button>
                <Button onClick={leaveprogramNow} autoFocus>
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    );

    async function leaveprogramNow() {
        try {
            await leaveProgram({ id });
        } catch (err) {
            reportError(err);
            return
        }
        toast(`You left ${name}.`);
        myProg.joined = false;
        myProg.joinedAt = null;
        myProg.added = false;
        myProg.addedAt = null;
        forceUpdate();
    }

    let btn: ReactNode;
    let btnHint: ReactNode;
    if (joined) {
        if (added) {
            btn = <Stack flexDirection={['column', 'row']} gap={2}>
                <Button sx={{ textTransform: 'none', width: ["100%",], px: 8, py: 2, whiteSpace: 'nowrap' }} disabled variant="contained">Joined Already</Button>
                <Button sx={{ textTransform: 'none', width: ["100%",] }} variant="text" disableTouchRipple disableElevation onClick={handleOpenLeaveProgDialog}>Leave Program</Button>
                {removalDialog}
            </Stack>
        } else {
            btn = <Button sx={{ textTransform: 'none', width: ["100%",], px: 8 }} variant="contained" disableRipple>
                <Stack>
                    <Typography>Join Now</Typography>
                    <Typography>{`Starts ${formatDate(startsAt)}`}</Typography>
                </Stack>
            </Button>
            btnHint = <Alert severity="info">
                Your request to join this program is pending approval.
                <Box sx={{ mt: 1 }}>
                    <Button variant="text" onClick={leaveprogramNow} size="small">Cancel</Button>
                </Box>
            </Alert>;
        }
    } else {
        if (added) {
            // btn = <Button size="small" variant="contained" disableElevation onClick={joinProgramNow}>Join</Button>;
            btn = <Button sx={{ textTransform: 'none', width: ["100%",], px: 8 }} variant="contained" disableRipple onClick={joinProgramNow}>
                <Stack>
                    <Typography>Join Now</Typography>
                    <Typography>{`Starts ${formatDate(startsAt)}`}</Typography>
                </Stack>
            </Button>
            btnHint = <Alert severity="info">
                You have been invited to join this program.
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                    <Button variant="text" onClick={joinProgramNow} size="small">Accept</Button>
                    <Divider orientation="vertical" flexItem />
                    <Button variant="text" onClick={leaveprogramNow} size="small">Decline</Button>
                </Box>
            </Alert>;
        } else {
            // btn = <Button size="small" variant="outlined" onClick={handleClickOpen} >Join</Button>;
            btn = <Button sx={{ textTransform: 'none', width: ["100%",], px: 8 }} variant="contained" disableRipple onClick={handleClickOpen}>
                <Stack>
                    <Typography>Join Now</Typography>
                    <Typography>{`Starts ${formatDate(startsAt)}`}</Typography>
                </Stack>
            </Button>
        }
    }

    let content: JSX.Element;
    switch (myProg.join) {
        case "open":
            content = <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                <Button variant="contained" disableElevation onClick={joinProgramNow}>Join Now</Button>
                <Typography variant="caption">Program is free to join.</Typography>
            </Box>;
            break;
        case "request":
            content = <Box>
                <Button variant="contained" disableElevation onClick={joinProgramNow}>Request Access</Button>
                <Typography variant="body2" sx={{ mt: 2 }}>Your request will be approved by the program admin or organization.</Typography>
            </Box>;
            break;
        case "invite":
            content = <Alert severity="info">
                <AlertTitle>Invitation Required</AlertTitle>
                This program is invite only. Please contact the program admin or organization to get access to the program.
            </Alert>
            break;
    }

    return <Box>
        {btnHint ? btnHint : btn}
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{name}</DialogTitle>
            <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Typography gutterBottom textAlign="justify">{desc}</Typography>
                {startsAt && <Typography sx={{ mb: 4 }}><b>Starting on:</b> {startsAt.toLocaleString()}</Typography>}
                {content}
            </DialogContent>;
        </Dialog>
    </Box>
}

import { styled } from "@mui/material/styles";
import { Button, SxProps, Theme } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { SystemStyleObject } from "@mui/system/styleFunctionSx";

export function queryString(query: any): string {
    if(!query) return "";
    var entries: [string, unknown][] = Array.from(Object.entries(query))
        .map(([key, value]) => [camel2snake(key), value]);
    // sort keys ascending
    entries.sort(([keyA], [keyB]) => keyA > keyB ? 1 : -1);
    // filter out all zero values
    entries = entries.filter(([key, value]) => !isZero(value));
    if (entries.length === 0) return "";
    const queryString = entries.map(urlFlatValue).join("&");
    return "?" + queryString;
}

function urlFlatValue([key, value]: [string, any]): string {
    if (typeof value === "boolean")
        return key;
    return `${key}=${value}`;
}

export function camel2snake(str: string): string {
    return str.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`);
}

export function snake2camelObject(resp: any) {
    var p = {} as any;
    for(var [key, value] of Object.entries(resp)) {
        p[snake2camel(key)] = value;
    }
    return p;
}

export function snake2camel(str: string): string {
    return str.replace(/_[a-z]/g, (c) => `${c[1].toUpperCase()}`);
}

export function camel2snakeObject(resp: any): any{
    if(typeof resp != "object") return resp;
    if (Array.isArray(resp)) return resp.map(camel2snakeObject);
    if (resp === null) return null;
    var p = {} as any;
    for(var [key, value] of Object.entries(resp)) {
        p[camel2snake(key)] = value;
    }
    return p;
}

function isZero(v: any): boolean {
    if (v === null || v === undefined)
        return true;
    switch (typeof v) {
        case "string":
            return v === "";
        case "number":
            return v === 0;
        case "boolean":
            return v === false;
        case "undefined":
            return true;
    }
    if (Array.isArray(v))
    
        return v.length === 0;
    return v.toString() === "";
}

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#304d71'),
    backgroundColor: '#304d71',
    display: 'flex',
    justifyContent: 'flex-end'
}));

export function useForceUpdate(): () => void {
    return useReducer(increment, 0)[1];
}

function increment(x: number) {
    return x+1;
}

////////////////////////////////////////////////////////////////////////////////


const KB = 1024;
const MB = 1024 * KB;
const GB = 1024 * MB;
const TB = 1024 * GB;

export const nbsp = String.fromCharCode(0xa0); // &nbsp;

// formatSize returns a human-readable memory size like "1.20 MB" from the number in bytes.
export function formatSize(s: number) {
    if (s < KB) return `${s}${nbsp}B`;
    if (s < MB) return `${(s / KB).toFixed(2)}${nbsp}KB`;
    if (s < GB) return `${(s / MB).toFixed(2)}${nbsp}MB`;
    if (s < TB) return `${(s / GB).toFixed(2)}${nbsp}GB`;
    return `${(s / TB).toFixed(2)}${nbsp}TB`;
}

// formatDuration returns a human-readable audio/video duration "10:25" (10 min and 25 sec) from the number in seconds.
export function formatDuration(d: number) {
    const mm = `0${Math.floor(d / 60) % 60}`.slice(-2);
    const ss = `0${d % 60}`.slice(-2);
    if (d > 3600) {
        const h = `${Math.floor(d / 3600)}`;
        return `${h}:${mm}:${ss}`;
    }
    return `${mm}:${ss}`;
}

////////////////////////////////////////////////////////////////////////////////

export default function firstOfSet<T>(s: Set<T>): T | undefined {
    for(const i of s) return i;
}

////////////////////////////////////////////////////////////////////////////////



// delay makes the Promise pending for at least [d] milliseconds.
// No matter if the promise fullfills or rejects before the delay, the returned promise will wait and the fullfill or reject accordingly.
export async function delay<T>(p: Promise<T>, d: number): Promise<T> {
    await wait(d);
    return p;
}

// wait returns a promise that fulfills after the given delay (milliseconds).
function wait(d: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, d);
    })
}

// snake2title converts a snake string "like_this_string" to a title case string "Like This String".
export function snake2title(key: string) {
    return key.replaceAll(/(?:^|_)([a-z])/g, (c, d) => " "+d.toUpperCase())
}

////////////////////////////////////////////////////////////////////////////////

export function initials(name: string) {
    if(!name) return "?";
    const s = name.split(" ");
    if (s[1]) return s[0][0]+s[1][0];
    if (s[0][1]) return s[0][0]+s[0][1];
    return name[0];
}

// Generates a color for a generic string to use with the `stringAvatar` function. 
// see https://mui.com/material-ui/react-avatar/#BackgroundLetterAvatars.tsx
export function stringToColor(str: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

////////////////////////////////////////////////////////////////////////////////

export function genRandomUUID() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any)  =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function preventDefault(ev: {preventDefault(): void}) {
    ev.preventDefault();
}

////////////////////////////////////////////////////////////////////////////////

interface EventEmitter<E, C> {
    on(ev: E, cb: C): void;
    off(ev: E, cb: C): void;
}

export function registerListener<E, C>(e: EventEmitter<E, C>, ev: E, cb: C) {
    e.on(ev, cb);
    return () => { e.off(ev, cb); }
}

export function useListener<E, C>(e: EventEmitter<E, C> | undefined, ev: E, cb: C) {
    useEffect(() => {
        if (e) return registerListener(e, ev, cb);
    }, [e, ev, cb]);
}

////////////////////////////////////////////////////////////////////////////////

export function joinSx(a: SystemStyleObject<Theme>, b?: SxProps<Theme>): SxProps<Theme> {
    if (b === undefined)
        return a;
    if (Array.isArray(b)) {
        return [a, ...b];
    }
    return [a, b as SystemStyleObject<Theme>];
}

////////////////////////////////////////////////////////////////////////////////

export function useState2<T>(initialState: T | ((oldState?: T) => T), deps?: React.DependencyList): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [state, setState] = useState<{
        t: T,
        deps?: React.DependencyList,
        setState: React.Dispatch<React.SetStateAction<T>>,
        noCheck: boolean,
    }>(() => ({
        t: typeof initialState === "function" ? (initialState as () => T)() : initialState,
        setState: wrappedSetState,
        noCheck: true,
        deps,
    }));

    function wrappedSetState(a: React.SetStateAction<T>) {
        if(typeof a === "function") {
            setState((s) => ({...s, t: (a as (prevState: T) => T)(s.t)}));
        } else {
            setState((s) => ({...s, t: a}));
        }
    }

    if(!state.noCheck) {
        if (deps?.length !== state.deps?.length) {
            throw new Error("useState2: deps length changed");
        }
        let depsChanged = deps === undefined;
        if(deps !== undefined) {
            for (let i = 0; i < deps.length; i++) {
                if(!Object.is(deps[i], state.deps![i])) {
                    depsChanged = true;
                    break;
                }
            }
        }
        if (depsChanged) {
            // We don't call setState, as we don't want to trigger a re-render.
            // Instead, we just update the state object directly.
            state.t = typeof initialState === "function" ? (initialState as (t: T) => T)(state.t) : initialState;
            state.deps = deps;
        }
    }
    state.noCheck = false;

    return [state.t, state.setState];
}

////////////////////////////////////////////////////////////////////////////////


const whitespaceRegExp = /[\s\-_\.!?\\/+~*'"`#]+/g;

export function weakId(t: string) {
    t = t.toLowerCase();
    t = t.replaceAll(whitespaceRegExp, "-");
    return t;
}

export const slugify = weakId;
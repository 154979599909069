import Grid from "@mui/material/Grid";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box } from "@mui/system";
import { Button, Stack, Typography, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalLibraryOutlined } from "@mui/icons-material";
import Page, { PageContent } from "../Page";
import { lineClamp } from "../tools";
import { CardResource, CardCourse, CardPrice, CardSolutions } from "./BusinessboxComponents/Cards";


import icon1 from "../../assets/images/dummy/wazidev.png";
import icon2 from "../../assets/images/dummy/soil-moisture-sensor.png";
import icon3 from "../../assets/images/dummy/promini.jpg";
import icon4 from "../../assets/images/dummy/image.webp";
import agriboxCover from "../../assets/images/dummy/agribox.png";


export default function BusinessboxPage() {

    const navigate = useNavigate();
    const content = (
        <Box
            sx={{
                pl: [3, 6, null, null, 8],
                pr: [3, 4, null, null, 12],
                pt: [0, 4],
                pb: "440px",
                minHeight: "calc(100vh - 104px)",
                // height: 'calc(100vh - 56px)',
                backgroundImage: `url(${agriboxCover})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: 'bottom',
                backgroundPositionX: 'center',
                // overflowY: 'auto',
                boxSizing: "border-box",
            }}
        >
            <Grid container spacing={6}>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="h3">Agribox</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        align="justify"
                                        sx={{ ...lineClamp(6) }}
                                    >
                                        Low power development boards with dedicated api integration for precision farming, livestock monitoring, smart greenhouses, climate monitoring, remote sensing for agricultural applications. Likely to be fitted with low power communication technologies such as LoRa, NB-IoT etc to facilitate multiple sensor nodes in remote sensing situations with power constraints.
                                        In addition to the base content, the AgriBox will have sensors specific to agriculture. In particular, the Water pH sensor, Soil pH and soil humidity will be provided.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item >
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle1" color={"text.secondary"}>
                                            Need more options?
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRightIcon />
                                            <Typography variant="subtitle1">Contact your DIH</Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRightIcon />
                                            <Typography variant="subtitle1">
                                                List of contents
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRightIcon />
                                            <Typography variant="subtitle1">
                                                Shipping prices
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                        <Typography variant="h6" mr={3}>120 Pieces in this box</Typography>
                                        <Typography variant="caption" sx={{ color: "#186DBF", cursor: "pointer" }}>see all (120)</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xl={6}>
                                            <CardResource icon={icon1} quantity={10} title={"Wazidev v1.2"} subtitle={"by waziup"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={icon2} quantity={5} title={"Soil Moisture Sensor"} subtitle={"by waziup"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={icon3} quantity={5} title={"Arduino Promini"} subtitle={"by arduino"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={icon4} quantity={5} title={"Waterpump"} subtitle={"by waziup"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                        <Typography variant="h6" mr={3}>16 courses in this box</Typography>
                                        <Typography variant="caption" sx={{ color: "#186DBF", cursor: "pointer" }}>see all (16)</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item sm={6} xl={5}>
                                            <CardCourse title={"Basic of Electronics"} subtitle={"by waziup"} desc={"Current, voltage, wires… IoT is, at its base, just electronics. In this course, we will learn the basics that you need to know to start building IoT prototypes."} />
                                        </Grid>
                                        <Grid item sm={6} xl={5}>
                                            <CardCourse title={"Overview of Boards and Peripherals"} subtitle={"by waziup"} desc={"An overview of the available boards and peripherals in the IoT world"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={2} display="flex" flexDirection="column">
                        <Grid item>
                            <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                <Typography variant="h6" mr={3}>18 Solutions in this box</Typography>
                                <Typography variant="caption" sx={{ color: "#186DBF", cursor: "pointer" }}>see all (18)</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item md={3}>
                                    <CardSolutions title={"Autonomous Irrigation"} desc={"Using AI to optimize plant irrigation procedures."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions sx={{mt: [undefined, 3]}} title={"Cattle Theft"} desc={"Monitor stock positions and record data."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions title={"Weather Station"} desc={"Measure rain, wind, temperature and display the values in the cloud."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions sx={{mt: [undefined, 3]}} title={"Fish Farm"} desc={"Collect water data, including temperture and other data."} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );

    const action = (
        <Button
            size="large"
            startIcon={<LocalLibraryOutlined />}
            sx={{
                mr: 2,
                bgcolor: "grey.200",
                ":hover": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                },
            }}
            onClick={() => navigate("/resources")}
        >
            Resource Catalog
        </Button>
    );

    return (
        <Page
            title="Businessbox"
            actions={action}
            onNavigateBack={() => navigate(-1)}
        >
            {/* <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} {...containerProps} className="LabPageContainer" ref={ref}>
                <Box sx={joinSx({ pl: [3, 6, null, null, 8], pr: [3, 4, null, null, 12], pt: [0, 4], pb: 8, display: "flex", flexDirection: "column", maxWidth: 1200, flexGrow: 1 }, sx)} className="LabPageContent" {...boxProps}>
                    {props.children}
                </Box>
            </Box> */}

            {content}
        </Page>
    );
}


const lorem = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation
ullamco laboris nisi ut aliquip ex ea commodo consequat.
`

import { Avatar, AvatarProps } from "@mui/material";
import React from "react";
import { initials, stringToColor } from "../../tools";
import { joinSx } from "../tools";
import { useUser } from "../../ident";

export type MyAccountAvatarProps = AvatarProps;

export const MyAccountAvatar: React.ComponentType<MyAccountAvatarProps> = React.forwardRef(function MyAccountAvatar(props: MyAccountAvatarProps, ref: React.Ref<any>) {
    const { sx } = props;
    const user = useUser();

    let avatar: JSX.Element;

    if (user) {
        const name = user.username || user.email;
        if (user.picture) {
            avatar = <Avatar {...props} ref={ref} alt={name} src={user.picture} />;
        } else if (name) {
            avatar = <Avatar {...props} sx={joinSx({ bgcolor: stringToColor(name) }, sx)} ref={ref} alt={name} children={initials(name)} />;
        } else {
            avatar = <Avatar {...props} ref={ref} />
        }
        avatar = <AccountAvatar name={user.username || user.email || "?"} picture={user.picture} {...props} ref={ref} />;
    } else {

        avatar = <Avatar {...props} ref={ref} />
    }

    return avatar;
});

export interface AccountAvatarProps extends AvatarProps {
    name: string,
    picture?: string,
}

export const AccountAvatar: React.ComponentType<AccountAvatarProps> = React.forwardRef(function AccountAvatar(props: AccountAvatarProps, ref: React.Ref<any>) {
    const { name, picture, ...avatarProps } = props;
    const {sx } = avatarProps;
    let avatar: JSX.Element;

    if (picture) {
        avatar = <Avatar {...avatarProps} ref={ref} alt={name} src={picture} />;
    } else if (name) {
        avatar = <Avatar {...avatarProps} sx={joinSx({ bgcolor: stringToColor(name) }, sx)} ref={ref} alt={name} children={initials(name)} />;
    } else {
        avatar = <Avatar {...avatarProps} ref={ref} />
    }

    return avatar;
});
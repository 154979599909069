import { Box, Typography, Card, CardContent, CardActions, Button, styled, Chip, TextField, InputAdornment, IconButton, SxProps, Divider, useMediaQuery, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { Resource, patchResource as apiPatchResource } from "../../api";
import { renderHTML } from "../tools";
import { useSession } from "../../ident";
import React from "react";
import resourceCover from "../../assets/images/resource-cover.png";
import { toast } from "@halliday/mui-toast";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";

export interface ResourceOverviewProps {
    resource: Resource;
}

const ResourceOverview = ({ resource }: ResourceOverviewProps) => {

    const sess = useSession();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [count, setCount] = useState(resource?.count ?? 0);
    const [focused, setFocused] = useState(false);

    const [state] = useState({
        autoFocusInput: false,
    });

    const Img = styled("img")({});
    const backgroundColor = resource?.color === "#000000" ? "#f1f1f1" : resource?.color;

    function addToMyLab() {
        if (sess === null) {
            window.openLogin();
            return
        }
        state.autoFocusInput = true;
        setCount(1);
    }

    function increaseCount() {
        setCount(count + 1);
        inputRef.current!.focus();
    }

    function decreaseCount() {
        setCount(count - 1);
        inputRef.current!.focus();
    }

    function handleAdornmentMouseDown(ev: React.MouseEvent) {
        ev.preventDefault();
    }

    async function patchResource(count: number) {
        setCount(count);

        await apiPatchResource({ id: resource?.id || "", count });

        if (!resource?.count && count) {
            window.dataLayer.push({
                "event": "addToMyLab",
                "resourceId": resource?.id,
                "resourceTitle": resource?.title,
                "count": count
            });
        }
    }

    async function handleKeyDown(ev: React.KeyboardEvent) {
        if (ev.key === "Enter") {
            await submitCount();
        }
    }

    async function submitCount() {
        const oldCount = resource?.count || 0;
        await patchResource(count);

        async function undoChange() {
            await patchResource(oldCount);
            toast(null);
        }

        let msg: string | undefined;
        if (oldCount === 0 && count > 0) {
            msg = resource?.title + " added to your lab.";
        } else if (oldCount > 0 && count === 0) {
            msg = resource?.title + " removed from your lab.";
        } else if (oldCount !== count) {
            msg = resource?.title + " count updated.";
        }

        if (msg) {
            toast({
                message: msg,
                SnackbarProps: { ClickAwayListenerProps: { mouseEvent: false } },
                action: <Button onClick={undoChange} color="secondary">Undo</Button>
            });
        }
    }

    async function handleInputBlur() {
        state.autoFocusInput = false;
        setFocused(false);
        await submitCount();
    }

    function handleInputFocus() {
        setFocused(true);
    }

    const inputRef = useRef<HTMLInputElement>(null);

    const noSpinButton: SxProps = {
        "&::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
        },
        "&::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
        },
        MozAppearance: "textfield",
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: ["column",], alignItems: 'flex-start', justifyContent: 'space-between', gap: [1, 3] }}>
            <Box display='flex' flexDirection={["column", "row"]} justifyContent='start' gap={[2]}>
                {
                    !isMobile ?
                        <Box sx={{ maxWidth: 300, position: "relative", overflow: "hidden", backgroundColor, backgroundImage: `url(${resourceCover})`, backgroundSize: "" }}>
                            <Img src={resource?.coverUrl} loading="lazy" width="100%" alt="" sx={{ filter: "drop-shadow(1px 1px 4px #0007)", "&:hover": { transform: "scale(1.05)" }, transition: (theme) => theme.transitions.create("transform", { duration: theme.transitions.duration.short }) }} />
                        </Box>
                        : null
                }

                <Box display='flex' flexDirection='column' gap={1}>
                    <Typography variant="h1">{resource?.title}</Typography>
                    <Typography variant="body1">{resource?.desc}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: ['row'], alignItems: 'flex-start', gap: [1,] }}>
                        {resource?.tags?.map((tag, index) => (
                            <Chip key={index} label={tag} />
                        ))}
                    </Box>
                    <Box pt={2}>
                        {(count > 0 || focused) ? <>
                            <TextField
                                type="number"
                                value={isNaN(count) ? "" : count}
                                autoFocus={state.autoFocusInput}
                                onChange={(e) => setCount(parseInt(e.target.value))}
                                onBlur={handleInputBlur}
                                onFocus={handleInputFocus}
                                onKeyDown={handleKeyDown}
                                label="Lab count"
                                size="small"
                                sx={{ width: ["100%", "auto"], mr: 1 }}
                                InputProps={{
                                    sx: { px: "4px" },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="decrease item count"
                                                size="small"
                                                onClick={decreaseCount}
                                                onMouseDown={handleAdornmentMouseDown}
                                                edge="start"
                                            >
                                                <RemoveOutlined />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="increase item count"
                                                size="small"
                                                onClick={increaseCount}
                                                onMouseDown={handleAdornmentMouseDown}
                                                edge="end"
                                            >
                                                <AddOutlined />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    inputProps: { sx: { ...noSpinButton, textAlign: "center", py: "6px", fontSize: "0.875rem", lineHeight: "1.75", height: "auto" }, ref: inputRef }
                                }}
                            />
                        </> : (
                            <Button
                                sx={{ textTransform: 'none', width: ["100%", "auto"] }}
                                variant="contained" disableRipple
                                startIcon={<AddOutlined />}
                                onClick={addToMyLab}
                            >
                                My Lab
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
            <Divider variant="middle" flexItem />
            <Box sx={{ order: [2, 1] }}>
                {renderHTML(resource?.content || "")}
            </Box>
        </Box>
    );
}

export default ResourceOverview;
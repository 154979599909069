import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OrganizationsList from "./OrganizationsList";
import Page, { PageContent } from "../Page";
import { NewOrganizationResponse, postOrganization } from "../../api";
import { useSession } from "../../ident";

function OrganizationsPage() {

    const navigate = useNavigate();
    const sess = useSession();

    const handleNewOrganization = async () => {
        const title = prompt("How should the organization be called?", sess!.user.username! + " Group");
        if (!title) return;
        let resp: NewOrganizationResponse;
        try {
            resp = await postOrganization({ title });
        } catch (e) {
            alert("Failed to create organization: " + e);
            return;
        }
        navigate("/orgs/" + resp.slug);
    }

    return <Page
        title="Organizations"
        actions={sess && (sess?.user.emailVerified ? <Button variant="contained" color="secondary" onClick={handleNewOrganization} sx={{ mr: 4 }}>Create Organization</Button>
            : <Tooltip title="To access, verify your email" arrow open>
                <Button variant="contained" color="secondary" onClick={handleNewOrganization} sx={{ mr: 4 }} disabled>Create Organization</Button>
            </Tooltip>)}
    >
        <PageContent sx={{ pt: 2 }}>
            <OrganizationsList />
        </PageContent>
    </Page>
}

export default OrganizationsPage;

import { L } from "@halliday/react-i18n";
import { DevicesOther, ExpandMoreOutlined, Extension, GroupOutlined, HelpCenter, MoreHoriz, OpenInNewOutlined, Settings, } from "@mui/icons-material";
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import { Avatar, Badge, createTheme, ListItemAvatar, ListItemButton, styled, ThemeProvider, Typography, TypographyProps, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { fontSize } from "@mui/system";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import hubiquitous from "../assets/images/hubiquitous.png";
// import Logo from "../assets/images/logoV1.svg";
import Logo from "../assets/svg/wazilab-logo-v1.svg";
import HomeIcon from '@mui/icons-material/Home';
import WazilabImage from "../assets/images/wazilab-dense.png";
import Waziup from '../components/icons/Waziup'
import flagOfEurope from "../assets/images/flag_of_europe.svg";
import hubiquitousIcon from "../assets/images/hubiquitous_icon.png";
import hubiquitousLogo from "../assets/images/hubiquitous_logo.png";
import { joinSx } from "./tools";
import { Organization, getOrganizations } from "../api";
import { useSession } from "../ident";
import { useHub } from "../Hub";
import WaziupShortLogo from "./icons/WaziupShortLogo";

export const drawerWidth = 250;
export const denseDrawerWidth = 64;

type DrawerPage = "home" | "my-solutions" | "my-courses" | "my-lab" | "my-programs" | string | undefined;

interface DrawerProps {
    page?: DrawerPage,
    open: boolean,
    dense?: boolean,
    onToggle: () => void,
}

const BannerDesktop = styled("img")({
    height: "56px",
    display: "block"
});

const BannerDense = styled("img")({
    height: "36px",
    // position: "fixed",
    display: "block",
    // left: 6,
    // top: 8,
});

const HubiquitousBanner = styled("img")({
    height: "48px",
    display: "block",
    // position: "fixed",
    // left: 16,
});
const HubiquitousBannerDense = styled("img")({
    height: "36px",
    display: "block",
    position: "fixed",
    left: 16,
});

const GroupIcon = styled("img")({
    objectFit: "contain",
    width: "40px",
    height: "40px",
})

const drawerTheme = createTheme({
    palette: {
        text: {
            primary: "#fff",
            secondary: "#fffc"
        },
        background: {
            paper: "#394E69"
        },
        action: {
            active: "#fff"
        },
        divider: "#fff5"
    },
});

const longHoverTime = 1000; // 1 s
const fadeInDuration = 250; // 0.25 s
const fadeOutDuration = 250; // 0.25 s

const Img = styled("img")({});

function generateButtonStyle(pathname: string, activePath: string) {
    return {
        pl: 2.5,
        backgroundColor: pathname === activePath ? "#fff" : "initial",
        color: pathname === activePath ? "#394E69" : "#fff",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#394E69"
        },
    };
}

function generateIconStyle(pathname: string, activePath: string) {
    return {
        color: pathname === activePath ? "#394E69" : "#fff",
    };
}


function Drawer(props: DrawerProps) {
    let navigate = useNavigate();
    const location = useLocation();
    const activePath = location.pathname;
    let { dense = false } = props;

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const [longHover, setLongHover] = useState(false);

    if (longHover) dense = false; // open drawer on long hover
    if (!sm) dense = false; // always open on mobile

    const [state] = useState<{
        fadeInTimeout?: ReturnType<typeof setTimeout>,
        fadeOutTimeout?: ReturnType<typeof setTimeout>,
        mouseOverTimeout?: ReturnType<typeof setTimeout>,
        mouseOutTimeout?: ReturnType<typeof setTimeout>,
        dense: boolean,
    }>({ dense });

    useLayoutEffect(() => {
        if (dense === state.dense) return;
        if (dense) animateClose();
        else animateOpen();
        state.dense = dense;
    }, [dense])

    function animateOpen() {
        const anim = ref.current!.animate([
            { width: denseDrawerWidth + "px" },
            { width: drawerWidth + "px" }
        ], {
            easing: "ease-in-out",
            duration: fadeInDuration,
        });
        anim.play();
    }

    function animateClose() {
        const anim = ref.current!.animate([
            { width: drawerWidth + "px" },
            { width: denseDrawerWidth + "px" }
        ], {
            easing: "ease-in-out",
            duration: fadeOutDuration,
        });
        anim.play();
    }

    const openDrawer = () => {
        setLongHover(true);
    }

    const handleMouseOver = (ev: React.MouseEvent) => {
        clearTimeout(state.fadeOutTimeout!);
        state.fadeInTimeout = setTimeout(() => { setLongHover(true); }, longHoverTime)
    }


    const handleMouseOut = (ev: React.MouseEvent) => {
        clearTimeout(state.fadeInTimeout!);
        state.fadeOutTimeout = setTimeout(() => { setLongHover(false); }, longHoverTime / 4);
    }

    const handleBannerClick = (ev: React.MouseEvent) => {
        const banner = ev.target as HTMLImageElement;
        const bannerRect = banner.getBoundingClientRect();
        const iDotX = 460 / 802 * bannerRect.width + bannerRect.left;
        const iDotY = 10 / 202 * bannerRect.height + bannerRect.top;
        const d = 20 / 802 * bannerRect.width;
        if (Math.pow(ev.clientX - iDotX, 2) + Math.pow(ev.clientY - iDotY, 2) < d * d) {
            navigate("/test")
        }
    }

    function showCookieDialog() {
        Cookiebot.show();
    }

    const generateStyle = (path: string) => ({
        pl: 2.5,
        backgroundColor: path === activePath ? "#fff" : "initial",
        color: path === activePath ? "#394E69" : "#fff",
        "&:hover": {
            backgroundColor: path === activePath ? "#fff" : "initial",
            color: path === activePath ? "#394E69" : "#fff",
        },
    });

    //

    const sess = useSession();

    const [orgs, setOrgs] = useState<Organization[] | null>(null);

    useEffect(() => {
        setOrgs(null);
        getOrganizations({ member: true }).then(resp => { setOrgs(resp.orgs); });
    }, [sess]);

    useHub(".organization-update", (org: Organization) => {
        if (!orgs) return;
        const o = orgs.find(o => o.id === org.id);
        if (!o) return;
        o.slug = org.slug;
        o.title = org.title;
        o.desc = org.desc;
        o.cover = org.cover;
        o.coverUrl = org.coverUrl;
        o.public = org.public;
        setOrgs([...orgs]);
    }, [orgs]);
    // hub.emit(".organization-update", org);


    //

    const content = <>
        <Box sx={{ height: "64px" }}>
            {dense ? (
                <BannerDense src={WazilabImage} alt="Waziup" sx={{ mt: "12px", ml: "6px" }} />
            ) : (
                <BannerDesktop src={Logo} alt="Waziup" onClick={handleBannerClick} sx={{ mt: "4px", ml: "40px" }} />
            )}
        </Box>

        <Divider sx={{ borderColor: "#5A6B81", mb: 2 }} />

        {/* <List>
            <ListItem
                // button
                // onClick={() => navigate("/")}
                sx={{ pl: 2.5, opacity: .6 }}
            >
                <ListItemIcon><Widgets /></ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
        </List> */}

        <List
            // subheader={
            //     <ListSubheader
            //         component="div"
            //         style={{ opacity: dense ? 0 : undefined }}
            //         sx={{ backgroundColor: "unset" }}
            //     >
            //         Solution Lab
            //     </ListSubheader>
            // }
            sx={{
                mb: 4,
                '&& .Mui-selected, && .Mui-selected:hover': {
                    bgcolor: 'white',
                    '&, & .MuiListItemIcon-root': {
                        color: '#394E69',
                    },
                },
                '& .MuiListItemButton-root:hover': {
                    bgcolor: 'white',
                    '&, & .MuiListItemIcon-root': {
                        color: '#394E69',
                    },
                },
            }}
        >
            {[
                { path: "/", text: "Home", icon: <HomeIcon /> },
                { path: "/my-programs", text: "Programs", icon: <InsightsIcon /> },
                { path: "/solutions", text: "Solutions", icon: <Extension /> },
                { path: "/my-courses", text: "Courses", icon: <SchoolIcon /> },
                { path: "/my-lab", text: "My Lab", icon: <DevicesOther /> },
            ].map((item) => (
                <ListItemButton
                    key={item.path}
                    onClick={() => navigate(item.path)}
                    sx={generateStyle(item.path)}
                >
                    <ListItemIcon sx={{ color: item.path === activePath ? "#394E69" : "#fff" }}>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                </ListItemButton>
            ))}
        </List>

        {/* {sess && <>
            <Divider />
            <List
                subheader={
                    <ListSubheader
                        component="div"
                        style={{ opacity: dense ? 0 : undefined }}
                        sx={{ backgroundColor: "unset", cursor: "pointer", "&:hover": { color: "#fff" } }}
                        onClick={() => navigate("/orgs/")}>
                        Organizations
                    </ListSubheader>
                }
            >
                {orgs ? (
                    orgs.length > 0 ? (
                        orgs.map((org) => (
                            <ListItemButton
                                key={org.id}
                                onClick={() => navigate("/orgs/" + org.slug)}
                                sx={{ pl: 0 }}
                            >
                                {org.cover ? (
                                    <Box component="img" src={org.cover?.url} sx={{ width: 36, height: 36, mr: "24px", ml: "12px" }} />
                                ) : (
                                    <ListItemIcon sx={{ color: "#fff", pl: 2.5 }}>
                                        <GroupOutlined />
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    primary={org.title}
                                    primaryTypographyProps={{ sx: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}
                                    secondary={org.desc}
                                    secondaryTypographyProps={{ sx: { overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "2", lineClamp: "2", WebkitBoxOrient: "vertical" } }} />
                            </ListItemButton>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText secondary="No organizations joined." secondaryTypographyProps={{ sx: { fontStyle: "italic" } }} />
                        </ListItem>
                    )
                ) : (
                    <ListItem>
                        <ListItemText primary="…" />
                    </ListItem>
                )}
            </List>
        </>} */}
        <Box sx={{ flexGrow: 1 }} onClick={openDrawer} />

        <List>
            <ListItem
                button
                onClick={() => navigate("/orgs/")}
                sx={{ pl: 2.5, opacity: .6 }}
            >
                <ListItemIcon><BusinessOutlinedIcon /></ListItemIcon>
                <ListItemText primary="Organizations" />
            </ListItem>
            {sess?.user.roles.includes("admin") && <>
                <ListItem
                    button
                    onClick={() => navigate("/settings")}
                    sx={{ pl: 2.5, opacity: .6 }}
                >
                    <ListItemIcon><Settings /></ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItem>
            </>}
            <ListItem
                button
                onClick={() => navigate("/resources?category=waziup")}
                sx={{ pl: 2.5, opacity: .6 }}
            >
                <ListItemIcon><Waziup /></ListItemIcon>
                <ListItemText primary="Waziup Resources" />
            </ListItem>
        </List>

        <Divider sx={{ borderColor: "#5A6B81" }} />

        <List>
            <ListItemButton
                onClick={() => navigate("/funding-acknowledgement")}
                sx={{ pl: "14px" }}
            >
                <ListItemIcon>
                    <Img src={flagOfEurope} sx={{ display: "block", width: 36, height: 24 }} alt="Flag of Europe" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: .6 }} secondary={<>Funded by<br />the European Union</>} />
                {/* <ExpandMoreOutlined /> */}
            </ListItemButton>
            <ListItemButton
                LinkComponent="a"
                href="https://hubiquitous.eu/"
            >
                <ListItemIcon>
                    <Img src={hubiquitousIcon} alt="Hubiquitous Icon" />
                </ListItemIcon>
                <Box sx={{ flexGrow: 1, my: .5, verticalAlign: "middle" }}><Img src={hubiquitousLogo} alt="Hubiquitous Logo" /></Box>
                {/* {dense ? <HubiquitousBannerDense src={hubiquitous} alt="hubiquious" /> : <HubiquitousBanner src={hubiquitousLogo} alt="hubiquious" onClick={handleBannerClick} />} */}
                {!dense && <OpenInNewOutlined fontSize="small" sx={{ color: "text.secondary" }} />}
            </ListItemButton>
        </List>
        <ListItem
            sx={{ pl: 2.5, pb: 1.5, pt: 0.5, opacity: .6 }}
        >
            <Typography variant="body2" sx={{ visibility: dense ? "hidden" : "auto" }}>
                <TextLink href="/terms-of-service">{L("terms_of_service")}</TextLink> • <TextLink href="/privacy">{L("privacy_policy")}</TextLink> • {/*<TextLink onClick={showCookieDialog}>{L("cookie_consent")}</TextLink> • */}<TextLink href="/imprint">{L("imprint")}</TextLink>
            </Typography>
            <MoreHoriz onClick={openDrawer} sx={{ position: "absolute", bottom: 20, left: 18, visibility: dense ? "auto" : "hidden" }} />
        </ListItem>
    </>;

    const ref = useRef<HTMLDivElement>(null);

    return <ThemeProvider theme={drawerTheme}>
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, backgroundColor: "#394E69", color: "#fff" }}
        >
            <MuiDrawer
                className="LabDrawer"
                id="drawer-temporary"
                variant="temporary"
                open={props.open}
                onClose={props.onToggle}
                ModalProps={{ keepMounted: true, }} // Better open performance on mobile.
                sx={{ display: { xs: 'block', sm: 'none' }, width: drawerWidth }}
                PaperProps={{ sx: { width: drawerWidth, } }}
            >
                {content}
            </MuiDrawer>
            <MuiDrawer
                className="LabDrawer"
                id="drawer-permanent"
                variant="permanent"
                sx={{ display: { xs: 'none', sm: 'block' }, width: drawerWidth }}
                PaperProps={{ ref: ref, sx: { width: drawerWidth, overflowX: "hidden" }, style: { width: dense ? denseDrawerWidth + "px" : undefined } }}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
                open
            >
                <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", width: drawerWidth }}>
                    {content}
                </Box>
            </MuiDrawer>
        </Box>
    </ThemeProvider>;
}

function TextLink(props: TypographyProps<"a">) {
    const { sx, ...typographyProps } = props;

    const navigate = useNavigate();

    function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        if (props.href) {
            event.preventDefault();
            navigate(props.href);
        }
    }

    return <Typography variant="body2" component="a" onClick={handleClick} sx={joinSx({ cursor: "pointer", color: "inherit", textDecoration: "none", "&:hover": { textDecoration: "underline" } }, sx)} {...typographyProps} />;
}

export default Drawer;

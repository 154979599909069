import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Page, { PageContent } from "../Page";
import { ProgramsList } from "../programs/ProgramsPage";
import SolutionBoxImage from "../../components/boxes/solutionbox-perspective.png"
import { SolutionBoxcatalogCard } from "../boxes/SolutionBoxCard";
import CoverWaziupSolutionBox from '../boxes/waziup-solutionbox.png'
import CoverTrainingKitBox from '../boxes/kit-box.png'
import CoverApplicationBusinessBox from '../boxes/app-business-box.png'



export default function Programs() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Page title="Program Catalog">
            <PageContent sx={{ pb: 'inherit', maxWidth: 'unset' }}>
                <Box display={'flex'} flexDirection={['column', 'row']} py={[5]} px={[5, 'undefined', 15]} mx={[-3, -8]} bgcolor='#F8F8F8'>
                    <Box width={['100%', '50%']} px={[0, 'undefined', 'undefined', 5, 10]} pl={[0, 5, 'undefined', 'undefined', 15]} display='flex' flexDirection='column' gap={[4, 8]} justifyContent='center' alignItems='flex-start'>
                        <Stack flexDirection='column' width={['100%']}>
                            <Typography variant='body1' fontSize={['1.5em', 'undefined', '2.8em']} color={'primary.main'} fontWeight={600} >Waziup Solution Box</Typography>
                            <Typography variant='body1' fontSize={['1em', 'undefined', '1.5em']} color={'primary.main'} fontWeight={600}>Rapid prototyping of IoT & AI solution</Typography>
                        </Stack>

                        <Stack flexDirection='column' width={['100%']}>
                            <Typography variant='body1' fontSize={['1em', 'undefined', '1.2em']} my={1}>30+ items with 16+ courses</Typography>
                            <Divider />
                            <Typography variant='body1' fontSize={['1em', 'undefined', '1.2em']} my={1}>Empowers IoT solutions</Typography>
                            <Divider />
                            <Typography variant='body1' fontSize={['1em', 'undefined', '1.2em']} my={1}>Selected and tested</Typography>
                            <Divider />
                        </Stack>
                    </Box>
                    <Box width={['100%', '50%']} px={['undefined', 'undefined', 'undefined', 5]} pt={[5, 0]} display='flex' justifyContent='center' alignItems='center'> <img src={SolutionBoxImage} width={'80%'} height='fit-object' /> </Box>
                </Box>

                <Box display='flex' flexDirection={['column', 'row']} justifyContent='center' gap={5} my={5}>
                    <SolutionBoxcatalogCard cover={CoverWaziupSolutionBox} name="MVP DIY Kit" itemCount={29} url="/businessbox/mvp-diy-kit" />
                    <SolutionBoxcatalogCard cover={CoverTrainingKitBox} name="Training Kit Box" itemCount={29} url="/businessbox/training-kit" />
                    <SolutionBoxcatalogCard cover={CoverApplicationBusinessBox} name="Application Business Box" itemCount={29} url="/businessbox/application-business-box" textColor="#ffffff" />
                </Box>
            </PageContent>
        </Page>
    );
}
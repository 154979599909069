import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page, { PageContent } from "../Page";

function NotFoundPage() {
    const navigate = useNavigate();

    return <Page
        title="Page not found"
    // onNavigateBack={() => navigate("/")}
    >
        <PageContent>
            <Typography>Page not found.</Typography>
        </PageContent>
    </Page>
}

export default NotFoundPage;
import { useMemo, useState } from "react";
import { ListUsersResponse, User } from "../../api";
import { DlgAddSingleUser } from "./DlgAddSingleUser";
import Page, { PageContent } from "../Page";
import UsersTable from "./UsersTable";
import Selection from "../../Selection";
import { useSession } from "../../ident";
import { UsersLoader, useUsers } from "../../loaders";
import { SearchOutlined, CloseOutlined } from "@mui/icons-material";
import { Box, DialogTitle, Typography, Tooltip, IconButton } from "@mui/material";
import { SearchTextField } from "../SearchTextField";


const defaultPageSize = 25;

export default function UsersPage() {

    const [dlgAddSingleUserOpen, setDlgAddSingleUserOpen] = useState(false);
    function openDlgAddSingleUser() {
        setDlgAddSingleUserOpen(true);
    }
    function closeDlgAddSingleUser() {
        setDlgAddSingleUserOpen(false);
    }
    function handleUserAdd(user: User) {
        closeDlgAddSingleUser();
    }

    // const sel = useMemo(() => new Selection<User>(), [search]);

    // const { users, page, numUsersTotal, loading, err, tablePaginationProps } = loader;

    // let content: React.ReactNode;

    // if (err) content = <ErrorView err={err} />;
    // else if (loading) content = <CircularProgress />;
    // else {
    //     content = <>

    //     </>;
    // }

    // const sess = useSession();
    const [pageSize, setPageSize] = useState(defaultPageSize);
    // const loader = useMemo(() => new UsersLoader(search, pageSize), [search, pageSize, sess]);
    function handlePageSizeChange(ev: {}, pageSize: number) {
        setPageSize(pageSize);
    }

    const [search, setSearch] = useState("");

    function handleSearchChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setSearch(ev.target.value);
    }

    const loader = useUsers(search, pageSize);
    const selection = useMemo(() => new Selection<User>(), [loader]);

    const [searchOpen, setSearchOpen] = useState(false);
    function closeSearch() { setSearch(""); setSearchOpen(false); }
    function openSearch() { setSearchOpen(true); }

    const [page, setPage] = useState<ListUsersResponse | null>(null);
    function handlePageChange(ev: {}, n: number, p: Promise<ListUsersResponse>) {
        p.then(setPage);
    }

    const numTotal = page?.numUsersFound ?? 0;
    const numSelected = selection.isAll ? numTotal - selection.size : selection.size;

    return (
        <Page title="Users">
            <PageContent>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, pr: 1.5 }}>
                    <DialogTitle sx={{ pb: .5, pr: .5, pl: 0, pt: 0, flexGrow: 1 }}>Users</DialogTitle>
                    {searchOpen ?
                        <SearchTextField onClose={closeSearch} placeholder="Name, Email, …" value={search} onChange={handleSearchChange} /> :
                        <Tooltip title="Search"><IconButton onClick={openSearch}><SearchOutlined /></IconButton></Tooltip>}
                </Box>
                <UsersTable
                    loader={loader}
                    selection={selection}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePageChange}
                    tableBodyProps={{ sx: { overflowY: "scroll", maxHeight: "calc(100vh - 352px)" } }}
                    tableHeadProps={{ sx: { ">tr": { pr: "var(--scrollbar-width)" } } }} />
                <DlgAddSingleUser open={dlgAddSingleUserOpen} onClose={closeDlgAddSingleUser} onUserAdd={handleUserAdd} />

            </PageContent>
        </Page>
    );
}

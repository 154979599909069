import { MsgBox, reportError } from "@halliday/mui-msgbox";
import { toast } from "@halliday/mui-toast";
import { BlockOutlined, Check, CircleOutlined, DeleteOutlined, Edit, EditOutlined, MoreVertOutlined, PasswordOutlined } from "@mui/icons-material";
import { AvatarProps, Box, Button, Checkbox, CheckboxProps, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, SxProps, TableBodyProps, TableCell, TableContainerProps, TableHeadProps, TablePagination, TableRow, TextField, Theme, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import React, { forwardRef, useEffect, useState } from "react";
import Selection, { useIsSelected, useSelectionCount } from "../../Selection";
import { ListUsersResponse, User, deleteUsers, updateUsers } from "../../api";
import { useSession } from "../../ident";
import { UsersLoader, useLoader } from "../../loaders";
import { preventDefault, useForceUpdate } from "../../tools";
import DataGrid, { DataGridPlaceholder } from "../DataGrid";
import DialogCloseButton from "../DialogCloseButton";
import PasswordField from "./PasswordField";
import TimeAgo from "../TimeAgo";
import { AccountAvatar } from "../ident/AccountAvatar";
import { EditUserDialog, EditUserDialogProps } from "../ident/users";

export interface UsersTableProps extends TableContainerProps {
    // search?: string,
    loader: UsersLoader,
    pageSize: number,
    onPageSizeChange: (ev: {}, pageSize: number) => void,
    selection?: Selection<User>,
    onPageChange?: (ev: {}, n: number, page: Promise<ListUsersResponse>) => void,

    tableBodyRef?: React.RefObject<HTMLTableSectionElement>,
    tableBodyProps?: TableBodyProps,
    tableHeadProps?: TableHeadProps,
    sx?: SxProps<Theme>,
}

export default function UsersTable(props: UsersTableProps) {
    const { loader, selection, pageSize, onPageSizeChange, onPageChange, tableBodyRef, tableBodyProps, tableHeadProps, sx, ...tableContainerProps } = props;

    const [dlgEditUserProps, setDlgEditUserProps] = useState<EditUserDialogProps>({ open: false, user: null as any });
    function handleDlgEditUserClose() {
        setDlgEditUserProps(props => ({ ...props, open: false }));
    }
    function editUser(props: EditUserDialogProps) {
        setDlgEditUserProps({ ...props, open: true });
    }

    const sess = useSession();
    // const [pageSize, setPageSize] = useState(25);
    // const loader = useMemo(() => new UsersLoader(search, pageSize), [search, pageSize, sess]);

    // const adapter = useMemo(() => {
    //     return (n: number) => loader(n).then(page => ({
    //         items: page.users,
    //         numItemsTotal: page.numTotal,
    //     }))
    // }, [loader]);

    // const [n, setN] = useState(0);
    // const [page, setPage] = useState<ListUsersResponse | null>(null);
    // const [loading, setLoading] = useState(false);
    // const [err, setErr] = useState<any>(null);

    // function changePage(n: number) {
    //     setN(n);
    //     setLoading(true);
    //     const p = loader(n);
    //     p.then(page => {
    //         setPage(page);
    //         setErr(null);
    //     }, (err) => {
    //         setErr(err);
    //     }).finally(() => {
    //         setLoading(false);
    //     });
    //     onPageChange?.({}, n, p);
    // }

    // useEffect(() => { changePage(0); }, [loader]);

    const { page, data, loading, err, changePage } = useLoader(loader);

    return <>
        <DataGrid<User>
            // {...dataGridProps} 
            sx={sx}
            selection={selection}
            // pageSize={pageSize}
            loading={loading}
            items={data?.users ?? []}
            numItemsTotal={data?.numUsersTotal ?? 0}
            itemsIndex={page * pageSize}
            // onPageChange={onPageChange}
            // onPageSizeChange={onPageSizeChange}
            itemKey={user => user.id}
            columns={[{
                key: "avatar",
                head: "",
                renderData: (user) => <UserAvatar user={user} />,
                props: { sx: { maxWidth: "72px", minWidth: "72px" } },
                skeleton: <Skeleton variant="circular" width={40} height={40} />
            }, {
                key: "username",
                head: "Username",
                renderData: (user) => {
                    return <Box sx={{ display: "flex", alignItems: "center" }}>
                        {user.username ? (
                            <Tooltip title={user.usernameVerified ? "Username verified" : "Username unverified"}>
                                <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }} onClick={() => { editUser({ user, autoFocusField: "username", open: true }); }}>
                                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit", cursor: "text" }} style={{ fontStyle: user.usernameVerified ? "normal" : "italic" }}>
                                        {user.username}
                                    </Box>
                                    {user.usernameVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
                                </Box>
                            </Tooltip>
                        ) : "-"}
                        {sess?.sub === user.id && <Chip sx={{ ml: 1 }} label="You" />}
                        {user.suspended && <Tooltip title="Suspended"><BlockOutlined fontSize="small" color="error" sx={{ ml: 1, verticalAlign: "middle" }} /></Tooltip>}
                    </Box>
                },
                props: { sx: { flex: "1 10em" } },
                skeleton: <Skeleton variant="text" />
            }, {
                key: "email",
                head: "Email",
                renderData: (user) => (
                    user.email ? (
                        <Tooltip title={user.emailVerified ? "Email verified" : "Email unverified"}>
                            <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }} onClick={() => { editUser({ user, autoFocusField: "email", open: true }); }}>
                                <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit", cursor: "text" }} style={{ fontStyle: user.emailVerified ? "normal" : "italic" }}>
                                    {user.email}
                                </Box>
                                {user.emailVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
                            </Box>
                        </Tooltip>
                    ) : "-"
                ),
                props: { sx: { flex: "2 15em" } },
                skeleton: <Skeleton variant="text" />
            }, {
                key: "createdAt",
                head: "Created At",
                renderData: (user) => <TimeAgo time={user.createdAt} />,
                props: { sx: { flex: "1 10em" } },
                skeleton: <Skeleton variant="text" />
            }, {
                key: "more",
                head: "",
                renderData: (user) => <IconButton id={user.id + "-btn"} className="btn-user-row" sx={{ "@media(hover)": { opacity: 0 } }} onClick={(ev) => { editUser({ user, open: true }); }}><EditOutlined /></IconButton>,
                props: { sx: { maxWidth: "72px", minWidth: "72px" } }
            }]}
            tableBodyProps={tableBodyProps}
            tableHeadProps={tableHeadProps}
            renderPlaceholder={() => (
                <DataGridPlaceholder>
                    <Typography variant="body2" color="text.disabled">No users found</Typography>
                </DataGridPlaceholder>
            )}
        />

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", px: 1.5 }}>
            {loading && <CircularProgress size="1.5rem" sx={{ pr: 2 }} />}
            <TablePagination
                component="div"
                showFirstButton
                onRowsPerPageChange={(ev) => onPageSizeChange(ev, parseInt(ev.target.value))}
                count={data ? data.numUsersTotal : 0}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(ev, page) => { changePage(page); }}
            />
        </Box>


        {dlgEditUserProps.user && <EditUserDialog onClose={handleDlgEditUserClose} {...dlgEditUserProps} />}
    </>

    // return <>
    //     <DataTable<User>
    //         sx={sx}
    //         selection={selection}
    //         loader={loader}
    //         pageSize={pageSize}
    //         onPageChange={onPageChange}
    //         onPageSizeChange={onPageSizeChange}
    //         itemKey={user => user.id}
    //         columns={[{
    //             key: "avatar",
    //             head: "",
    //             renderData: (user) => <UserAvatar user={user} />,
    //             props: { sx: { maxWidth: "72px", minWidth: "72px" } },
    //             skeleton: <Skeleton variant="circular" width={40} height={40} />
    //         }, {
    //             key: "username",
    //             head: "Username",
    //             renderData: (user) => {
    //                 return <Box sx={{ display: "flex", alignItems: "center" }}>
    //                     {user.username ? (
    //                         <Tooltip title={user.usernameVerified ? "Username verified" : "Username unverified"}>
    //                             <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }} onClick={() => { editUser({ user, autoFocusField: "username", open: true }); }}>
    //                                 <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit", cursor: "text" }} style={{ fontStyle: user.usernameVerified ? "normal" : "italic" }}>
    //                                     {user.username}
    //                                 </Box>
    //                                 {user.usernameVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
    //                             </Box>
    //                         </Tooltip>
    //                     ) : "-"}
    //                     {sess?.sub === user.id && <Chip sx={{ ml: 1 }} label="You" />}
    //                     {user.suspended && <Tooltip title="Suspended"><BlockOutlined fontSize="small" color="error" sx={{ ml: 1, verticalAlign: "middle" }} /></Tooltip>}
    //                 </Box>
    //             },
    //             props: { sx: { flex: "1 10em" } },
    //             skeleton: <Skeleton variant="text" />
    //         }, {
    //             key: "email",
    //             head: "Email",
    //             renderData: (user) => (
    //                 user.email ? (
    //                     <Tooltip title={user.emailVerified ? "Email verified" : "Email unverified"}>
    //                         <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }} onClick={() => { editUser({ user, autoFocusField: "email", open: true }); }}>
    //                             <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit", cursor: "text" }} style={{ fontStyle: user.emailVerified ? "normal" : "italic" }}>
    //                                 {user.email}
    //                             </Box>
    //                             {user.emailVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
    //                         </Box>
    //                     </Tooltip>
    //                 ) : "-"
    //             ),
    //             props: { sx: { flex: "2 15em" } },
    //             skeleton: <Skeleton variant="text" />
    //         }, {
    //             key: "createdAt",
    //             head: "Created At",
    //             renderData: (user) => <TimeAgo time={user.createdAt} />,
    //             props: { sx: { flex: "1 10em" } },
    //             skeleton: <Skeleton variant="text" />
    //         }, {
    //             key: "more",
    //             head: "",
    //             renderData: (user) => <IconButton id={user.id + "-btn"} className="btn-user-row" sx={{ "@media(hover)": { opacity: 0 } }} onClick={(ev) => { editUser({ user, open: true }); }}><EditOutlined /></IconButton>,
    //             props: { sx: { maxWidth: "72px", minWidth: "72px" } }
    //         }]}
    //         tableBodyProps={tableBodyProps}
    //         tableHeadProps={tableHeadProps}
    //         renderPlaceholder={() => (
    //             <DataGridPlaceholder>
    //                 <Typography variant="body2" color="text.disabled">No users found</Typography>
    //             </DataGridPlaceholder>
    //         )}
    //     />

    //     {dlgEditUserProps.user && <EditUserDialog onClose={handleDlgEditUserClose} {...dlgEditUserProps} />}
    // </>;
}

function EmptyTableRow() {
    return <TableRow sx={{ display: "block" }}>
        <TableCell colSpan={6} sx={{ display: "block", py: 6, color: "text.disabled", fontSize: "1rem", textAlign: "center" }}>
            This table is empty.
        </TableCell>
    </TableRow>;
}

function skeletonRows() {
    var rows: JSX.Element[] = [];
    for (var i = 0; i < 5; i++) {
        rows.push(<TableRow
            key={i}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            {/* {checkboxSelection && <TableCell sx={{pr: 0}}><Checkbox onChange={handleSelectionChange} checked={selected} /></TableCell>} */}
            <TableCell>
                <Skeleton variant="circular" width={40} height={40} />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" />
            </TableCell>
            <TableCell >
                <Skeleton variant="text" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" />
            </TableCell>
            <TableCell>
            </TableCell>
        </TableRow>)
    }
    return rows;
}

export const issWaziup = "https://login.waziup.io/auth/realms/waziup";

export interface UsersTableRowProps {
    user: User,
    onUserDelete: (user: User) => void,
    checkboxSelection?: boolean,
    selection?: Selection<string>,
}
export function UsersTableRow(props: UsersTableRowProps) {
    var { user, onUserDelete, checkboxSelection, selection } = props;
    const selected = useIsSelected(selection, user.id);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const id = "user-table-row-" + user.id;

    const forceUpdate = useForceUpdate();

    function suspendUser() {
        closeMenu();
        MsgBox({
            title: "Suspend User",
            text: <>The selected account will be suspended and can not login anymore. You can release the user at any time.<br />The account will not be deleted!</>,
            handleCancel: () => { },
            handleOk: () => {
                updateUsers({ query: { ids: [user.id] }, update: { suspended: true } }).then(() => {
                    user.suspended = true;
                    forceUpdate();
                    toast("User suspended.");
                }, reportError);
            }
        });
    }

    function releaseUser() {
        closeMenu();
        updateUsers({ query: { ids: [user.id] }, update: { suspended: false } }).then(() => {
            user.suspended = false;
            forceUpdate();
            toast("User released.");
        }, reportError);
    }

    function deleteUser() {
        closeMenu();
        MsgBox({
            title: "Delete User",
            text: <>The selected user will be deleted. This can not be undone!</>,
            handleCancel: () => { },
            handleOk: () => {
                deleteUsers({ ids: [user.id] }).then(() => {
                    onUserDelete(user);
                    toast("User deleted.");
                }, reportError);
            }
        });
    }

    // useHub<UserUpdateEvent>(".user-update", ({ selection, update }) => {
    //     if (selection.has(user.id)) {
    //         forceUpdate();
    //     }
    // });

    const session = useSession();
    const self = session?.sub === user.id;

    // function setUserEmailVerified() {
    //     closeMenu();
    //     identApi.updateUsers({ ids: [user.id] }, { emailVerified: true }, { fetcher: api.fetch }).then(() => {
    //         user.emailVerified = true;
    //         forceUpdate();
    //         toast("User email set to be verified.");
    //     }, reportError);
    // }

    // function setUserEmailNotVerified() {
    //     closeMenu();
    //     identApi.updateUsers({ ids: [user.id] }, { emailVerified: false }, { fetcher: api.fetch }).then(() => {
    //         user.emailVerified = false;
    //         forceUpdate();
    //         toast("User email set to be not verified.");
    //     }, reportError);
    // }

    const [dlgSetPasswordOpen, setDlgSetPasswordOpen] = useState(false);
    const [dlgEditUserProps, setDlgEditUserProps] = useState<Partial<EditUserDialogProps>>({});
    function handleDlgEditUserClose() {
        setDlgEditUserProps(props => ({ ...props, open: false }));
    }
    function editUser(props: Partial<EditUserDialogProps> = {}) {
        closeMenu();
        setDlgEditUserProps({ ...props, open: true });
    }

    function setPassword() {
        closeMenu();
        setDlgSetPasswordOpen(true);
    }

    function handleDlgSetPasswordClose() {
        setDlgSetPasswordOpen(false);
    }

    return <TableRow
        key={user.id}
        id={id}
        className={clsx(selected && "selected")}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, "&:hover .btn-user-row": { opacity: 1 }, "&.selected": { bgcolor: "#1976d214" } }}
    >
        {checkboxSelection && <TableCell sx={{ pr: 0, width: 58, minWidth: 58 }}><Checkbox checked={selected} onChange={(ev, checked) => { selection?.handleChange(ev, checked, user.id); }} /></TableCell>}
        <TableCell sx={{ width: "72px", minWidth: "72px" }}><UserAvatar user={user} /></TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", width: "15em", maxWidth: "25em" }}>
            {/* <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit" }}>
                {user.username}
            </Box> */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {user.username ? (
                    <Tooltip title={user.usernameVerified ? "Username verified" : "Username unverified"}>
                        <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }} onClick={() => { editUser({ autoFocusField: "username" }); }}>
                            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit", cursor: "text" }} style={{ fontStyle: user.usernameVerified ? "normal" : "italic" }}>
                                {user.username}
                            </Box>
                            {user.usernameVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
                        </Box>
                    </Tooltip>
                ) : "-"}
                {self && <Chip sx={{ ml: 1 }} label="You" />}
                {user.suspended && <Tooltip title="Suspended"><BlockOutlined fontSize="small" color="error" sx={{ ml: 1, verticalAlign: "middle" }} /></Tooltip>}
            </Box>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", width: "20em", maxWidth: "25em" }}>
            {user.email ? (
                <Tooltip title={user.emailVerified ? "Email verified" : "Email unverified"}>
                    <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }} onClick={() => { editUser({ autoFocusField: "email" }); }}>
                        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "inherit", cursor: "text" }} style={{ fontStyle: user.emailVerified ? "normal" : "italic" }}>
                            {user.email}
                        </Box>
                        {user.emailVerified && <Check fontSize="small" color="success" sx={{ ml: 1, verticalAlign: "middle", mb: "2px" }} />}
                    </Box>
                </Tooltip>
            ) : "-"}
        </TableCell>
        <TableCell sx={{ width: "10em", maxWidth: "20em", whiteSpace: "nowrap" }}>

            {user.methods?.find(s => s.iss === issWaziup) && "Waziup"}
        </TableCell>
        <TableCell sx={{ width: 72, minWidth: 72, maxWidth: 72 }}>
            <IconButton id={id + "-btn"} className="btn-user-row" sx={{ "@media(hover)": { opacity: 0 } }} onClick={handleClick}><MoreVertOutlined /></IconButton>
        </TableCell>

        <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            MenuListProps={{
                'aria-labelledby': id + "-btn",
            }}
        >
            <MenuItem onClick={() => { editUser(); }}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
            </MenuItem>
            {user.suspended ?
                <MenuItem onClick={releaseUser}>
                    <ListItemIcon>
                        <CircleOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Release</ListItemText>
                </MenuItem> :
                <MenuItem onClick={suspendUser}>
                    <ListItemIcon>
                        <BlockOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Suspend</ListItemText>
                </MenuItem>
            }
            {/* {user.username && (
                user.emailVerified ? (
                    <MenuItem onClick={setUserEmailNotVerified}>
                        <ListItemIcon>
                            <MarkEmailUnreadOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Set Email not verified</ListItemText>
                    </MenuItem>
                ) : (
                    <MenuItem onClick={setUserEmailVerified}>
                        <ListItemIcon>
                            <MarkEmailReadOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Set Email verified</ListItemText>
                    </MenuItem>
                )
            )}
            {user.email && (
                user.emailVerified ? (
                    <MenuItem onClick={setUserEmailNotVerified}>
                        <ListItemIcon>
                            <MarkEmailUnreadOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Set Email not verified</ListItemText>
                    </MenuItem>
                ) : (
                    <MenuItem onClick={setUserEmailVerified}>
                        <ListItemIcon>
                            <MarkEmailReadOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Set Email verified</ListItemText>
                    </MenuItem>
                )
            )} */}
            <MenuItem onClick={setPassword} disabled={!user.email}>
                <ListItemIcon>
                    <PasswordOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <MenuItem onClick={deleteUser} disabled={self}>
                <ListItemIcon>
                    <DeleteOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </Menu>
        {user.email && <DlgSetPassword user={user} onClose={handleDlgSetPasswordClose} open={dlgSetPasswordOpen} />}
        <EditUserDialog open={false} user={user} onClose={handleDlgEditUserClose} {...dlgEditUserProps} />
    </TableRow>
}

////////////////////////////////////////////////////////////////////////////////

interface SelectionCheckboxProps extends CheckboxProps {
    sel: Selection<any>,
    numTotal: number,
}

const SelectionCheckbox = forwardRef((props: SelectionCheckboxProps, ref) => {
    const { sel, numTotal, ...checkboxProps } = props;
    const numSelected = useSelectionCount(sel, numTotal);
    const checked = numSelected === numTotal;
    const indeterminate = numSelected > 0 && numSelected < numTotal;
    return <Checkbox checked={checked} indeterminate={indeterminate} onChange={(ev, checked) => { if (checked) sel.all(); else sel.clear(); }} {...checkboxProps} />
});


////////////////////////////////////////////////////////////////////////////////

export interface UserAvatarProps extends AvatarProps {
    user: User
}

export function UserAvatar(props: UserAvatarProps) {
    const { user, ...avatarProps } = props;
    const { username, email, picture } = user;
    return <AccountAvatar name={username || email || "??"} picture={picture} {...avatarProps} />;
    // if (user.picture)
    //     return <Avatar alt={user.username} src={user.picture} {...avatarProps} />
    // if (user.username)
    //     return <StringAvatar name={user.username} {...avatarProps} />;
    // return <Avatar {...avatarProps} />;
}

////////////////////////////////////////////////////////////////////////////////


export interface DlgSetPasswordProps extends DialogProps {
    onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown" | "closedClicked" | "cancelClicked" | "passwordChanged") => void),
    onPasswordChanged?: (ev: {}, password: string) => void
    user: User,
}

export function DlgSetPassword(props: DlgSetPasswordProps) {
    const { user, ...dialogProps } = props;

    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    function handleCancel(ev: {}) {
        props.onClose?.(ev, "cancelClicked");
    }

    function handleClose(ev: {}) {
        props.onClose?.(ev, "closedClicked");
    }

    function reset() {
        setPassword("");
        setLoading(false);
    }

    function handleSubmit(ev: {}) {
        setLoading(true);
        updateUsers({ query: { ids: [user.id] }, update: { newPassword: password } }).then(() => {
            props.onPasswordChanged?.({}, password);
            props.onClose?.(ev, "passwordChanged");
            toast("Password set.");
        }, (err) => {
            setLoading(false);
            reportError(err);
        });
    }

    function onTransitionExited() {
        reset();
    }

    return <Dialog {...dialogProps} TransitionProps={{ onExited: onTransitionExited }}>
        <DialogTitle>Set Password</DialogTitle>
        <DialogCloseButton onClick={handleClose} />
        <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
                To change the user password, please enter a new password below. Changes will take affect immidiatly. <br />
                However, the user is not prompted to enter the new password until the next time they login.
            </DialogContentText>
            <form id="change-user-password" onSubmit={preventDefault}>
                <TextField label="Email" autoComplete="off" type="text" disabled fullWidth value={user.email!} variant="filled" sx={{ mb: 2 }} />
                <PasswordField id="password" autoComplete="off" label="Password" autoFocus fullWidth variant="filled" disabled={loading} sx={{ mb: 2 }} value={password} onChange={ev => setPassword(ev.target.value)} />
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained" disabled={loading}>Change Password</Button>
        </DialogActions>
    </Dialog>
}

import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { joinProgram, leaveProgram, listPrograms, ListProgramsResponse, Program } from "../../api";
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, styled, Typography, Alert, AlertTitle, Divider, Chip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { useSession } from "../../ident";
import ErrorView from "../ErrorView";
import { lineClamp } from "../tools";
import React from "react";
import Page, { PageContent } from "../Page";
import { MsgBox, reportError } from "@halliday/mui-msgbox";
import { useForceUpdate } from "../../tools";
import { toast } from "@halliday/mui-toast";
import EventIcon from '@mui/icons-material/Event';
import TodayIcon from '@mui/icons-material/Today';
import ProgramPlaceholderCover from '../../assets/images/prog-placeholder-cover.png'
import dayjs from 'dayjs';

export default function Programs() {

    return (
        <Page title="Program Catalog">
            <PageContent sx={{ pb: 'inherit' }}>
                <ProgramsList />
            </PageContent>
        </Page>
    );
}

interface ProgramSectionProps {
    title: string;
    description: string;
    programs: Program[];
}

export function ProgramsList() {
    const [page, setPage] = useState<ListProgramsResponse | null>(null);
    const [err, setErr] = useState<any>(null);

    const sess = useSession();

    useEffect(() => {
        listPrograms({}).then(setPage, setErr);
    }, [sess]);

    if (err) return <ErrorView err={err} />;
    if (page === null) return <CircularProgress />;

    const { programs } = page;

    const ProgramSection: React.FC<ProgramSectionProps> = ({ title, description, programs }) => (
        <Box display='flex' flexDirection='column' mb='inherit'>
            <Typography variant="h1" mb={1}>{title}</Typography>
            <Typography variant="body1">{description}</Typography>
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', alignItems: 'flex-start' }} mt={2} mb={['15%', '5%']}>
                {programs.map((program) => (
                    !program.draft || program.roles.length ? <ProgramCard key={program.id} myProg={program} /> : null
                ))}
            </Box>
        </Box>
    );

    const popularPrograms = programs.filter(program => program.org.title === "Waziup");
    const iotPrograms = programs.filter(program => program.org.title !== "Waziup");

    return (
        <Box>
            <ProgramSection
                title="Popular Programs"
                description="Explore our most popular programs and get job-ready for an in-demand career."
                programs={popularPrograms}
            />
            {iotPrograms.length > 0 && (
                <ProgramSection
                    title="IoT Certification Programs"
                    description="Get certified in IoT with the European Union Program, Hubiquitous."
                    programs={iotPrograms}
                />
            )}
        </Box>

    );
}

export interface ProgramCardProps {
    myProg: Program;
    noActionButton?: boolean;
}

const OrganizationIcon = styled("img")({
    height: 24,
    display: "inline-block",
    marginTop: "-6px",
    marginBottom: "-6px"
});

const getFormattedDate = (date: Date) => {
    const formattedDate = dayjs(date).format("MMMM D, YYYY");
    return formattedDate;
};

export function ProgramCard({ myProg, noActionButton }: ProgramCardProps) {
    const navigate = useNavigate();
    const { name, id, desc, createdBy, startsAt, endsAt, cover, joined, joinedAt, added, addedAt, org } = myProg;

    const [open, setOpen] = React.useState(false);

    const forceUpdate = useForceUpdate();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const sess = useSession();

    function navigateProgram() {
        navigate(`/programs/${id}`);
    }

    async function joinProgramNow() {
        if (!sess) {
            window.openLogin();
            return;
        }
        try {
            await joinProgram({ id });
        } catch (err) {
            reportError(err);
            return
        }
        switch (myProg.join) {
            case "open":
                navigateProgram();
                break;
            case "request":
                if (!addedAt) {
                    MsgBox({
                        title: "Request Sent",
                        text: "Your request has been sent. You will be notified when your request is approved.",
                    });
                    myProg.joined = true;
                    myProg.joinedAt = new Date();
                    setOpen(false);
                    forceUpdate();
                } else {
                    navigateProgram();
                }
                break;
            case "invite":
                // unreachable
                break;
        }
    }

    async function leaveprogramNow() {
        try {
            await leaveProgram({ id });
        } catch (err) {
            reportError(err);
            return
        }
        toast(`You left ${name}.`);
        myProg.joined = false;
        myProg.joinedAt = null;
        myProg.added = false;
        myProg.addedAt = null;
        forceUpdate();
    }

    let btn: ReactNode;
    let btnHint: ReactNode;
    if (joined) {
        if (added) {
            btn = <Button size="small" variant="contained" disableElevation onClick={joined && added ? () => navigate(`/programs/${id}/progress`) : navigateProgram}>Open</Button>;
        } else {
            btn = <Button size="small" variant="outlined" disabled>Join</Button>;
            btnHint = <Alert severity="info">
                Your request to join this program is pending approval.
                <Box sx={{ mt: 1 }}>
                    <Button variant="text" onClick={leaveprogramNow} size="small">Cancel</Button>
                </Box>
            </Alert>;
        }
    } else {
        if (added) {
            btn = <Button size="small" variant="contained" disableElevation onClick={joinProgramNow}>Join</Button>;
            btnHint = <Alert severity="info">
                You have been invited to join this program.
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                    <Button variant="text" onClick={joinProgramNow} size="small">Accept</Button>
                    <Divider orientation="vertical" flexItem />
                    <Button variant="text" onClick={leaveprogramNow} size="small">Decline</Button>
                </Box>
            </Alert>;
        } else {
            btn = <Button size="small" variant="outlined" onClick={handleClickOpen} >Join</Button>;
        }
    }

    let content: JSX.Element;
    switch (myProg.join) {
        case "open":
            content = <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                <Button variant="contained" disableElevation onClick={joinProgramNow}>Join Now</Button>
                <Typography variant="body2">Program is free to join.</Typography>
            </Box>;
            break;
        case "request":
            content = <Box>
                <Button variant="contained" disableElevation onClick={joinProgramNow}>Request Access</Button>
                <Typography variant="body2" sx={{ mt: 2 }}>Your request will be approved by the program admin or organization.</Typography>
            </Box>;
            break;
        case "invite":
            content = <Alert severity="info">
                <AlertTitle>Invitation Required</AlertTitle>
                This program is invite only. Please contact the program admin or organization to get access to the program.
            </Alert>
            break;
    }

    return (
        <>
            <Card sx={{ width: ["100%", 300], "&:hover": { " .btnMore": { visibility: "visible" } }, cursor: 'pointer' }} onClick={() => noActionButton ? navigate(`/programs/${id}`) : null} >
                <CardMedia component="img" width="300" height="200" image={cover ? cover.url : ProgramPlaceholderCover} alt={name} />
                <CardHeader
                    sx={{ mb: 0 }}
                    titleTypographyProps={{ fontSize: 16, width: 198, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}
                    title={name}
                    subheader={<><OrganizationIcon src={org.cover?.url} /> {org.title}</>}
                    subheaderTypographyProps={{ sx: { ...lineClamp(2) } }}
                />
                {
                    btnHint ?
                        <CardContent sx={{ pt: 'inherit', }}>{btnHint}</CardContent>
                        :
                        (
                            noActionButton ? null :
                                <>
                                    <CardContent sx={{ pt: 'inherit', }}>
                                        {/* 
                                            <Typography variant="body1" color="text.primary" sx={{ mb: 1, overflow: 'hidden', textOverflow: "ellipsis", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", display: "-webkit-box" }}>
                                                Starts: {<Chip icon={<TodayIcon />} label={getFormattedDate(startsAt!)} />}
                                            </Typography>
                                            <Typography variant="body1" color="text.primary" sx={{ mb: 1, overflow: 'hidden', textOverflow: "ellipsis", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", display: "-webkit-box" }}>
                                                Ends: {<Chip icon={<EventIcon />} label={getFormattedDate(endsAt!)} />}
                                            </Typography> 
                                        */}
                                        <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', textOverflow: "ellipsis", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", display: "-webkit-box" }}>{desc}</Typography>
                                    </CardContent>

                                    <CardActions sx={{ p: 2, pt: 'inherit' }}>
                                        {btn}
                                        <Button size="small" onClick={() => navigate(`/programs/${id}`)} disabled={joined && added ? true : false}>Learn More</Button>
                                    </CardActions>
                                </>
                        )
                }

            </Card>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{name}</DialogTitle>
                <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography gutterBottom textAlign="justify">{desc}</Typography>
                    {startsAt && <Typography sx={{ mb: 4 }}><b>Starting on:</b> {startsAt.toLocaleString()}</Typography>}
                    {content}
                </DialogContent>;
            </Dialog>
        </>
    );
}

import { Box, Button, Checkbox, Divider, FormControlLabel, IconButton, Menu, MenuItem, Stack, Typography, } from "@mui/material";
import { ListProgramMembersResponse, Program, ProgramMember, ProgramMemberUpdate, RemoveProgramMembersResponse, UpdateProgramMembersResponse, UsersQuery, addProgramMembers, findProgram, removeProgramMembers, updateProgramMembers } from "../../../api";
import { useEffect, useMemo, useState } from "react";
import DialogSelectUsers from "../../users/DlgSelectUsers";
import ProgramMembersTable from "./ProgramMembersTable";
import { useProgramMembers } from "../../../loaders";
import Selection from "../../../Selection";
import { AddOutlined, DeleteOutlined, ExpandMore, MoreVert } from "@mui/icons-material";
import { useForceUpdate } from "../../../tools";
import { MsgBox, reportError } from "@halliday/mui-msgbox";
import { useSession } from "../../../ident";


export type ParticipantsPageProps = {
    programId: Program["id"],
}

const defaultPageSize = 25;

function isInvitationPending(m: ProgramMember) {
    return m.added && !m.joined;
}

export default function PaticipantsPage(props: ParticipantsPageProps) {
    const { programId } = props;

    const [dlgAddMembersOpen, setDlgAddMembersOpen] = useState(false);
    const [program, setProgram] = useState<Program | null>(null);
    const [err, setErr] = useState<any>(null);
    const session = useSession();

    const [sendInvitationMail, setSendInvitationMail] = useState(true);
    const [autoJoin, setAutoJoin] = useState(false);

    function handleSendInvitationMailChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setSendInvitationMail(ev.target.checked);
    }

    function handleAutoJoinChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setAutoJoin(ev.target.checked);
    }

    const isAdmin = session?.user.roles.includes("admin") ?? false;

    async function loadProgram() {
        try {
            const resp = await findProgram({ id: programId });
            setProgram(resp.program);
            setErr(null);
        } catch (err) {
            setErr(err);
        }
    }
    useEffect(() => { loadProgram(); }, [programId, session]);

    function askAddMembers() {
        setDlgAddMembersOpen(true);
    }
    function closeDlgAddMembers() {
        setDlgAddMembersOpen(false);
    }

    async function handleUsersSelect(users: UsersQuery) {
        await addProgramMembers({ program: programId, users: users, autoJoin, noSendEmail: !sendInvitationMail});
        setDlgAddMembersOpen(false);
        setX(x + 1);
    }

    const [pageSize, setPageSize] = useState(defaultPageSize);
    // const loader = useMemo(() => new UsersLoader(search, pageSize), [search, pageSize, sess]);
    function handlePageSizeChange(ev: {}, pageSize: number) {
        setPageSize(pageSize);
    }

    function handlePageChange(ev: {} | null, page: number, data: Promise<ListProgramMembersResponse>) {
        data.then(setData);
    }

    const [x, setX] = useState(0);
    const loader = useProgramMembers(programId, pageSize, [x]);
    const selection = useMemo(() => new Selection<ProgramMember>(), [loader]);
    const forceUpdate = useForceUpdate();
    selection.on("change", forceUpdate);
    const [data, setData] = useState<ListProgramMembersResponse | null>(null);

    const numMembers = data?.numMembers ?? 0;
    const numSelected = selection.isAll ? numMembers - selection.size : selection.size;
    const pendingInvitations = Array.from(selection.items).filter(isInvitationPending);

    function selectionUsersQuery(): UsersQuery {
        return {
            all: selection.isAll,
            ids: Array.from(selection.items).map(m => m.user.id),
        };
    }


    async function removeMembers() {
        let resp: RemoveProgramMembersResponse;
        try {
            resp = await removeProgramMembers({
                program: programId,
                users: selectionUsersQuery(),
            });
        } catch (err) {
            reportError(err);
        }
        setX(x + 1);
    }


    async function completePendingInvitations() {
        let resp: UpdateProgramMembersResponse;
        try {
            resp = await updateProgramMembers({
                program: programId,
                users: selectionUsersQuery(),
                update: { joined: true },
            });
        } catch (err) {
            reportError(err);
        }
        setX(x + 1);
    }

    function askRemoveMembers() {
        MsgBox({
            title: "Remove Members",
            text: `Are you sure you want to remove ${numSelected} members?`,
            handleOk: removeMembers,
            handleCancel: () => { },
        });
    }

    function askCompletePendingInvitations() {
        MsgBox({
            title: "Complete Invitation",
            text: `Are you sure you want to complete ${pendingInvitations.length} pending invitations?`,
            handleOk: completePendingInvitations,
            handleCancel: () => { },
        });
    }

    async function handleMembersUpdate(ev: {}, users: UsersQuery, update: ProgramMemberUpdate) {
        await updateProgramMembers({ program: programId, users, update });
        setX(x + 1);
    }

    async function handleMembersRemove(ev: {}, query: UsersQuery) {
        await removeProgramMembers({ program: programId, users: query });
        setX(x + 1);
    }


    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    function handleMenuOpen(ev: React.MouseEvent<HTMLButtonElement>) {
        setMenuAnchorEl(ev.currentTarget);
    }
    function handleMenuClose() {
        setMenuAnchorEl(null);
    }


    return (
        <Box>
            <Stack direction="row" spacing={2} mb={1}>
                <Typography variant="h5" sx={{ flexGrow: 1 }}>Participants</Typography>
                <Button startIcon={<AddOutlined />} onClick={askAddMembers}>Add Members</Button>
                {isAdmin && <>
                    <IconButton id="btn-add-members-options" onClick={handleMenuOpen} sx={{ bgcolor: menuAnchorEl !== null ? "grey.300" : undefined }}><ExpandMore /></IconButton>
                    <Menu
                        id="add-members-menu"
                        anchorEl={menuAnchorEl}
                        open={menuAnchorEl !== null}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'btn-add-members-options',
                        }}
                    >
                        <MenuItem>
                            <FormControlLabel control={<Checkbox checked={sendInvitationMail} onChange={handleSendInvitationMailChange} />} label="Send Invitation Email" />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel control={<Checkbox checked={autoJoin} onChange={handleAutoJoinChange} />} label="Auto Join" />
                        </MenuItem>
                    </Menu>
                </>}
            </Stack>
            <ProgramMembersTable loader={loader} selection={selection} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} onPageChange={handlePageChange} onMembersUpdates={handleMembersUpdate} onMembersRemove={handleMembersRemove} />

            <Typography gutterBottom>Actions</Typography>
            <Stack direction="row" spacing={2}>
                <Button startIcon={<DeleteOutlined />} disabled={numSelected === 0} onClick={askRemoveMembers}>Remove Members ({numSelected})</Button>
                {isAdmin && <>
                    <Divider orientation="vertical" flexItem />
                    <Button disabled={pendingInvitations.length === 0} onClick={askCompletePendingInvitations}>Complete Invitation ({pendingInvitations.length})</Button>
                </>}
            </Stack>

            <DialogSelectUsers open={dlgAddMembersOpen} onClose={closeDlgAddMembers} onUsersSelect={handleUsersSelect} updateAutoJoin={setAutoJoin} updateInvitationMail={setSendInvitationMail} autoJoinParent={autoJoin} sendInvitationMailParent={sendInvitationMail} />
        </Box>
    )
}

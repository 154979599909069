import { Alert, Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, CardProps, Chip, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Organization, OrganizationsResponse, getOrganizations, joinOrganization, leaveOrganization } from "../../api";
import { useSession } from "../../ident";
import ErrorView from "../ErrorView";
import { toast } from "@halliday/mui-toast";
import { useForceUpdate } from "../../tools";

function OrganizationsList() {

    const [page, setPage] = useState<OrganizationsResponse | null>(null);
    const [err, setErr] = useState<any>(null);

    function refresh() {
        getOrganizations({}).then(setPage, setErr);
    }

    const sess = useSession();
    useEffect(refresh, [sess]);

    let content: JSX.Element;
    if (err) {
        content = <ErrorView err={err} />;
    } else if (page === null) {
        content = <CircularProgress />
    } else {
        content = <Box sx={{ px: 4, py: 2 }}>
            <Typography variant="body2" gutterBottom>{page.orgs.length} Organizations</Typography>
            <Box component="ul" sx={{ display: "flex", gap: 4, p: 0, m: 0, flexWrap: "wrap", alignItems: "flex-start" }}>
                {page.orgs.map(org => <OrganizationCard key={org.id} org={org} />)}
            </Box>
        </Box>
    }

    return content;
}

export default OrganizationsList;

interface OrganizationCardProps extends CardProps {
    org: Organization
}

function OrganizationCard(props: OrganizationCardProps) {
    const { org, ...cardProps } = props;
    const navigate = useNavigate();

    const { joined, added, title } = org;

    const forceUpdate = useForceUpdate();

    const i = org.desc.indexOf("\n");
    const sub = i > 0 ? org.desc.substring(0, i) : org.desc;
    const desc = i > 0 ? org.desc.substring(i + 1).trim() : "";

    const sess = useSession();

    function navigateOrg() {
        navigate("/orgs/" + org.slug);
    }

    async function joinProgramNow() {
        if (!sess) {
            window.openLogin();
            return;
        }
        try {
            await joinOrganization({ id: org.id });
        } catch (err) {
            reportError(err);
            return
        }
        navigateOrg();
    }

    async function leaveprogramNow() {
        try {
            await leaveOrganization({ id: org.id });
        } catch (err) {
            reportError(err);
            return
        }
        toast(`You left ${title}.`);
        org.joined = false;
        org.joinedAt = null;
        org.added = false;
        org.addedAt = null;
        forceUpdate();
    }


    let action: ReactNode;
    let btnHint: ReactNode;
    if (joined && !added) {
        action = <Alert severity="info">
            Your request to join this program is pending approval.
            <Box sx={{ mt: 1 }}>
                <Button variant="text" onClick={leaveprogramNow} size="small">Cancel</Button>
            </Box>
        </Alert>;
    }
    if (!joined && added) {
        action = <Alert severity="info">
            You have been invited to join this organization.
            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                <Button variant="text" onClick={joinProgramNow} size="small">Accept</Button>
                <Divider orientation="vertical" flexItem />
                <Button variant="text" onClick={leaveprogramNow} size="small">Decline</Button>
            </Box>
        </Alert>;
    }

    return <Card sx={{ width: 345 }} {...cardProps} component="li" >
        <CardActionArea onClick={navigateOrg}>
            <Stack sx={{ p: 2 }} direction="row" gap={1}>
                <Avatar src={org.coverUrl} sx={{ border: '.3px #bbb solid' }} aria-label="recipe">R</Avatar>
                <Stack sx={{ flexGrow: 1, width: 0 }} direction="column">
                    <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: 'flex', justifyContent: 'space-between' }}>
                        {org.title}
                        {joined && added && <Chip size="small" label="Joined" color="primary" sx={{ ml: 1, verticalAlign: "top" }} />}
                    </Typography>
                    <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} color="text.secondary">
                        {sub}
                    </Typography>
                </Stack>
            </Stack>
            <CardContent sx={{ pt: 0 }}>
                <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{desc}</Typography>
            </CardContent>
            <CardActions>
                {action}
            </CardActions>
            {/* <CardActions>
            <Button size="small" onClick={() => navigate("/orgs/" + org.id)}>See Modules</Button>
            <Button size="small" onClick={() => navigate("/orgs/" + org.id)}>Manage</Button>
        </CardActions> */}
        </CardActionArea>
    </Card>
}

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Alert, AlertTitle, Box, Button, Chip, FormControl, Grid, IconButton, InputBase, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page, { PageContent } from "../Page";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ResourceEditorPage() {
  const navigate = useNavigate();
  const [resourceType, setResourceType] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setResourceType(event.target.value as string);
  };

  useEffect(() => { }, []);

  const skeleton = (
    <Stack spacing={1}>
      <Skeleton variant="text" />
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={118} />
    </Stack>
  );

  let specificationComponents: JSX.Element | undefined;

  specificationComponents = (
    <>
      {tools.map((item, index) => {
        return (
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              height: "2.5rem",
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="drag icon">
              <DragHandleIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "1rem" }}
              value={`Spec ${index + 1}.  ${item.title}`}
              placeholder="topic name"
              inputProps={{ "aria-label": "topic name" }}
            />
            <Chip label="draft" />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <EditIcon />
            </IconButton>
            <IconButton sx={{ p: "10px" }} aria-label="edit">
              <MoreVertIcon />
            </IconButton>
          </Paper>
        );
      })}
    </>
  );

  let content: JSX.Element | null;
  content = (
    <Grid container spacing={5}>
      <Grid item xs={12} md={5}>
        <Box component="form" noValidate autoComplete="off">
          <FormControl sx={{ width: "100%" }}>
            <Box>
              <Typography variant="body1">Resource Type</Typography>
              <FormControl fullWidth>
                <Select
                  id="demo-simple-select"
                  value={resourceType}
                  onChange={handleChange}
                >
                  <MenuItem value={"hardware"}>Hardware</MenuItem>
                  <MenuItem value={"dataset"}>Dataset</MenuItem>
                  <MenuItem value={"software"}>Software</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={3}>
              <Typography variant="body1">Title</Typography>
              <TextField
                fullWidth
                multiline
                id="outlined-bare"
                variant="outlined"
                //   value={course.title}
                placeholder="random title"
                InputProps={{ style: { fontSize: "1rem", padding: "8px" } }}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="body1">Description (optional)</Typography>
              <TextField
                fullWidth
                multiline
                id="outlined-bare"
                variant="outlined"
                rows={4}
                //   value={course.desc}
                placeholder="some random text for description idea"
                InputProps={{ style: { fontSize: "1rem", padding: "8px" } }}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="body1">Files</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: 100,
                    height: 100,
                  },
                }}
              >
                <Paper elevation={2} />
                <Paper elevation={2} />
                <Paper elevation={2} />
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Alert severity="error">
                <AlertTitle>Warning (this cannot be undone)</AlertTitle>
                <Button startIcon={<DeleteIcon />} color="error">
                  Delete Course
                </Button>{" "}
              </Alert>
            </Box>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">Specification</Typography>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{
                  ":hover": { backgroundColor: "primary.main", color: "#fff" },
                }}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
          <Box
            mt={1}
            gap={1}
            height="20vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {specificationComponents}
          </Box>
        </Box>
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">User Guides</Typography>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{
                  ":hover": { backgroundColor: "primary.main", color: "#fff" },
                }}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
          <Box
            mt={1}
            gap={1}
            height="20vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {specificationComponents}
          </Box>
        </Box>
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">Attachments</Typography>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{
                  ":hover": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                  },
                }}
              >
                <PlaylistAddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
          <Box
            mt={1}
            gap={1}
            height="20vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {specificationComponents}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  // Now returns the whole currentModule component:
  return (
    <Page
      title="Resource Editor"
    >
      <PageContent>{content}</PageContent>
    </Page>
  );
}

export default ResourceEditorPage;

const dummy_array = [1, 2, 3, 4];
const tools = [
  { title: "lorem ipsum", rank: 1 },
  { title: "lorem ipsum", rank: 2 },
  { title: "lorem ipsum", rank: 3 },
];

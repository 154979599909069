import { ReactNode, useEffect, useState } from "react";
import { Program, listPrograms, ListProgramsResponse, joinProgram, leaveProgram, FindProgramResponse, findProgram, ProgramUnit, unitProgress } from "../../api";
import { CardContent, Box, Typography, Button, Card, CircularProgress, useTheme, CardMedia, CardActionArea, CardActions, LinearProgress, LinearProgressProps, styled, Alert, Divider, Stack, useMediaQuery, Chip } from "@mui/material";
import wazicloudLogo from "../../assets/images/wazicloud-logo.svg";
import ContentMissing from "../../assets/images/course-not-found.svg";
import CastForEducationIcon from '@mui/icons-material/CastForEducation'; // online education
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // menu dots
import TodayIcon from '@mui/icons-material/Today';
import Page, { PageContent, TOCListItem } from "../Page";
import { useLocation, useNavigate } from "react-router-dom";
import LocalLibraryOutlined from "@mui/icons-material/LocalLibraryOutlined";
import BusinessIcon from '@mui/icons-material/Business';
import { useSession } from "../../ident";
import ErrorView from "../ErrorView";
import React from "react";
import { DateRangeInWeeks, formatDate, lineClamp } from "../tools";
import { MsgBox } from "@halliday/mui-msgbox";
import { toast } from "@halliday/mui-toast";
import { useForceUpdate } from "../../tools";
import { linearProgressClasses } from '@mui/material/LinearProgress';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ProgramPlaceholderCover from '../../assets/images/prog-placeholder-cover.png';

const BannerDesktop = styled("img")({
    height: 350,
    display: "block",
});

export default function MyProgramsPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const [page, setPage] = useState<ListProgramsResponse | null>(null);
    const [err, setErr] = useState<any>(null);
    const sess = useSession();
    const hasSession = sess !== null;

    const programsCatalog = (
        <Button
            size="medium"
            startIcon={<LocalLibraryOutlined />}
            sx={{
                mr: 2,
                bgcolor: "grey.200",
                ":hover": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                },
            }}
            onClick={() => navigate("/programs")}
        >
            Programs Catalog
        </Button>
    );

    useEffect(() => {
        listPrograms({}).then(setPage, setErr);
    }, [sess]);

    if (err) return <ErrorView err={err} />;
    if (page === null) return <CircularProgress />;

    const filteredPrograms = page.programs.filter(program => program.joined || program.added);

    if (!hasSession || filteredPrograms.length === 0) {
        navigate('/programs')
        return null
    }


    return (
        <Page title="My Programs" actions={programsCatalog}>
            <PageContent sx={{ pb: 'inherit' }}>
                <MyProgramsList programs={filteredPrograms} />
            </PageContent>
        </Page>
    );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} sx={{ height: "8px", borderRadius: 25 }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export interface ListProgramsResponseProps {
    programs: Program[];
}

export function MyProgramsList(props: ListProgramsResponseProps) {
    const programs = props.programs

    return (
        <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '10%' }}>
            {
                    programs.map((program) => (
                        <MyProgramCard key={program.id} myProg={program} />
                    ))
            }
        </Box>
    );
}

export interface MyProgramCardProps {
    myProg: Program;
}

export function MyProgramCard(props: MyProgramCardProps) {

    const navigate = useNavigate();
    const { myProg } = props;
    const theme = useTheme();
    const { name, id, desc, createdBy, cover, startsAt, endsAt, numUnits, added, addedAt, joined, org } = myProg;
    const [singleProgram, setSingleProgram] = useState<FindProgramResponse | null>(null);
    const [err, setErr] = useState<any>(null);
    const [open, setOpen] = React.useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const sess = useSession();
    const currentDate: Date = new Date();
    let progress: number;
    let currentUnitProgress: any;
    let currentUnit: ProgramUnit | undefined;

    if (singleProgram !== null) {
        const { units } = singleProgram.program;

        const totalTopics = units?.reduce((acc, unit) => acc + unit.numTopics, 0) || 0;
        const totalTopicsDone = units?.reduce((acc, unit) => acc + unit.numTopicsDone, 0) || 0;

        progress = ((totalTopicsDone / totalTopics) * 100)
        currentUnit = units?.find((unit) => currentDate >= unit.from && currentDate <= unit.to);
        // currentUnitProgress = unitProgress(currentUnit!)
        // console.log(currentUnitProgress)

    } else {
        progress = 0;
    }

    useEffect(() => {
        setSingleProgram(null);
        setErr(null);
        findProgram({ id }).then((program) => {
            setSingleProgram(program)
        }, setErr);
    }, [id]);

    const forceUpdate = useForceUpdate();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    function navigateProgram() {
        navigate(`/programs/${id}`);
    }

    async function joinProgramNow() {
        if (!sess) {
            window.openLogin();
            return;
        }
        try {
            await joinProgram({ id });
        } catch (err) {
            reportError(err);
            return
        }
        switch (myProg.join) {
            case "open":
                navigateProgram();
                break;
            case "request":
                if (!addedAt) {
                    MsgBox({
                        title: "Request Sent",
                        text: "Your request has been sent. You will be notified when your request is approved.",
                    });
                    myProg.joined = true;
                    myProg.joinedAt = new Date();
                    setOpen(false);
                    forceUpdate();
                } else {
                    navigateProgram();
                }
                break;
            case "invite":
                // unreachable
                break;
        }
    }

    async function leaveprogramNow(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        try {
            await leaveProgram({ id });
        } catch (err) {
            reportError(err);
            return
        }
        toast(`You left ${name}.`);
        myProg.joined = false;
        myProg.joinedAt = null;
        myProg.added = false;
        myProg.addedAt = null;
        forceUpdate();
    }


    let btn: ReactNode;
    let btnHint: ReactNode;
    if (joined) {
        if (added) {
            btn = <Button size="small" variant="contained" disableElevation onClick={navigateProgram}>Open</Button>;
        } else {
            btn = <Button size="small" variant="outlined" disabled>Join</Button>;
            btnHint = <Alert severity="info">
                Your request to join this program is pending approval.
                <Box sx={{ mt: 1 }}>
                    <Button variant="text" onClick={(event) => leaveprogramNow(event)} size="small">Cancel</Button>
                </Box>
            </Alert>;
        }
    } else {
        if (added) {
            btn = <Button size="small" variant="contained" disableElevation onClick={joinProgramNow}>Join</Button>;
            btnHint = <Alert severity="info">
                You have been invited to join this program.
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                    <Button variant="text" onClick={joinProgramNow} size="small">Accept</Button>
                    <Divider orientation="vertical" flexItem />
                    <Button variant="text" onClick={leaveprogramNow} size="small">Decline</Button>
                </Box>
            </Alert>;
        } else {
            btn = <Button size="small" variant="outlined" onClick={handleClickOpen} >Join</Button>;
        }
    }

    return (
        <Card sx={{ display: 'flex', cursor: "pointer", width: ["100%"] }} elevation={2} onClick={() => joined && added ? navigate(`/programs/${id}/progress`) : navigate(`/programs/${id}`)}>
            {
                isMobile || isTablet ? null : <CardMedia component="img" sx={{ width: ['undefined', 120, 200], height: 'fit-content', padding: 2 }} image={cover ? cover.url : ProgramPlaceholderCover} alt={name} />
            }

            <CardContent sx={{ display: 'flex', flexDirection: ['column', 'row'], flexGrow: 1 }}>
                <Stack flexDirection='column' flexGrow={1}>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>{ org.coverUrl? <img src={org.coverUrl} alt="" width={24} />: <BusinessIcon/>} {org?.title || "Waziup"}</Typography>
                    <Typography variant="h1" sx={{ ...lineClamp(2) }} py={1} fontWeight={600} fontSize={['1.2rem', 'undefined', '2.125rem']}>{name}</Typography>
                    {
                        joined && added ?
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Box sx={{ width: '100%', mr: 1 }}>
                                        <BorderLinearProgress variant="determinate" value={progress} />
                                    </Box>
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
                                    </Box>
                                </Box>
                                <Typography>Overall Progress</Typography>
                            </Box>

                            :
                            <Box>
                                <Typography variant="body1" pb={1}><DateRangeInWeeks from={startsAt!} to={endsAt!} />  •  {`${numUnits} Units`}</Typography>
                                <Stack flexDirection='row' alignItems='center'>
                                    <Typography variant="body1" mr={1}>Starts: </Typography>
                                    <Chip icon={<TodayIcon />} label={formatDate(startsAt)} />
                                </Stack>
                            </Box>
                    }
                </Stack>
                <Divider orientation={isMobile ? 'horizontal' : "vertical"} flexItem sx={{ mx: [0, 3], my: [3, 0] }} />

                {
                    btnHint ?
                        btnHint
                        :
                        currentUnit ?
                            <Stack flexDirection='column'>
                                <Typography>On Hand</Typography>
                                <TOCListItem
                                    key={id}
                                    sx={{ width: 200 }}
                                    primary={<Stack direction="column" alignItems="left"><Typography sx={{ flexGrow: 1, fontWeight: 600 }}>{currentUnit?.name}</Typography></Stack>}
                                    opener={<BookmarkBorderIcon />}
                                    onClick={() => { navigate(`/programs/${id}/units/${id}`) }}
                                    secondary={<Stack>
                                        <Typography variant="caption">{currentUnitProgress > 0 && `${Math.round(currentUnitProgress)}% done`}</Typography>
                                    </Stack>
                                    }
                                />
                            </Stack>
                            :
                            <TOCListItem
                                key={id}
                                sx={{ width: 200 }}
                                primary={<Stack direction="column" alignItems="left"><Typography sx={{ flexGrow: 1, fontWeight: 400 }}>{`Program starts on  ${formatDate(startsAt)}`}</Typography></Stack>}
                                opener={<CalendarMonthOutlinedIcon />}
                            />
                    // <Typography display='flex' alignItems='center' variant="body1" sx={{width:200}}> {`Program starts on  ${formatDate(startsAt)}`}</Typography>
                }
            </CardContent>
        </Card>
    );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#394E69' : '#308fe8',
    },
}));
import { snake2camel } from "@halliday/rest";
import { Close, ExpandMore } from "@mui/icons-material";
import { ButtonBase, ButtonBaseProps, Slide, SxProps, Toolbar, Typography, useMediaQuery, useScrollTrigger, useTheme, Grid } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box, { BoxProps } from "@mui/material/Box";
import { createContext, forwardRef, Ref, useContext, useEffect, useMemo, useState } from "react";
import { appName } from "../config";
import AppBar from "./AppBar";
import Drawer, { denseDrawerWidth, drawerWidth } from "./Drawer";
import { joinSx, lineClamp } from "./tools";
import BreadCrumbs from "./common/BreadCrumbs";

export interface PageProps {
  title?: string,
  breadcrumbs?: React.ReactNode,
  actions?: React.ReactNode,
  toc?: React.ReactNode,
  tocPrimary?: React.ReactNode,
  tocSecondary?: React.ReactNode,
  tocOpener?: React.ReactNode,
  onNavigateBack?: React.MouseEventHandler
  prevPage?: string,
  docName?: string,
  scrollToHeading?: string,
  org?: string,
  activeUnit?: string,
  activeDoc?: string,
  progName?: string,
  shortTOC?: boolean, // boolean for indicating if the toc is short or long so that the width of the toc can be adjusted
}

export const navigationWidth = '17%';

class PageContextValue {
  constructor(readonly setProps: React.Dispatch<React.SetStateAction<PageProps>>) { }
  setTitle(title: string) {
    this.setProps(props => ({ ...props, title }));
  }
  setBradcrumbs(breadcrumbs: React.ReactNode | null) {
    this.setProps(props => ({ ...props, breadcrumbs }));
  }
  setActions(actions: React.ReactNode | null) {
    this.setProps(props => ({ ...props, actions }));
  }
  setTOC(opener: React.ReactNode, primary: string, secondary: string, toc: React.ReactNode | null) {
    this.setProps(props => ({ ...props, tocOpener: opener, tocPrimary: primary, tocSecondary: secondary, toc }));
  }
  setNavigateBack(onNavigateBack: React.MouseEventHandler | undefined) {
    this.setProps(props => ({ ...props, onNavigateBack }));
  }
}

const PageContext = createContext<PageContextValue | null>(null);

interface PageParentProps {
  children: React.ReactNode
}

export interface PageComponentProps extends PageProps {
  children: React.ReactNode,
}

export function usePage() {
  return useContext(PageContext)!;
}

export default function Page(props: PageComponentProps): JSX.Element {
  const page = useContext(PageContext)!;
  window.document.title = props.title + " - " + appName;
  useEffect(() => {
    page.setProps(props);
  }, [props.title, props.actions, props.breadcrumbs, props.onNavigateBack, props.toc, props.tocOpener, props.tocPrimary, props.tocSecondary, props.onNavigateBack, page, props, props.activeUnit, props.activeDoc]);
  return <>{props.children}</>;
}

export function PageParent(props: PageParentProps) {

  const [{ title, actions, breadcrumbs, toc, tocOpener, tocPrimary, tocSecondary, onNavigateBack, prevPage, docName, scrollToHeading, org, activeDoc, activeUnit, progName, shortTOC }, setProps] = useState<PageProps>({ title: "" });
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));


  const denseDrawer = (!xl && (lg && !!toc)) || !lg;

  const handleDrawerToggle = () => {
    setDrawerOpen((open) => !open);
  };

  const [ref, setRef] = useState<Node | undefined>();
  const scrolled = useScrollTrigger({ target: ref }); // , threshold: 130

  const [navOpen, setNavOpen] = useState(false);
  function handleAppBarClick() {
    setNavOpen(open => !open);
  }

  function handleLoginClick() {
    window.openLogin();
  }

  const appBar = <AppBar
    drawerOpen={drawerOpen}
    onDrawerToggle={handleDrawerToggle}
    title={title ? title : ""}
    actions={sm ? actions : null}
    onNavigateBack={onNavigateBack}
    onLoginClick={handleLoginClick}
    prevPage={prevPage}
    docName={docName}
    scrollToHeading={scrollToHeading}
    org={org}
    pl={toc && sm ? "112px" : undefined}
  />;

  let content = <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, marginTop: sm ? undefined : (toc ? "56px" : "0px"), height: (toc && sm) ? undefined : "100%" }}>
    {
      sm ? appBar : (
        <Slide appear={false} direction="down" in={!scrolled}>
          {appBar}
        </Slide>

      )
    }
    {
      toc && !sm ? (
        <Slide appear={false} direction="down" in={!scrolled}>
          <MuiAppBar position="fixed" sx={{ top: "56px", zIndex: theme.zIndex.appBar - 1 }} color="inherit" onClick={handleAppBarClick}>
            <Toolbar sx={{ gap: 2 }}>
              <Typography variant="body2" component="div" sx={{ color: "text.primary" }}>{tocOpener}</Typography>
              <Box sx={{ flexGrow: 1, whiteSpace: "nowrap", width: 0 }}>
                <Typography variant="body1" component="div" sx={{ overflow: "hidden", textOverflow: "ellipsis", color: "text.primary" }}>{tocPrimary}</Typography>
                <Typography variant="body2" component="div" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{tocSecondary}</Typography>
              </Box>
              {navOpen ? <Close sx={{ ml: 2 }} /> : <ExpandMore sx={{ ml: 2 }} />}
            </Toolbar>
          </MuiAppBar>
        </Slide>
      ) : null
    }
    {
      toc && !sm ? (
        <Slide appear={false} direction="down" in={navOpen}>
          <Box sx={{ position: "fixed", top: 112, left: 0, right: 0, bottom: 0, backgroundColor: "background.paper", zIndex: theme.zIndex.appBar - 2, overflowY: "auto" }} onClick={() => setNavOpen(false)}>
            {toc}
          </Box>
        </Slide>
      ) : null
    }

    {!sm ? <BreadCrumbs name={title ? title : ""} activeUnit={activeUnit} activeDoc={activeDoc} progName={progName} /> : null}
    <PageContext.Provider value={useMemo(() => new PageContextValue(setProps), [])}>
      {props.children}
    </PageContext.Provider>
    {sm ? null : actions}
  </Box>

  return (
    // <Box sx={{ marginLeft: { sm: (denseDrawer ? denseDrawerWidth : drawerWidth) + "px" }, height: "100%", overflow: "hidden auto", display: "flex", flexDirection: "column" }} ref={setRef} className="LabPage">
    <Box sx={{ marginLeft: { sm: (denseDrawer ? denseDrawerWidth : drawerWidth) + "px" }, height: "100vh", display: "flex", flexDirection: "column" }} ref={setRef} className="LabPage">
      <Drawer open={drawerOpen} onToggle={handleDrawerToggle} dense={denseDrawer} key="drawer" />
      { sm && <BreadCrumbs name={title ? title : ""} activeUnit={activeUnit} activeDoc={activeDoc} progName={progName} /> }
      {toc && sm ?
       <>
        <Grid container spacing={0} sx={{ height: "100%", overflowY: "auto" }}>
          {/* <Grid item xs={2} sx={{ height: "100%", overflowY: "auto", maxWidth: "20%"}}> */}
          {/* <Grid item xs={md ? 2 : 3} sx={{ height: "100%", overflowY: "auto", maxWidth: md ? "20%" : "30%"}}> */}
          <Grid item xs={shortTOC && xl ? 1 : md ? 2 : 3} sx={{ height: "100%", overflowY: "auto", maxWidth: shortTOC && xl ? "100%" : md ? "20%" : "30%" }}>
            {toc}
          </Grid>
          {/* <Grid item xs={10} sx={{ height: "100%", overflowY: "auto", maxWidth: "80%"}}> */}
          {/* <Grid item xs={md ? 10 : 9} sx={{ height: "100%", overflowY: "auto", maxWidth: md ? "80%" : "70%"}}> */}
          <Grid item xs={shortTOC && xl ? 11 : md ? 10 : 9 } sx={{ height: "100%", overflowY: "auto", maxWidth: shortTOC && xl ? "90%" : md ? "80%" : "70%"}}>
            {content}
          </Grid>
        </Grid>  </> : content
      }
    </Box>
  );
}

export function useMediaQueryMobile() {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up('sm'))
}

export interface PageContentProps extends BoxProps {
  containerProps?: BoxProps,
  title?: string,
  activeUnit?: string,
  activeDoc?: string,
  disableBreadcrumbs?: boolean,
  fullWidth?: boolean,
}

export const PageContent = forwardRef((props: PageContentProps, ref: Ref<HTMLElement>) => {
  const { containerProps, sx, title, activeUnit, activeDoc, disableBreadcrumbs, fullWidth, ...boxProps } = props;
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} {...containerProps} className="LabPageContainer" ref={ref}>
      <Box sx={joinSx({ pl: [3, 6, null, null, 8], pr: [3, 4, null, null, 12], pt: [0, 4], pb: 8, display: "flex", flexDirection: "column", maxWidth: fullWidth ? "auto" : 1200, flexGrow: 1 }, sx)} className="LabPageContent" {...boxProps}>
        {/* !disableBreadcrumbs &&  <BreadCrumbs name={title || ""} activeUnit={activeUnit} activeDoc={activeDoc} /> */}
        {props.children}
      </Box>
    </Box>
  );
})


export function pageParams(): Record<string, string> {
  const search = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};
  for (const [key, value] of Array.from(search.entries()))
    params[snake2camel(key)] = value;
  return params;
}

export interface NavigationHeaderProps {
  children: React.ReactNode,
}

export function TOCHeader(props: NavigationHeaderProps) {
  const { children } = props;
  return <Box sx={{ height: "64px", boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "center", px: 2 }}>{children}</Box>
}

export interface TOCProps {
  children: React.ReactNode,
  sx?: SxProps
}

export function TOC(props: TOCProps) {
  const { children, sx } = props;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  return <Box
    sx={joinSx({
      marginTop: [0,],
      paddingTop: "10px",
      // width: sm ? navigationWidth : undefined,
      // minWidth: sm ? navigationWidth : undefined,
      display: "flex",
      flexDirection: "column",
      borderRight: sm ? "1px solid" : undefined,
      // style the scroll bar to reduce it's size
      '&::-webkit-scrollbar': {
        width: "0.3em",
      },
      borderRightColor: sm ? (theme) => theme.palette.divider : undefined
    }, sx)}
    children={children}
  />
}

export interface TOCListProps {
  children: React.ReactNode,
}

export function TOCList(props: TOCListProps) {
  const { children } = props;
  return <Box sx={{ p: 1, display: "flex", flexDirection: "column", flexGrow: 1, gap: 1, }}>{children}</Box>;
}

export interface TOCListItem extends ButtonBaseProps {
  primary?: React.ReactNode,
  secondary?: React.ReactNode,
  footer?: React.ReactNode,
  opener?: React.ReactNode,
  active?: boolean,
}

export function TOCListItem(props: TOCListItem) {
  const { children, opener, primary, secondary, footer, active, sx, ...buttonBaseProps } = props;
  return (
    <div>
      <ButtonBase {...buttonBaseProps} sx={joinSx({ display: "flex", width: '-webkit-fill-available', p: 1, gap: 2, alignItems: "unset", textAlign: "start", borderRadius: 1, backgroundColor: active ? "grey.100" : undefined }, sx)} >
        <Box sx={{ color: active ? "primary.light" : undefined }} >{opener}</Box>
        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column", }}>
          <Typography sx={{ wordWrap: 'break-word', fontSize: ".95rem", color: active ? "primary.light" : undefined, ...lineClamp(2) }}>{primary}</Typography>
          <Typography sx={{ color: "text.secondary", ...lineClamp(2) }}>{secondary}</Typography>
          <Typography sx={{ color: "text.disabled", textAlign: "end", fontSize: "0.875rem" }}>{footer}</Typography>
        </Box>
      </ButtonBase>
      {children}
    </div>
  )
}

export function TOCListSubItem(props: TOCListItem) {
  const { opener, primary, secondary, footer, active, ...buttonBaseProps } = props;
  return (
    <ButtonBase sx={{ display: "flex", width: '-webkit-fill-available', p: 0.7, pl: 3, gap: 2, alignItems: "unset", textAlign: "start", borderRadius: 1, backgroundColor: active ? "grey.100" : undefined }} {...buttonBaseProps}>
      <Box sx={{ color: active ? "primary.light" : undefined }} >{opener}</Box>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column", }}>
        <Typography sx={{ wordWrap: 'break-word', fontSize: ".95rem", color: active ? "primary.light" : undefined, ...lineClamp(2) }}>{primary}</Typography>
        <Typography sx={{ color: "text.secondary", ...lineClamp(2) }}>{secondary}</Typography>
        <Typography sx={{ color: "text.disabled", textAlign: "end", fontSize: "0.875rem" }}>{footer}</Typography>
      </Box>
    </ButtonBase>
  )
}

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField as MuiTextField, TextFieldProps } from "@mui/material";
import React from "react";

type PasswordFieldProps = TextFieldProps & {
    TextField?: React.ComponentType<TextFieldProps>,
};

export default function PasswordField(props: PasswordFieldProps) {
    const { TextField = MuiTextField, ...textFieldProps } = props;
    const [visible, setVisible] = React.useState(false);

    return <TextField {...props} InputProps={{
        type: visible ? "text" : "password",
        sx: { fontFamily: "Monospace", letterSpacing: "0.065em" },
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVisible(!visible)}
                    onMouseDown={preventDefault}
                >
                    {visible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        )
    }} />;
}

function preventDefault(ev: React.SyntheticEvent) {
    ev.preventDefault();
}
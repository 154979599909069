import { Box, Breadcrumbs, CircularProgress, Link, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Organization, getOrganization } from "../../api";
import { useSession } from "../../ident";
import { useForceUpdate } from "../../tools";
import ErrorView from "../ErrorView";
import Page, { PageContent } from "../Page";
import CoursesPage from "../orgs/CoursesPage";
import MembersPage from "../orgs/MembersPage";
import ResourcesPage from "../orgs/ResourcesPage";
import SettingsPage from "../orgs/SettingsPage";
import SolutionsPage from "../orgs/SolutionsPage";
import ProgramsPage from "../orgs/ProgramsPage";
import OverviewPage from "./OverviewPage";

// const Banner = styled("img")({
//     display: "block",
//     height: "15vw",
//     objectFit: "cover",
//     width: "100%",
// })

function OrganizationPage() {

    const { id } = useParams();
    const [org, setOrg] = useState<Organization | null>(null);
    const [err, setErr] = useState<any>(null);

    const navigate = useNavigate();

    const sess = useSession();
    useEffect(() => {
        if (!id) navigate("/orgs");
        else getOrganization({ id }).then(org => { setOrg(org); setErr(null) }, err => { setOrg(null); setErr(err) });
    }, [sess, id]);

    let title: string;
    let content: JSX.Element | null;
    if (err) {
        title = "Organization";
        content = <ErrorView err={err} />;
    } else if (org === null) {
        title = "";
        content = <CircularProgress />;
    } else {
        title = org.title;
        // content = <>
        //     <Box sx={{ mx: -3, mt: -3 }}>
        //         <Banner src={org.bannerUrl ? absoluteURL(org.bannerUrl) : "https://waziup.io/img/slide-show/sfondo2.png"} />
        //     </Box>
        // </>;
        content = <OrganizationPageContent org={org} key={id} />
    }

    return <Page
        // onNavigateBack={() => { navigate("/orgs"); }}
        title={title}

    // actions={org ? <MenuIcon org={org} onPatch={forceUpdate} /> : undefined}
    >
        <PageContent
            sx={{ maxWidth: "unset" }}
            disableBreadcrumbs >
            {content}
        </PageContent>
    </Page>
}

export default OrganizationPage;

//

interface OrganizationPageContentProps {
    org: Organization
}

function OrganizationPageContent(props: OrganizationPageContentProps) {
    const { org } = props;
    const navigate = useNavigate();

    const [tab, setTab] = useState(0);

    let content: JSX.Element;
    let currentTab: string;

    const sess = useSession();
    const isAdmin = org.roles.includes("org-admin") || org.roles.includes("admin") || sess?.user.roles.includes("admin") || false;

    if (!isAdmin) {
        return <OverviewPage org={org} />;
    }

    switch (tab) {
        case 0:
            content = <OverviewPage org={org} />;
            currentTab = "Courses"
            break;
        // case 0:
        //     content = <CoursesPage org={org} />;
        //     currentTab = "Courses"
        //     break;
        // case 1:
        //     content = <ResourcesPage org={org} />;
        //     currentTab = "Resources"
        //     break;
        // case 2:
        //     content = <SolutionsPage org={org} />;
        //     currentTab = "Solutions"
        //     break;
        case 1:
            content = <MembersPage org={org} />;
            currentTab = "Members"
            break;
        // case 4:
        //     content = <ProgramsPage org={org} />;
        //     currentTab = "Programs"
        //     break;
        case 2:
            content = <SettingsPage org={org} />;
            currentTab = "Settings"
            break;
    }

    return <>
        {/* <Box sx={{ display: "flex", mb: 2, gap: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
                <Link underline="hover" color="inherit" onClick={() => { navigate("/orgs"); }}>Organizations</Link>
                <Link underline="hover" color="inherit" onClick={() => { navigate(`/orgs/${org.id}`); }}>{org.title}</Link>
                <Typography color="text.primary">{currentTab!}</Typography>
            </Breadcrumbs>
            <Typography variant="body2">Last modified {org.modifiedAt.toLocaleString()}</Typography>
        </Box> */}
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
            <Tabs value={tab} onChange={(ev, idx) => setTab(idx)} aria-label="basic tabs example">
                <Tab label="Overview" id="overview" aria-controls="org-tab-overview" />
                {/* <Tab label="Courses" id="courses" aria-controls="org-tab-courses" /> */}
                {/* <Tab label="Resources" id="resources" aria-controls="org-tab-resources" /> */}
                {/* <Tab label="Solutions" id="solutions" aria-controls="org-tab-solutions" /> */}
                <Tab label="Members" id="members" aria-controls="org-tab-members" />
                {/* <Tab label="Programs" id="programs" aria-controls="org-tab-programs" /> */}
                <Tab label="Settings" id="settings" aria-controls="org-tab-settings" />
            </Tabs>
        </Box>
        <Box sx={{ mb: 8 }}>{content!}</Box>
    </>;
}

// interface MenuIconProps {
//     org: Organization,
//     onPatch: () => void,
// }

// function MenuIcon(props: MenuIconProps) {
//     const { org, onPatch } = props;

//     const api = useAPI();
//     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//     const open = anchorEl !== null;
//     const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     const navigate = useNavigate();

//     const handleDelete = async () => {
//         handleClose();
//         const ok = window.confirm("Do you wan to delete this organization? This action can not be undone!");
//         if (ok) {
//             await api.deleteOrganization({ id: org.id });
//             navigate("/orgs");
//         }
//     }

//     const handleChangeTitle = async () => {
//         handleClose();
//         const title = prompt("Insert the new title:", org.title);
//         if (title && title !== org.title) {
//             await api.patchOrganization({ id: org.id, title });
//             org.title = title;
//             onPatch();
//         }
//     }

//     //

//     const [bannerDlgOpen, setBannerDlgOpen] = useState(false);
//     const handleChangeBanner = () => setBannerDlgOpen(true);
//     const handleBannerDlgClose = () => setBannerDlgOpen(false);
//     const onBannerSelectionInput = async (sel: Set<File>) => {
//         const banner = firstOfSet(sel);
//         setBannerDlgOpen(false);
//         if (banner) {
//             await api.patchOrganization({ id: org.id, banner: banner.id });
//             org.banner = banner.id;
//             org.bannerUrl = `/files/${banner.id}/file${banner.ext}`;
//             onPatch();
//         }
//     }

//     //

//     const [coverDlgOpen, setCoverDlgOpen] = useState(false);
//     const handleChangeCover = () => setCoverDlgOpen(true);
//     const handleCoverDlgClose = () => setCoverDlgOpen(false);
//     const onCoverSelectionInput = async (sel: Set<File>) => {
//         const cover = firstOfSet(sel);
//         setCoverDlgOpen(false);
//         if (cover) {
//             await api.patchOrganization({ id: org.id, cover: cover.id });
//             org.cover = cover.id;
//             org.coverUrl = `/files/${cover.id}/file${cover.ext}`;
//             onPatch();
//         }
//     }

//     //

//     return <>
//         <IconButton
//             size="large"
//             aria-label="display more actions"
//             edge="end"
//             color="inherit"
//             id="org-menu-btn"
//             aria-controls={open ? 'org-menu' : undefined}
//             aria-haspopup="true"
//             aria-expanded={open ? 'true' : undefined}
//             onClick={handleClick}
//         >
//             <MoreVert />
//         </IconButton>
//         <Menu
//             id="org-menu"
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//             MenuListProps={{
//                 'aria-labelledby': 'basic-button',
//             }}
//         >
//             <MenuItem onClick={handleChangeTitle}>Change Title</MenuItem>
//             <MenuItem onClick={handleChangeCover}>Change Cover</MenuItem>
//             <MenuItem onClick={handleChangeBanner}>Change Banner</MenuItem>
//             <MenuItem onClick={handleDelete}>Delete</MenuItem>
//         </Menu>

//         <FileSelectionDialog open={bannerDlgOpen} onClose={handleBannerDlgClose} onSelectionInput={onBannerSelectionInput}/>
//         <FileSelectionDialog open={coverDlgOpen} onClose={handleCoverDlgClose} onSelectionInput={onCoverSelectionInput}/>
//     </>
// }
import { Box } from "@mui/system";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Typography,
    CardProps,
    CardMedia,
} from "@mui/material";
import { lineClamp } from "../../tools";

export interface CardResourceProps extends CardProps {
    quantity?: number,
    icon?: string,
    title?: string,
    subtitle?: string,
    desc?: string,
}
export const CardResource = (props: CardResourceProps) => {
    const { quantity, icon, title, subtitle } = props;
    return (
        <Card
            sx={{
                display: "flex",
                height: 50,
                minWidth: 224,
                mb: 1,
                mr: 1,
                p: 1,
            }}
            elevation={2}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1">{`${quantity}x`}</Typography>
                <Avatar alt="Remy Sharp" src={icon} sx={{ ml: 0.5, mr: 0.5 }} />
            </Box>

            <CardHeader
                title={title}
                subheader={subtitle}
                titleTypographyProps={{ fontSize: "1rem" }}
                sx={{ p: 0.5, flexGrow: 1 }}
            />
        </Card>
    )
}

export const CardResourceWithUnit = (props: CardResourceProps) => {
    const { quantity, icon, title, subtitle } = props;
    return (
        <Card sx={{ width: ["100%", 300], "&:hover": { " .btnMore": { visibility: "visible" } }, cursor: 'pointer', borderRadius:1 }} elevation={0}>
            <CardMedia sx={{ width: 300, height: 200 }} image={icon} title={title} />
            <CardContent sx={{ padding: 0, paddingTop:1 }}>
                <Typography gutterBottom variant="body1">{`${quantity} units -`} {title}</Typography>
            </CardContent>
        </Card>
    )
}

export const CardPrice = (props: CardResourceProps) => {
    const { quantity, title, desc } = props;
    return (
        <Card elevation={2}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h5" component="div">
                    $ {quantity}
                </Typography>

                <Typography variant="caption">{desc}</Typography>
            </CardContent>
        </Card>
    )
}

export const CardCourse = (props: CardResourceProps) => {
    const { title, subtitle, desc } = props;
    return (
        <Card sx={{ mr: 1, mb: 1 }} elevation={2}>
            <CardHeader
                title={title}
                subheader={subtitle}
                titleTypographyProps={{
                    fontSize: "1rem",
                    width: 200,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                xs={{ ...lineClamp(1) }}
            />
            <CardContent sx={{ pt: 0, pb: 2 }}>
                <Typography
                    sx={{ ...lineClamp(2), fontSize: ".9rem" }}
                    color="text.secondary"
                >
                    {desc}
                </Typography>
            </CardContent>
        </Card>
    )
}

export const CardSolutions = (props: CardResourceProps) => {
    const { title, desc, ...cardProps } = props;
    return (
        <Card elevation={2} {...cardProps}>
            <CardContent sx={{ textAlign: "center" }}>
                <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    {title}
                </Typography>

                <Typography variant="body1" sx={{ color: "text.secondary", ...lineClamp(2) }}>
                    {desc}
                </Typography>
            </CardContent>
        </Card>
    )
}

import {Alert as MuiAlert} from "@mui/material";
import { renderHTML } from "./tools";

export type Severity = "error" | "warning" | "info" | "success";

export interface AlertProps {
    severity?: Severity,
    children?: React.ReactNode
}

const defaultSeverity: Severity = "info";

export function Alert(props: AlertProps) {
    const {severity = defaultSeverity} = props;
    return <MuiAlert severity={severity} children={props.children} />
}

export function alertPropsFromElement(elm: Element): AlertProps {
    return {
        severity: elm.getAttribute("type") as Severity || defaultSeverity,
        children: renderHTML(elm.innerHTML)
    };
}
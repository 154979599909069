import React, { Fragment, memo } from "react";
import { apiUrl } from "../config";

type ElementRenderer = (elm: Element) => JSX.Element | null | undefined;

export interface HTMLRendererProps {
    html: string,
    renderElement?: ElementRenderer
}

const HTMLRenderer = memo(function HTMLRenderer(props: HTMLRendererProps): JSX.Element | null {
    const template = document.createElement("template");
    template.innerHTML = props.html;

    template.content.querySelectorAll<HTMLImageElement>("img[src]").forEach(mapImageSources);

    return <>{renderChildren(props, template.content)}</>;
});

export function imgSrcAbs(src: string) {
    return new URL(src, new URL(apiUrl, window.location.href).origin).toString();
}

function mapImageSources(img: HTMLImageElement) {
    img.src = imgSrcAbs(img.getAttribute("src")!);
}

export default HTMLRenderer;

export function renderNode(props: HTMLRendererProps, node: Node): JSX.Element | null {
    if (node instanceof Text) {
        return <Fragment key={noKey()}>{node.data}</Fragment>
    }
    if (node instanceof Element) {
        const elm = props.renderElement ? props.renderElement(node) : undefined;
        if (elm !== undefined) return <Fragment key={noKey()}>{elm}</Fragment>;
        return React.createElement(node.tagName.toLowerCase(), { key: noKey(), ...attr(node) }, renderChildren(props, node));
    }
    return null;
}


export function renderChildren(props: HTMLRendererProps, node: ParentNode): ((JSX.Element | null)[] | null) {
    const children = Array.from(node.childNodes).map(child => renderNode(props, child));
    return children.length === 0 ? null : children;
}

function attr(elm: Element): any {
    var attrs = Array.from(elm.attributes);
    return attrs
        .filter(safeAttr)
        .map(a => mapAttr(elm as HTMLElement, a))
        .reduce((v, a) => {
            if (a.name in v) v[a.name] += " " + a.value;
            else v[a.name] = a.value;
            return v;
        }, {
            className: `${elm.tagName.toLowerCase()}-primitive`,
            style: {
                width: elm.hasAttribute("data-width") ? elm.getAttribute("data-width") + "px" : undefined,
                marginLeft: elm.getAttribute("data-align") === "left" ? "0" : undefined,
                marginRight: elm.getAttribute("data-align") === "right" ? "0" : undefined
            }
        } as any);
}

const safeAttrs: Set<string> = new Set(["id", "src", "alt", "href", "data-width", "data-align"]);

function safeAttr(attr: Attr) {
    return safeAttrs.has(attr.name);
}

const safeStyles: Set<string> = new Set([
    "marginRight", "marginLeft", "marginTop", "marginBottom", "margin",
    "whiteSpace", "color", "userSelect",
    "padding", "paddingLeft", "paddingRight", "paddingTop", "paddingBottom"]);

const attrMap: Record<string, string> = { "class": "className" };

function mapAttr(elm: HTMLElement, attr: Attr): { name: string, value: any } {
    switch (attr.name) {
        case "style":
            const style: Record<string, string> = {};
            for (const s of safeStyles) {
                const v = elm.style[s as keyof CSSStyleDeclaration] as string;
                if (v) style[attrMap[s] ?? s] = v;
            }
            return {
                name: attr.name,
                value: style
            }
        default:
            return {
                name: attr.name,
                value: attr.value
            };
    }
}

let counter = 0;
function noKey(): number {
    return counter++;
}

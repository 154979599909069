import { useEffect } from "react";

export class Hub {
    readonly listeners: { [key: string]: Set<Function> } = {};

    on(event: string, callback: Function) {
        if (!this.listeners[event]) this.listeners[event] = new Set([callback]);
        else this.listeners[event].add(callback);
        return () => this.off(event, callback);
    }

    off(event: string, callback: Function) {
        const l = this.listeners[event];
        if (l) l.delete(callback);
    }

    emit(event: string, ...args: any[]) {
        const l = this.listeners[event];
        if (l) l.forEach((c) => c(...args));
    }
}

export function useHub(key: string, cb: Function, deps?: React.DependencyList) {
    useEffect(() => hub.on(key, cb), deps);
}


export const hub = new Hub();
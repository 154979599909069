import { Box, Card, CardContent, Chip, CircularProgress, LinearProgress, LinearProgressProps, Stack, Step, StepConnector, StepLabel, Stepper, linearProgressClasses, stepConnectorClasses, styled, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { StepIconProps } from '@mui/material/StepIcon';
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams, } from "react-router-dom";
import { FindProgramResponse, Program, findProgram, unitProgress } from "../../api";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSession } from "../../ident";
import ErrorView from "../ErrorView";
import { Check } from "@mui/icons-material";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#394E69',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#394E69',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));



function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} sx={{ mt: 0 }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="h6" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#394E69' : '#394E69',
    },
}));

interface ProgramProgressProps {
    programId: Program["id"],
}

export default function ProgressPage(props: ProgramProgressProps) {
    const { programId } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const sess = useSession();
    const navigate = useNavigate();
    const { id: progID } = useParams();

    const [page, setPage] = useState<FindProgramResponse | null>(null);
    const [err, setErr] = useState<any>(null);

    useEffect(() => {
        if (!progID) {
            navigate("/my-programs");
            return;
        }
        setPage(null);
        setErr(null);
        findProgram({ id: progID }).then((program) => {
            setPage(program)
        }, setErr);
    }, [sess, progID]);




    const currentDate: Date = new Date()
    let activeStep: number = 0;
    let content: ReactElement | null;

    if (err) content = <ErrorView err={err} />;
    else if (page === null) content = <CircularProgress />;
    else {
        const { program } = page
        const { units } = page.program;

        // If activeStep is -1, it means no unit's date range includes the current date
        // If currentDate is greater than the "to" date of the last unit, assign the last index
        if (activeStep === -1 || currentDate > units![units!.length - 1].to) {
            activeStep = units!.length - 1;
        }
        // If currentDate is less than the "from" date of the first unit, assign the 0 index
        else if (currentDate < units![0].from) {
            activeStep = 0;
        }
        else activeStep = units!.findIndex((unit) => (currentDate >= unit.from && currentDate <= unit.to))

        const totalTopics = units?.reduce((acc, unit) => acc + unit.numTopics, 0) || 1;
        const totalTopicsDone = units?.reduce((acc, unit) => acc + unit.numTopicsDone, 0) || 0;

        content = <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection={['column', 'row']} justifyContent='start' gap={[2]}>
                {/* <Box width={[null, 500, 300]}><img src={program.cover?.url} width="100%" /></Box> */}
                <Box width='100%'  display='flex' flexDirection='column' gap={1} >
                    <Typography variant="h1" sx={{ fontWeight: 600 }}>{program.name}</Typography>
                    {isMobile ? null : <Typography variant="body1" mt={1}>{program.desc}</Typography>}
                    <BorderLinearProgress variant="determinate" sx={{ mt: 2 }} value={(totalTopicsDone / totalTopics) * 100} />
                    <Typography variant="caption" >Total Progress</Typography>
                </Box>
            </Box>

            {/* timeline progress */}
            <Stack sx={{ marginY: [6, 10], }} spacing={4} overflow=' hidden'>
                <Stepper sx={{}} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {units?.map((unit) => {
                        const progress = unitProgress(unit);
                        return (<Step key={unit.id} sx={{}}>
                            <StepLabel>
                                <Typography variant="body1">{progress > 0 && `${Math.round(progress * 100)}% done`}</Typography>
                                <Typography variant='caption'> {unit.name} </Typography>
                            </StepLabel>
                        </Step>
                        )
                    })}
                </Stepper>
                {/* <Stepper activeStep={3} >
                    {units?.map((unit) => (
                        <Step key={unit.id}>
                            <StepLabel sx={{ textAlign: 'left', fontSize: '12px' }}>{unit.name}</StepLabel>
                        </Step>
                    ))}
                </Stepper> */}
            </Stack>

            <Typography variant="h6">Your Activities</Typography>
            <Box sx={{ display: "flex", flexDirection: 'column', gap: 2, pt: 2 }}>
                {
                    units?.map((unit, index) =>
                        <Card
                            sx={{
                                width: '100%',
                                p: 2,
                                border: ' .5px solid #efefef',
                                "&:hover .actions": { visibility: "visible" },
                                cursor: 'pointer',
                            }}
                            elevation={1}
                            onClick={() => { navigate(`/programs/${progID}/units/${unit.id}`) }}
                        >
                            <CardContent sx={{ display: 'flex', flexDirection: ['column', 'row'], gap: [1, 0], p: 2, alignItems: ['start', 'center'] }}>
                                <Typography variant="body1" flexGrow={1} justifyContent='flex-start' fontWeight={[600, 400]}>Unit {index + 1}: {unit.name}</Typography>
                                <Box width={['100%', 250]} order={[2, 1]}>
                                    <Box px={[0, 5]}>
                                        {currentDate < unit.from ?
                                            <Chip label="NOT STARTED" />
                                            : (currentDate >= unit.from && currentDate <= unit.to) ?
                                                <Chip label="IN PROGRESS" color="primary" />
                                                : (unit.numTopicsDone === unit.numTopics ?
                                                    <Chip label="COMPLETED" color="success" />
                                                    : <Chip label="COMPLETED" color="success" variant="outlined" />)
                                        }
                                    </Box>
                                </Box>
                                <Typography variant="body1" width={['100%', 200]} order={[1, 2]}>{unit.numTopics > 0 ? `${unit.numTopicsDone} of ${unit.numTopics}  topics completed` : "Online Session"}</Typography>
                                {
                                    isMobile ? null : <Box order={3}><ChevronRightIcon className="actions" sx={{ "@media (hover: hover)": { visibility: "hidden" } }} /></Box>
                                }
                            </CardContent>
                        </Card>
                    )
                }
            </Box>
        </Box>
    }

    const scrollToStep = (targetStepIndex: number) => {
        // Find the Stepper component
        const stepper = document.querySelector(".MuiStepper-root") as HTMLElement | null;
        if (!stepper) return;

        // Find the target step element within the Stepper
        const targetStep = stepper.querySelectorAll(".MuiStep-root")[targetStepIndex] as HTMLElement;

        // Scroll the Stepper to make the target step visible
        if (targetStep) {
            stepper.scrollLeft = targetStep.offsetLeft;
        }
    };

    useEffect(() => scrollToStep(activeStep))


    return <Box>
        {content}
    </Box>
}
import { DescriptionOutlined, TopicOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Resource, ResourceDocument, getResource, findResourceDoc } from "../../api";
import ErrorView from "../ErrorView";
import Page, { PageContent, TOC, TOCList, TOCListItem, TOCListSubItem } from "../Page";
import { renderHTML } from "../tools";
import { useSession } from "../../ident";
import React from "react";
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ResourceOverview from "./ResourceOverview";
import { useNavigate, useLocation } from "react-router-dom";
import LabelIcon from '@mui/icons-material/Label';
import ArticleIcon from '@mui/icons-material/Article';

export interface ResourceDocuments {
  docs: Resource;
}

type ElementRenderer = (elm: Element) => JSX.Element | null | undefined;
interface HTMLRendererProps {
  html: string;
  renderElement?: ElementRenderer;
}

export const ResourceGuideIcon = DescriptionOutlined;
export const ResourceSpecIcon = TopicOutlined;



function ResourcePage() {

  const sess = useSession();
  const navigate = useNavigate();
  const pageContentRef = useRef<HTMLDivElement>(null);

  const params = useParams() as { id: string } | { org: string; short: string };
  const [err, setErr] = useState<any>(null);

  const id = "id" in params ? params.id : params.org + "/" + params.short;
  const docId = "docId" in params ? params.docId : null;

  const [resource, setResource] = useState<Resource | null>(null);
  const [activeHeading, setActiveHeading] = useState("");
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [selectedDoc, setSelectedDoc] = useState<ResourceDocument | null>(null);
  const [overviewPage, setOverviewPage] = useState<boolean>(true);

  let defaultContent: JSX.Element | undefined;

  useEffect(() => {
    if (id && !docId) {
      getResource({ id }).then((resource) => {
        setResource({
          ...resource,
          docs: resource.docs.sort((a, b) => a.rank - b.rank),
        });
        setErr(null);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  
        setOverviewPage(true);
  
      }, setErr);
    }

    if (docId) {
     findResourceDoc( docId.toString() ).then((doc: ResourceDocument) => {
        setSelectedDoc(doc);
        setOverviewPage(false);
        setErr(null);
      } , setErr);

      getResource({ id }).then((resource) => {
        setResource({
          ...resource,
          docs: resource.docs.sort((a, b) => a.rank - b.rank),
        });
        setErr(null);
      }, setErr);
    }

  }, [sess, id, docId, defaultContent]);

  if (err) defaultContent = <ErrorView err={err} />;
  if (resource) { defaultContent = (<ResourceOverview resource={resource} />); }

  // get anchor element from the url using the location and scroll to the element
  const location = useLocation();
  const anchor = location.hash.substring(1);

  useEffect(() => {
    const shouldScroll = anchor && (selectedDoc?.content || (overviewPage && resource?.content));
    
    if (shouldScroll) {
      const headingElement = document.getElementById(anchor);
      const scrollingDiv = pageContentRef.current;
  
      if (headingElement && scrollingDiv) {
        const scrollOptions: ScrollIntoViewOptions = {
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        };
  
        headingElement.scrollIntoView(scrollOptions);
        setActiveHeading(anchor);
      }
    }
  }, [anchor, selectedDoc, overviewPage, resource]);
    

  // Function to handle scrolling and set the active heading
  useEffect(() => {
    const handleScroll = (e: any) => {
      const scrollingDiv = e.target;
  
      // Function to get all headers from the documents
      function getAllHeaders(): { level: string; text: string; id: string }[] {
        const allHeaders: { level: string; text: string; id: string }[] = [];

        // get headers from active document and push to allHeaders
        const headers = GetHeadersWithIDs({ html: selectedDoc?.content ?? '' });
        if (headers) {
          allHeaders.push(...headers);
        }

        // if resource.doc is empty get headers from resource.content
        if (resource?.docs.length === 0) {
          const headers = GetHeadersWithIDs({ html: resource?.content ?? '' });
          if (headers) {
            allHeaders.push(...headers);
          }
        }

        // if overviewPage is true get headers from resource.content
        if (overviewPage) {
          const headers = GetHeadersWithIDs({ html: resource?.content ?? '' });
          if (headers) {
            allHeaders.push(...headers);
          }
        }

        return allHeaders;
      }

      if ((scrollingDiv && selectedDoc && selectedDoc.content) || (overviewPage && resource?.content)) {
        const currentScrollTop = scrollingDiv.scrollTop;
  
        const allHeaderIds = getAllHeaders().map((header) => header.id);
  
        let closestHeaderId = "";
        let closestHeaderDistance = Number.MAX_SAFE_INTEGER;
  
        allHeaderIds.forEach((headerId) => {
          const headerElement = document.getElementById(headerId);
          if (headerElement) {
            const distance = Math.abs(headerElement.offsetTop - currentScrollTop);
            if (distance < closestHeaderDistance) {
              closestHeaderId = headerId;
              closestHeaderDistance = distance;
            }
          }
        });
  
        setActiveHeading(closestHeaderId);
      }
    };
  
    const div = pageContentRef.current;
    div?.addEventListener("scroll", handleScroll);
  
    return () => {
      div?.removeEventListener("scroll", handleScroll);
    };
  }, [resource, selectedDoc, overviewPage]);

  // Render the content of the selected document
  useMemo(() => {
    if (selectedDoc) {
      setContent(
        <Box sx={{ flexGrow: 1 }} className="box-with-html">
          <Typography variant="h4">{selectedDoc?.title}</Typography>
          <br />
          {renderHTML(selectedDoc?.content || "")}
        </Box>
      );
    }
  }, [selectedDoc]);

  // Function to Extract headings with IDs from the selected document
  function GetHeadersWithIDs( props: HTMLRendererProps ): { level: string; text: string; id: string }[] | null {
    const template = document.createElement("template");
    template.innerHTML = props.html;

    // Query for H1 elements and extract headings with IDs
    const headingsWithIDs = Array.from(
      template.content.querySelectorAll("h1[id]")
    ).map((heading) => ({
      level: heading.tagName.toLowerCase(),
      text: heading.textContent || "",
      id: heading.id,
    }));

    return headingsWithIDs;
  }

  var toc = (
    <TOC>
      <TOCList>
        <Typography variant="caption" color='text.secondary'>Overview</Typography>
        <TOCListItem
          key={0}
          active={overviewPage}
          primary={resource?.title}
          opener={overviewPage ? (<ArticleIcon />) : (<ArticleOutlinedIcon />)}
          onClick={() => {
            setOverviewPage(true);
            setContent( defaultContent || null )
            navigate(`/resources/${id}`);
          }}
        >
          {GetHeadersWithIDs({ html: resource?.content ?? '' })?.map((heading, subIndex) => (
            <TOCListSubItem
              key={subIndex}
              active={activeHeading === heading.id && overviewPage}
              primary={heading.text}
              opener={activeHeading === heading.id && overviewPage ? (<LabelIcon />) : (<LabelOutlinedIcon />)}
              onClick={() => {
                setOverviewPage(true);
                setContent( defaultContent || null )
                navigate(`/resources/${id}#${heading.id}`);
              }}
            />
          ))}
        </TOCListItem>
        {resource?.docs.length !== 0 ? <Typography variant="caption" color='text.secondary'>Documentation</Typography> : null}
        {resource?.docs.sort(d => d.rank).map((doc, index) => (
          <TOCListItem
            key={index}
            active={selectedDoc?.id === doc.id && !overviewPage}
            primary={doc.title}
            opener={selectedDoc?.id === doc.id && !overviewPage ? (<ArticleIcon />) : (<ArticleOutlinedIcon />)}
            onClick={() => {
              setOverviewPage(false);
              navigate(`/resources/${id}/docs/${doc.id}`);
            }}
          >
            {GetHeadersWithIDs({ html: doc.content })?.map((heading, subIndex) => (
              <TOCListSubItem
                key={subIndex}
                active={activeHeading === heading.id && selectedDoc?.id === doc?.id && !overviewPage}
                primary={heading.text}
                opener={activeHeading === heading.id && selectedDoc?.id === doc?.id && !overviewPage ? (<LabelIcon />) : (<LabelOutlinedIcon />)}
                onClick={() => {
                  setOverviewPage(false);
                  navigate(`/resources/${id}/docs/${doc.id}#${heading.id}`);
                }}
              />
            ))}
          </TOCListItem>
        ))}
      </TOCList>
    </TOC>
  );

  return (
    <Page
      title={resource ? resource?.title : "Resource"}
      tocPrimary={selectedDoc?.title || "Resource"}
      toc={toc}
      org={resource?.orgName}
      activeDoc={selectedDoc?.title}
    >
      <PageContent ref={pageContentRef} id="page-content" title={resource?.title} activeDoc={selectedDoc?.title} >
        {content || defaultContent}
      </PageContent>
    </Page>
  );
}

export default ResourcePage;

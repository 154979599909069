import { ArrowForward, LocalLibraryOutlined, LoginOutlined, PersonOutlined } from "@mui/icons-material";
import { Box, Button, FormControl, IconButton, InputBase, OutlinedInput, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getResources, Resource, ResourceDocument, ResourcesResponse } from "../../api";
import CustomAccordion from "../CustomAccordion";
import Page from "../Page";
import { PageContent } from "../Page";
import ABBCard from "./ABBCard";
import QuickManualCard from "./QuickManualCard";
import emptyLabImage from "../../assets/images/empty-bucket.svg";
import { ResourceGuideIcon, ResourceSpecIcon } from "./ResourcePage";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SearchIcon from '@mui/icons-material/Search';
import { useSession } from "../../ident";
import CoverWaziupSolutionBox from '../boxes/waziup-solution-box.png'
import CoverApplicationBusinessBox from '../boxes/application-business-box.png'
import CoverTrainingKitBox from '../boxes/trainig-kit-box.png'
import { weakId } from "../../tools";
import SolutionBoxCard from "../boxes/SolutionBoxCard";
import { ApplicationBusinessBoxCard } from "../boxes/ApplicationBusinessBox";
import { TrainingKitBoxCard } from "../boxes/TrainingKitBox";

export default function MyLabPage() {
  const navigate = useNavigate();
  const [resources, setResources] = useState<ResourcesResponse | null>(null);
  const [err, setErr] = useState<any>(null);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const sess = useSession();
  const hasSession = sess !== null;
  useEffect(() => {
    const hasSession = sess !== null;
    if (hasSession) {
      getResources({ myLab: true }).then((resource) => {
        setResources(resource);
        setErr(null);
      }, setErr);
    } else {
      setErr(null);
      setResources(null);
    }
  }, [sess]);

  const btnCatalog = (
    <Button
      variant="contained" disableElevation
      startIcon={<LocalLibraryOutlined />}
      sx={{ mr: 2, color: "text.primary", bgcolor: "grey.200", ":hover": { bgcolor: "grey.300" } }}
      onClick={() => navigate("/resources")}
    >
      Resource Catalog
    </Button>
  );

  const btnCatalogFloating = (
    <Button
      variant="contained"
      size="large"
      startIcon={<LocalLibraryOutlined />}
      sx={{ position: "fixed", left: "50%", transform: "translateX(-50%)", bottom: 8, color: "text.primary", bgcolor: "grey.200", ":hover": { bgcolor: "grey.300" }, whiteSpace: "nowrap", boxShadow: 6 }}
      onClick={() => navigate("/resources")}
    >
      Resource Catalog
    </Button>
  )

  const emptyLab = !hasSession || resources?.resources.length === 0;

  const resourceTable = (
    emptyLab ?
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 4, bgcolor: "#f5f5f5" }}>
        <img src={emptyLabImage} alt="Empty Lab" width="60%" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          No resources in your lab.
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {hasSession ?
            "Add resources to your lab by clicking the button below." :
            "Use the lab to manage your resources and to see solutions using the hardware you have."}
        </Typography>
        <Typography variant="body2" sx={{ mt: 4 }}>
          {hasSession ? <>
            <Button variant="contained" disableElevation startIcon={<LocalLibraryOutlined />} sx={{ mx: 2, mb: 1, color: "text.primary", bgcolor: "grey.300", ":hover": { bgcolor: "grey.400" } }} onClick={() => navigate("/resources")}>
              Resource Catalog
            </Button>
            {/* <Button variant="contained" disableElevation startIcon={<WorkspacesOutlined />} sx={{ bgcolor: "#48a5ff", color: "white", ":hover": { bgcolor: "#3e91e1" }, verticalAlign: "top" }} onClick={() => navigate("/resource/catalog")} >
                  Setup your Lab
                </Button> */}
          </> : <>
            {/* <Button variant="contained" disableElevation startIcon={<PersonOutlined />} sx={{ mx: 2, mb: 1, color: "text.primary", bgcolor: "grey.300", ":hover": { bgcolor: "grey.400" } }} onClick={window.openRegistration}>
              Register
            </Button> */}
            {btnCatalog}
            <Button variant="contained" disableElevation startIcon={<LoginOutlined />} sx={{ bgcolor: "#48a5ff", color: "white", ":hover": { bgcolor: "#3e91e1" }, verticalAlign: "top" }} onClick={window.openLogin} >
              Login
            </Button>
          </>}
        </Typography>
      </Box>
      :
      <TableContainer
        component={Paper}
        sx={{
          // boxShadow: [0, 0, 6],
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth: ["unset", "unset", 760],
          flexGrow: 1,
          minHeight: "70%",
          mt: [0, 0, 1],
          mr: [0, 0, 1],
          mb: 4,
        }}
      >
        <Table stickyHeader aria-label="my lab resources">
          <TableHead>
            <StyledTableRow sx={{ p: 1 }}>
              {
                emptyLab ? <StyledTableCell></StyledTableCell>
                  :
                  <>
                    <StyledTableCell align="center" sx={{ fontSize: "1rem", minWidth: 60 }}>Units</StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontSize: "1rem", minWidth: 60, width: "100%" }} colSpan={2}>Name</StyledTableCell>
                    <StyledTableCell align="right" sx={{ minWidth: 210 }}></StyledTableCell>
                    {/*<StyledTableCell align="right" sx={{ minWidth: 210 }}>
                      <Stack flexDirection='row' alignItems='center' justifyContent='right'>
                        <IconButton type="button" sx={{ p: '10px' }}>
                          <SearchIcon />
                        </IconButton>
                        <IconButton type="button" sx={{ p: '10px' }}>
                          <BorderColorIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    </StyledTableCell>*/}
                  </>
              }
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {resources?.resources.map((res) => <ResourceTableRow key={res.id} resource={res} />)}
          </TableBody>
        </Table>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ p: 2, flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ ml: 2 }}>
              {resources?.numTotal} items
            </Typography>
          </Box>
          <Box sx={{ p: 2, display: ["none", "block"] }}>
            {btnCatalog}
          </Box>
        </Box>
      </TableContainer>
  );

  const accordionWithCards = (
    <Box sx={{ width: ["100%", "100%", 340, 340, undefined], display: "flex", flexDirection: "column", gap: 2 }}>
      {/* <CustomAccordion title="Quick Manuals">
        <QuickManualCard cardType="setup" /> */}
      {/* <QuickManualCard cardType="manual" /> */}
      {/* </CustomAccordion>
      <CustomAccordion title="Application Business Boxes">
        <ABBCard cardType="agribox" title="Agriculture Box" />
        <ABBCard cardType="citybox" title="City Box" />
      </CustomAccordion> */}
      <SolutionBoxCard cover={CoverWaziupSolutionBox} name="MVP DIY Kit" itemCount={29} url="/businessbox/mvp-diy-kit"  />
      <SolutionBoxCard cover={CoverTrainingKitBox} name="Training Kit Box" itemCount={29} url="/businessbox/training-kit"  />
      <SolutionBoxCard cover={CoverApplicationBusinessBox} name="Application Business Box" itemCount={29} url="/businessbox/application-business-box" textColor="#ffffff" />
    </Box>
  );

  return (
    <Page title="My Lab" actions={!sm && btnCatalogFloating}>
      <PageContent fullWidth>
        <Box sx={{ flexGrow: 1, pl: 1, display: ["block", "block", "flex"], flexDirection: ["column", null, "row"], gap: [2, 4, 6], height: "100%", boxSizing: "border-box" }}>
          {resourceTable}
          {/* <Box sx={{ minWidth: "fit-content", pr: [0, 3], pb: 7 }}>
            {accordionWithCards}
          </Box> */}
        </Box>
      </PageContent>
    </Page>
  );
}

interface ResourceTableRowProps {
  resource: Resource;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[100],
  },
}));

function ResourceTableRow(props: ResourceTableRowProps) {
  const { resource: res } = props;

  const navigate = useNavigate();

  // const [popupOpen, setPopupOpen] = useState(false);
  // const [popupPos, setPopupPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  // function showPopup() {
  //   setPopupOpen(true);
  // }

  // const [state] = useState<{
  //   popupTimeout: ReturnType<typeof setTimeout> | null,
  // }>({
  //   popupTimeout: null
  // });

  // function handleMouseEnter(ev: React.MouseEvent<HTMLElement>) {
  //   state.popupTimeout = setTimeout(showPopup, 1000);
  // }

  // function handleMouseLeave(ev: React.MouseEvent<HTMLElement>) {
  //   if (state.popupTimeout) {
  //     clearTimeout(state.popupTimeout);
  //     state.popupTimeout = null;
  //   }
  //   setPopupOpen(false);
  // }

  // function handleMouseMove(ev: React.MouseEvent<HTMLElement>) {
  //   const rect = ev.currentTarget.getBoundingClientRect();
  //   const x = Math.min(rect.right - 300 - 8, Math.max(rect.left + 8, ev.clientX - 150));
  //   const y = (window.innerHeight - rect.bottom < 500) ? rect.top - 500 + 8 : rect.bottom - 8;
  //   if (ev.clientY > rect.bottom || ev.clientY < rect.top) return;
  //   setPopupPos({ x, y });
  // }

  // const popup = (
  //   <ResourceCard item={res} sx={{ position: "fixed", top: popupPos.y, left: popupPos.x }} onMouseDown={ev => ev.stopPropagation()} onClick={ev => ev.stopPropagation()} />
  // );

  // const firstGuide = res.docs.filter(d => d.type === "guide").sort((a, b) => a.rank - b.rank)[0];
  // const firstSpec = res.docs.filter(d => d.type === "spec").sort((a, b) => a.rank - b.rank)[0];

  // const firstGuide = res.docs[0];
  // const firstSpec = res.docs[1];

  // function navigateDoc(type?: string) {
  //   // navigate(`/resources/${res.weakId!}#${weakId(doc.title)}`);
  //   const url = `/resources/${res.weakId}${type ? `?docType=${type}` : ""}`;
  //   navigate(url);
  // }

  function handleResourceClick() {
    // navigate(`/resources/${res.id}`)
    navigate(`/resources/${res.weakId}`)
  }

  // function handleGuideClick(ev: React.MouseEvent<HTMLElement>) {
  //   ev.stopPropagation();
  //   navigateDoc("guide");
  // }

  // function handleSpecClick(ev: React.MouseEvent<HTMLElement>) {
  //   ev.stopPropagation();
  //   navigateDoc("spec");
  // }



  // onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}
  return <StyledTableRow sx={{ p: 0.5, cursor: "pointer", "&:hover .actions": { visibility: "visible" } }} onClick={handleResourceClick}>
    <StyledTableCell align="center" component="th" scope="row" sx={{ width: 60 }}>
      {res.count!}x
    </StyledTableCell>
    <StyledTableCell component="th" scope="row" sx={{ px: 1, py: 0, width: 40 }}>
      <img src={res.iconUrl} width={40} height={40} alt="" />
    </StyledTableCell>
    <StyledTableCell align="left" sx={{ width: "100%" }}>{res.title}</StyledTableCell>
    <StyledTableCell align="right" className="actions" sx={{ py: 0 }}>
      <Box className="actions" sx={{ display: "flex", alignItems: "center", "@media (hover: hover)": { visibility: "hidden" } }}>
        {/* {firstGuide && (
          <Button sx={{ display: "flex", flexDirection: "column", alignItems: "center", textTransform: "unset", pt: .72, pb: .25, opacity: .6 }} size="small" color="inherit" onClick={handleGuideClick}>
            <ResourceGuideIcon />
            <span>Guides</span>
          </Button>
        )}
        {firstSpec && (
          <Button sx={{ display: "flex", flexDirection: "column", alignItems: "center", textTransform: "unset", pt: .72, pb: .25, opacity: .6, mr: 1 }} size="small" color="inherit" onClick={handleSpecClick}>
            <ResourceSpecIcon />
            <span>Specs</span>
          </Button>
        )} */}
        <IconButton aria-label="delete">
          <ArrowForward fontSize="inherit" />
        </IconButton>
      </Box>
    </StyledTableCell>
  </StyledTableRow>;
}

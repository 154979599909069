import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Box, Button, Chip, FormControl, Grid, IconButton, InputBase, Paper, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page, { PageContent } from "../Page";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function SolutionEditorPage() {
  const navigate = useNavigate();

  const skeleton = (
    <Stack spacing={1}>
      <Skeleton variant="text" />
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={118} />
    </Stack>
  );
  const articles = (title: String) => (
    <Paper
      elevation={2}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "2.5rem",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="drag icon">
        <DragHandleIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1, fontSize: "1rem" }}
        value={title}
        placeholder="topic name"
        inputProps={{ "aria-label": "topic name" }}
      />
      <Chip label="draft" />
      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
        <EditIcon />
      </IconButton>
      <IconButton sx={{ p: "10px" }} aria-label="edit">
        <MoreVertIcon />
      </IconButton>
    </Paper>
  );

  const inputForm = (
    <Grid container spacing={5}>
      <Grid item xs={12} md={5}>
        <Box component="form" noValidate autoComplete="off">
          <FormControl sx={{ width: "100%" }}>
            <Box>
              <Typography variant="body1">Title</Typography>
              <TextField
                fullWidth
                multiline
                id="outlined-bare"
                variant="outlined"
                value={"Soil moisture monitoring"}
                InputProps={{ style: { fontSize: "1rem", padding: "8px" } }}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="body1">Description (optional)</Typography>
              <TextField
                fullWidth
                multiline
                id="outlined-bare"
                variant="outlined"
                rows={4}
                value={desc}
                InputProps={{ style: { fontSize: "1rem", padding: "8px" } }}
              />
            </Box>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">Articles</Typography>
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              ":hover": { backgroundColor: "primary.main", color: "#fff" },
            }}
          >
            <PlaylistAddIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Box
          sx={{ mt: 1 }}
          gap={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {articles('DHT11')}
          {articles('Soilmoister Moisture Sensor')}
        </Box>
      </Grid>
    </Grid>
  );

  const actionButton = (
    <Button
      startIcon={<EditIcon />}
      sx={{
        mr: 2,
        pl: 2,
        pr: 2,
        bgcolor: "grey.200",
        ":hover": {
          backgroundColor: "primary.main",
          color: "#fff",
        },
      }}
      onClick={() => navigate('/my-solutions/solution')}
    >
      Save Changes
    </Button>
  )

  return (
    <Page
      title="Solution Editor"
      actions={actionButton}
      // onNavigateBack={() => navigate("/my-solutions/solution")}
    >
      <PageContent>
        {inputForm}
        <Box mt={2}>
          <Typography variant="body1">Architecture</Typography>
          {/* <img
            alt="solution architecture"
            src={fadedImage}
            height='auto'
            width={"100%"}
          /> */}
          {/* <SVGComponent /> */}
        </Box>
      </PageContent>
    </Page>
  );
}

export default SolutionEditorPage;

const desc = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing
semper tortor amet habitant velit tristique diam risus nunc. Ullamcorper
maecenas tellus velit ultrices maecenas lobortis. Egestas aliquam
gravida blandit nisl dignissim nullam commodo, velit. Mi adipiscing
scelerisque tortor quisque bibendum nibh sit.`

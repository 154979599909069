import { Box, Button, Fade, FormControl, FormControlLabel, MenuItem, Select, Skeleton, Stack, Switch, TextField, Typography, useTheme, } from "@mui/material";
import { Program, ProgramJoinType, ProgramMember, ProgramUpdate, deleteProgram, findProgram, updateProgram } from "../../../api";
import { useEffect, useMemo, useState } from "react";
import { useProgramMembers } from "../../../loaders";
import Selection from "../../../Selection";
import { useForceUpdate } from "../../../tools";
import { MsgBox, reportError } from "@halliday/mui-msgbox";
import { toast } from "@halliday/mui-toast";
import { useSession } from "../../../ident";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { InfoOutlined, NoteOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ErrorView from "../../ErrorView";


export type ManageProgramPageProps = {
    programId: Program["id"],
}

const defaultPageSize = 25;

export default function ManageProgramPage(props: ManageProgramPageProps) {
    const { programId } = props;

    const [name, setName] = useState<string>("");
    const [desc, setDesc] = useState<string>("");

    const [program, setProgram] = useState<Program | null>(null);
    const [err, setErr] = useState<any>(null);
    const session = useSession();

    async function loadProgram() {
        try {
            const resp = await findProgram({ id: programId });
            setProgram(resp.program);
            setName(resp.program.name);
            setDesc(resp.program.desc);
            setErr(null);
        } catch (err) {
            setErr(err);
        }
    }
    useEffect(() => { loadProgram(); }, [programId, session]);

    function handleNameChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setName(ev.target.value);
    }

    function handleDescChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setDesc(ev.target.value);
    }

    const changed = program !== null && (name !== program!.name || desc !== program!.desc);

    function saveChanges() {
        if (program === null) return;
        updateProgram({ id: programId, name, desc })
            .then(() => { toast("Changes saved"); }, reportError);
        setProgram({ ...program, name, desc });
    }

    function updateProgramJoin(t: ProgramJoinType) {
        setProgram({ ...program!, join: t });
        updateProgram({ id: programId, join: t })
            .then(() => { toast("Join-Method changed"); }, reportError)
    }

    function updateIndividualStart(t: boolean) {
        setProgram({ ...program!, individualStart: t });
        updateProgram({ id: programId, individualStart: t })
            .then(() => { toast("Individual Start changed"); }, reportError)
    }

    function updateProgramEndDate(date: Date | null) {
        setProgram({ ...program!, endsAt: date });
        updateProgram({ id: programId, endsAt: date })
            .then(() => { toast("End Date changed"); }, reportError)
    }

    function updateProgramStartDate(date: Date | null) {
        setProgram({ ...program!, startsAt: date });
        updateProgram({ id: programId, startsAt: date })
            .then(() => { toast("Start Date changed"); }, reportError)
    }

    const theme = useTheme();
    const navigate = useNavigate();

    const [pageSize, setPageSize] = useState(defaultPageSize);

    const [x, setX] = useState(0);
    const loader = useProgramMembers(programId, pageSize, [x]);
    const selection = useMemo(() => new Selection<ProgramMember>(), [loader]);
    const forceUpdate = useForceUpdate();
    selection.on("change", forceUpdate);



    function handleDraftChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const draft = ev.target.checked;
        if (draft) {
            MsgBox({
                title: "Change draft mode",
                text: "The program will be put in draft mode and be hidden to the users.",
                handleOk: () => {
                    setProgram({ ...program!, draft });
                    updateProgram({ id: programId, draft })
                        .then(() => { toast("Draft mode changed"); }, reportError)
                },
                handleCancel: () => { },
            });
        } else {
            MsgBox({
                title: "Change draft mode",
                text: "The program will be made visible to users.",
                handleOk: () => {
                    setProgram({ ...program!, draft });
                    updateProgram({ id: programId, draft })
                        .then(() => { toast("Draft mode changed"); }, reportError)
                },
                handleCancel: () => { },
            })
        }
    }

    function askDeleteProgram() {
        MsgBox({
            title: "Delete Program",
            text: "Are you sure you want to delete this program?",
            handleOk: () => {
                deleteProgram({ id: programId })
                    .then(() => {
                        toast("Program deleted");
                        navigate("/my-programs", { replace: true });
                    }, reportError)
            }
        })
    }

    const template = program?.tags?.includes("template") ?? false;

    function handleTemplateChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const template = ev.target.checked;
        const u: ProgramUpdate = {};
        if (template) {
            u.addTags = ["template"];
            setProgram({ ...program!, tags: [...program!.tags, "template"] });
        } else {
            u.removeTags = ["template"];
            setProgram({ ...program!, tags: program!.tags.filter(t => t !== "template") });
        }
        updateProgram({ id: programId, ...u })
            .then(() => { toast("Template mode changed"); }, reportError);
    }

    if (err) return <ErrorView err={err} />

    return (
        <Box>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Title</Typography>
                <TextField variant="outlined" fullWidth sx={{ maxWidth: 800 }} value={name} onChange={handleNameChange} />
            </Box>

            <Box sx={{ mb: 3 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Description</Typography>
                <TextField variant="outlined" fullWidth multiline rows={4} sx={{ maxWidth: 800 }} value={desc} onChange={handleDescChange} />
            </Box>

            <Box sx={{ mb: 5 }}>
                <Fade in={changed}>
                    <Button variant="contained" color="primary" onClick={saveChanges} disableElevation>Save Changes</Button>
                </Fade>
            </Box>

            <Box sx={{ mb: 3 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Join Methods</Typography>
                {program ? (
                    <FormControl sx={{ width: ["100%", 250] }}>
                        <Select
                            id="program-join"
                            value={program.join}
                            onChange={(ev) => updateProgramJoin(ev.target.value as ProgramJoinType)}
                        >
                            <MenuItem value="open">Free to join</MenuItem>
                            <MenuItem value="request">Request to join</MenuItem>
                            <MenuItem value="invite">Invitation only</MenuItem>
                        </Select>
                    </FormControl>
                ) : (
                    <Skeleton variant="rectangular" width={250} height={56} sx={{ m: 1 }} />
                )}
            </Box>

            <Box sx={{ mb: 3 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Individual Start</Typography>
                {program ? (
                    <FormControl sx={{ width: ["100%", 250] }}>
                        <Select
                            id="individual-start"
                            value={program.individualStart}
                            onChange={(ev) => updateIndividualStart(ev.target.value === "true" ? true : false)}
                        >
                            <MenuItem value="true">Yes (each participant starts individually, sets own dates)</MenuItem>
                            <MenuItem value="false">No (all participants start together, admin sets dates)</MenuItem>
                        </Select>
                    </FormControl>
                ) : (
                    <Skeleton variant="rectangular" width={250} height={56} sx={{ m: 1 }} />
                )}
            </Box>

            {
                !program?.individualStart && (
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Start and End Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box display='flex' flexDirection='column' gap={3}>
                            <DatePicker
                                sx={{ marginRight: 2, width: ['100%', 'fit-content'] }}
                                label="Start Date"
                                value={dayjs(program?.startsAt)}
                                onChange={(date) => updateProgramStartDate(date?.toDate() ?? null)}
                            />
                            <DatePicker
                                sx={{ marginRight: 2, width: ['100%', 'fit-content'] }}
                                label="End Date"
                                value={dayjs(program?.endsAt)}
                                onChange={(date) => updateProgramEndDate(date?.toDate() ?? null)}
                            />
                            </Box>
                        </LocalizationProvider>
                        <Box sx={{ mt: 2, display: "flex", direction: "row", alignItems: "center" }}></Box>
                    </Box>
                )
            }

            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    <NoteOutlined sx={{ verticalAlign: "sub", mr: 1 }} />
                    <span>Draft</span>
                </Typography>
                <FormControlLabel control={program ? <Switch checked={program.draft ?? false} onChange={handleDraftChange} /> : <></>} label="Draft Document" />
                <Typography variant="body2" color="text.secondary"><InfoOutlined fontSize="small" sx={{ verticalAlign: "sub", mr: .5 }} />Draft content is not visible to users.</Typography>
            </Box>

            <Box sx={{ mb: 12 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Template Program</Typography>
                <FormControlLabel control={program ? <Switch checked={template} onChange={handleTemplateChange} /> : <></>} label="Template Document" />
                <Typography variant="body2" color="text.secondary"><InfoOutlined fontSize="small" sx={{ verticalAlign: "sub", mr: .5 }} />Makes you program available for others organizations. Template content can be cloned by other orgaizations.</Typography>
            </Box>

            <Box sx={{ minHeight: 160, borderWidth: 1, borderRadius: theme.shape.borderRadius, borderStyle: "solid", borderColor: theme.palette.error.light, p: 3, mb: 6 }}>
                <Typography variant="h4" sx={{ bgcolor: "background.default", px: 2, mt: "-36px", mb: 3, color: "error.main", width: "fit-content" }}>Danger Zone</Typography>

                <Stack direction="row" alignItems="center" gap={3}>
                    <Button variant="outlined" color="error" onClick={askDeleteProgram}>Delete Program</Button>
                    <Typography variant="body2">This can not be undone!</Typography>
                </Stack>

            </Box>
        </Box>
    )
}

import { reportError } from "@halliday/mui-msgbox";
import { ArrowForward, NavigateNextOutlined } from "@mui/icons-material";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EnroleCourseResponse, FindProgramUnitResponse, Program, ProgramUnit, courseProgress, enroleCourse, findProgramUnit } from "../../api";
import placeholderImage from "../../assets/images/cover_card_course.png";
import { useSession } from "../../ident";
import ErrorView from "../ErrorView";
import { DateChip, formatDifficulty, lineClamp, renderHTML } from "../tools";
import ReadMoreText from "../ReadMoreText";

const pageDoneMinDelay = 1500;

export interface ProgramUnitPageProps {
    programId: Program["id"],
    unitId: ProgramUnit["id"],
    programJoined?: boolean,
    programAdded?: boolean,
}

export default function ProgramUnitPage(props: ProgramUnitPageProps) {
    const navigate = useNavigate();
    const { id } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { programId, unitId, programAdded, programJoined } = props;

    const [page, setPage] = useState<FindProgramUnitResponse | null>(null);
    const [err, setErr] = useState<any>(null);

    const sess = useSession();
    useEffect(() => {

        if (!id) {
            navigate("/my-programs");
            return;
        }
        setPage(null);
        setErr(null);

        if (programId && unitId === null) {

            navigate(`/programs/${id}`);
        } else {

            findProgramUnit({ program: programId, unit: unitId }).then((resp) => {
                setPage(resp)
            }, setErr);
        }
    }, [sess, unitId]);

    let name: string = "Please wait...";
    let content: JSX.Element | null;

    if (err) content = <ErrorView err={err} />;
    else if (page === null) content = <CircularProgress />;
    else {
        const { program, unit } = page

        let name: string = unit.name;
        let from: Date = unit.from;
        let to: Date = unit.to;

        content = (
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h1" mb={1}>{name}</Typography>
                {/* <Typography sx={{ fontStyle: "italic" }}>{unit.desc}</Typography> */}
                <Stack flexDirection='row' gap={1}>
                    <DateChip date={from} type="start" />
                    <DateChip date={to} type="end" />
                </Stack>

                {unit.target ? (
                    <Stack sx={{ flexDirection: "row", flexGrow: 1, gap: 1 }}>
                        <FlagOutlinedIcon />
                        <Typography variant="body1">{unit.target}</Typography>
                    </Stack>
                )
                    : null}
                <br />
                {
                    <Box sx={{ maxWidth: "1200px", " img.img-float-right": { float: "right", ml: 6, mb: 2 }, " img.image-size-m": { maxWidth: "50%" }, " h1": { mt: 0 } }}>
                        {renderHTML(`${unit.content}`)}
                    </Box>
                }

                {
                    unit.solutions?.length ?
                        <>
                            <Typography variant="h2" mb={1} mt={4}>Solutions</Typography>
                            {
                                unit.solutions?.map(({ id, intro, title, coverUrl }) => {
                                    return (
                                        <Card sx={{ display: "flex", cursor: "pointer", mb: 1 }} elevation={1} onClick={() => navigate(`/solutions/${id}`)}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 150 }}
                                                image={coverUrl ?? placeholderImage}
                                                alt="cover image"
                                            />
                                            <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1, pt: 1, flexDirection: ["column", null, "row"] }}>
                                                <Stack sx={{ flexGrow: 1 }}>
                                                    <Typography sx={{ ...lineClamp(2), fontSize: ["1.1em", null, "1.24em"], fontWeight: 600, color: '#4F4F4F' }}>{title}</Typography>

                                                    {/* <Typography variant="body2" mt={1}>Progress:</Typography>
                                                    <LinearProgress variant="determinate" value={70} sx={{ height: isMobile ? null : "8px", borderRadius: "30px", width: isMobile ? null : "40%" }} /> */}
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                    )
                                })
                            }
                        </>
                        :
                        null
                }

                {
                    unit.courses?.length ?
                        <>
                            <Typography variant="h2" mb={1} mt={4}>Courses</Typography>
                            <Box display="flex" flexDirection="column" gap={2}>
                                {
                                    unit.courses?.map(course => {
                                        const { cover, id, desc, title, numTopics, owner, difficulty, joined, joinedAt } = course;
                                        const progress = courseProgress(course);

                                        async function startCourseNow() {
                                            let resp: EnroleCourseResponse;
                                            try {
                                                resp = await enroleCourse({ course: id });
                                            } catch (err) {
                                                reportError(err);
                                                return;
                                            }
                                            navigate(`/courses/${id}`);
                                            // const id = resp.id;
                                        }

                                        // function handleOpenClick(ev: React.MouseEvent) {
                                        //     ev.stopPropagation();
                                        //     navigate(`/courses/${id}`);
                                        // }

                                        function navigateCourse() {
                                            if (joined) navigate(`/courses/${id}`);
                                            else navigate(`/courses/${id}`);
                                        }

                                        function handleStartNowClick(ev: React.MouseEvent) {
                                            ev.stopPropagation();
                                            startCourseNow();
                                        }

                                        return (
                                            <Card
                                                sx={{
                                                    minWidth: 275,
                                                    display: "flex",
                                                    flexDirection: isMobile ? 'column' : 'row',
                                                    transition: 'box-shadow 0.3s', // Add a transition for a smoother effect
                                                    // boxShadow: isHovered ? '1px 5px 5px #EBEBEB' : 'none',
                                                }}
                                                variant="outlined"

                                            // onMouseEnter={handleMouseEnter}
                                            // onMouseLeave={handleMouseLeave}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: ["100%", null, 160], height: "fit-content" }}
                                                    image={cover?.url ?? placeholderImage}
                                                    alt="course cover image"
                                                />
                                                <CardContent style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", flexGrow: 1 }}>
                                                    <Box display={"flex"} justifyContent={"space-between"} width={"100%"} flexDirection={isMobile ? 'column' : 'row'}>
                                                        <Box gap={1}>
                                                            <Typography sx={{ ...lineClamp(2) }}>{title}</Typography>
                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>{formatDifficulty(difficulty!)} Level</Typography>
                                                            <Typography variant="body1" mt={.5}>{progress > 0 && `${Math.round(progress * 100)}% completed`}</Typography>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                                <CardActions sx={{ pr: [1, 3], justifyContent: ['space-around', 'undefined', 'unset',] }}>
                                                    <Button variant="text" onClick={navigateCourse}>Learn more</Button>
                                                    {!joined && <Button variant="outlined" sx={{ width: 120 }} onClick={handleStartNowClick} disabled={programAdded && programJoined ? false : true}>Start now</Button>}
                                                    {joined && <Button variant="contained" sx={{ width: 120 }} onClick={navigateCourse} disableElevation endIcon={<NavigateNextOutlined />} disabled={programAdded && programJoined ? false : true}>Open</Button>}
                                                </CardActions>
                                            </Card>
                                        )
                                    })
                                }
                            </Box>
                        </>
                        :
                        null
                }
            </Box>
        );
    }
    return content
}

export const UserProgramContext = createContext<ProgramUnit | null>(null);
export function useUserProgram() {
    return useContext(UserProgramContext);
}

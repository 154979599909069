import { Check, LocalLibraryOutlined } from "@mui/icons-material";
import { Box, BoxProps, CircularProgress, InputBase, List, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import { useNavigate, useLocation } from "react-router-dom";
import { ResourceType, ResourcesResponse, getResources, resourceTypes } from "../../api";
import { useSession } from "../../ident";
import Page, { PageContent, TOC, TOCList } from "../Page";
import { joinSx } from "../tools";
import ResourceCard from "./ResourceCard";

const allHardwareTypes = [
  { id: "actuator", name: "Actuator" },
  { id: "sensor", name: "Sensor" },
  { id: "microcontroller", name: "Microcontroller" },
  { id: "rpi-hat", name: "Raspberry Pi Hat" },
  { id: "power-management", name: "Power Management" },
  { id: "communication", name: "Communication" },
  { id: "antenna", name: "Antenna" },
  { id: "rasp-pi", name: "Raspberry Pi" },
];

const allFields = [
  { id: "City", name: "City" },
  { id: "Agriculture", name: "Agriculture" },
  { id: "Health", name: "Health" },
  { id: "Industry", name: "Industry" },
];

export default function ResourcesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const sess = useSession();

  const [page, setPage] = useState<ResourcesResponse | null>(null);
  const [err, setErr] = useState<any>(null);
  const [selectedCatagory, setSelectedCatagory] = useState<ResourceType>("");
  const [tocItemSelelcted, setTOCItemSelected] = useState<String>('');
  const [hardwareType, setHardwareType] = useState<null | string>(null);
  const [fields, setFields] = useState<string[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const category = urlParams.get("category");
    const type = urlParams.get("type");
    const use = urlParams.get("use");

    if (category) {
      setSelectedCatagory(category as ResourceType);
      setTOCItemSelected(category as string);
    } else {
      setSelectedCatagory("all");
      setTOCItemSelected('All');
    };

    if (type) {
      setHardwareType(type);
    }
    if (use) {
      setFields(use.split("|"));
    }

    getResources({
      tagsX: encodeURIComponent(
        category === "hardware" ? `${category}${urlParams.get("type") ? `|${urlParams.get("type")}` : ""}${urlParams.get("use") ? `|${urlParams.get("use")}` : ""}` : category === "software" ? "software|cloud" : category || ""
      ),
    }).then((page) => {
        setPage(page);
      }, setErr);
  }, [sess, location]);

  const ref = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const resourcesDetailsElm = document.getElementById("resources-details");
    const resourcesListElm = document.getElementById("resources-list");
    if (!resourcesDetailsElm || !resourcesListElm) return;

    function updateLayout() {
      if (window.innerWidth < 600) {
        resourcesDetailsElm!.style.width = "auto";
      } else {
        const numCards = Math.floor((resourcesListElm!.offsetWidth + 32) / (300 + 32));
        resourcesDetailsElm!.style.width = `${numCards * (300 + 32) - 32}px`;
      }
    }
    updateLayout();

    window.addEventListener("resize", updateLayout);
    return () => window.removeEventListener("resize", updateLayout);
  });

//////////////////////// navigation and filtering //////////////////////////
  useEffect(() => {
    if (selectedCatagory === "hardware"){
      if (hardwareType && fields.length > 0) {
        navigate(`/resources?category=hardware&type=${hardwareType}&use=${fields.join("|")}`);
      } else if (hardwareType) {
        navigate(`/resources?category=hardware&type=${hardwareType}`);
      } else if (fields.length > 0) {
        navigate(`/resources?category=hardware&use=${fields.join("|")}`);
      } else {
        navigate(`/resources?category=hardware`);
      }
    }
  }, [selectedCatagory, fields, hardwareType, navigate]);

  function resetFilters() {
    setFields([]);
    setHardwareType(null);
    setSelectedCatagory("all");
    setTOCItemSelected('All');
    navigate(`/resources`);
  }

  const handleCategorySelect = (id: string, name: string) => {
    // if id is all and name is All, then show all resources
    if (id === "all" && name === "All") {
      setSelectedCatagory("all");
      setTOCItemSelected('All');
      navigate(`/resources`);
      return;
    }
    setSelectedCatagory(id);
    setTOCItemSelected(name)
    if (id !== "hardware") {
      navigate(`/resources?category=${id}`);
    }
  }
//////////////////////// navigation and filtering //////////////////////////

  const toolbar = <Box sx={{ display: "flex", gap: 1.5 }}>

    {/* hardware type */}
    {selectedCatagory === "hardware" ? <>
      <Select
        labelId="select-hardware-label"
        id="select-provider"
        value={hardwareType || ""}
        displayEmpty
        onChange={(ev: SelectChangeEvent<string>) => {
          setHardwareType(ev.target.value || null);
        }}
        input={<InputBase sx={{ bgcolor: hardwareType ? "#b1d8ff" : "grey.200", pl: 1.5, pr: 1, borderRadius: 1, maxWidth: 250 }} />}
        renderValue={(value) => {
          if (!value) return <Box component="span" sx={{ color: "text.secondary" }}>Hardware Types</Box>
          const p = allHardwareTypes.find((t) => t.id === value)!;
          return <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box component="span" sx={{ ml: 1 }}>{p.name}</Box>
                 </Box>
        }}
      >
        <MenuItem value="">All</MenuItem>
        {allHardwareTypes.map((t) => {
          return (
            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
          );
        })}
      </Select>

      {/* field of use */}
      <Select
        labelId="select-fields-label"
        id="select-fields"
        value={fields}
        displayEmpty
        multiple
        onChange={(ev: SelectChangeEvent<string[]>) => {
          const field = ev.target.value;
          setFields(typeof field === "string" ? (field ? [] : field.split(",")) : field);
        }}
        input={<InputBase sx={{ bgcolor: fields.length > 0 ? "#b1d8ff" : "grey.200", pl: 1.5, pr: 1, borderRadius: 1, maxWidth: 250 }} />}
        renderValue={(value) => {
          if (value.length === 0) return <Box component="span" sx={{ color: "text.secondary" }}>Field of use</Box>
          const p = allFields.find((t) => t.id === value[0])!;
          return <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box component="span" sx={{ ml: 1 }}>{p.name}</Box>
            {value.length > 1 && <Box component="span" sx={{ color: "text.secondary", ml: 1 }}>+{value.length - 1}</Box>}
          </Box>
        }}
      >
        {allFields.map((t) => {
          const selected = fields.includes(t.id);
          return (
            <MenuItem key={t.id} value={t.id}>
              {selected && (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              )}
              <ListItemText primary={t.name} inset={!selected} sx={{ m: 0 }} />
            </MenuItem>
          );
        })}
      </Select>
    </>
      : null
    }

  </Box>;

  var sidebar = useMemo(() => (
    <TOC sx={{ border: "none", width: "fit-content", minWidth: "fit-content", pl: 4, pt: 5 }}>
      <TOCList>
        {/* all components */}
        <MenuItem key="all" value="all" sx={{ mb: 1 }} selected={selectedCatagory === "all"} onClick={() => handleCategorySelect("all", "All")}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <ListItemIcon sx={{ mb: .6 }}>
              <LocalLibraryOutlined sx={{ fontSize: "2em" }} />
            </ListItemIcon>
            <ListItemText primary="All" sx={{ m: 0 }} />
          </Box>
        </MenuItem>
        {resourceTypes.map(t => {
          return <MenuItem key={t.id} value={t.id} sx={{ mb: 1 }} selected={selectedCatagory === t.id} onClick={() => handleCategorySelect(t.id, t.name)}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <ListItemIcon sx={{ mb: .6, justifyContent:'center' }}>
                <t.Icon sx={{ fontSize: "2em" }} />
              </ListItemIcon>
              <ListItemText primary={t.name} sx={{ m: 0 }} />
            </Box>
          </MenuItem>;
        })}
        {/* solution box
        <MenuItem key="box" value="box" sx={{ mb: 1 }} onClick={() => navigate('/businessbox')}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <ListItemIcon sx={{ mb: .6 }}>
              <SportsEsportsOutlinedIcon sx={{ fontSize: "2em" }} />
            </ListItemIcon>
            <ListItemText primary="Solution Box" sx={{ m: 0 }} />
          </Box>
        </MenuItem> */}
      </TOCList>
    </TOC>
  ), [selectedCatagory]);

  const content = page ? (
    <PageContent sx={{ maxWidth: "unset" }} ref={ref}>

      <Box>
        {/* {categories} */}
        {/* {tags} */}
        {toolbar}
        <Typography id="resources-details" variant="body2" color="text.secondary" sx={{ textAlign: "right", mt: isMobile ? 2 : null }}>
          {
            page.resources.length < page.numFound ?
             <>Showing {page.resources.length} Items&ensp;•&ensp;<TextLink onClick={resetFilters}>Reset Filters</TextLink></> : 
             <>Showing {page.numFound} Items</>
          }
        </Typography>
      </Box>

      {page.resources.length > 0 ? (
        <List sx={{ display: "flex", flexWrap: "wrap", gap: 3, mt: 3, justifyContent: ["center", "flex-start"] }} id="resources-list">
          {
            page.resources.slice().sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
              <ResourceCard key={item.id} item={item} />
            ))
          }
        </List>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 5 }}>No items found. Adjust your search or filters.</Typography>
      )}

    </PageContent>

  ) : (
    <CircularProgress />
  );

  return (
    <Page
      title="Resource Catalog"
      toc={sidebar}
      tocPrimary={tocItemSelelcted}
      onNavigateBack={() => navigate("/my-lab")}
      shortTOC={true}
    >
      {content}
    </Page>
  );
}

function TextLink(props: BoxProps) {
  const { sx, ...boxProps } = props;
  return <Box component="span" sx={joinSx({ cursor: "pointer", "&:hover": { textDecoration: "underline" } }, sx)} {...boxProps} />
}

import { ArrowRight, LocalLibraryOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Grid, Link, List, ListItem, ListItemButton, ListItemText, Paper, Rating, Stack, Typography, styled, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../Page";
import { CardCourse, CardResource, CardResourceWithUnit } from "../pages/BusinessboxComponents/Cards";
import { lineClamp } from "../tools";

import raspberryPiIcon from "./raspberry-pi-icon.png";
import solarPanelIcon from "./solar-panel-icon.png";
import cover from "./solution-box-background.png";
import wazidevIcon from "./wazidev-icon.png";
import waziSenseIcon from "./wazisense-icon.png";
import sensorKitIcon from "./sensor-kit-icon.png";
import jumperWiresIcon from "./jumper-wires-icon.png";
import sensorsKitBanner from "./sensores-actuators-kit.png";
import WazidevNew from "./wazidev-v1.4.png";
import DHT22 from "./dht22.png";
import SoilMoistureSensore from "./soil-moisture-sensor-v2.png";
import WaziHat from "./wazihat-v2.png";
import BoxCover from "./diy-kit.png";

export default function TrainingKitBoxPage() {

    const navigate = useNavigate();

    function navigateSeeAllResources() {
        navigate("/resources?category=mvp-diy-kit");
    }

    const content = (
        <Box
            sx={{
                pl: [3, 6, null, null, 8],
                pr: [3, 4, null, null, 12],
                pt: [0, 4],
                pb: ["120px", "440px"],
                height: ["fit-content", undefined, "calc(100vh - 104px)"],
                // height: 'calc(100vh - 56px)',
                backgroundImage: `url(${sensorsKitBanner})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: 'bottom',
                backgroundPositionX: 'center',
                // overflowY: 'auto',
                boxSizing: "border-box",
            }}
        >
            <Grid container spacing={6}>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="h3">Training Kit</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        align="justify"
                                    >
                                        The Training Kit is a class-sized collection of sensors and actuators for DIY learning and IOT prototyping.
                                        It is intended for use in a classroom setting, where students can learn about electronics and programming in a hands-on way.
                                        The kit includes a variety of sensors and actuators, as well as <strong>15 WaziDev 1.4 Pro microcontroller boards</strong> with built-in LoRa communication.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item >
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle1" color={"text.secondary"}>
                                            Need more options?
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRight />
                                            <Typography variant="subtitle1">Contact your DIH</Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRight />
                                            <Typography variant="subtitle1">
                                                List of contents
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ color: "#186DBF", cursor: "pointer" }}
                                        >
                                            <ArrowRight />
                                            <Typography variant="subtitle1">
                                                Shipping prices
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                        <Typography variant="h6" mr={3}>115 pieces in this box</Typography>
                                        <Typography variant="caption" sx={{ color: "#186DBF", cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={navigateSeeAllResources}>see all (115)</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xl={6}>
                                            <CardResource icon={sensorKitIcon} quantity={1} title={"Sensors & Actuators Kit"} subtitle={"by Waziup"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={wazidevIcon} quantity={15} title={"WaziDev 1.4 Pro"} subtitle={"by Waziup"} />
                                        </Grid>
                                        <Grid item xl={6}>
                                            <CardResource icon={jumperWiresIcon} quantity={40} title={"Jumper Wires Set"} subtitle={"10cm, 20cm, 30cm"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                        <Typography variant="h6" mr={3}>16 courses in this box</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item sm={6} xl={5}>
                                            <CardCourse title={"Basic of Electronics"} subtitle={"by Waziup"} desc={"Current, voltage, wires… IoT is, at its base, just electronics. In this course, we will learn the basics that you need to know to start building IoT prototypes."} />
                                        </Grid>
                                        <Grid item sm={6} xl={5}>
                                            <CardCourse title={"Overview of Boards and Peripherals"} subtitle={"by Waziup"} desc={"An overview of the available boards and peripherals in the IoT world"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item>
                    <Grid container spacing={2} display="flex" flexDirection="column">
                        <Grid item>
                            <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                                <Typography variant="h6" mr={3}>18 Solutions in this box</Typography>
                                <Typography variant="caption" sx={{ color: "#186DBF", cursor: "pointer" }}>see all (18)</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item md={3}>
                                    <CardSolutions title={"Autonomous Irrigation"} desc={"Using AI to optimize plant irrigation procedures."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions sx={{ mt: [undefined, 3] }} title={"Cattle Theft"} desc={"Monitor stock positions and record data."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions title={"Weather Station"} desc={"Measure rain, wind, temperature and display the values in the cloud."} />
                                </Grid>
                                <Grid item md={3}>
                                    <CardSolutions sx={{ mt: [undefined, 3] }} title={"Fish Farm"} desc={"Collect water data, including temperture and other data."} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </Box>
    );

    const contents = (
        <Box
            sx={{ pl: [3, 6, null, null, 8], pr: [3, 4, null, null, 12], }} display='flex' flexDirection='column' gap={4}>
            <Box display='flex' flexDirection={['column', 'row']} gap={4}>
                <Box width={['100%', '40%']}><img src={BoxCover} alt="" width='100%' /></Box>
                <Box width={['100%', '60%']}>
                    <Typography variant="h1">Training Kit</Typography>
                    <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly />
                    <Typography variant="body1" align="justify" mt={2}>
                        The Training Kit is a class-sized collection of sensors and actuators for DIY learning and IOT prototyping.
                        It is intended for use in a classroom setting, where students can learn about electronics and programming in a hands-on way.
                        The kit includes a variety of sensors and actuators, as well as <strong>15 WaziDev 1.4 Pro microcontroller boards</strong> with built-in LoRa communication.
                    </Typography>
                    <Stack mt={2}>
                        <Button variant="contained" disableElevation sx={{ width: ['100%', 'fit-content'] }}>Get Now</Button>
                        <Typography variant="caption" fontStyle='italic'>Click the button to claim yours box</Typography>
                    </Stack>
                </Box>
            </Box>
            <Box>
                <Stack flexDirection='row' alignItems='center' gap={[2, 3]}>
                    <Typography variant="h2">115 Units of Components</Typography>
                    <Typography variant="h4" sx={{ color: "#186DBF", cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={navigateSeeAllResources}>see all (115)</Typography>
                </Stack>
                <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus, tortor sit amet luctus luctus, dolor nisi aliquet leo, a lacinia leo ante id risus. Donec tincidunt quam sed pharetra semper.</Typography>
                <Grid container mt={2} gap={2}>
                    <Grid item>
                        <CardResourceWithUnit icon={sensorsKitBanner} quantity={1} title={"1 Unit - 45 Sensors and Actuators Box"} subtitle={"by Waziup"} />
                    </Grid>
                    <Grid item>
                        <CardResourceWithUnit icon={WazidevNew} quantity={1} title={"WaziDev 1.4 Pro"} subtitle={"by Waziup"} />
                    </Grid>
                    <Grid item>
                        <CardResourceWithUnit icon={DHT22} quantity={2} title={"DHT22 Temperature Sensor"} subtitle={"by Waziup"} />
                    </Grid>
                    <Grid item>
                        <CardResourceWithUnit icon={SoilMoistureSensore} quantity={2} title={"Soil Moisture Sensor"} subtitle={"by Waziup"} />
                    </Grid>
                </Grid>


            </Box>
            <Box>
                <Typography variant="h2">16 Courses</Typography>
                <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus, tortor sit amet luctus luctus, dolor nisi aliquet leo, a lacinia leo ante id risus. Donec tincidunt quam sed pharetra semper.</Typography>

                {/* <List>
                    <ListItem disablePadding>
                        <ListItemButton component="ol">
                            <ListItemText primary='1. Introduction to IoT' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="ol">
                            <ListItemText primary='2. Basics of electronics for IoT' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="ol">
                            <ListItemText primary='3. Advanced electronics for IoT' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="ol">
                            <ListItemText primary='4. Overview of boards and peripherals' />
                        </ListItemButton>
                    </ListItem>
                </List> */}

                <ol style={{color: '#296dbf'}}>
                    <li><Link href="/courses/aOJfma3yr1Q?topic=9_ld8OsmymT" underline="hover" variant="body1" color="#296dbf" >Introduction to IoT  </Link></li>
                    <li><Link href="/courses/aOJfma3yr1Q?topic=9_ld8OsmymT" underline="hover" variant="body1" color="#296dbf" >Basics of electronics for IoT  </Link></li>
                    <li><Link href="/courses/aOJfma3yr1Q?topic=9_ld8OsmymT" underline="hover" variant="body1" color="#296dbf" >Advanced electronics for IoT  </Link></li>
                    <li><Link href="/courses/aOJfma3yr1Q?topic=9_ld8OsmymT" underline="hover" variant="body1" color="#296dbf" >Overview of boards and peripherals  </Link></li>
                </ol>
            </Box>
        </Box>
    )

    const action = (
        <Button
            size="large"
            startIcon={<LocalLibraryOutlined />}
            sx={{
                mr: 2,
                bgcolor: "grey.200",
                ":hover": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                },
            }}
            onClick={() => navigate("/resources")}
        >
            Resource Catalog
        </Button>
    );

    return (
        <Page
            title="Businessbox"
            actions={action}
            onNavigateBack={() => navigate(-1)}
        >
            {/* <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} {...containerProps} className="LabPageContainer" ref={ref}>
                <Box sx={joinSx({ pl: [3, 6, null, null, 8], pr: [3, 4, null, null, 12], pt: [0, 4], pb: 8, display: "flex", flexDirection: "column", maxWidth: 1200, flexGrow: 1 }, sx)} className="LabPageContent" {...boxProps}>
                    {props.children}
                </Box>
            </Box> */}

            {contents}
        </Page>
    );
}


export function TrainingKitBoxCard() {

    const navigate = useNavigate();

    function navigateSolutionBoxPage() {
        navigate("/businessbox/training-kit");
    }

    return <Card sx={{ maxWidth: 380, bgcolor: "#ffe5a0", position: "relative" }} onClick={navigateSolutionBoxPage}>
        <CardActionArea>
            <Box sx={{ p: 2, pb: 0, gap: 2, display: "flex", alignItems: "baseline" }}>
                <Typography variant="h5" sx={{ "&:hover": { textDecoration: "underline" } }}>Training Kit Box</Typography>
                <Typography variant="body2">29 items</Typography>
            </Box>
            <CardContent sx={{ pt: 1 }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                    <BoxCardItem title="WaziDev" icon={wazidevIcon} quantity={15} />
                    <BoxCardItem title="Jumper Wires" icon={jumperWiresIcon} quantity={1} />
                    <BoxCardItem title="45 Sensor Box" icon={sensorKitIcon} quantity={1} />
                    <Box sx={{ pt: 5 }}>
                        <Typography variant="body1" sx={{ "&:hover": { textDecoration: "underline" } }}>+21 more</Typography>
                    </Box>
                </Box>
            </CardContent>

            <SVG sx={{ position: "absolute", width: "100%", bottom: 0, left: 0, zIndex: -1, opacity: .6 }} width="378" height="47" viewBox="0 0 378 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10.2965C65.0913 -4.36946 147.004 -2.46176 197.627 10.2965C247.033 22.7483 333.299 24.6851 378 10.2965V40.7344H0V10.2965Z" fill="#F87B43" />
                <path d="M0 16.5621C65.0913 1.89617 147.004 3.80387 197.627 16.5621C247.033 29.0139 333.299 30.9507 378 16.5621V47H0V16.5621Z" fill="#2861A6" />
            </SVG>
        </CardActionArea>
    </Card>
}

type BoxCardItemProps = {
    title: string;
    icon: string;
    quantity: number;
}

function BoxCardItem(props: BoxCardItemProps) {
    const { title, icon, quantity } = props;

    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>{title}</Typography>
        <Box component="img" src={icon} sx={{ width: 40, height: 40 }} />
        <Typography variant="body1">{quantity}x</Typography>
    </Box>
}

const SVG = styled("svg")({});

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, CardActions, CardHeader, CardMedia, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton } from "@mui/material";
import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "@halliday/mui-toast";
import { ModuleCourse, ModuleId, TopicsResponse, enroleCourse, toImageURL } from "../../api";
import courseCoverPlaceholder from "../../assets/images/cover_card_course.png";
import TopicDialog from "./TopicDialog";
import { formatDifficulty, formatDuration, joinSx } from "../tools";
import { useSession } from "../../ident";

export interface LecturesCardProps extends CardProps {
  course: ModuleCourse;
  module?: ModuleId;
  noActionButton?: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function LecturesCard(props: LecturesCardProps) {
  const { id, course, module, noActionButton, ...cardProps } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openDialog, setOpenDialogue] = useState<boolean>(false);
  const sess = useSession();

  const handleClickOpenDialog = () => {
    setOpenDialogue(true);
  };
  const handleCloseDialog = () => {
    setOpenDialogue(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCloseClick(ev: React.MouseEvent) {
    handleClose();
    ev.stopPropagation();
  }

  async function addToMyCourse() {
    if (!sess) {
      window.openLogin();
      return
    }

    // if course is already in my courses, do nothing
    if (course.joined){
      return;
    }

    await enroleCourse({ course: course.id }).then((resp) => {
      navigate("/my-courses");
      toast("Successfully added!");
    }, reportError);

    window.dataLayer.push({
      "event": "enroleCourse",
      "courseId": course.id,
      "courseTitle": course.title,
    });
  }

  const menuButton = (
    <>
      <IconButton
        aria-label="settings"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        onMouseDown={handleStopPropagation}
        className="btnMore"
        sx={{ "@media (hover: hover)": { visibility: "hidden" } }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleCloseClick}
        onMouseDown={handleStopPropagation}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <MenuItem
          onClick={() =>
            navigate(`/courses/${course.id}/editor`)
          }
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <Card {...cardProps} sx={joinSx({ maxWidth: 300, "&:hover": { " .btnMore": { visibility: "visible" } }, cursor: noActionButton ? 'pointer' : 'default'}, cardProps.sx)} onClick={() => noActionButton ? navigate(`/courses/${course.id}`) : null} >
        <CardMedia
          component="img"
          alt={course.title}
          sx={{ width: "100%", height: "auto" }}
          image={course.cover?.url || courseCoverPlaceholder}
        />
        <CardHeader
          sx={{ mb: 0 }}
          titleTypographyProps={{ fontSize: 16, width: 198, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}
          subheaderTypographyProps={{ fontSize: 14 }}
          title={course.title}
          subheader={`by ${course.orgName}, ${formatDuration(course.duration!)}, ${formatDifficulty(course.difficulty!)}`}
        />
        {
          noActionButton ? null :
            <>
              <CardContent sx={{ pt: 'inherit' }}>
                <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                  {course.desc}
                </Typography>
              </CardContent>
              <CardActions sx={{ p: 2, pt: 'inherit' }}>
                {
                  course.joined ? <Button
                    size="small"
                    onClick={() => navigate(`/courses/${course.id}`)}
                    variant="contained"
                    color="primary"
                  >
                    Open
                  </Button> : <Button variant="outlined" size="small" onClick={addToMyCourse}>Join Course</Button>
                }
                <Button size="small" onClick={handleClickOpenDialog}>Learn More</Button>
              </CardActions>
            </>
        }
      </Card>
      <TopicDialog
        key={course.id}
        course={course}
        open={openDialog}
        onClose={handleCloseDialog}
      />
    </>
  );
}

export function LecturesCardSkeleton(props: CardProps) {
  const { sx, ...cardProps } = props;
  return <Card
    {...cardProps}
    sx={joinSx({
      maxWidth: 300,
      "&:hover": { " .btnMore": { visibility: "visible" } },
    }, sx)}
  >
    <Skeleton variant="rectangular" width={300} height={200} />
    <CardHeader
      sx={{ mb: 0 }}
      title={<Skeleton variant="text" width={200} />}
      subheader={<Skeleton variant="text" width={150} />}
    />
  </Card>
}

function handleStopPropagation(ev: React.SyntheticEvent) {
  ev.stopPropagation();
}

export default LecturesCard;


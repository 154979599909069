import { useEffect, useState } from "react";

interface YouTubePlayerProps {
    videoId: string
}

var counter = 0;

const yt = window.YT ? Promise.resolve(window.YT) : new Promise<void>((resolve) => {
    (window as any)["onYouTubeIframeAPIReady"] = resolve;
})

export function YouTubePlayer(props: YouTubePlayerProps) {
    const { videoId } = props;
    const [id] = useState(() => `_${counter++}`);
    useEffect(() => {
        let mounted = true;
        let player: YT.Player;
        yt.then(() => {
            if(!mounted) return;
            player = new YT.Player(id, {
                height: '390',
                width: '640',
                videoId: videoId,
                playerVars: {
                    'playsinline': 1
                },
                // events: {
                //     'onReady': onPlayerReady,
                //     'onStateChange': onPlayerStateChange
                // }
            });
        })
        return () => {
            mounted = false;
            player?.destroy();
        }
    }, []);

    return <div dangerouslySetInnerHTML={{__html: `<div id="${id}">Loading...</div>`}}></div>
}
import { useCallback, useEffect, useMemo, useState } from "react";
import Selection from "./Selection";
import { ListProgramMembersRequest, ListProgramMembersResponse, ListUsersRequest, ListUsersResponse, Program, ProgramMember, User, listProgramMembers, listUsers } from "./api";
import { useSession } from "./ident";

// export type DataPage<T> = {
//     page: number;
//     items: T[];
//     numItems: number;
// }

// export class UsersLoader {
//     page = -1;
//     awaitPending = false;
//     pending: Promise<DataPage<User>> | null = null;
//     err: any = null;

//     private nextPageToken: string | null = null;
//     private pages: DataPage<User>[] = [];

//     selection: Selection<User>;

//     constructor(
//         readonly search: string | undefined,
//         readonly pageSize: number,
//     ) {
//         this.selection = new Selection<User>();
//         this.selection.itemAt = (idx) => this.itemAt(idx);
//     }

//     get numSelected(): number {
//         return this.selection.isAll ? this.numItems - this.selection.size : this.selection.size;
//     }

//     get numItems(): number {
//         return this.pages[0]?.numItems ?? 0;
//     }

//     itemAt(idx: number): User | undefined {
//         return this.pages[Math.floor(idx / this.pageSize)]?.items[idx % this.pageSize];
//     }

//     async loadPage(n: number): Promise<DataPage<User>> {
//         if (n < this.pages.length) {
//             this.page = n;
//             this.awaitPending = false;
//             return this.pages[n];
//         }
//         if (n > this.pages.length) throw new Error("Cannot load overflow page " + n);
//         if (n > 0 && !this.nextPageToken) throw new Error("Cannot load next page");
//         if (this.pending) {
//             this.awaitPending = true;
//             return this.pending;
//         }

//         const promise = async () => {
//             const all = this.pageSize === -1;
//             const pageSize = all ? undefined : this.pageSize;
//             let resp: ListUsersResponse;
//             const req: ListUsersRequest = { search: this.search, pageSize, all, pageToken: this.nextPageToken || undefined };
//             try {
//                 resp = await listUsers(req);
//             } catch (err) {
//                 this.err = err;
//                 throw err;
//             }
//             this.err = null;

//             this.page = this.pages.length;
//             const page: DataPage<User> = {
//                 page: this.page,
//                 items: resp.users,
//                 numItems: resp.numUsersTotal ?? 0
//             };
//             this.pages.push(page);
//             this.nextPageToken = resp.nextPageToken ?? null;
//             this.pending = null;
//             return page;
//         }
//         this.awaitPending = true;
//         this.pending = promise();
//         return this.pending;
//     }
// }

////////////////////////////////////////////////////////////////////////////////


// export type DataLoader<T> = {
//     selection: Selection<T>
//     numSelected: number
//     itemAt(idx: number): T | undefined,
//     loadPage(n: number): Promise<DataPage<T>>
//     page: number
// }

type DataFetcherRequest = {
    pageToken?: string
    pageSize?: number
}

type DataFetcherResponse<T> = {
    items: T[]
    numItems: number
    nextPageToken?: string
}

type PageRequest = {
    pageToken?: string
}

type PageResponse = {
    nextPageToken?: string
}

type PageFetcher<R extends PageResponse> = (req: PageRequest) => Promise<R>;

type PageLoader<R> = (n: number) => Promise<R>;

// type DataFetcher<T> = (req: DataFetcherRequest) => Promise<DataFetcherResponse<T>>;


function createDataLoader<R extends PageResponse>(fetcher: PageFetcher<R>): PageLoader<R> {
    var page = -1;
    var awaitPending = false;
    var pending: Promise<R> | null = null;

    var nextPageToken: string | undefined;
    var pages: R[] = [];

    return async function loadData(n: number): Promise<R> {
        if (n < pages.length) {
            page = n;
            awaitPending = false;
            return pages[n];
        }
        if (n > pages.length) throw new Error("Cannot load overflow page " + n);
        if (n > 0 && !nextPageToken) throw new Error("Cannot load next page");
        if (pending) {
            awaitPending = true;
            return pending;
        }
        const promise = async () => {
            var resp = await fetcher({ pageToken: nextPageToken });
            page = pages.length;
            pages.push(resp);
            nextPageToken = resp.nextPageToken;
            pending = null;
            awaitPending = false;
            return resp;
        }
        awaitPending = true;
        pending = promise();
        return pending;
    }
}

// function createDataLoader<T>(fetcher: DataFetcher<T>) {
//     return class GenericDataLoader implements PageLoader<T> {
//         page = -1;
//         awaitPending = false;
//         pending: Promise<DataPage<T>> | null = null;
//         err: any = null;

//         private nextPageToken: string | null = null;
//         private pages: DataPage<T>[] = [];

//         selection: Selection<T>;

//         constructor(
//             readonly search: string | undefined,
//             readonly pageSize: number,
//         ) {
//             this.selection = new Selection<T>();
//             this.selection.itemAt = (idx) => this.itemAt(idx);
//         }

//         get numSelected(): number {
//             return this.selection.isAll ? this.numItems - this.selection.size : this.selection.size;
//         }

//         get numItems(): number {
//             return this.pages[0]?.numItems ?? 0;
//         }

//         itemAt(idx: number): T | undefined {
//             return this.pages[Math.floor(idx / this.pageSize)]?.items[idx % this.pageSize];
//         }

//         async loadPage(n: number): Promise<DataPage<T>> {
//             if (n < this.pages.length) {
//                 this.page = n;
//                 this.awaitPending = false;
//                 return this.pages[n];
//             }
//             if (n > this.pages.length) throw new Error("Cannot load overflow page " + n);
//             if (n > 0 && !this.nextPageToken) throw new Error("Cannot load next page");
//             if (this.pending) {
//                 this.awaitPending = true;
//                 return this.pending;
//             }

//             const promise = async () => {
//                 const all = this.pageSize === -1;
//                 const pageSize = all ? undefined : this.pageSize;
//                 let resp: DataFetcherResponse<T>;
//                 const req: DataFetcherRequest = { pageSize, pageToken: this.nextPageToken || undefined };
//                 try {
//                     resp = await fetcher(req);
//                 } catch (err) {
//                     this.err = err;
//                     throw err;
//                 }
//                 this.err = null;

//                 this.page = this.pages.length;
//                 const page: DataPage<T> = {
//                     page: this.page,
//                     items: resp.items,
//                     numItems: resp.numItems ?? 0
//                 };
//                 this.pages.push(page);
//                 this.nextPageToken = resp.nextPageToken ?? null;
//                 this.pending = null;
//                 return page;
//             }
//             this.awaitPending = true;
//             this.pending = promise();
//             return this.pending;
//         }
//     }
// }

// export type GenericPage<T> = {
//     items: T[],
//     numItems: number,
//     nextPageToken?: string
// }
// export type GenericLoader<T> = PageLoader<GenericPage<T>>;

export type UsersLoader = PageLoader<ListUsersResponse>;
export function createUsersLoader(search: string | undefined, pageSize: number) {
    return createDataLoader<ListUsersResponse>(req => listUsers({ search, pageSize, ...req }));
}

export function useUsers(search: string | undefined, pageSize: number) {
    const sess = useSession();
    const loader = useMemo(() => createUsersLoader(search, pageSize), [search, pageSize, sess]);
    return loader;
}

export type ProgramMembersLoader = PageLoader<ListProgramMembersResponse>;
export function createProgramMembersLoader(program: Program["id"], pageSize: number) {
    return createDataLoader<ListProgramMembersResponse>(req => listProgramMembers({ program, pageSize, ...req }));
}

export function useProgramMembers(program: Program["id"], pageSize: number, deps: React.DependencyList = []) {
    const sess = useSession();
    const loader = useMemo(() => createProgramMembersLoader(program, pageSize), [program, pageSize, sess, ...deps]);

    return loader;
}


export type LoaderState<T> = {
    page: number,
    data: T | null,
    loading: boolean,
    err: any,
    changePage(n: number): Promise<T>,
}

export function useLoader<T>(loader: PageLoader<T>): LoaderState<T> {

    const [page, setPage] = useState(0);
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState<any>(null);

    const changePage = useCallback(function changePage(n: number) {
        setPage(n);
        setLoading(true);
        const p = loader(n);
        p.then(data => {
            setData(data);
            setErr(null);
        }, (err) => {
            setErr(err);
        }).finally(() => {
            setLoading(false);
        });
        return p;
    }, [loader]);

    useEffect(() => { changePage(0); }, [loader]);

    return { page, data, loading, err, changePage };
}


// export class ProgramMembersLoader {
//     page = -1;
//     awaitPending = false;
//     pending: Promise<DataPage<ProgramMember>> | null = null;
//     err: any = null;

//     private nextPageToken: string | null = null;
//     private pages: DataPage<ProgramMember>[] = [];

//     selection: Selection<ProgramMember>;

//     constructor(
//         readonly search: string | undefined,
//         readonly pageSize: number,
//     ) {
//         this.selection = new Selection<ProgramMember>();
//         this.selection.itemAt = (idx) => this.itemAt(idx);
//     }

//     get numSelected(): number {
//         return this.selection.isAll ? this.numItems - this.selection.size : this.selection.size;
//     }

//     get numItems(): number {
//         return this.pages[0]?.numItems ?? 0;
//     }

//     itemAt(idx: number): ProgramMember | undefined {
//         return this.pages[Math.floor(idx / this.pageSize)]?.items[idx % this.pageSize];
//     }

//     async loadPage(n: number): Promise<DataPage<ProgramMember>> {
//         if (n < this.pages.length) {
//             this.page = n;
//             this.awaitPending = false;
//             return this.pages[n];
//         }
//         if (n > this.pages.length) throw new Error("Cannot load overflow page " + n);
//         if (n > 0 && !this.nextPageToken) throw new Error("Cannot load next page");
//         if (this.pending) {
//             this.awaitPending = true;
//             return this.pending;
//         }

//         const promise = async () => {
//             const all = this.pageSize === -1;
//             const pageSize = all ? undefined : this.pageSize;
//             let resp: ListProgramMembersResponse;
//             const req: ListProgramMembersRequest = { search: this.search, pageSize, all, pageToken: this.nextPageToken || undefined };
//             try {
//                 resp = await listProgramMembers(req);
//             } catch (err) {
//                 this.err = err;
//                 throw err;
//             }
//             this.err = null;

//             this.page = this.pages.length;
//             const page: DataPage<ProgramMember> = {
//                 page: this.page,
//                 items: resp.members,
//                 numItems: resp.numMembers ?? 0
//             };
//             this.pages.push(page);
//             this.nextPageToken = resp.nextPageToken ?? null;
//             this.pending = null;
//             return page;
//         }
//         this.awaitPending = true;
//         this.pending = promise();
//         return this.pending;
//     }
// }

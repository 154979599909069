import { ArrowLeft, ArrowRight, Favorite } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Checkbox, Divider, Fab, FormControlLabel, FormGroup, Link, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HTMLRenderer from "../HTMLRenderer";
import Page, { PageContent, TOC, TOCHeader, TOCList, TOCListItem, useMediaQueryMobile } from "../Page";
import { renderHTML } from "../tools";
import { YouTubePlayer } from "../YouTube";

const paramShowTOC = "show_toc";

export default function TestPage() {
    const query = useQuery();
    const navigate = useNavigate();

    // This state parameter is read from the URL query:
    const showTOC = query.has(paramShowTOC);
    function setShowTOC(showTOC: boolean) {
        if (showTOC) query.set(paramShowTOC, "");
        else query.delete(paramShowTOC);
        navigate("/test?" + query.toString());
    }

    // These are normal REACT states:
    const [showBreadcrumbs, setShowBreadcrumbs] = useState(true);
    const [showActionButton, setShowActionButton] = useState(false);

    // Mobile is true on small devices.
    const mobile = useMediaQueryMobile();


    // The main page content:
    const content = <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h1" gutterBottom>This is a test page.</Typography>
        <Typography gutterBottom>
            You found the test page. Here, and in the source code at <code>src/components/page/TestPage</code> you will find some features to use for this platform.
            Check the settings below.
        </Typography>

        <Typography variant="h2" gutterBottom sx={{ mt: 3 }}>Settings</Typography>
        <FormGroup>
            <Typography>
                This parameter is stored in the url with the <code>{paramShowTOC}</code> parameter.
                If you click the checkbox, the url will be changed and the page renders again.
            </Typography>
            <FormControlLabel control={<Checkbox checked={showTOC} onChange={(ev) => setShowTOC(ev.target.checked)} />} label="Show TOC (Table of Content)" />
            <Typography>
                The other parameters are stored a normal React states. Reloading the page will reset them.
            </Typography>
            <FormControlLabel control={<Checkbox checked={showBreadcrumbs} onChange={(ev) => setShowBreadcrumbs(ev.target.checked)} />} label="Show Breadcrumbs" />
            <FormControlLabel control={<Checkbox checked={showActionButton} onChange={(ev) => setShowActionButton(ev.target.checked)} />} label="Show Action Button" />
        </FormGroup>

        <Typography variant="h2" gutterBottom sx={{ mt: 3 }}>Example HTML Rendering</Typography>
        <Divider />

        {renderHTML(`
            <p>This text and the paragraph below was rendered using the <code>renderHTML()</code> function.</p>
            <h2>This is some <i>rendered</i> html!</h2>
            <p>You can even have some custom elements like &lt;youtube&gt; in the html text.</p>
            <p><youtube>sf7SRVEChbY</youtube></p>
        `)}

        <Divider />

        <Typography variant="h2" gutterBottom sx={{ mt: 3 }}>Lorem Ipsum</Typography>
        <LoremIpsum />
        <LoremIpsum />
        <LoremIpsum />
        <LoremIpsum />
        <LoremIpsum />

        <Typography variant="h2" gutterBottom sx={{ mt: 3 }}>Lorem Ipsum</Typography>
        <LoremIpsum />
        <LoremIpsum />
        <LoremIpsum />
        <LoremIpsum />
        <LoremIpsum />
    </Box>;

    const [time, setTime] = useState(new Date());
    const setCurrentTime = () => setTime(new Date());
    useInterval(setCurrentTime, 1000);

    // This is an action button that will be shown in the top AppBar (on desktop)
    // or as a FAB (floating action button) on mobile.
    const action = showActionButton ? (
        mobile ? (
            <Fab sx={{ position: "fixed", bottom: 16, right: 16 }} aria-label="like" color="primary">
                <Favorite />
            </Fab>
        ) : (
            <Button variant="contained">Action!</Button>
        )
    ) : null;

    // Breadcrumbs help navigate the page
    const breadcrumbs = showBreadcrumbs ? <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1, whiteSpace: "nowrap" }}>
            <Link underline="hover" color="inherit" onClick={() => navigate("/")}>Section 1</Link>
            <Link underline="hover" color="inherit" onClick={() => navigate("/settings")}>Section 2</Link>
            <Typography component="div" color="text.primary">Section 3</Typography>
        </Breadcrumbs>
        <Typography color="text.secondary" sx={{ textAlign: "end" }}>last changed {time.toLocaleString()}</Typography>
    </> : null;

    // The table-of-contents (TOC) will be shown on a left bar on desktop
    // or on a second AppBar on mobile.
    const toc = showTOC ? <TOC>
        <TOCHeader><Typography variant="body2">3 Items</Typography></TOCHeader>
        <TOCList>
            <TOCListItem
                active
                primary="Getting started with Arduino"
                secondary="Introduction to the most famous Arduino controller of all time."
                footer="6 topics, 21 files, 2 quiz" />
            <TOCListItem
                primary="Fundamentals of IoT"
                secondary="Beginners Course."
                footer="2 topics, 1 quiz" />
            <TOCListItem
                primary="Getting started with Waziup Technologies"
                secondary="Part 2: Hardware Gateway"
                footer="4 topics, 3 images" />
        </TOCList>
    </TOC> : null;

    // This page navigation shows up at the bottom of the page below the main content.
    const nav = <Toolbar>
        <Button startIcon={<ArrowLeft />} sx={{ mr: "auto" }}>Previous Page</Button>
        <Button endIcon={<ArrowRight />} disabled>Next Page</Button>
    </Toolbar>

    // Now returns the whole page component:
    return <Page
        title="Test Page 123"
        actions={action}
        breadcrumbs={breadcrumbs}

        // Below opener, primary and secondary will only show on mobile
        toc={toc}
        tocOpener="2."
        tocPrimary="Introduction to Arduino"
        tocSecondary="This is some very cool stuff. Very cool, I promise!"

        // Will show a navigate-back arrow on desktop
        // onNavigateBack={() => navigate("/")}
    >
        <PageContent>{content}</PageContent>
        {nav}
    </Page>;
}

export function LoremIpsum() {
    return <Typography gutterBottom>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
        unknown printer took a galley of type and scrambled it to make a type specimen book.
        It has survived not only five centuries, but also the leap into electronic typesetting,
        remaining essentially unchanged. It was popularised in the 1960s with the release of
        Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
        software like Aldus PageMaker including versions of Lorem Ipsum.
    </Typography>
}

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function useInterval(cb: () => void, delta: number) {
    useEffect(() => {
        const interval = setInterval(cb, delta);
        return () => clearInterval(interval);
    })
}
import FilesList from "../FilesList";
import Page from "../Page";

function FilesPage() {

    return <Page title="Files">
        <FilesList />
    </Page>
}

export default FilesPage;
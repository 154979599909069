import { Fetcher } from "@halliday/rest";
// import * as api from "./api";
import { login as apiLogin, register as apiRegister, resetPassword as apiResetPassword, updateProfilePicture as apiUpdateProfilePicture, TokenRequest, TokenResponse, User, changeEmail, completeRegistration, instructEmailChange, logout, refreshToken as apiRefreshToken, exchangeSocialLogin, AuthResponse, NewUser, UserUpdate, updateUsersSelf, LoginResponse, SocialLoginResponse, userinfo } from "./api";
import { hub } from "./Hub";
import { useEffect, useState } from "react";

export const nearlyExpiredThreshold = 30 * 1000; // 1 minute
// export const defaultKey = "session";

// export type User = api.User;
// export type UserUpdate = api.UserUpdate;
// export type NewUser = api.NewUser;

const accessTokenSubjectPrefix = "user|";

// export type SessionStatus =
//     "no-session" |
//     "login" | "logout" |
//     "userinfo" |
//     "refreshed" | "revoked" | "loaded" |
//     "email-confirmed" | "email-confirmation-failed" | "login-for-email-confirmation-required" |
//     "password-reset-required" |
//     "registration-completed" | "registration-failed" | "login-for-registration-required" |
//     "social-login-exchanged" | "social-login-failed" |
//     "unknown-token" |
//     "invalid-subject" |
//     `oauth2-${OAuth2ErrorStatus}`;

export type ChangeEmailTokenClaims = {
    aud: "_change_email"
    sub: string,
    email: string
}

export type PasswordResetTokenClaims = {
    aud: "_reset_password"
    sub: string,
    email: string
}

export type RegistrationTokenClaims = {
    aud: "_complete_registration"
    sub: string,
    email: string
}

export type FetchOptions = {
    fetcher?: Fetcher
}

export class Session {
    constructor(
        public accessToken: string,
        public refreshToken: string,
        // public scopes: string[],
        public issuedAt: Date,
        public expiresAt: Date,
        public user: User,
    ) {
        this.fetch = this.fetch.bind(this);
    }

    get expired() {
        return new Date() > this.expiresAt;
    }

    get nearlyExpired() {
        return new Date().getTime() + nearlyExpiredThreshold > this.expiresAt.getTime();
    }

    // Session subject = User ID
    get sub(): string | null {
        const token = parseToken(this.accessToken) as AccessTokenClaims;
        return token.sub.slice(accessTokenSubjectPrefix.length);
    }

    refresh = sequential(async (): Promise<void> => {
        const token = this.refreshToken;
        if (!token) throw new Error("No refresh token");
        const resp = await apiRefreshToken(token);
        this.accessToken = resp.accessToken;
        this.refreshToken = resp.refreshToken ?? this.refreshToken;
        // this.scopes = resp.scope === undefined ? this.scopes : (resp.scope === "" ? [] : resp.scope.split(" "));
        this.issuedAt = new Date();
        this.expiresAt = new Date(this.issuedAt.getTime() + resp.expiresIn * 1000);
        if (resp.idToken) {
            try {
                this.user = parseToken(resp.idToken);
            } catch (err) {
                console.error("Error parsing ID token", err);
            }
        }
        // this.store();
        // this.emit("refresh");
        // if (resp.idToken) {
        //     this.emit("userinfo");
        // }
    });

    async fetch(req: Request, opts: FetchOptions = {}): Promise<Response> {
        const { fetcher = globalThis.fetch } = opts;
        if (this.nearlyExpired) {
            try {
                await this.refresh();
            } catch (err) {
                console.warn("The session could not be refreshed:", err);
                if (window.session === this) setSession(null);
                throw { error: "session_refresh_failed", desc: "The session could not be refreshed.", code: 401, causedBy: err };
                // return Promise.reject({ error: "session_refresh_failed", desc: "The session could not be refreshed.", code: 401, causedBy: err });
            }
        }
        req.headers.set("Authorization", `Bearer ${this.accessToken}`);
        return fetcher(req);
    }

    // async fetchUserinfo() {
    //     this.user = await userinfo({ fetcher: this.fetch });
    //     this.emit("userinfo");
    // }

    async logout() {
        if (window.session === this) {
            localStorage.removeItem("session");
            window.session = null;
            hub.emit(".session", null);
        }
        if (this.refreshToken) {
            try {
                await logout(this.refreshToken);
            } catch (err) {
                console.error("Error logging out", err);
                return;
            }
        }
        return
    }

    // delete() {
    //     deleteSession(this.key);
    // }

    instructEmailChange(email: string, redirectUri = document.location.href): Promise<void> {
        return instructEmailChange(email, redirectUri, { fetcher: this.fetch });
    }

    async completeRegistration(token: string, redirectUri?: string) {
        await completeRegistration(token, redirectUri, { fetcher: this.fetch });
        this.user = { ...this.user, emailVerified: true };
        hub.emit(".user", this.user);
    }

    async changeEmail(token: string, redirectUri?: string) {
        const claims = parseToken(token) as ChangeEmailTokenClaims;
        if (claims.sub !== this.sub) throw new Error("Invalid subject");
        const email = claims.email;
        await changeEmail(token, redirectUri, { fetcher: this.fetch });
        this.user = { ...this.user, email, emailVerified: true };
        hub.emit(".user", this.user);
    }

    //

    // private listeners: { [type: string]: Set<(...args: any[]) => void> } = {};

    // addEventListener(type: SessionEventType, l: SessionEventListener): void {
    //     if (!this.listeners[type]) this.listeners[type] = new Set();
    //     this.listeners[type].add(l);
    // }

    // removeEventListener(type: SessionEventType, l: SessionEventListener): void {
    //     if (!this.listeners[type]) return;
    //     this.listeners[type].delete(l);
    // }

    // private emit(type: SessionEventType): void {
    //     if (!this.listeners[type]) return;
    //     const ev = new SessionEvent(this, type);
    //     for (const l of this.listeners[type]) {
    //         try {
    //             l(ev);
    //         } catch (err) {
    //             console.error("Error in session event listener", err);
    //         }
    //     }
    // }

    //

    static fromTokenResponse(resp: LoginResponse): Session {
        const now = new Date();
        return new Session(
            resp.accessToken,
            resp.refreshToken,
            now,
            new Date(now.getTime() + resp.expiresIn * 1000),
            resp.user,
        );
    }

    // static fromURLSearchParams(params: URLSearchParams): Session | null {
    //     const accessToken = params.get("access_token");
    //     const tokenType = params.get("token_type");
    //     if (!accessToken || !tokenType) return null;
    //     const refreshToken = params.get("refresh_token");
    //     const expiresAt = new Date(parseInt(params.get("expires_at")!) * 1000);
    //     const issuedAt = new Date(parseInt(params.get("issued_at")!) * 1000);
    //     const idToken = params.get("id_token")!;
    //     return new Session(accessToken, refreshToken, issuedAt, expiresAt, idToken);
    // }

    toURLSearchParams(): URLSearchParams {
        const p = new URLSearchParams();
        p.set("access_token", this.accessToken);
        if (this.refreshToken) p.set("refresh_token", this.refreshToken);
        p.set("issued_at", Math.floor(this.issuedAt.getTime() / 1000).toString());
        p.set("expires_at", Math.floor(this.expiresAt.getTime() / 1000).toString());
        if (this.user) p.set("id_token", createToken(this.user));
        return p;
    }


    async updateUser(u: UserUpdate) {
        await updateUsersSelf(u);
        this.user = { ...this.user, ...u };
        hub.emit(".user", this.user);
    }

    // store() {
    //     localStorage.setItem("session", this.toURLSearchParams().toString());
    // }
}

export type SessionEventListener = (ev: SessionEvent) => void;

export type SessionEventType = "refresh" | "userinfo" | "delete-user";

export class SessionEvent {
    constructor(
        readonly session: Session,
        readonly type: SessionEventType,
        readonly err?: any,
    ) { }
}

type AccessTokenClaims = {
    sub: string,
    scope: string
}

function parseToken(token: string): any {
    const parts = token.split(".");
    return JSON.parse(atob(parts[1]));
}

function createToken(u: User): string {
    const jwtHeaderAlgNone = {
        alg: "none",
        typ: "JWT",
    };
    return btoa(JSON.stringify(jwtHeaderAlgNone)) + "." + btoa(JSON.stringify(u)) + ".";

}

function sequential<Fn extends () => Promise<any>>(fn: Fn): Fn {
    let pending: Promise<any> | null = null;
    return ((...args) => {
        if (pending) return pending;
        pending = fn(...args);
        pending.then(() => pending = null);
        return pending;
    }) as Fn;
}

////////////////////////////////////////////////////////////////////////////////

function setSession(sess: Session | null) {
    window.session = sess;
    if (sess) localStorage.setItem("session", sess.toURLSearchParams().toString());
    else localStorage.removeItem("session");
    hub.emit(".session", sess);
}

export async function login(username: string, password: string) {
    const resp = await apiLogin(username, password);
    const sess = Session.fromTokenResponse(resp);
    setSession(sess);
    if (token) consumeToken();
    return sess;
}

export async function register(user: NewUser) {
    if (!user.email) throw new Error("email is required");
    const resp = await apiRegister(user, document.location.href);
    const session = Session.fromTokenResponse(resp);
    setSession(session);
    emit("register");
}

export async function resetPassword(newPassword: string): Promise<void> {
    if (!token) throw new Error("No token loaded from the URL.");
    const claims = parseToken(token) as PasswordResetTokenClaims;
    if (claims.aud !== "_reset_password") throw new Error("The token loaded from the URL is not a password reset token.");
    await apiResetPassword(token, newPassword);
    token = null;
}

export async function clearSession() {
    window.session = null;
    localStorage.removeItem("session");
    hub.emit(".session", null);
}

export function useSession(): Session | null {
    const [session, setSession] = useState<Session | null>(window.session);
    useEffect(() => {
        hub.on(".session", setSession);
        return () => hub.off(".session", setSession);
    }, []);
    return session;
}

export function useUser(): User | null {
    const [user, setUser] = useState<User | null>(window.session?.user || null);
    useEffect(() => {
        const setSession = (sess: Session | null) => setUser(sess?.user || null);
        hub.on(".session", setSession);
        hub.on(".user", setUser);
        return () => {
            hub.off(".session", setSession);
            hub.off(".user", setUser);
        }
    }, []);
    return user;
}

export async function updateProfilePicture(body: BodyInit) {
    const sess = window.session;
    if (!sess) throw new Error("Not logged in.");
    const { picture } = await apiUpdateProfilePicture(body);
    window.session!.user = { ...window.session!.user, picture, updatedAt: new Date() };
    hub.emit(".session", window.session);
}

////////////////////////////////////////////////////////////////////////////////

let setupCalled = false;
let token: string | null = null;
let emailHint: string | null = null;
let requiredAction: "password-reset" | "login-for-email-verify" | "login-for-registration" | null = null;

export function hintEmail(): string | null {
    return emailHint;
}

export function getRequiredAction() {
    return requiredAction;
}

// Setup performs initial work for the identity manager.
// It loads the last session from local storage and tries to resume it.
// This function must be called once.
export async function setupIdent(): Promise<void> {
    window.session = null;
    //

    const hash = new URLSearchParams(window.location.hash.slice(1));

    // check for an code or access_token in the URL, as returned by an OAuth2 authorization server (e.g. a social login provider)
    const search = new URLSearchParams(window.location.search);

    // response_type=code
    // see https://www.rfc-editor.org/rfc/rfc6749#section-4.1.2
    const code = search.get("code") ?? hash.get("code") ?? undefined;
    // response_type=token
    // see https://www.rfc-editor.org/rfc/rfc6749#section-4.2.2
    const access_token = search.get("access_token") ?? hash.get("access_token") ?? undefined;
    const token_type = search.get("token_type") ?? hash.get("token_type") ?? undefined;
    const expires_in = search.get("expires_in") ?? hash.get("expires_in") ?? undefined;
    const scope = search.get("scope") ?? hash.get("scope") ?? undefined;
    const id_token = search.get("id_token") ?? hash.get("id_token") ?? undefined;

    const state = search.get("state") ?? hash.get("state") ?? undefined;

    if (code || access_token || id_token) {
        stripParams("code", "access_token", "token_type", "expires_in", "scope", "id_token", "state");
        window.location.hash = "";

        let resp: SocialLoginResponse | undefined;
        try {
            resp = await exchangeSocialLogin({ code, access_token, token_type, expires_in, scope, id_token, state } as AuthResponse);
            const sess = Session.fromTokenResponse(resp);
            setSession(sess);
            emit("social-login");
            return;
        } catch (err) {
            console.warn("The social login could not be completed. The token loaded from the URL is invalid or has expired.");
            emit("social-login-error", err);
            return;
        }
    }

    const error = search.get("error") ?? hash.get("error") ?? undefined;
    if (error) {
        const errorDescription = search.get("error_description") ?? hash.get("error_description") ?? undefined;
        const errorUri = search.get("error_uri") ?? hash.get("error_uri") ?? undefined;
        stripParams("error", "error_description", "error_uri", "state");
        console.warn("OAuth2 error:", error, errorDescription, errorUri);
        emit(`oauth2-${error as OAuth2ErrorStatus}`);
        return;
    }

    // load the last session from storage

    let sess: Session | undefined | null;
    try {
        sess = loadLastSessionFromStorage();
    } catch (err) {
        console.warn("The last session could not be loaded from storage:", err);
    }

    if (sess) {
        let user: User | undefined;
        try {
            user = await userinfo({}, { fetcher: sess.fetch });
        } catch (err) {
            console.warn("The user info could not be loaded:", err);
        }
        if (user) {
            sess.user = user;
            setSession(sess);
        }
    }

    // check for a token in the URL that might require some action

    token = hash.get("token");
    if (token) {
        stripHashParams("token");
        try {
            await consumeToken();
        } catch (err) {
            if (err instanceof IdentityError) {
                if (err.type === "invalid-subject") {
                    localStorage.removeItem("session");
                    try {
                        await window.session!.logout();
                    } catch (err) {
                        // log error but discard anyways
                        console.warn("The old session could not be logged out:", err);
                    }
                    setSession(null);
                    // try again without a session
                    await consumeToken();
                    return;
                } else {
                    throw err;
                }
            } else {
                throw err;
            }
        }
    }
}

async function consumeToken(): Promise<void> {
    if (!token) throw new Error("no token");

    const redirectUri = new URLSearchParams(window.location.hash.slice(1)).get("redirect_uri") || undefined;
    const claims = parseToken(token) as ChangeEmailTokenClaims | PasswordResetTokenClaims | RegistrationTokenClaims;
    if (window.session && window.session.sub !== claims.sub) {
        throw new IdentityError("invalid-subject");
    }

    switch (claims.aud) {
        case "_change_email":
            if (window.session) {
                try {
                    await window.session.changeEmail(token, redirectUri);
                    token = null;
                    emit("email-verify");
                    return;
                } catch (err) {
                    console.warn("The email change could not be completed. The token loaded from the URL is invalid or has expired.");
                    token = null;
                    emit("email-verify-error", err);
                    return;
                }
            } else {
                emailHint = claims.email;
                requiredAction = "login-for-email-verify";
                emit("login-for-email-verify-required");
                return
            }

        case "_reset_password":

            emailHint = claims.email;
            requiredAction = "password-reset";
            emit("password-reset-required");
            return

        case "_complete_registration":

            if (window.session) {
                try {
                    await window.session.completeRegistration(token, redirectUri);
                    token = null;
                    emit("registration-complete");
                    return;
                } catch (err) {
                    console.warn("The registration could not be completed. The token loaded from the URL is invalid or has expired.");
                    token = null;
                    emit("registration-error", err);
                    return;
                }
            } else {
                emailHint = claims.email;
                requiredAction = "login-for-registration";
                emit("login-for-registration-required");
                return;
            }
        default:
            console.warn("The token loaded from the URL has an unknown audience and can not be processed.");
            token = null;
            emit("unknown-token");
            return;
    }
}

////////////////////////////////////////////////////////////////////////////////

export type OAuth2ErrorStatus = "invalid_request" | "unauthorized_client" | "access_denied" | "unsupported_response_type" | "invalid_scope" | "server_error" | "temporarily_unavailable";

export type IdentityEventType =
    "session" |
    "login" | "logout" | "revoke" |
    "email-verify" | "email-verify-error" | "login-for-email-verify-required" |
    "password-reset-required" |
    "register" | "registration-complete" | "registration-error" | "login-for-registration-required" |
    "social-login" | "social-login-error" |
    "unknown-token" |
    "invalid-subject" |
    `oauth2-${OAuth2ErrorStatus}` |
    SessionEventType;

export class IdentityEvent {
    constructor(
        readonly type: IdentityEventType,
        readonly err?: any) { }
}

export class IdentityError {
    constructor(
        readonly type: IdentityEventType,
        readonly cause?: any) { }
}

function emit(status: IdentityEventType, err?: any): void {
    const ev = new IdentityEvent(status, err);
    hub.emit(".ident", ev);
}

////////////////////////////////////////////////////////////////////////////////

function loadLastSessionFromStorage(): Session | null {
    const storage = localStorage.getItem("session");
    if (!storage) return null;

    const params = new URLSearchParams(storage);
    const accessToken = params.get("access_token");
    if (!accessToken) {
        console.log("There is a session in storage, but it has no access token.");
        localStorage.removeItem("session");
        throw null;
    }
    const refreshToken = params.get("refresh_token")!;
    const expiresAt = new Date(parseInt(params.get("expires_at")!) * 1000);
    const issuedAt = new Date(parseInt(params.get("issued_at")!) * 1000);
    const user = parseToken(params.get("id_token")!);
    return new Session(accessToken, refreshToken, issuedAt, expiresAt, user);
}

function stripHashParams(...params: string[]) {
    const url = new URL(window.location.href);
    const hash = new URLSearchParams(url.hash.substring(1));
    for (const param of params) {
        hash.delete(param);
    }
    url.hash = hash.toString();
    window.history.replaceState(window.history.state, document.title, url);
}

function stripSearchParams(...params: string[]) {
    const url = new URL(window.location.href);
    const search = new URLSearchParams(url.search);
    for (const param of params) {
        search.delete(param);
    }
    url.search = search.toString();
    window.history.replaceState(window.history.state, document.title, url);
}

function stripParams(...params: string[]) {
    const url = new URL(window.location.href);

    const search = new URLSearchParams(url.search);
    for (const param of params) {
        search.delete(param);
    }
    url.search = search.toString();

    const hash = new URLSearchParams(url.hash.substring(1));
    for (const param of params) {
        hash.delete(param);
    }
    url.hash = hash.toString();

    window.history.replaceState(window.history.state, document.title, url);
}
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Fab, IconButton, Link, List, ListItem, ListItemButton, ListItemText, ListSubheader, Menu, MenuItem, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSolution, Solution, SolutionResource, SolutionStep } from "../../api";
import ErrorView from "../ErrorView";
import { FirstPage, Fullscreen, FullscreenExit, NavigateBefore, NavigateNext } from "@mui/icons-material";
import Page, { useMediaQueryMobile, TOC, TOCList, TOCListItem } from "../Page";
import { renderHTML } from "../tools";
import { useSession } from "../../ident";
import ExtensionIcon from '@mui/icons-material/Extension';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import LabelIcon from '@mui/icons-material/Label';


const architectureP = 40;
const popupMx = 8;
const popupMy = 8;
const popupWidth = 300;

const SVG = styled("svg")({});
const IMG = styled("img")({});

export default function SolutionPage() {
    const navigate = useNavigate();

    const params = useParams() as { id: string } | {org: string, short: string};
    const id = "id" in params ? params.id : params.org + "/" + params.short;
    
    // Mobile is true on small devices.
    const mobile = useMediaQueryMobile();
    const [solution, setSolution] = useState<Solution | null>(null)
    const [err, setErr] = useState<any>(null);
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const drawerWidth = sm ? (lg ? 250 : 64) : 0;
    let tocContent: JSX.Element | null = null;

    const [selectedStepId, setSelectedStepId] = useState<SolutionStep["id"] | null>(null);
    const selectedStepIdx = solution?.steps.findIndex(step => step.id === selectedStepId) ?? -1;
    const numSteps = solution?.steps.length ?? 0;
    const selectedStep = solution?.steps[selectedStepIdx] ?? null;
    const canSelectNextStep = selectedStepIdx < numSteps - 1 && numSteps > 0;
    function selectNextStep() {
        if (!canSelectNextStep) return;
        state.scrollToStep = true;
        setSelectedStepId(solution?.steps[selectedStepIdx + 1].id ?? null);
    }
    const canSelectPrevStep = selectedStepIdx > 0 && numSteps > 0;
    function selectPrevStep() {
        state.scrollToStep = true;
        if (!canSelectPrevStep) setSelectedStepId(null);
        else setSelectedStepId(solution?.steps[selectedStepIdx - 1].id ?? null);
    }
    function selectFirstStep() {
        state.scrollToStep = true;
        setSelectedStepId(null);
    }

    function load() {
        if (!id) {
            navigate("/solutions");
            return;
        }
        getSolution(id).then(setSolution, setErr);
    }

    const sess = useSession();
    useEffect(load, [sess, id]);

    const [fullscreen, setFullscreen] = useState(false);
    function toggleFullscreen() {
        setFullscreen(!fullscreen);
    }

    const ref = useRef<HTMLElement>(null);

    let [popupX, setPopupX] = useState(0);
    let [popupY, setPopupY] = useState(0);
    popupX = Math.max(Math.min(popupX, window.innerWidth - 20 - popupWidth), 20);
    popupY = Math.max(Math.min(popupY, window.innerWidth - 20 - popupWidth), 20);

    const [state] = useState({
        scrollToStep: false,
    });

    const [selectedResource, setSelectedResource] = useState<SolutionResource | null>(null);
    const [popupOpen, setPopupOpen] = useState(false);

    useLayoutEffect(() => {
        if (popupOpen && ref.current) {
            const r = ref.current.querySelector(`[data-item-id="${selectedResource!.id}"]`) as HTMLElement;
            const rect = r.getBoundingClientRect();
            if (rect.x > window.innerWidth - rect.right) {
                setPopupX(rect.x - popupMx - popupWidth);
                setPopupY(rect.y + popupMy);
            } else {
                setPopupX(rect.right + popupMx);
                setPopupY(rect.y + popupMy);
            }
        }
    });

    useEffect(() => {
        if (state.scrollToStep) {
            const page = document.querySelector(".LabPage")!;
            state.scrollToStep = false;
            page.scrollTop = canvasHeight;
        }
    });

    useEffect(() => {
        function handleClick(ev: MouseEvent) {
            if (ev.target && ev.target instanceof HTMLElement && ev.target.closest(".ResourcePopup, .Resource"))
                return;
            setPopupOpen(false);
        }
        document.body.addEventListener("click", handleClick);
        return () => {
            document.body.removeEventListener("click", handleClick);
        }
    }, []);

    const [stepsListAnchorEl, setStepsListAnchorEl] = useState<HTMLElement | null>(null);
    function handleStepsListClose() {
        setStepsListAnchorEl(null);
    }
    function handleStepsClick(ev: React.MouseEvent<HTMLElement>) {
        setStepsListAnchorEl(ev.currentTarget);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    if (err) return <ErrorView err={err} sx={{mt: 8}}/>;
    if (solution === null) return null;

    const { coverUrl, title, desc, resources, lines, steps } = solution

    const canvasHeight = sm ? 600 : windowWidth;
    const canvasWidth = windowWidth - drawerWidth;
    const height = canvasHeight - 2 * architectureP;
    const width = canvasWidth - 2 * architectureP;
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    for (const r of resources) {
        minX = Math.min(minX, r.x);
        minY = Math.min(minY, r.y);
        maxX = Math.max(maxX, r.x + r.imageWidth!);
        maxY = Math.max(maxY, r.y + r.imageHeight!);
    }

    const s = Math.min(height / (maxY - minY), width / (maxX - minX));
    const oX = (width / 2 - (minX + (maxX - minX) * s / 2)) + architectureP;
    const oY = (height / 2 - (minY + (maxY - minY) * s / 2)) + architectureP;

    function handleResourceClick(ev: React.MouseEvent, r: SolutionResource) {
        setSelectedResource(r);
        setPopupOpen(true);
    }

    let popup: React.ReactNode = null;
    if (selectedResource) {
        const resourceSteps = solution!.steps.filter(step => selectedResource.steps.includes(step.id));

        popup = <Card
            hidden={popupOpen === false}
            className="ResourcePopup"
            sx={{
                width: popupWidth,
                boxSizing: "border-box",
                position: "fixed",
                zIndex: 1200,
                top: 0,
                left: 0,
            }}
            style={{ transform: `translate(${popupX}px, ${popupY}px)` }}
        >
            <CardHeader
                avatar={<Avatar src={selectedResource.iconUrl} />}
                title={selectedResource.title}
                titleTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                subheader="by Waziup"
            />
            <CardContent>
                <List
                    dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {resourceSteps.length} steps
                        </ListSubheader>
                    }
                >
                    {resourceSteps.map((step, idx) => {

                        function selectStep() {
                            state.scrollToStep = true;
                            setSelectedStepId(step.id);
                            setPopupOpen(false);
                        }

                        return (
                            <ListItem
                                key={step.id}
                                disablePadding
                            >
                                <ListItemButton onClick={selectStep}>
                                    <ListItemText primary={step.title} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </CardContent>
        </Card>;
    }



    const architecture = (
        <Box
            ref={ref}
            sx={fullscreen ? {
                position: "fixed",
                zIndex: 1205,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#eeeeee',
                backgroundSize: "15px 15px",
                backgroundPosition: "-7px -7px",
                backgroundImage: "linear-gradient(to right, #e3e3e3 1px, transparent 1px), linear-gradient(to bottom, #e3e3e3 1px, transparent 1px)"
            } : {
                position: 'relative',
                height: canvasHeight,
                width: '100%',
                backgroundColor: '#eeeeee',
                backgroundSize: "15px 15px",
                backgroundPosition: "-7px -7px",
                backgroundImage: "linear-gradient(to right, #e3e3e3 1px, transparent 1px), linear-gradient(to bottom, #e3e3e3 1px, transparent 1px)",
                mb: 5,
            }}
        >
            {resources.map(item => {
                const x = oX + minX + (item.x - minX) * s;
                const y = oY + minY + (item.y - minY) * s;
                const width = item.imageWidth! * s;
                const height = item.imageHeight! * s;
                return <Fragment key={item.id}>
                    <IMG
                        src={item.imageUrl}
                        style={{ transform: `translate(${x}px, ${y}px)`, width: `${width}px`, height: `${height}px` }}
                        sx={{ cursor: "pointer", position: "absolute", top: 0, left: 0, "&:hover": { filter: "drop-shadow(1px 2px 3px #0005)" } }}
                        onClick={ev => handleResourceClick(ev, item)}
                        className="Resource"
                        data-item-id={item.id}
                    />
                    <Box
                        component="label"
                        sx={{pointerEvents: "none", position: "absolute", top: 0, left: 0, height: "20px", bgcolor: "#eeeeee", textAlign: "center", px: "4px", py: "2px", fontFamily: "monospace", color: "#a3a3a3" }}
                        style={{transform: `translate(${x}px, ${y + height + 10*s}px)`, width: `${width}px`}}
                    >
                        {item.title}
                    </Box>
                </Fragment>
            })}
            <SVG sx={{
                width: "100%",
                height: `100%`,
            }}>
                {/* <rect x={minX + oX} y={minY + oY} width={(maxX - minX) * s} height={(maxY - minY) * s} fill="none" stroke="red" strokeWidth={1} /> */}
                {lines!.map(line => {
                    const d = line.l.reduce((p, d, i) => ({ x: p.x + d * ((i + 1) % 2), y: p.y + d * (i % 2) }), { x: 0, y: 0 });
                    return <path key={line.id} d={`M ${minX + (line.x - minX) * s + oX} ${minY + (line.y - minY) * s + oY} l ${d.x * s} ${d.y * s}`} stroke="#8fb8f5" strokeWidth="16" fill="none" strokeLinecap="round"/>;
                })}
            </SVG>

            <Box sx={{ position: "absolute", bottom: 0, right: 0, display: "flex", gap: .5, px: 1, py: .5 }}>
                <IconButton onClick={toggleFullscreen}>{fullscreen ? <FullscreenExit /> : <Fullscreen />}</IconButton>
            </Box>

            {popup}
        </Box>
    )

    const stepsMenu = <Menu
        id="all-steps-menu"
        anchorEl={stepsListAnchorEl}
        open={stepsListAnchorEl !== null}
        onClose={handleStepsListClose}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
        {
            steps!.map((step, idx) => {
                function selectStep() {
                    setSelectedStepId(step.id);
                    handleStepsListClose();
                }
                return (
                    <MenuItem
                        key={step.id}
                        onClick={selectStep}
                        sx={{ bgcolor: step.id === selectedStepId ? 'grey.200' : 'transparent' }}
                    >
                        <ListItemText primary={step.title} />
                    </MenuItem>
                );
            })
        }
    </Menu>;

    let content: React.ReactNode = null;
    if (selectedStep) {
        const resource = resources.find(r => r.id === selectedStep.resource);
        const idx = steps!.findIndex(s => s.id === selectedStep.id);
        content = <Box>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
                    {resource && <Link underline="hover" color="inherit">
                        {resource.title}
                    </Link>}
                    <Typography color="text.primary">{selectedStep.title}</Typography>
                </Breadcrumbs>
                <Link underline="hover" color="text.secondary" onClick={handleStepsClick} sx={{cursor: "pointer"}}>
                    <Typography color="text.primary">Step {idx + 1} / {steps!.length}</Typography>
                </Link>
            </Stack>

            {stepsMenu}

            <Typography variant="h6">
                {selectedStep.title}
            </Typography>

            {renderHTML(selectedStep.content)}

            <Stack direction="row" gap={2} sx={{ mt: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <IconButton onClick={selectFirstStep}><FirstPage /></IconButton>
                    <IconButton onClick={selectPrevStep}><NavigateBefore /></IconButton>
                </Box>
                <Button variant="outlined" color="inherit" endIcon={<NavigateNext />} disabled={!canSelectNextStep} onClick={selectNextStep}>Next</Button>
            </Stack>
        </Box>
    } else {

        content = <Box>

            {stepsMenu}

            {renderHTML(solution.intro)}

            <Stack direction="row" gap={2} sx={{ mt: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <IconButton disabled={true}><FirstPage /></IconButton>
                    <IconButton disabled={true}><NavigateBefore /></IconButton>
                </Box>
                <Button variant="contained" endIcon={<NavigateNext />} disabled={!canSelectNextStep} onClick={selectNextStep}>Start</Button>
            </Stack>
        </Box>
    }

    const action = mobile ? (
        <Fab
            sx={{ position: "fixed", bottom: 16, right: 16 }}
            aria-label="like"
            color="primary"
            onClick={() => navigate('/my-solutions/solution/editor')}
        >
            <EditIcon />
        </Fab>
    ) : (
        <Button
            startIcon={<EditIcon />}
            sx={{
                mr: 2,
                pl: 2,
                pr: 2,
                bgcolor: "grey.200",
                ":hover": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                },
            }}
            onClick={() => navigate('/my-solutions/solution/editor')}
        >
            Edit
        </Button>
    );

    tocContent = <>
        <TOCList>
            <TOCListItem
                    key={"overview"}
                    active={selectedStepId === null}
                    primary={<Stack direction="column" alignItems="left">
                        <Typography sx={{ flexGrow: 1 }}>Overview</Typography>
                    </Stack>}
                    opener={ selectedStepId === null ? <ExtensionIcon /> : <ExtensionOutlinedIcon /> }
                    onClick={() => {
                        setSelectedStepId(null);
                    }}
            />
            <Typography variant="caption" pl={2} pt={2} color='text.secondary'>Solution Steps</Typography>
            <TOCListItem>
                {steps!.map((step, idx) => {
                    function selectStep() {
                        setSelectedStepId(step.id);
                    }
                    return (
                        <TOCListItem key={idx}
                            active={step.id === selectedStepId}
                            primary={<Stack direction="column" alignItems="left">
                                <Typography sx={{ flexGrow: 1 }}>
                                    {step?.title?.split(":")[1] ?? step.title}
                                </Typography>
                            </Stack>}
                            secondary={
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography variant="caption" color="text.secondary">{step?.title?.split(":")[0] ?? ""}</Typography>
                                </Stack>
                            }
                            opener={ step.id === selectedStepId ? <LabelIcon /> : <LabelOutlinedIcon /> }
                            onClick={selectStep}
                        />
                    );
                })}
            </TOCListItem>
        </TOCList>
    </>

    return (
        <Page
            title={title}
            tocPrimary={desc}
            toc={<TOC>{tocContent}</TOC>}
            onNavigateBack={() => navigate(-1)}
            prevPage="Solutions"
        >
            <Box sx={{ width: "100%" }} className="LabPageContainer">
                {architecture}
                <Box sx={{ p: [2, 6, null, null, 8], pr: [2, 4, null, null, 12], pt: [4, 1, null, null, 1], pb: 8, display: "flex", flexDirection: "column", overflow: "hidden auto", maxWidth: 1200 }} className="LabPageContent">
                    {content}
                </Box>
            </Box>
        </Page>
    );
}

////////////////////////////////////////////////////////////////////////////////

import { DeveloperBoardOutlined, SvgIconComponent, ApiOutlined, TerminalOutlined } from "@mui/icons-material";
import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import restrest, { handleResponse, Polish, RestError, RestOptions } from "@halliday/rest";
import { camel2snakeObject, snake2camelObject } from "./tools";
import Waziup from "./components/icons/Waziup";
import { hub } from "./Hub";
import { apiUrl } from "./config";

export async function fetch(req: Request): Promise<Response> {
    // return ident.fetch(req, { onRefreshFailed: "fail" });
    if (window.session) return window.session.fetch(req);
    return globalThis.fetch(req);
}

export function abs(path: string) {
    if (path.includes(":") || path.startsWith("/")) return path;
    return apiUrl + path;
}

export interface APIRestOptions extends RestOptions {
    unauthorized?: boolean,
}

function rest(method: string, endpoint: string, data: any, opts: APIRestOptions = {}): Promise<any> {
    const fetcher = opts.unauthorized ? globalThis.fetch : fetch;
    return restrest(method, abs(endpoint), data, { fetcher, ...opts });
}

////////////////////////////////////////////////////////////////////////////////

export type Social = {
    iss: string,
    picture?: string,
    profile?: string,
    website?: string,
}

// export type User = Userinfo & {
//     suspended?: boolean,
//     created_at: number,
//     updated_at: number,
//     socials?: Social[],
// }

export interface PageRequest {
    pageToken?: string,
    pageSize?: number,
}

export interface PageResponse {
    numFound: number,
    numTotal: number,
    nextPageToken?: string,
}


// *** Resources ***

export type OrgItemId = string

export interface OrgItem {
    id: OrgItemId,
    weakId?: string,

    owner: UserId | null,
    ownerPreferredUsername?: string,
    ownerPicture?: string,

    org: OrganizationId,
    orgName: string,
    orgIconUrl?: string,

    createdAt: Date,
    modifiedAt: Date,
}

function polishOrgItem<R extends OrgItem>(r: R): R {
    r.createdAt = new Date(r.createdAt);
    r.modifiedAt = new Date(r.modifiedAt);
    if (r.orgIconUrl) r.orgIconUrl = absURL(r.orgIconUrl);
    if (r.ownerPicture) r.ownerPicture = absURL(r.ownerPicture);
    return r;
}


export interface NewOrgItem {
    org: OrganizationId | null
}

export interface NewOrgItemRespone {
    id: OrgItemId
}

export interface PatchOrgItemRequest {
    id: OrgItemId,
}

export interface OrgItemRequest {
    id: OrgItemId,
}

export function absURL(path: string) {
    if (path.startsWith("http:") || path.startsWith("https:"))
        return path;
    if (path.startsWith("/"))
        return new URL(apiUrl, window.location.href).origin + path;
    return new URL(apiUrl, window.location.href).origin + "/" + path;
}

export function toURL(p: string) {
    return absURL("api/v1/" + p);
}

export function toThumbnailURL(id: OrgItemId) {
    return absURL(`files/${id}/thumbnail.png`);
}

export function toImageURL(id: OrgItemId) {
    return absURL(`files/${id}/file.png`);
}

export function fileUrl(file: File) {
    return absURL(`files/${file.id}/file${file.ext}`);
}

// *** Files ***

export type FileId = OrgItemId;

export interface File extends OrgItem {
    name: string,
    ext: string,
    related: OrgItemId | null,
    width?: number,
    height?: number,
    duration?: number,
    pages?: number,
    size: number,
    url: string,
}

export interface NewFileRequest {
    file: globalThis.File,
    thumbnail?: Blob,
    width?: number,
    height?: number,
    // related: ResourceId | null,
}

export interface NewFileResponse extends NewOrgItemRespone {
    id: OrgItemId,
    width?: number,
    height?: number,
    duration?: number,
    pages?: number,
    url: string,
}

export interface FilesFilter {
    q?: string, // text search query
    related?: OrgItemId,
}

export interface FilesRequest extends FilesFilter, PageRequest {
    org?: OrganizationId
}

export interface PatchFileRequest extends OrgItemRequest {
    name?: string,
    rank?: number,
}

export interface FilesResponse extends PageResponse {
    files: File[]
}

function polishFiles(r: FilesResponse) {
    r.files.forEach(polishFile);
    return r;
}

function polishFile(f: File) {
    polishOrgItem(f);
    f.url = absURL(f.url);
    return f;
}



// *** Organizations ***

export type OrganizationId = string;

export interface Organization {
    id: OrganizationId,
    slug: string,
    owner: UserId | null,
    createdAt: Date,
    modifiedAt: Date,

    title: string,
    desc: string,
    cover: File | null,
    coverUrl?: string;
    banner: FileId | null,
    bannerUrl?: string;

    numFiles?: number,
    filesSize?: number,

    public: boolean,
    open: boolean,
    issuer?: string,

    joined: boolean,
    joinedAt: Date | null,
    added: boolean,
    addedAt: Date | null,
    roles: string[],
}

export interface OrganizationsFilter {
    q?: string, // text search query
    private?: boolean,
    member?: boolean, // returns only organizations where the user is a member
    user?: UserId, // defaults to current user
}

export interface OrganizationRequest {
    id: OrganizationId,
}

export interface OrganizationsRequest extends OrganizationsFilter, PageRequest {
}


export interface OrganizationsResponse extends PageResponse {
    orgs: Organization[]
}


function polishOrganizations(r: OrganizationsResponse) {
    r.orgs.forEach(polishOrganization);
    return r;
}

export interface NewOrganization {
    title: string,
    desc?: string,
    cover?: OrgItemId | null,
    banner?: OrgItemId | null,
    public?: boolean,
    issuer?: string,
}

export interface PatchOrganizationRequest {
    slug: string,
    title?: string,
    desc?: string,
    cover?: OrgItemId | null,
    banner?: OrgItemId | null,
    public?: boolean,
    open?: boolean,
}

function polishOrganization(o: Organization) {
    o.createdAt = new Date(o.createdAt);
    o.modifiedAt = new Date(o.modifiedAt);
    if (o.coverUrl) o.coverUrl = absURL(o.coverUrl);
    if (o.cover?.url) o.cover.url = absURL(o.cover.url);
    if (o.bannerUrl) o.bannerUrl = absURL(o.bannerUrl);
    // if (o.banner?.url) o.banner.url = absURL(o.banner.url);
    return o;
}

// *** Modules ***

export type ModuleId = string;
export type ModuleCourse = Omit<Course, "topics" | "files">;
export interface Module {
    id: ModuleId,
    owner: UserId | null,
    createdAt: Date,
    modifiedAt: Date,
    title: string,
    shortTitle: string,
    desc: string,
    content: string,
    cover: FileId | null,
    coverUrl?: string;
    rank: number,
    numCourses: number,
    icon: string,
    iconUrl?: string,
    // courses: ModuleCourse[],
}

export interface ModulesFilter {
    q?: string, // text search query
}

export interface ModulesRequest extends ModulesFilter, PageRequest {
    orgs?: OrganizationId[],
}


export interface ModulesResponse extends PageResponse {
    modules: Module[]
}

function polishModules(r: ModulesResponse) {
    r.modules.forEach(polishModule);
    return r;
}

export interface NewModule extends NewOrgItem {
    title: string,
}

export interface PatchModuleRequest extends PatchOrgItemRequest {
    title?: string,
    desc?: string,
    content?: string,
    cover?: OrgItemId | null,
    rank?: number,
}

function polishModule(m: Module) {
    m.createdAt = new Date(m.createdAt);
    m.modifiedAt = new Date(m.modifiedAt);
    if (m.coverUrl) m.coverUrl = absURL(m.coverUrl);
    if (m.iconUrl) m.iconUrl = absURL(m.iconUrl);
    return m;
}



// *** Courses ***

type CourseId = OrgItemId;
export type UserCourseId = string;

export interface Course extends OrgItem {
    module: ModuleId,
    moduleTitle: string,
    title: string,
    desc: string,
    cover?: File,
    files: File[],
    // topics: Topic[],
    draft?: boolean,
    duration?: number,
    difficulty?: number,
    rank: number,

    joined: boolean,
    joinedAt: Date | null,

    numQuiz: number,
    numQuizDone: number,

    numTopics: number,
    numTopicsDone: number,
}

export function genericProgress(topics: number, topicsDone: number, quiz: number, quizDone: number): number {
    if (topics && quiz) return (topicsDone + quizDone) / (topics + quiz);
    if (topics) return topicsDone / topics;
    if (quiz) return quizDone / quiz;
    return -1;

}

export function courseProgress(c: Course): number {
    if (!c.joined) return 0;
    return genericProgress(c.numTopics, c.numTopicsDone, c.numQuiz, c.numQuizDone);
}

export interface NewCourse extends NewOrgItem {
    module: ModuleId,
    title: string,
    rank?: number,
}

export interface PatchCourseRequest extends PatchOrgItemRequest {
    title?: string,
    desc?: string,
    content?: string,
    cover?: OrgItemId | null,
    rank?: number,
}

export interface CoursesRequest extends PageRequest {
    module?: ModuleId,
    orgs?: OrganizationId[],
    difficulty?: string,
    duration?: string,
    joined?: boolean,
}

export interface CoursesResponse extends PageResponse {
    courses: Course[],
}

function polishCourse(c: Course) {
    polishOrgItem(c);
    if (c.joinedAt) c.joinedAt = new Date(c.joinedAt);
    return c;
}

function polishCourses(r: CoursesResponse) {
    r.courses = r.courses.map(polishCourse);
    return r;
}

// *** Topics ***

export type TopicId = OrgItemId;

export interface Topic extends OrgItem {
    //module: OrgItemId,
    course: OrgItemId,
    title: string,
    desc: string,
    content: string,   //Markdown string
    draft?: boolean,
    done?: boolean,
    rank: number,
}

export interface NewTopic extends NewOrgItem {
    course: OrgItemId,
    title: string,
    rank?: number,
}

export interface PatchTopicRequest extends PatchOrgItemRequest {
    title?: string,
    desc?: string,
    content?: string,
    cover?: OrgItemId | null,
    draft?: boolean,
}

export interface TopicsRequest extends PageRequest {
    course?: CourseId,
    user?: UserId,
    // userCourse?: UserCourseId,
}

export interface TopicsResponse extends PageResponse {
    topics: Topic[],
    // topic: TopicId,
}

function polishTopics(r: TopicsResponse) {
    r.topics = r.topics.map(polishOrgItem);
    return r;
}

////////////////////////////////////////////////////////////////////////////////

export type AnswerQuizRequest = {
    quiz: string,
    course: string,
    answer: any,
}

export type AnswerQuizResponse = {
    right: boolean,
    feedback?: string,
}

// export type UserCourse = {
//     id: string,
//     user: UserId,
//     createdAt: Date,
//     course: Course,
//     numQuizDone: number,
// }

// export type UserCourseRequest = {
//     id: string,
// }

// export type UserCourseResponse = UserCourse

// export type UserCoursesRequest = {
//     user?: UserId
// }

// export type UserCoursesResponse = {
//     courses: UserCourse[]
// }

export type EnroleCourseRequest = {
    course: string,
    // user?: string,
}

export type EnroleCourseResponse = {
    id: string,
}

export type UnenroleCourseRequest = {
    id: string,
}


////////////////////////////////////////////////////////////////////////////////

export type ResourceId = OrgItemId;

export type ResourceType = "hardware" | "software" | "api" | string;

export interface Resource extends OrgItem {
    type: ResourceType,
    title: string,
    desc: string,
    color: string,
    icon: FileId | null,
    iconUrl?: string;
    cover: FileId | null,
    coverUrl?: string;
    draft: boolean,
    count?: number,
    tags?: string[],
    docs: ResourceDocument[],
    files: ResourceFile[],
    content: string,
}

export interface NewResource extends NewOrgItem {
    title: string,
    desc: string,
    type: ResourceType,
    draft: boolean,
}

export interface PatchResourceRequest extends PatchOrgItemRequest {
    title?: string,
    desc?: string,
    icon?: FileId | null,
    cover?: OrgItemId | null,
    draft?: boolean,
}

export interface ResourcesRequest extends PageRequest {
    tags?: string[],
    tagsX?: string,
    type?: ResourceType,
    // user?: UserId,
    myLab?: boolean,
    org?: OrganizationId[]
}

export interface ResourcesResponse extends PageResponse {
    resources: Resource[],
}

function polishResources(r: ResourcesResponse) {
    r.resources = r.resources.map(polishResource);
    return r;
}

function polishResource(r: Resource) {
    polishOrgItem(r);
    if (r.coverUrl) r.coverUrl = absURL(r.coverUrl);
    if (r.iconUrl) r.iconUrl = absURL(r.iconUrl);
    return r;
}

export type ResourceDocumentType = "spec" | "guide" | string;

export type Slug = string;

export interface ResourceDocument extends OrgItem {
    slug: Slug,
    type: ResourceDocumentType,
    title: string,
    desc: string,
    content: string,
    cover: FileId | null,
    draft: boolean,
    rank: number,
    toc: string,
}

export interface ResourceFile extends File {
    rank: number;
}
//

export interface UserSelection {
    all?: boolean,
    ids?: string[],
    search?: string,
}

export interface FindUsersRequest extends UserSelection {
    pageToken?: string,
    pageSize?: number,
}

export interface FindUsersResponse {
    users: User[],
    numFound?: number,
    numTotal: number,
    nextPageToken?: string,
}

// export interface User extends identApi.User {
//     created_at: number,
//     updated_at: number,
//     socials?: Social[],
//     isAdmin?: boolean,
// }

// export interface NewUser extends NewUser {
//     password?: string,
// }

export interface InsertUsersRequest {
    users: NewUser[],
}

export interface InsertUsersResponse {
    ids: UserId[],
}

// export interface UserUpdate extends ident.UserUpdate {
//     isAdmin?: boolean,
// }

//

export type SolutionId = OrgItemId;

export interface Solution extends OrgItem {
    title: string,
    desc: string,
    intro: string,
    cover: FileId | null,
    coverUrl?: string,
    draft?: boolean,
    resources: SolutionResource[],
    steps: SolutionStep[],
    lines: SolutionLine[],
}

export interface SolutionResource {
    id: number,
    resource: ResourceId,
    title: string,
    steps: number[],
    icon?: FileId,
    iconUrl?: string,
    image?: FileId,
    imageWidth?: number,
    imageHeight?: number,
    imageUrl?: string,
    skeleton?: FileId,
    skeletonUrl?: string,
    rot: number,
    x: number,
    y: number,
    params: unknown,
}

export interface SolutionStep {
    id: number,
    resource?: number,
    title: string,
    desc: string,
    content: string,
    draft?: boolean,
    rank: number
}

export interface SolutionLine {
    id: number,
    from: number,
    to: number,
    color: string,
    x: number,
    y: number,
    l: number[]
}

function polishSolution(s: Solution) {
    polishOrgItem(s);
    if (s.coverUrl) s.coverUrl = absURL(s.coverUrl);
    s.resources = s.resources.map(polishSolutionResource);
    return s;
}

function polishSolutionResource(r: SolutionResource) {
    if (r.iconUrl) r.iconUrl = absURL(r.iconUrl);
    if (r.imageUrl) r.imageUrl = absURL(r.imageUrl);
    if (r.skeletonUrl) r.skeletonUrl = absURL(r.skeletonUrl);
    return r;
}

export interface SolutionsRequest {
    org?: OrganizationId,
}

export interface SolutionsResponse {
    solutions: Solution[],
}

function polishSolutions(page: SolutionsResponse) {
    page.solutions.forEach(polishSolution);
    return page;
}

////////////////////////////////////////////////////////////////////////////////


export interface OrgMember extends User {
    isAdmin?: boolean,
    isAuthor?: boolean,
    isMember?: boolean,
    isTrainer?: boolean,
}

export interface FindOrgMembersRequest {
    org: OrganizationId,
    q?: string,
    pageToken?: string,
    pageSize?: number,
}

export interface OrgMembersList {
    members: OrgMember[],
    numTotal: number,
    nextPageToken?: string,
}

export interface AddOrgMembersRequest {
    org: OrganizationId,
    ids: UserId[],
    emails: string[],
}

export interface AddOrgMembersResponse {
    ids: UserId[],
}

////////////////////////////////////////////////////////////////////////////////

export interface UpdateResourceRequest extends PatchOrgItemRequest {
    count?: number,
}

////////////////////////////////////////////////////////////////////////////////

// apiUrl = config.apiUrl + "api/v1/";

export const getOrganizations = (req: OrganizationsRequest): Promise<OrganizationsResponse> => rest("GET", "orgs", req, { polish: polishOrganizations });

export type NewOrganizationResponse = {
    id: OrganizationId,
    slug: string,
}

export const postOrganization = (req: NewOrganization): Promise<NewOrganizationResponse> => rest("POST", "orgs", req);
export const getOrganization = (req: OrganizationRequest): Promise<Organization> => rest("GET", "orgs/{id}", req, { polish: polishOrganization });
export const deleteOrganization = (req: OrganizationRequest): Promise<void> => rest("DELETE", "orgs/{id}", req);
export const patchOrganization = (req: PatchOrganizationRequest): Promise<void> => rest("PATCH", "orgs/{slug}", req);

export const getModules = (req: ModulesRequest): Promise<ModulesResponse> => rest("GET", "modules", req, { polish: polishModules });
export const postModule = (req: NewModule): Promise<ModuleId> => rest("POST", "modules", req);
export const getModule = (req: OrgItemRequest): Promise<Module> => rest("GET", "modules/{id}", req, { polish: polishModule });
export const deleteModule = (req: OrgItemRequest): Promise<void> => rest("DELETE", "modules/{id}", req);
export const patchModule = (req: PatchModuleRequest): Promise<void> => rest("PATCH", "modules/{id}", req);

export const getCourses = (req: CoursesRequest): Promise<CoursesResponse> => rest("GET", "courses", req, { polish: polishCourses });
export const postCourse = (req: NewCourse): Promise<CourseId> => rest("POST", "courses", req);
export const getCourse = (req: OrgItemRequest): Promise<Course> => rest("GET", "courses/{id}", req, { polish: polishOrgItem });
export const deleteCourse = (req: OrgItemRequest): Promise<void> => rest("DELETE", "courses/{id}", req);
export const patchCourse = (req: PatchCourseRequest): Promise<void> => rest("PATCH", "courses/{id}", req);

export const getTopics = (req: TopicsRequest): Promise<TopicsResponse> => rest("GET", "topics", req, { polish: polishTopics });
export const postTopic = (req: NewTopic): Promise<OrgItemId> => rest("POST", "topics", req);
export const deleteTopic = (req: OrgItemRequest): Promise<void> => rest("DELETE", "topics/{id}", req);
export const patchTopic = (req: PatchTopicRequest): Promise<void> => rest("PATCH", "topics/{id}", req);

export const answerQuiz = (req: AnswerQuizRequest): Promise<AnswerQuizResponse> => rest("POST", "answer-quiz", req);

export const enroleCourse = (req: EnroleCourseRequest): Promise<EnroleCourseResponse> => rest("POST", "enrole-course", req);
export const unenroleCourse = (req: UnenroleCourseRequest): Promise<void> => rest("POST", "unenrole-course", req);

// export const getUserCourse = (req: UserCourseRequest): Promise<UserCourseResponse> => rest("POST", "user-course", req);
// export const getUserCourses = (req: UserCoursesRequest): Promise<UserCoursesResponse> => rest("POST", "user-courses", req);

//

export type CompleteUserCourseTopicRequest = {
    course: UserCourseId,
    topic: TopicId,
}

export const completeUserCourseTopic = (req: CompleteUserCourseTopicRequest): Promise<void> => rest("POST", "complete-topic", req);

///

export type ResetUserCourseTopicRequest = {
    course: UserCourseId,
    topic: TopicId,
}

export const resetCourseTopic = (req: ResetUserCourseTopicRequest): Promise<void> => rest("POST", "reset-topic", req);

//

export type ResetCourseRequest = {
    course: UserCourseId,
}

export const resetCourse = (req: ResetCourseRequest): Promise<void> => rest("POST", "reset-course", req);

//
export interface ImportResponse {
    importLogs: string
}

export const importContents = (): Promise<ImportResponse> => rest("POST", "import-contents", null);

export const getSolution = (id: SolutionId): Promise<Solution> => rest("GET", "solutions/{id}", { id }, { polish: polishSolution });
export const getSolutions = (req: SolutionsRequest): Promise<SolutionsResponse> => rest("GET", "solutions", req, { polish: polishSolutions });

export const getFiles = (req: FilesRequest): Promise<FilesResponse> => rest("GET", "files", req, { polish: polishFiles });
export const getFile = (req: OrgItemRequest): Promise<File> => rest("GET", "files/{id}", req, { polish: polishFile });
export const deleteFile = (req: OrgItemRequest): Promise<void> => rest("DELETE", "files/{id}", req);
export const patchFile = (req: PatchFileRequest): Promise<void> => rest("PATCH", "files/{id}", req);

export async function postFile(req: NewFileRequest): Promise<NewFileResponse> {
    const body = new FormData();
    const metadata = { width: req.width, height: req.height };
    body.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }), "metadata.json");
    if (req.thumbnail) body.append("thumbnail", req.thumbnail, "thumbnail.png");
    body.append("file", req.file, req.file.name);
    const method = "POST";
    const path = "files";
    let resp: Response;
    const r = new Request(path, { method, body });
    try {
        resp = await fetch(r);
    } catch (err) {
        throw new RestError("network error", 0, `${err}`, method, path);
    }
    return await handleResponse(r, resp) as NewFileResponse;
}

export async function postFileRaw(req: NewOrgItem, file: globalThis.File): Promise<NewFileResponse> {
    const path = abs("files");
    let resp: Response;
    const r = new Request(path, {
        method: "POST",
        body: file,
        headers: {
            "Upload-Metadata": `filename ${btoa(file.name)}${req.org ? `,org ${btoa(req.org)}` : ""}`,
        }
    });
    try {
        resp = await fetch(r);
    } catch (err) {
        throw new RestError("network error", 0, `${err}`, r.method, path);
    }
    return await handleResponse(r, resp) as NewFileResponse;
}

export const getResources = (req: ResourcesRequest): Promise<ResourcesResponse> => rest("GET", "resources", req, { polish: polishResources });
export const postResource = (req: NewOrgItem): Promise<NewOrgItemRespone> => rest("POST", "resources", req);
export const getResource = (req: OrgItemRequest): Promise<Resource> => rest("GET", "resources/{id}", req, { polish: polishOrgItem });
export const deleteResource = (req: OrgItemRequest): Promise<void> => rest("DELETE", "resources/{id}", req);
export const patchResource = (req: UpdateResourceRequest): Promise<void> => rest("PATCH", "resources/{id}", req);

export type FindResourceDocRequest = OrgItemId | Slug;
export type FindResourceDocResponse = ResourceDocument;
export const findResourceDoc = (req: FindResourceDocRequest): Promise<FindResourceDocResponse> => rest("GET", "resource-docs/" + req, {}, {});

// export const findUsers = (req: FindUsersRequest): Promise<FindUsersResponse> => rest("GET", "users", req);
// export const updateUsers = (sel: UsersQuery, u: UserUpdate): Promise<unknown> => rest("PATCH", "users", { sel, update: u });;
// export const postUsers = (req: InsertUsersRequest): Promise<InsertUsersResponse> => rest("POST", "/ident/users", req);

export const findOrgMembers = (req: FindOrgMembersRequest): Promise<OrgMembersList> => rest("GET", "org-members", req);
export const addOrgMembers = (req: AddOrgMembersRequest): Promise<AddOrgMembersResponse> => rest("POST", "org-members", req);


////////////////////////////////////////////////////////////////////////////////


export type TokenRequest = {
    grantType: "authorization_code" | "refresh_token" | "password";
    code?: string;
    refreshToken?: string;
    username?: string;
    password?: string;
    scope?: string;
}

export type TokenResponse = {
    accessToken: string,
    tokenType: string,
    expiresIn: number,
    refreshToken: string,
    idToken: string,
}

const token = (req: TokenRequest) => rest("POST", "token", camel2snakeObject(req), { polish: snake2camelObject, unauthorized: true }) as Promise<TokenResponse>;

export const refreshToken = (refreshToken: string) => token({ grantType: "refresh_token", refreshToken });

//

export type UserId = string;

export type User = {
    id: UserId,
    createdAt: Date,

    username?: string,
    usernameVerified?: boolean,

    profile?: string,
    picture?: string,
    website?: string,

    email?: string,
    emailVerified?: boolean,

    gender?: string,
    birthdate?: string,
    zoneinfo?: string,
    locale?: string,

    phoneNumber?: string,
    phoneNumberVerified?: boolean,

    methods?: SocialProvider[],

    suspended?: boolean,

    roles: string[],

    password?: string,

    updatedAt: Date,
}

export type SocialProvider = {
    iss: string,
}


function polishUser(u: User) {
    u.createdAt = new Date(u.createdAt);
    u.updatedAt = new Date(u.updatedAt);
    // u.lastLogin = new Date(u.lastLogin);
    return u;
}

type UserinfoRequest = {
    user?: UserId,
}

export const userinfo = (req: UserinfoRequest, opts?: APIRestOptions) => rest("GET", "userinfo", undefined, { polish: polishUser, ...opts }) as Promise<User>;

// export const userinfo = () => rest("GET", "userinfo", undefined, { polish: polishUser }) as Promise<User>;

//

export type UploadPictureResponse = {
    picture: string,
}

export async function updateProfilePicture(picture: BodyInit): Promise<UploadPictureResponse> {
    const req = new Request(abs("users/self/picture"), {
        body: picture,
        method: "POST",
    });
    const resp = await fetch(req);
    return await handleResponse(req, resp);
}

//

export type UserUpdate = {
    username?: string,
    usernameVerified?: boolean,

    email?: string,
    emailVerified?: boolean,

    gender?: string,
    birthdate?: string,
    zoneinfo?: string,
    locale?: string,
    phoneNumber?: string,
    phoneNumberVerified?: boolean,

    suspended?: boolean,

    newPassword?: string,
    oldPassword?: string,

    addRoles?: string[],
    removeRoles?: string[],
}

export async function updateUser(u: UserUpdate) {
    await rest("PATCH", "users/self", u)
    // if (this.user) {
    //     this.user = { ...this.user, ...u };
    //     this.emit("userinfo");
    // }
}

export async function updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    await updateUser({ newPassword: newPassword, oldPassword: oldPassword });
}

export async function deleteUser() {
    await rest("DELETE", "users/self", null);
    // this.emit("delete-user");
}

export async function logout(refreshToken: string) {
    await rest("POST", "logout", { refreshToken });
}

export type LoginResponse = {
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
    user: User,
}

export async function login(username: string, password: string): Promise<LoginResponse> {
    return rest("POST", "login", { username, password }, { polish: snake2camelObject, unauthorized: true });
}

export type NewUser = Omit<User, "id" | "createdAt" | "updatedAt" | "profile" | "picture" | "website" | "roles">;

export type RegistrationResponse = LoginResponse;

export const register = (user: NewUser, redirectUri: string, opts?: RestOptions): Promise<RegistrationResponse> => rest("POST", "register", { ...user, redirectUri }, { unauthorized: true, ...opts });
export const completeRegistration = (registrationToken: string, redirectUri?: string, opts?: RestOptions): Promise<void> => rest("POST", "complete-registration", { registrationToken, redirectUri }, { unauthorized: true, ...opts });
// export async function completeRegistration(registrationToken: string, redirectUri?: string, opts?: RestOptions): Promise<void> {
//     const sess = window.session;
//     if(!sess) throw new Error("No session");
//     await rest("POST", "complete-registration", { registrationToken, redirectUri }, { unauthorized: true, ...opts });
//     sess.user = { ...sess.user, emailVerified: true };
//     hub.emit(".user", sess.user);
// }

export const instructPasswordReset = (email: string, redirectUri?: string, opts?: RestOptions): Promise<void> => rest("POST", "instruct-password-reset", { email, redirectUri }, { unauthorized: true, ...opts });
export const resetPassword = (resetPasswordToken: string, password: string, redirectUri?: string, opts?: RestOptions): Promise<void> => rest("POST", "reset-password", { resetPasswordToken, password, redirectUri }, opts);

// export type ChangeEmailTokenClaims = {
//     aud: "_change_email"
//     sub: string,
//     email: string
// }

// export type PasswordResetTokenClaims = {
//     aud: "_reset_password"
//     sub: string,
//     email: string
// }

// export type RegistrationTokenClaims = {
//     aud: "_complete_registration"
//     sub: string,
//     email: string
// }

export const instructEmailChange = (email: string, redirectUri?: string, opts?: RestOptions): Promise<void> => rest("POST", "instruct-email-change", { email, redirectUri }, { ...opts });
export const changeEmail = (changeEmailToken: string, redirectUri?: string, opts?: RestOptions): Promise<void> => rest("POST", "change-email", { changeEmailToken, redirectUri }, opts);
// export async function changeEmail(changeEmailToken: string, redirectUri?: string, opts?: RestOptions): Promise<void> {
//     const sess = window.session;
//     if(!sess) throw new Error("No session");
//     const claims = parseToken(changeEmailToken) as ChangeEmailTokenClaims;
//     if (claims.sub !== sess.sub) throw new Error("Invalid subject");
//     const email = claims.email;
//     await rest("POST", "change-email", { changeEmailToken, redirectUri }, opts);
//     sess.user = { ...sess.user, email, emailVerified: true };
//     hub.emit(".user", sess.user);
// }

export const socialLoginUri = (iss: string, redirectUri?: string) => abs(`social-login?iss=${encodeURIComponent(iss)}${redirectUri ? `&redirect_uri=${encodeURIComponent(redirectUri)}` : ""}`);
export const socialLogin = (iss: string, redirectUri?: string, opts?: RestOptions): Promise<{ redirectUri: string }> => rest("POST", "social-login", { iss, redirectUri }, { unauthorized: true, ...opts });

export type AuthCodeResponse = {
    code: string,
    state?: string,
}

export type AuthTokenResponse = {
    access_token: string,
    refresh_token?: string,
    token_type: string,
    expires_in: number,
    scope?: string,
    id_token?: string,
    state?: string,
}

export type IdTokenResponse = {
    idToken: string,
    state?: string,
}

export type AuthResponse = AuthCodeResponse | AuthTokenResponse | IdTokenResponse;

export type SocialLoginResponse = LoginResponse;

export function exchangeSocialLogin(auth: AuthResponse, scope?: string, nonce?: string, redirectUri?: string, opts?: RestOptions): Promise<SocialLoginResponse> {
    return rest("POST", "exchange-social-login", { auth, scope, nonce, redirectUri }, { polish: snake2camelObject, unauthorized: true, ...opts });
}

export function listSocialProviders(opts?: RestOptions): Promise<SocialProvider[]> {
    return rest("GET", "social-providers", null, { unauthorized: true, ...opts });
}

//

export type UsersQuery = {
    all?: boolean,
    ids?: string[],
    email?: string,
    search?: string
}

export type ListUsersRequest = UsersQuery & {
    pageSize?: number,
    pageToken?: string,
}

export function polishUsers(req: ListUsersResponse) {
    req.users.forEach(polishUser);
    return req;
}

export type ListUsersResponse = {
    users: User[],
    nextPageToken?: string,
    numUsersTotal: number,
    numUsersFound: number,
}

export const listUsers = (req: ListUsersRequest) => rest("GET", "users", req, { polish: polishUsers }) as Promise<ListUsersResponse>;

//

export type AddUsersRequest = {
    users: NewUser[],
}

export type AddUsersResponse = {
    ids: UserId[],
}

export const addUsers = (req: AddUsersRequest) => rest("POST", "users", req) as Promise<AddUsersResponse>;

//

export type UpdateUsersRequest = {
    query: UsersQuery,
    update: UserUpdate,
}

export type UpdateUsersResponse = {
    numUpdated: number,
}

export const updateUsers = (req: UpdateUsersRequest) => rest("PATCH", "users", req) as Promise<UpdateUsersResponse>;

//

export type DeleteUsersRequest = UsersQuery;

export type DeleteUsersResponse = {
    numDeleted: number,
}

export const deleteUsers = (req: DeleteUsersRequest) => rest("DELETE", "users", req) as Promise<DeleteUsersResponse>;

//    

export type SelfUserUpdate = Omit<UserUpdate, "preferred_username_verified" | "email_verified" | "phone_number_verified" | "suspended">;

export const updateUsersSelf = (update: UserUpdate, opts?: RestOptions): Promise<void> => rest("PATCH", "users/self", update, opts);

export const deleteUsersSelf = (opts?: RestOptions): Promise<void> => rest("DELETE", "users/self", null, opts);


////////////////////////////////////////////////////////////////////////////////

// type APIListener = () => void;

// const apiListeners = new Set<APIListener>()

// function handleIdentityEvent(ev: IdentityEvent) {
//     const scope = ident.session ? ident.session.scopes.join(" ") : "";
//     if (ident.session !== api.sess || scope !== api.scope) {
//         api = new API(ident.session);
//         for (const listener of Array.from(apiListeners)) {
//             try {
//                 listener(api);
//             } catch (err) {
//                 reportError(err);
//             }
//         }
//     }
// }

// const identEvents: IdentityEventType[] = [
//     "session", "refresh"
// ];

// for (const ev of identEvents) {
//     ident.addEventListener(ev, handleIdentityEvent);
// }


// export function getApi(): API {
//     return api;
// }


// type Destructor = () => void;

// type APICallback = () => (void | Destructor);

// export function useApi(cb: APICallback, deps?: React.DependencyList) {
//     useEffect(() => {
//         let destructor = cb();
//         function handleAPI() {
//             destructor?.();
//             destructor = cb();
//         }
//         apiListeners.add(handleAPI);
//         return () => {
//             destructor?.();
//             apiListeners.delete(handleAPI);
//         };
//     }, deps);
// }

// export function useAPI(): API {
//     const [api, setApi] = useState<API>(getApi());
//     useApi(setApi, []);
//     return api;
// }

// export function parseToken(token: string): any {
//     const parts = token.split(".");
//     return JSON.parse(atob(parts[1]));
// }

////////////////////////////////////////////////////////////////////////////////


export type ResourceTypeDef = {
    id: string,
    name: string,
    cover: string,
    Icon: React.ComponentType<SvgIconProps>,
}

export const resourceTypes: ResourceTypeDef[] = [
    { id: "hardware", name: "Hardware", cover: "/static/images/resource_hardware.png", Icon: DeveloperBoardOutlined },
    { id: "software", name: "Software", cover: "/static/images/resource_software.png", Icon: TerminalOutlined },
    { id: "dataset", name: "Dataset", cover: "/static/images/resource_dataset.png", Icon: DatasetOutlined },
    { id: "api", name: "API", cover: "/static/images/resource_api.png", Icon: ApiOutlined },
    { id: "waziup", name: "Waziup", cover: "/static/images/resource_api.png", Icon: Waziup },
]

// Dataset material icon is not included in the @mui/material-icons package yet.
// Check https://mui.com/material-ui/material-icons/?query=Dataset&theme=Outlined
function DatasetOutlined(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 48 48">
            <path d="M14.5 33.5h7v-7h-7Zm12 0h7v-7h-7Zm-12-12h7v-7h-7Zm12 0h7v-7h-7ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
        </SvgIcon>
    );
}


////////////////////////////////////////////////////////////////////////////////

export type ProgramJoinType = "invite" | "open" | "request";

export type Program = {
    // unique program ID
    id: string,
    // short name
    name: string,
    // description
    desc: string,
    // program overview from markdown
    content: string,
    // cover image URL
    cover?: File,
    // time of creation
    createdAt: Date,
    // creator, if any
    createdBy?: User,
    // related organization
    org: Organization,
    // started when user joins
    individualStart: boolean,
    // program status
    started: boolean,
    // starting date 
    startsAt: Date | null,
    // end date
    endsAt: Date | null,
    // program events
    events?: ProgramEvent[],
    numEvents: number,
    // program units
    units?: ProgramUnit[],
    numUnits: number,
    // program members
    // will be unset if you lack the permission to list members
    members?: ProgramMember[],
    numMembers: number,
    // program teams
    // will be only the list of teams you are member of if you lack the permission
    // to list all teams
    teams?: ProgramTeam[],
    numTeams: number,
    // your roles on this program
    roles: string[],

    added: boolean,
    addedAt: Date | null,

    joined: boolean,
    joinedAt: Date | null,

    // audience list
    audience: string[],
    // join restriction
    join: ProgramJoinType,

    draft?: boolean,

    tags: string[],
}

export type ProgramMember = {
    user: User,

    joined: boolean,
    // Time when the user joined the organization
    // null if the invitation is still pending
    joinedAt: Date | null,

    added: boolean,
    addedAt: Date | null,
    // User who invited this user, if any
    addedBy?: User,

    // program roles held by the user (empty if none)
    roles?: string[],
}

export type ProgramUnit = {
    // unique unit ID (program scoped)
    id: string,
    // short unit name
    name: string,
    // unit description
    desc: string,
    // unit target description
    target: string,
    // starting date within the program time
    from: Date,
    // end date withing the program time
    to: Date,
    content: string,
    // related courses
    // will be unset when using the listPrograms or findProgram endpoint
    // will be set when using the findProgramUnit endpoint
    numCourses: number,
    courses?: Course[],
    // solutions
    // will be unset when using the listPrograms or findProgram endpoint
    // will be set when using the findProgramUnit endpoint
    numSolutions: number,
    solutions?: Solution[],

    numTopics: number,
    numTopicsDone: number,

    numQuiz: number,
    numQuizDone: number,
}

export function unitProgress(u: ProgramUnit): number {
    return genericProgress(u.numTopics, u.numTopicsDone, u.numQuiz, u.numQuizDone);
}

export type ProgramEvent = {
    // unique event ID (program scoped)
    id: string,
    // short name
    name: string,
    // description
    desc: string,
    // link to the meeting, if any
    // e.g. Google Meets link, Zoom invitation link
    link?: string,
    // event date
    date: Date,
    // cover image, if any
    cover?: File,
    // contents
    content: string,

}

export type ProgramTeam = {
    // unique team ID (program scoped)
    id: string | number,
    // short name
    name: string,
    // description
    desc: string,
    // team members
    members: TeamMember[],
    // team courses
    // this users permissions on the course decode if it's a
    // read-only course or if it can be edited
    courses: Course[],
    // team solutions (prototyping)
    // this users permissions on the solution decide if it's a 
    // managed-solution (read-only) or a prototyping work (write perm.)
    solutions: Solution[],
    // your roles on that team
    roles: string[],
}

export type TeamMember = {
    // related user
    user: User,
    // team roles held by the user (empty if none)
    roles: string[],
}


//

function polishProgramMember(m: ProgramMember) {
    polishUser(m.user);
    if (m.joinedAt) m.joinedAt = new Date(m.joinedAt);
    if (m.addedAt) m.addedAt = new Date(m.addedAt);
    return m
}

function polishTeamMember(m: TeamMember) {
    polishUser(m.user);
}

function polishProgramTeam(t: ProgramTeam) {
    t.members.forEach(polishTeamMember);
    t.solutions.forEach(polishSolution);
    t.courses.forEach(polishCourse);
    return t;
}

function sortByRank(a: { rank: number }, b: { rank: number }) {
    return a.rank - b.rank;
}

function polishProgramUnit(u: ProgramUnit) {
    u.from = new Date(u.from);
    u.to = new Date(u.to);
    if (u.courses) {
        u.courses.sort(sortByRank);
        u.courses.forEach(polishCourse);
    }
    if (u.solutions) u.solutions.forEach(polishSolution);
    return u;
}

function polishProgramEvent(e: ProgramEvent) {
    e.date = new Date(e.date);
    return e;
}

function polishProgram(p: Program) {
    if (p.members) p.members.forEach(polishProgramMember);
    if (p.teams) p.teams.forEach(polishProgramTeam);
    if (p.units) p.units.forEach(polishProgramUnit);
    if (p.events) p.events.forEach(polishProgramEvent);
    if (p.addedAt) p.addedAt = new Date(p.addedAt);
    if (p.joinedAt) p.joinedAt = new Date(p.joinedAt);
    if (p.startsAt) p.startsAt = new Date(p.startsAt);
    if (p.endsAt) p.endsAt = new Date(p.endsAt);
    return p;
}

function polishFindProgramResponse(req: FindProgramResponse) {
    polishProgram(req.program);
    return req;
}

export type FindProgramRequest = {
    id: Program["id"]
}

export type FindProgramResponse = {
    program: Program
}

export const findProgram = (req: FindProgramRequest) => rest("GET", "programs/" + req.id, null, { polish: polishFindProgramResponse }) as Promise<FindProgramResponse>;

//

export type ProgramUpdate = {
    name?: string,
    desc?: string,
    content?: string,
    cover?: File,
    individualStart?: boolean,
    started?: boolean,
    startsAt?: Date | null,
    endsAt?: Date | null,
    join?: ProgramJoinType,
    draft?: boolean,
    addTags?: string[],
    removeTags?: string[],
}

export type UpdateProgramRequest = ProgramUpdate & {
    id: Program["id"],
};

export type UpdateProgramResponse = void;

export const updateProgram = (req: UpdateProgramRequest) => rest("PATCH", "programs/" + req.id, req) as Promise<UpdateProgramResponse>;

//

function polishFindProgramUnitReponse(req: FindProgramUnitResponse) {
    polishProgramUnit(req.unit);
    return req;
}

export type FindProgramUnitRequest = {
    program: Program["id"],
    unit: ProgramUnit["id"],
    user?: UserId,
}

export type FindProgramUnitResponse = {
    program: Program["id"],
    unit: ProgramUnit
}

export const findProgramUnit = (req: FindProgramUnitRequest) => rest("GET", "programs/" + req.program + "/units/" + req.unit, { user: req.user }, { polish: polishFindProgramUnitReponse }) as Promise<FindProgramUnitResponse>;

//

export type FindProgramProgressRequest = {
    program: Program["id"],
}

export type ProgramUnitProgress = {
    user: UserId,
    unit: ProgramUnit["id"],
    numTopicsDone: number,
    numQuizDone: number,
}

export type FindProgramProgressResponse = {
    progress: ProgramUnitProgress[],
}

export const findProgramProgress = (req: FindProgramProgressRequest) => rest("GET", "programs/" + req.program + "/progress", null) as Promise<FindProgramProgressResponse>;



//

function polishListProgramsResponse(req: ListProgramsResponse) {
    req.programs.forEach(polishProgram);
    return req;
}

export type ListProgramsRequest = {
    // filter by organization
    org?: Organization["id"][],
    // filter by user
    user?: User["id"],
    // filter by role
    role?: string,
    // filter by time
    from?: Date,
    to?: Date,
    // text search
    q?: string,
    // filter by tags (any of)
    tag?: string[],
    // include drafts
    drafts?: boolean,

    // only programs where I am member of
    withMembership?: boolean,
    // withoutMembership?: boolean,

    pageSize?: number,
    pageToken?: string,
}

export type ListProgramsResponse = {
    programs: Program[],
    numFound?: number,
    numTotal?: number,

    nextPageToken?: string,
}


export const listPrograms = (req: ListProgramsRequest) => rest("GET", "programs", req, { polish: polishListProgramsResponse }) as Promise<ListProgramsResponse>;

//

export type JoinProgramRequest = {
    id: Program["id"],
}

export type JoinProgramResponse = void;

export const joinProgram = (req: JoinProgramRequest) => rest("POST", "programs/" + req.id + "/join", null) as Promise<JoinProgramResponse>;

//

export type LeaveProgramRequest = {
    id: Program["id"],
}

export type LeaveProgramResponse = void;

export const leaveProgram = (req: LeaveProgramRequest) => rest("POST", "programs/" + req.id + "/leave", null) as Promise<LeaveProgramResponse>;

//

export type AddProgramMembersRequest = {
    program: Program["id"],
    users: UsersQuery,
    autoJoin?: boolean,
    noSendEmail?: boolean,
    sendEmailsSync?: boolean,
}

export type AddProgramMembersResponse = {
    numAdded: number,
}

export const addProgramMembers = ({ program, users, autoJoin, noSendEmail, sendEmailsSync }: AddProgramMembersRequest) => rest("POST", "programs/" + program + "/members", { users, autoJoin, noSendEmail, sendEmailsSync }) as Promise<AddProgramMembersResponse>;

//

export type ListProgramMembersRequest = {
    program: Program["id"],
    pageSize?: number,
    pageToken?: string,
}

export type ListProgramMembersResponse = {
    members: ProgramMember[],
    numMembers: number,
    nextPageToken?: string,
}

function polishListProgramMembersResponse(req: ListProgramMembersResponse) {
    req.members.forEach(polishProgramMember);
    return req;
}

export const listProgramMembers = ({ program, pageSize, pageToken }: ListProgramMembersRequest) => rest("GET", "programs/" + program + "/members", { pageSize, pageToken }, { polish: polishListProgramMembersResponse }) as Promise<ListProgramMembersResponse>;

//

export type RemoveProgramMembersRequest = {
    program: Program["id"],
    users: UsersQuery,
}

export type RemoveProgramMembersResponse = {
    numRemoved: number,
}

export const removeProgramMembers = (req: RemoveProgramMembersRequest) => rest("DELETE", "programs/" + req.program + "/members", req.users) as Promise<RemoveProgramMembersResponse>;

//

export type ProgramMemberUpdate = {
    roles?: string[],
    joined?: boolean,
    added?: boolean,
}

export type UpdateProgramMembersRequest = {
    program: Program["id"],
    users: UsersQuery,
    update: ProgramMemberUpdate,
}

export type UpdateProgramMembersResponse = {
    numUpdated: number,
}

export const updateProgramMembers = ({ program, users, update }: UpdateProgramMembersRequest) => rest("PATCH", "programs/" + program + "/members", { ...users, ...update }) as Promise<UpdateProgramMembersResponse>;

//

export type CopyProgramRequest = {
    program: Program["id"],
    org: OrganizationId,
}

export type CopyProgramResponse = {
    id: Program["id"],
    name: string,
    slug: string,
}

export const copyProgram = ({ program, org }: CopyProgramRequest) => rest("POST", "programs/" + program + "/copy", { org }) as Promise<CopyProgramResponse>;

//

export type DeleteProgramRequest = {
    id: Program["id"],
}

export type DeleteProgramResponse = void;

export const deleteProgram = ({ id }: DeleteProgramRequest) => rest("DELETE", "programs/" + id, null) as Promise<DeleteProgramResponse>;

//

export type CreateTeamRequest = {
    program: Program["id"],
    team: {
        name: string,
    }
}

export type CreateTeamResponse = {
    team: ProgramTeam,
}

export const createTeam = (req: CreateTeamRequest) => rest("POST", "programs/" + req.program + "/teams", req.team) as Promise<CreateTeamResponse>;

//

export type DeleteTeamRequest = {
    program: Program["id"],
    team: ProgramTeam["id"],
}

export type DeleteTeamResponse = void;

export const deleteTeam = (req: DeleteTeamRequest) => rest("DELETE", "programs/" + req.program + "/teams/" + req.team, null) as Promise<DeleteTeamResponse>;

//

export type AddTeamMemberRequest = {
    program: Program["id"],
    team: ProgramTeam["id"],
    user: User["id"],
}

export type AddTeamMemberResponse = void;

export const addTeamMember = (req: AddTeamMemberRequest) => rest("POST", "programs/" + req.program + "/teams/" + req.team + "/members", { user: req.user }) as Promise<AddTeamMemberResponse>;

//

export type RemoveTeamMemberRequest = {
    program: Program["id"],
    team: ProgramTeam["id"],
    user: User["id"],
}

export type RemoveTeamMemberResponse = void;

export const removeTeamMember = (req: RemoveTeamMemberRequest) => rest("DELETE", "programs/" + req.program + "/teams/" + req.team + "/members/" + req.user, null) as Promise<RemoveTeamMemberResponse>;

//

export type TeamMemberUpdate = {
    roles?: string[],
}

export type UpdateTeamMemberRequest = {
    program: Program["id"],
    team: ProgramTeam["id"],
    user: User["id"],
}

export type UpdateTeamMemberResponse = void;

export const updateTeamMember = (req: UpdateTeamMemberRequest, update: TeamMemberUpdate) => rest("PATCH", "programs/" + req.program + "/teams/" + req.team + "/members/" + req.user, update) as Promise<UpdateTeamMemberResponse>;

////////////////////////////////////////////////////////////////////////////////

export type InviteMembersRequest = {
    org: OrganizationId,
    users: UsersQuery,
    roles: string[],
    autoJoin?: boolean,
    noSendEmail?: boolean,
    sendEmailsSync?: boolean,
}

export type InviteMembersResponse = {
    numInvited: number,
}

export const inviteMembers = (req: InviteMembersRequest) => rest("POST", "org-members", req) as Promise<InviteMembersResponse>;


export type ListMembersRequest = {
    org: OrganizationId,
}

export interface OrgMember2 extends User {
    joinedAt?: Date | null,
    invitedBy?: User | null,
    organizationRoles: string[],
}

export type ListMembersResponse = {
    members: OrgMember2[],
}

function polishOrgMember(m: OrgMember2) {
    polishUser(m);
    if (m.joinedAt) m.joinedAt = new Date(m.joinedAt);
    return m
}

export const listMembers = (req: ListMembersRequest) => rest("POST", "org-members", req, { polish: polishOrgMember }) as Promise<ListMembersResponse>;

export type RemoveMembersRequest = {
    org: OrganizationId,
    users: UsersQuery,
}

export type RemoveMembersResponse = {
    numRemoved: number,
}

export const removeMembers = (req: RemoveMembersRequest) => rest("DELETE", "org-members", req) as Promise<RemoveMembersResponse>;

export type ResendInvitationRequest = {
    org: OrganizationId,
    user: UserId,
}

export type ResendInvitationResponse = void;

export const resendInvitation = (req: ResendInvitationRequest) => rest("POST", "org-members/resend-invitation", req) as Promise<ResendInvitationResponse>;

export type AcceptInvitationRequest = {
    org: OrganizationId,
}

export type AcceptInvitationResponse = void;

export const acceptInvitation = (req: AcceptInvitationRequest) => rest("POST", "org-members/accept-invitation", req) as Promise<AcceptInvitationResponse>;

////////////////////////////////////////////////////////////////////////////////

export type OrganizationMember = {
    user: User,

    joined: boolean,
    // Time when the user joined the organization
    // null if the invitation is still pending
    joinedAt: Date | null,

    added: boolean,
    addedAt: Date | null,
    // User who invited this user, if any
    addedBy?: User,

    // program roles held by the user (empty if none)
    roles?: string[],
}

export type OrganizationMembersQuery = UsersQuery & {
    org: Organization["id"],
}

export type ListOrganizationMembersRequest = OrganizationMembersQuery & {
    pageSize?: number,
    pageToken?: string,
}

export type ListOrganizationMembersResponse = {
    members: OrganizationMember[],
    numMembers: number,
    nextPageToken?: string,
}

function polishOrganizationMember(m: ProgramMember) {
    polishUser(m.user);
    if (m.joinedAt) m.joinedAt = new Date(m.joinedAt);
    if (m.addedAt) m.addedAt = new Date(m.addedAt);
    return m
}

function polishListOrganizationMembersResponse(req: ListOrganizationMembersResponse) {
    req.members.forEach(polishOrganizationMember);
    return req;
}

export const listOrganizationMembers = (req: ListOrganizationMembersRequest) => rest("GET", "org-members2", req, { polish: polishListOrganizationMembersResponse }) as Promise<ListOrganizationMembersResponse>;

//


export type AddOrganizationMembersRequest = {
    id: OrganizationId,
    users: UsersQuery,
    roles?: string[],
    autoJoin?: boolean,
    noSendEmails?: boolean,
    sendEmailsSync?: boolean,
}

export type AddOrganizationMembersResponse = {
    numAddd: number,
}

export const addOrganizationMembers = (req: AddOrganizationMembersRequest) => rest("POST", "org-members2", req) as Promise<AddOrganizationMembersResponse>;

//


export type RemoveOrganizationMembersRequest = OrganizationMembersQuery

export type RemoveOrganizationMembersResponse = {
    numRemoved: number,
}

export const removeOrganizationMembers = (req: RemoveOrganizationMembersRequest) => rest("DELETE", "org-members2", req) as Promise<RemoveOrganizationMembersResponse>;

//

export type OrganizationMemberUpdate = {
    roles?: string[],
    joined?: boolean,
    added?: boolean,
}

export type UpdateOrganizationMembersRequest = {
    query: OrganizationMembersQuery,
    update: OrganizationMemberUpdate;
}

export type UpdateOrganizationMembersResponse = {
    numUpdated: number,
}

export const updateOrganizationMembers = (req: UpdateOrganizationMembersRequest) => rest("PATCH", "org-members2", req) as Promise<UpdateOrganizationMembersResponse>;

//

export type JoinOrganizationRequest = {
    id: Organization["id"],
}

export type JoinOrganizationResponse = void;

export const joinOrganization = (req: JoinOrganizationRequest) => rest("POST", "join-organization", req) as Promise<JoinOrganizationResponse>;

//

export type LeaveOrganizationRequest = {
    id: Organization["id"],
}

export type LeaveOrganizationResponse = void;

export const leaveOrganization = (req: LeaveOrganizationRequest) => rest("POST", "leave-organization", req) as Promise<LeaveOrganizationResponse>;

import React from "react";
import Typography from "@mui/material/Typography";
import { Alert, AlertTitle, Box, Button, Card, CardContent, CardMedia, Chip, Dialog, DialogContent, DialogTitle, IconButton, InputBase, LinearProgress, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, useMediaQuery, useTheme } from "@mui/material";
import { ProgramEvent } from "../../api";
import { title } from "process";
import { lineClamp } from "../tools";
import placeholderImage from "../../assets/images/cover_card_course.png";
import DesktopAccessDisabledOutlinedIcon from '@mui/icons-material/DesktopAccessDisabledOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined'
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';
import { renderHTML } from "../tools";
import ReadMoreText from "../ReadMoreText";
import NoEventsIllustration from '../../assets/svg/no-events.svg';


interface ProgramEventProps {
  events: ProgramEvent[]
}

interface NextProgramEventProps {
  event: ProgramEvent | null
}

interface v2ProgramEventProps {
  content: string,
  cover: string,
  createdAt: Date,
  date: Date,
  desc: string,
  id: string,
  name: string,
}

// const getFormattedDate = (date: Date) => {
//   return date.toLocaleDateString("en-US", {
//     year: "numeric",
//     month: "2-digit", // Use "2-digit" to get a zero-padded month (e.g., 09)
//     day: "2-digit",   // Use "2-digit" to get a zero-padded day (e.g., 23)
//   }).replace(/\//g, '-'); // Replace slashes with hyphens to get "23-09-2023"
// };
const getFormattedDate = (date: Date) => {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const sortEventsByDate = (events: ProgramEvent[]) => {
  return events.sort((a, b) => a.date.getTime() - b.date.getTime());
};

const getUpcomingEvents = (events: ProgramEvent[]) => {
  const currentDate = new Date();
  return events.filter((event) => event.date >= currentDate);
};

const NextEventCard: React.FC<NextProgramEventProps> = (props) => {

  const { event } = props;

  if (!event) {
    // Handle the case where event is null
    return <Box>
      <Alert severity="success" sx={{ mb: 2 }}>
        <AlertTitle>Congratulations!</AlertTitle>
        you have successfully completed all the program events! Further information will be provided by the Program Organizer.
      </Alert>
    </Box>
  }

  const { content, desc, date, id, name, cover, link } = event;

  return (
    <Box>
      <Typography variant="h3">Next Event</Typography>
      <br />
      {renderHTML(content)}
      {/* <ReadMoreText markdownContent={content} /> */}
      {/* <br /> */}
      <Stack flexDirection={"row"} alignItems={"center"} gap={1} mb={1.5} mt={4}>
        <Typography variant="h6">Program Events </Typography>
      </Stack>
    </Box>
  );
}

const EventsList: React.FC<NextProgramEventProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  if (!props.event) {
    // Handle the case where event is null
    return <Alert severity="info" sx={{ mb: 2 }}>
      <AlertTitle>No Events!</AlertTitle>
      No available event is found! Further information will be provided by the Program Organizer.
    </Alert>
  }

  const { content, desc, date, id, name, cover, link } = props.event;

  const currentDate = getFormattedDate(new Date());
  const eventDate = getFormattedDate(date);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card
        key={id}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "flex-start"
        }}
        elevation={1}
        onClick={handleClickOpen}
      >
        <CardMedia
          component="img"
          sx={{ width: ["100%", 160], height: "fit-content" }}
          image={cover?.url ?? placeholderImage}
          alt="Live from space album cover"
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1, // Allow the content to expand horizontally
            alignItems: "flex-start",
            p: 2
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography sx={{ ...lineClamp(2), fontSize: ["1.1em", null, "1.24em"], fontWeight: 600, color: "#4F4F4F", }}>{name}</Typography>
            {/* {
              currentDate > eventDate ?
                <Chip label="completed" /> :
                currentDate === eventDate ?
                <Chip label="Today!" />
                : <Chip icon={<EventIcon />} label={getFormattedDate(date)} />
            } */}
            {/* {
              date ? <Chip icon={<EventIcon />} label={getFormattedDate(date)} /> : <></>
            } */}
          </Box>
          <Box display="flex" justifyContent={["center", "flex-start", "flex-end"]}>
            <Typography>{desc}</Typography>
          </Box>
        </CardContent>
      </Card>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{name}</DialogTitle>
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {/* <Typography gutterBottom textAlign="justify">{desc}</Typography>
              <Typography sx={{ mb: 4 }}><b>Scheduled on:</b> {eventDate.toLocaleString()}</Typography> */}
          {renderHTML(content)}
        </DialogContent>;
      </Dialog>
    </>
  )
}


const UpcomingEventsList = ({ events }: { events: ProgramEvent[] }) => (
  <Box mt={2}>
    <Typography variant="h6">Upcoming Events</Typography>
    <Box component={"ul"} sx={{ display: "flex", flexWrap: "wrap", gap: 2, p: 0 }}>
      {events.map(({ name, date, id, desc }) => (

        <Card key={id}>
          <CardMedia
            component="img"
            // sx={{ width: [150, null, "100%"], height: "fit-content" }}
            // image={cover ? toImageURL(cover) : placeholderImage}
            height={140}
            image={placeholderImage}
            alt="Live from space album cover"
          />
          <CardContent>
            <Typography >{name}</Typography>
            <Typography variant="body2">{getFormattedDate(date)}</Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  </Box>
);

export default function ProgramEventContent(props: ProgramEventProps) {
  const { events } = props
  const sortedEvents = sortEventsByDate(events);
  const upcomingEvents = getUpcomingEvents(sortedEvents);
  const nextEvent = upcomingEvents.length > 0 ? upcomingEvents[0] : null;

  return (
    <Box>
      {
        events ?
          <>
            <NextEventCard event={nextEvent} />
            <Box display="flex" flexDirection="column" gap={2}>
              {events.map(event => <EventsList key={event.id} event={event} />)}
            </Box></>
          :
          <Box> <NoEventsIllustration /></Box>
      }


      {/* {upcomingEvents.length >= 1 && (
        <UpcomingEventsList events={upcomingEvents.slice(1)} />
      )} */}

    </Box>
  );
};


import { Box, useMediaQuery, useTheme, Typography, Link } from "@mui/material";
import Page, { PageContent } from "./Page";
import { useEffect, useState } from "react";
import { useSession } from "../ident";
import { listPrograms, getSolutions, getCourses, ListProgramsResponse, Program, Solution, SolutionsResponse, Course, CoursesResponse } from "../api";
import { MyProgramCard } from "./programs/MyProgramsPage";
import { SolutionCard } from "./solutions/SolutionsPage";
import LecturesCard from "./courses/LecturesCard";
import { ProgramCard } from "./programs/ProgramsPage";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from "react-router-dom";

export default function WaziLabHomePage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const session = useSession();
    const hasSession = session !== null;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [firstProgram, setFirstProgram] = useState<Program | null>(null);
    const [programs, setPrograms] = useState<Program[] | null>(null);
    const [courses, setCourses] = useState<Course[] | null>(null);
    const [solutions, setSolutions] = useState<Solution[] | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError("");

                const [firstProgramResponse, programsResponse, coursesResponse, solutionsResponse] = await Promise.all([
                    session ? listPrograms({ withMembership: true }) : null,
                    listPrograms({}),
                    getCourses({}),
                    getSolutions({})
                ]);

                if (session) setFirstProgram(firstProgramResponse!.programs[0]);
                else setFirstProgram(null);
                setPrograms(programsResponse.programs.slice(0, 2));
                setCourses(coursesResponse.courses.slice(0, 2));
                setSolutions(solutionsResponse.solutions.slice(0, 2));
            } catch (error) {
                setError("");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [session]);

    return (
        <Page title="Home">
            <PageContent sx={{ pt: 1 }}>
                {!isMobile && (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "start", gap: 0 }}>
                        <Typography variant="h1">Welcome to WaziLab</Typography>
                        <Typography variant="body2" color="text.secondary">Jump back into your learning, or start something new.</Typography>
                    </Box>
                )}

                {hasSession && firstProgram && (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "start", gap: 1, marginTop: [0, 4] }}>
                        <Typography variant="h2">Continue Learning</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: "100%", gap: 4 }}>
                            <MyProgramCard key={firstProgram.id} myProg={firstProgram} />
                        </Box>
                    </Box>
                )}

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: [2, undefined, 4], marginTop: 5 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", maxWidth: ["unset", 350], width: ["100%", "unset"] }}>
                        <Typography variant="h2">Programs increase IoT capabilities</Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ width: "100%", marginTop: 2 }}>Increase your IoT capabilities and boost the development of sustainable value-added services with the program.</Typography>
                        <Link underline="none" component="button" variant="body1" color="blue" sx={{ marginTop: 5 }} onClick={() => { navigate("/programs"); }}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 1 }}>See all<ArrowRightAltIcon fontSize="small" /></Box>
                        </Link>
                    </Box>
                    {programs && programs.map((program) => <ProgramCard key={program.id} myProg={program} noActionButton />)}
                </Box>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: [2, undefined, 4], marginTop: 10 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", maxWidth: ["unset", 350], width: ["100%", "unset"] }}>
                        <Typography variant="h2">Courses empower IoT knowledge</Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ width: "100%", marginTop: 2 }}>Learn about Embedded systems design and IoT using Waziup technologies.</Typography>
                        <Link underline="none" component="button" variant="body1" color="blue" sx={{ marginTop: 5 }} onClick={() => { navigate("/courses"); }}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 1 }}>See all<ArrowRightAltIcon fontSize="small" /></Box>
                        </Link>
                    </Box>
                    {courses && courses.map((course) => <LecturesCard key={course.id} course={course} noActionButton />)}
                </Box>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: [2, undefined, 4], marginTop: 10 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", maxWidth: ["unset", 350], width: ["100%", "unset"] }}>
                        <Typography variant="h2">Solutions encourage innovative ideas</Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ width: "100%", marginTop: 2 }}>Different IoT solutions that can be built with Waziup technologies after learning from the courses.</Typography>
                        <Link underline="none" component="button" variant="body1" color="blue" sx={{ marginTop: 5 }} onClick={() => { navigate("/solutions"); }}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 1 }}>See all<ArrowRightAltIcon fontSize="small" /></Box>
                        </Link>
                    </Box>
                    {solutions && solutions.map((solution) => <SolutionCard key={solution.id} solution={solution} />)}
                </Box>
            </PageContent>
        </Page>
    )
}

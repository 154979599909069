import { Delete, Edit } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import { ImageListItem, ImageListItemBar, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@mui/system/styled";
import React, { useEffect, useState } from "react";
import { deleteFile, File, FilesResponse, getFiles, OrganizationId, OrgItemId, patchFile, toThumbnailURL } from "../api";
import { useHub } from "../Hub";
import { formatDuration, formatSize, useForceUpdate } from "../tools";
import { askUpload, thumbnailSize } from "../upload";
import ErrorView from "./ErrorView";
import { useSession } from "../ident";

interface FilesListProps {
    multiple?: boolean,
    accept?: string,
    selection?: FilesSelection,
    selectable?: boolean,
    onFilesSelect?: (files: File[]) => void,
    org?: OrganizationId
}

export function useFilesSelection(multiple = false, defaultSelection = new Set<OrgItemId>()) {
    const handleChange = () => {
        setSelection(new FilesSelection(multiple, selection.files, handleChange));
    }
    const [selection, setSelection] = useState(new FilesSelection(multiple, new Set, handleChange));
    return selection;
}

class FilesSelection {
    constructor(
        readonly multiple: boolean,
        readonly files: Set<File>,
        private readonly onChange: () => void) {
    }

    toggle(file: File) {
        if (this.files.has(file)) this.files.delete(file);
        else if (this.multiple) this.files.add(file);
        else {
            this.files.clear();
            this.files.add(file);
        }
        this.onChange();
    }

    has(file: File) {
        return this.files.has(file);
    }

    get size() {
        return this.files.size;
    }
}


function FilesList(props: FilesListProps) {
    const {multiple, org, selection} = props;

    const [page, setPage] = useState<FilesResponse | null>(null);
    const [err, setErr] = useState<any>(null);

    function refresh() {
        getFiles({org}).then(setPage, setErr);
    }

    useHub(".upload-file", (file: File) => {
        if (file.org === org) {
            if(page) {
                page.files.unshift(file);
                page.numTotal++;
                setPage({...page});
            }
        }
    });

    const sess = useSession();
    useEffect(refresh, [sess, org]);

    // useUploadListener(refresh, []);

    const dense = !useMediaQuery('(min-width:600px)');

    function onFileDelete(file: File) {
        refresh();
    }

    function handleFileSelect(file: File) {
        selection!.toggle(file);
        // setSelection(selection => {
        //     const s = new Set(selection);
        //     if (s.has(file.id)) s.delete(file.id);
        //     else if (multiple) s.add(file.id);
        //     else {
        //         s.clear();
        //         s.add(file.id);
        //     }
        //     return s;
        // });
    }

    let content: JSX.Element;
    if (err) {
        content = <ErrorView err={err} />;
    } else if (page === null) {
        content = <CircularProgress />
    } else {
        let list: JSX.Element;
        if (dense) list = <MasonryFileList files={page.files} selection={selection} onFileDelete={onFileDelete} onFileSelect={handleFileSelect} {...props} />;
        else list = <FlowFilesList files={page.files} selection={selection} onFileDelete={onFileDelete} onFileSelect={handleFileSelect} {...props} />;

        content = <Box>
            <Typography variant="body2">{page.files.length} files</Typography>
            {list}
        </Box>
    }

    return content;
}


export default FilesList;

////////////////////////////////////////////////////////////////////////////////


interface FileListListProps extends FilesListProps {
    files: File[],
    selection?: FilesSelection,
    onFileSelect: (file: File) => void, 
    onFileDelete: (file: File) => void
}

function FlowFilesList(props: FileListListProps) {
    const {files, selection, onFileDelete, selectable, onFileSelect} = props;
    const handleUpload = () => {
        askUpload(props.org ?? null);
    }
    return <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {files.map(file => <FlowFile key={file.id} file={file} onFileDelete={() => onFileDelete(file)} selectable={selectable} selected={selection?.has(file)} onFileSelect={onFileSelect ? () => onFileSelect(file) : undefined} />)}
        <ButtonBase sx={{ width: 210, height: 90, borderRadius: 4, border: "3px dashed gray", margin: 1 }} onClick={handleUpload}>
            <Add />
            Upload file
        </ButtonBase>
    </Box>
}
const FlowFileImg = styled("img")({
    maxWidth: 420,
    height: 180,
    display: "block",
    objectFit: "scale-down",
    minWidth: 180,
})

interface FileItemProps {
    file: File,
    selected?: boolean,
    selectable?: boolean,
    onFileSelect?: () => void,
    onFileDelete: () => void,
}

function FlowFile(props: FileItemProps) {
    const {file, selected, onFileDelete, selectable, onFileSelect} = props;

    let [w, _] = thumbnailSize(file.width!, file.height!);
    w = Math.max(90, Math.min(w, 210));

    const theme = useTheme();
    var details: string[] = [formatSize(file.size)];
    if (file.width && file.height) details.push(`${file.width}⨯${file.height}`);
    if (file.duration) details.push(formatDuration(file.duration));
    if (file.pages) details.push(`${file.pages} pages`);

    //


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = anchorEl !== null;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if(selectable) onFileSelect!();
        else setAnchorEl(event.currentTarget.querySelector("img"));

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        deleteFile({id: file.id}).then(() => {
            handleClose();
            onFileDelete();
        });
    }

    const forceUpdate = useForceUpdate();

    const handleRename = async () => {
        const name = prompt("Insert new name:", file.name);
        if(name && name !== file.name) {
            patchFile({id: file.id, name}).then(() => {
                file.name = name;
                handleClose();
                forceUpdate();
            });
        }
    }

    //

    return <>
        <ImageListItem
            sx={{ p: 1, borderRadius: 1, cursor: "pointer", transition: theme.transitions.create(['background-color', 'opacity', 'transform']), "&:hover": { backgroundColor: "#def0ff" } }}
            onClick={handleClick}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            style={{
                opacity: (selected || open) ? 0.7 : undefined,
                transform: (selected || open) ? "scale(0.95)" : undefined,
                filter: open ? "grayscale(80%)" : undefined,
                backgroundColor: selected ? "#cfefff" : undefined,
                outline: selected ? "1px solid #00acff" : undefined
            }}
        >
            <FlowFileImg style={{ width: w, height: 180 }} src={toThumbnailURL(file.id)} loading="lazy" />
            <ImageListItemBar
                style={{ width: w }}
                title={file.name}
                subtitle={<span>{details.join(" • ")}</span>}
                position="below"
            />
        </ImageListItem>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ horizontal: "center", vertical: "center" }}
            transformOrigin={{ horizontal: "center", vertical: "center" }}
        >
            <MenuItem onClick={handleRename}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rename</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </Menu>
    </>
}

////////////////////////////////////////////////////////////////////////////////


function MasonryFileList(props: FileListListProps) {
    const {files, onFileDelete} = props;
    return <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {files.map(file => <MasonryFile key={file.id} file={file} onFileDelete={() => onFileDelete(file)} />)}
        {/* <ButtonBase sx={{width: 240, height: 180, borderRadius: 4, border: "3px dashed gray", margin: 1}} onClick={askUpload}>
            <Add />
            Upload file
        </ButtonBase> */}
    </Box>
}

function MasonryFile(props: FileItemProps) {
    const file = props.file;

    let [w, h] = thumbnailSize(file.width!, file.height!);
    // w = Math.max(180, Math.min(w, 420));

    // const theme = useTheme();
    const ww = window.innerWidth - 48;
    const rw = ww / 2 - 16;
    const rh = rw / w * h;

    return <Box sx={{ width: rw, p: 1 }}>
        <FlowFileImg style={{ width: rw, height: rh }} src={toThumbnailURL(props.file.id)} />
        <Box style={{ width: w, overflow: "hidden", textOverflow: "ellipsis", height: 32 }}>
            {file.name}
        </Box>
    </Box>
}